export const mdfItemSrnoDropdownApi = "/mdf-inventory/item-srno-dropdown";
export const mdfInwardSrnoDropdownApi = "/mdf-inventory/inward-srno-dropdown";

//Approval
export const fetchMdfApprovalApi = `/approval/mdf-approval-invoice-listing`;
export const fetchMdfItemsApprovalApi = function (id, invoice_id) {
  return `/approval/mdf-approval-item-listing-by-invoice/${id}/${invoice_id}`;
};
export const mdfApproveInvoiceApi = function (id, invoice_id) {
  return `/approval/mdf-approve_invoice_details/${id}/${invoice_id}`;
};
export const mdfRejectInvoiceApi = function (id, invoice_id) {
  return `/approval/mdf-reject_invoice_details/${id}/${invoice_id}`;
};
