export const veneerInventoryFilter = "veneerInventoryFilter";
export const faceInventoryFilter = "faceInventoryFilter";
export const coreInventoryFilter = "coreInventoryFilter";
export const logInventoryTabFilter = "logInventoryTabFilter";
export const logInventoryFilter = "logInventoryFilter";
export const logHistoryInventoryFilter = "logHistoryInventoryFilter";
export const flitchInventoryFilter = "flitchInventoryFilter";
export const flitchInventoryTabFilter = "flitchInventoryTabFilter";
export const plywoodInventoryTabFilter = "plywoodInventoryTabFilter";
export const veneerInventoryTabFilter = "veneerInventoryTabFilter";
export const mdfInventoryTabFilter = "mdfInventoryTabFilter";
export const faceInventoryTabFilter = "faceInventoryTabFilter";
export const coreInventoryTabFilter = "coreInventoryTabFilter";
export const fleeceInventoryTabFilter = "fleeceInventoryTabFilter";
export const otherGoodsInventoryTabFilter = "otherGoodsInventoryTabFilter";
export const fleeceInventoryFilter = "fleeceInventoryFilter";
export const otherGoodsInventoryFilter = "otherGoodsInventoryFilter";
export const mdfInventoryFilter = "mdfInventoryFilter";
export const plywoodInventoryFilter = "plywoodInventoryFilter";
//Master
//Expenses
//Log-expenses
export const logExpensesFilter = "logExpensesFilter";
export const flitchExpensesFilter = "flitchExpensesFilter";
export const faceExpensesFilter = "faceExpensesFilter";
export const coreExpensesFilter = "coreExpensesFilter";
export const plywoodExpensesFilter = "plywoodExpensesFilter";
export const veneerExpensesFilter = "veneerExpensesFilter";
export const mdfExpensesFilter = "mdfExpensesFilter";
export const fleeceExpensesFilter = "fleeceExpensesFilter";
export const otherGoodsExpensesFilter = "otherGoodsExpensesFilter";

//Factory
//crosscutting
export const factoryCrosscutTabFilter = "factoryCrosscutTabFilter";
export const issueForCrosscuttingFilter = "issueForCrosscuttingFilter";
export const crosscuttingDoneFilter = "crosscuttingDoneFilter";
//Flitching
export const factoryFlitchingTabFilter = "factoryFlitchingTabFilter";
export const issueForFlitchingFilter = "issueForFlitchingFilter";
export const rejectedCrosscuttingFilter = "rejectedCrosscuttingFilter";
export const flitchingDoneFilter = "flitchingDoneFilter";

//Approval
export const logApprovalFilter = "logApprovalFilter";
export const flitchApprovalFilter = "flitchApprovalFilter";
export const plywoodApprovalFilter = "plywoodApprovalFilter";
export const veneerApprovalFilter = "veneerApprovalFilter";
export const mdfApprovalFilter = "mdfApprovalFilter";
export const faceApprovalFilter = "faceApprovalFilter";
export const coreApprovalFilter = "coreApprovalFilter";
export const fleeceApprovalFilter = "fleeceApprovalFilter";
export const otherGoodsApprovalFilter = "otherGoodsApprovalFilter";
export const crosscuttingDoneApprovalFilter = "crosscuttingDoneApprovalFilter";
export const flitchingDoneApprovalFilter = "flitchingDoneApprovalFilter";
