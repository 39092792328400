import axios from "axios";

export const updateUserDetailsAdmin = async (details) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_URL}/user/update-user?id=${details.id}`,
      details,
      // { ...details, role_name: details.role_name.role_name },
      config
    );
    return data;
  } catch (error) {
    // console.log(data)
    return error?.response?.data;
  }
};

export const addUser = async (details) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_URL}/user/add-user`,
      details,
      // { ...details, role_name: details.role_name.role_name },
      config
    );
    // console.log(data);
    return data;
  } catch (error) {
    // console.log(error);
    return error.response;
  }
};

export const getUserForApprover = async (details) => {
  console.log(details, "details");
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(`${process.env.REACT_APP_URL}/user/list-approver-user`, details, config);
    // console.log(data);
    return data;
  } catch (error) {
    // console.log(error);
    return error.response;
  }
};

export const getRoleListForDept = async (details) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(`${process.env.REACT_APP_URL}/user/role-list-for-dept`, details, config);
    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};
