import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import { CompareValue } from "app/components/CompareValue";
import React from "react";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  width: "100%",
  borderRight: "1px solid #7352C7",
};

const ListingInvoiceItems = ({ invoiceItems = [], isApprovalPending }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h5">Items Details</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
              }}
            >
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Item Name</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Name</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Sub Category</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Department</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Machine</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Brand Name</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "180px" }}>Description</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Total Quantity</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Exchange Rate</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate In Currency</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate In Inr</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Amount</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Expense Amount</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceItems &&
              invoiceItems?.map((row, i) => (
                <TableRow key={i} sx={{ background: !row?.previous_data && isApprovalPending ? "rgb(255 0 0 / 15%) !important" : 'inherit' }}>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.item_sr_no}
                      new_data={row?.item_sr_no}
                    />
                    {/* {row?.item_sr_no} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.supplier_item_name}
                      new_data={row?.supplier_item_name}
                    />
                    {/* {row?.supplier_item_name} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.item_name}
                      new_data={row?.item_name}
                    />
                    {/* {row?.item_name} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.item_sub_category_name}
                      new_data={row?.item_sub_category_name}
                    />
                    {/* {row?.item_sub_category_name} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.department_name}
                      new_data={row?.department_name}
                    />
                    {/* {row?.department_name} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.machine_name}
                      new_data={row?.machine_name}
                    />
                    {/* {row?.machine_name} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.brand_name}
                      new_data={row?.brand_name}
                    />
                    {/* {row?.brand_name} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.item_description}
                      new_data={row?.item_description}
                    />
                    {/* {row?.item_description} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.total_quantity}
                      new_data={row?.total_quantity}
                    />
                    {/* {row?.total_quantity} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.exchange_rate}
                      new_data={row?.exchange_rate}
                    />
                    {/* {row?.exchange_rate} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.rate_in_currency}
                      new_data={row?.rate_in_currency}
                    />
                    {/* {row?.rate_in_currency} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.rate_in_inr}
                      new_data={row?.rate_in_inr}
                    />
                    {/* {row?.rate_in_inr} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending} previous_data={row?.previous_data?.amount} new_data={row?.amount} />
                    {/* {row?.amount} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.expense_amount || 0}
                      new_data={row?.expense_amount}
                    />
                    {/* {row?.expense_amount} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending} previous_data={row?.previous_data?.remark} new_data={row?.remark} />
                    {/* {row?.remark} */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ListingInvoiceItems;
