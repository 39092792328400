import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import ListingMdf from "./ListingMdf";
import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import React from "react";
import { mdfInventoryTabFilter } from "app/utils/constants/sessionFilter";
import MdfApproval from "./Approval";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const MdfInventoryList = () => {
  const userReducer = useSelector((state) => state.userReducer);
  const user = userReducer?.user?.[0];

  const [value, setValue] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem(mdfInventoryTabFilter, newValue);
  };

  React.useEffect(() => {
    let newValue = Number(sessionStorage.getItem(mdfInventoryTabFilter) || 0);
    setValue(newValue);
  }, []);
  return (
    // <Box sx={{mt:-4}}>
    //   <ListingMdf />
    // </Box>
    <Box sx={{ width: "100%", mt: -4 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Mdf Inventory" {...a11yProps(0)} />
          {user?.user_type === "ADMIN" && <Tab label="Mdf Approval" {...a11yProps(1)} />}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ListingMdf />
      </CustomTabPanel>
      {user?.user_type === "ADMIN" && (
        <CustomTabPanel value={value} index={1}>
          <MdfApproval />
        </CustomTabPanel>
      )}
    </Box>
  );
};

export default HeaderTitleHoc(MdfInventoryList, "MDF Inventory");
