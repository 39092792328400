import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  // width: "100%",
  borderRight: "1px solid #7352C7",
};

const IssuedLogTable = ({ actualData }) => {
  const { user_type } = useSelector((state) => state?.userReducer?.user?.[0]);

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
              }}
            >
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "100px" }}>
                Log Sr No.
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                Item Name
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                Item Subcategory
              </TableCell>

              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                Log No.
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                Log Formula
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                Actual Length
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                Avl Length
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                Physical Diameter
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                Actual CMT
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                Avl CMT
              </TableCell>
              {user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                  Total Amount
                </TableCell>
              )}
              {user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                  Avl Amount
                </TableCell>
              )}

              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                Remark
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell sx={{ ...tableBodyCellStyle }}>
                {actualData?.[0]?.issuedCrossCuttingDetails?.item_sr_no}
              </TableCell>
              <TableCell sx={{ ...tableBodyCellStyle }}>
                {actualData?.[0]?.issuedCrossCuttingDetails?.item_name}
              </TableCell>
              <TableCell sx={{ ...tableBodyCellStyle }}>
                {
                  actualData?.[0]?.issuedCrossCuttingDetails
                    ?.item_sub_category_name
                }
              </TableCell>
              <TableCell sx={{ ...tableBodyCellStyle }}>
                {actualData?.[0]?.issuedCrossCuttingDetails?.log_no}
              </TableCell>
              <TableCell sx={{ ...tableBodyCellStyle }}>
                {actualData?.[0]?.issuedCrossCuttingDetails?.log_formula}
              </TableCell>
              <TableCell sx={{ ...tableBodyCellStyle }}>
                {actualData?.[0]?.issuedCrossCuttingDetails?.physical_length}
              </TableCell>
              <TableCell sx={{ ...tableBodyCellStyle }}>
                {
                  actualData?.[0]?.issuedCrossCuttingDetails?.available_quantity
                    ?.physical_length
                }
              </TableCell>
              <TableCell sx={{ ...tableBodyCellStyle }}>
                {
                  actualData?.[0]?.issuedCrossCuttingDetails?.available_quantity
                    ?.physical_diameter
                }
              </TableCell>
              <TableCell sx={{ ...tableBodyCellStyle }}>
                {actualData?.[0]?.issuedCrossCuttingDetails?.physical_cmt}
              </TableCell>
              <TableCell sx={{ ...tableBodyCellStyle }}>
                {
                  actualData?.[0]?.issuedCrossCuttingDetails?.available_quantity
                    ?.physical_cmt
                }
              </TableCell>
              {user_type == "ADMIN" && <TableCell sx={{ ...tableBodyCellStyle }}>
                {actualData?.[0]?.issuedCrossCuttingDetails?.amount}
              </TableCell>}
              {user_type == "ADMIN" && <TableCell sx={{ ...tableBodyCellStyle }}>
                {
                  actualData?.[0]?.issuedCrossCuttingDetails?.available_quantity
                    ?.amount
                }
              </TableCell>}
              <TableCell sx={{ ...tableBodyCellStyle }}>
                {actualData?.[0]?.issuedCrossCuttingDetails?.remark || "-"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default IssuedLogTable;
