import Page from "@jumbo/shared/Page";
import FactoryCrosscut from "app/pages/Factory/CrossCut";
import CreateCrossCut from "app/pages/Factory/CrossCut/IssueForCrossCut/CreateCrossCut";
import CreateCutting from "app/pages/Factory/Cutting/Add";
import FactoryCuttingList from "app/pages/Factory/Cutting/List";
import CreateDying from "app/pages/Factory/Dying/Create";
import CreateGroupDying from "app/pages/Factory/Dying/Create/createGroupDying";
import FactoryDyingList from "app/pages/Factory/Dying/List";
import CreateFinishing from "app/pages/Factory/Finishing/Create";
import FactoryFinishingList from "app/pages/Factory/Finishing/List";
import FactoryPressingList from "app/pages/Factory/Pressing/List";
import CreateSmoking from "app/pages/Factory/Smoking/Create";
import CreateGroupSmoking from "app/pages/Factory/Smoking/Create/createGroupSmoking";
import FactorySmokingList from "app/pages/Factory/Smoking/List";
import CreateTapping from "app/pages/Factory/Tapping/Create";
import FactoryTappingList from "app/pages/Factory/Tapping/List";
import CuttingMiddleWare from "./middleware/auth/cuttingValidRoute";
import DyingMiddleWare from "./middleware/auth/dyingValidRoute";
import FinishingMiddleWare from "./middleware/auth/finishingValidRoute";
import PressingMiddleWare from "./middleware/auth/pressingValidRoute";
import SmokingMiddleWare from "./middleware/auth/smokingValidRoute";
import TappingMiddleWare from "./middleware/auth/tappingValidRoute";
import FactoryFlitching from "app/pages/Factory/Flitching";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";
import EditCrossCutDone from "app/pages/Factory/CrossCut/CrossCutDone/Edit";
import CreateFlitching from "app/pages/Factory/Flitching/CreateFlitching";
import EditFlitchingDone from "app/pages/Factory/Flitching/FlitchingDone/Edit";
import CrosscuttingApprovalDetails from "app/pages/Factory/CrossCut/CrossCutDone/Approval/ViewDetails";
import FlitchingApprovalDetails from "app/pages/Factory/Flitching/FlitchingDone/Approval/ViewDetails";

export const factoryRoutes = [
  {
    middleware: [
      {
        element: CuttingMiddleWare,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "factory/cutting",
        element: <Page component={FactoryCuttingList} layout={"vertical-default"} />,
      },
      {
        path: "factory/add-cutting",
        element: <Page component={CreateCutting} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: SmokingMiddleWare,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "factory/smoking",
        element: <Page component={FactorySmokingList} layout={"vertical-default"} />,
      },
      {
        path: "factory/smoking/create-smoking-individual",
        element: <Page component={CreateSmoking} layout={"vertical-default"} />,
      },
      {
        path: "factory/smoking/create-smoking-group",
        element: <Page component={CreateGroupSmoking} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: DyingMiddleWare,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "factory/dying",
        element: <Page component={FactoryDyingList} layout={"vertical-default"} />,
      },
      {
        path: "factory/dying/create-dying-individual",
        element: <Page component={CreateDying} layout={"vertical-default"} />,
      },
      {
        path: "factory/dying/create-dying-group",
        element: <Page component={CreateGroupDying} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: TappingMiddleWare,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "factory/tapping",
        element: <Page component={FactoryTappingList} layout={"vertical-default"} />,
      },
      {
        path: "factory/add-tapping",
        element: <Page component={CreateTapping} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: PressingMiddleWare,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "factory/pressing",
        element: <Page component={FactoryPressingList} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: FinishingMiddleWare,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "factory/finishing",
        element: <Page component={FactoryFinishingList} layout={"vertical-default"} />,
      },
      {
        path: "factory/add-finishing",
        element: <Page component={CreateFinishing} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "crosscut_factory", "view"],
      },
    ],
    routes: [
      {
        path: "factory/crosscut",
        element: <Page component={FactoryCrosscut} layout={"vertical-default"} />,
      },
      {
        path: "factory/add-crosscut",
        element: <Page component={CreateCrossCut} layout={"vertical-default"} />,
      },
      {
        path: "factory/edit-crosscut",
        element: <Page component={EditCrossCutDone} layout={"vertical-default"} />,
      },
      {
        path: "factory/crosscut-approval/view-details/:id/:issued_for_cutting_id",
        element: <Page component={CrosscuttingApprovalDetails} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "flitching_factory", "view"],
      },
    ],
    routes: [
      {
        path: "factory/flitching",
        element: <Page component={FactoryFlitching} layout={"vertical-default"} />,
      },
      {
        path: "factory/add-flitching",
        element: <Page component={CreateFlitching} layout={"vertical-default"} />,
      },
      {
        path: "factory/edit-flitching",
        element: <Page component={EditFlitchingDone} layout={"vertical-default"} />,
      },
      {
        path: "factory/flitching-approval/view-details/:id/:issue_for_flitching_id",
        element: <Page component={FlitchingApprovalDetails} layout={"vertical-default"} />,
      },
    ],
  },
];
