import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { rejectedCrosscuttingFilter } from "app/utils/constants/sessionFilter";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import RejectedCrosscuttingTableRow from "../Components/RejectedCrosscuttingTableRow";
import { fetchRejectedCrosscutting } from "app/redux/actions/Factory/Crosscutting/RejectedCrosscuting/rejectedCrosscutting";

const tableHeadCellSx = {
  textAlign: "left",
  minWidth: "100px",
  verticalAlign: "middle",
  px: 1,
  fontWeight: "400",
};

const tableHeadSortSx = {
  color: "white",
  "&:hover": { color: "white" },
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white !important",
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important", // Set the color for the sorting icon
  },
  "&:hover": {
    color: "white !important", // Set the color when hovering
  },
};

const tableRowHeadSticky = {
  textAlign: "center",
  minWidth: "60px",
  verticalAlign: "middle",
  color: "white",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "#7352C7",
};

const RejectedCrosscuttingTableListing = ({
  allFilterState,
  setAllFilterState,
}) => {
  const rejectedCrosscuttingReducer = useSelector(
    (state) => state?.rejectedCrosscuttingReducer
  );
  const userDetails = useSelector((state) => state?.userReducer?.user?.[0]);

  const dispatch = useDispatch();
  const sort = allFilterState?.sort;
  const sortBy = allFilterState?.sortBy;
  const page = allFilterState?.page;

  const handleSort = function (name) {
    const filter = {
      ...allFilterState,
      sort: allFilterState.sort === "desc" ? "asc" : "desc",
      sortBy: name,
      page: 1,
    };
    setAllFilterState(filter);
    dispatch(fetchRejectedCrosscutting(filter));
    sessionStorage.setItem(rejectedCrosscuttingFilter, JSON.stringify(filter));
  };

  const handleChangePage = (event, newPage) => {
    const filter = {
      ...allFilterState,
      page: newPage,
    };
    setAllFilterState(filter);
    dispatch(fetchRejectedCrosscutting(filter));
    sessionStorage.setItem(rejectedCrosscuttingFilter, JSON.stringify(filter));
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
                "& .MuiTableCell-root": {
                  // py: 2,
                },
              }}
            >
              {/* <TableCell
                sx={{
                  ...tableHeadCellSx,
                  color: "white",
                  minWidth: "50px",
                  textAlign: "center",
                }}
              >
                <Checkbox
                  {...label}
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                  checked={isSelectedAll()}
                  onChange={handleSelectAll}
                  size="small"
                />
              </TableCell> */}
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "log_invoice_details.inward_sr_no"}
                  direction={sort}
                  onClick={(e) =>
                    handleSort("log_invoice_details.inward_sr_no")
                  }
                  sx={{ ...tableHeadSortSx }}
                >
                  Inward Sr No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "log_invoice_details.inward_date"}
                  direction={sort}
                  onClick={(e) => handleSort("log_invoice_details.inward_date")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Inward Date
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={
                    sortBy === "log_invoice_details.invoice_Details.invoice_no"
                  }
                  direction={sort}
                  onClick={(e) =>
                    handleSort("log_invoice_details.invoice_Details.invoice_no")
                  }
                  sx={{ ...tableHeadSortSx }}
                >
                  Invoice No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "110px" }}>
                <TableSortLabel
                  active={sortBy === "item_sr_no"}
                  direction={sort}
                  onClick={(e) => handleSort("item_sr_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Log Sr No
                </TableSortLabel>
              </TableCell>
              {/* <TableCell sx={{ ...tableHeadCellSx, minWidth: "180px" }}>
                <TableSortLabel
                  active={
                    sortBy === "supplier_details.company_details.supplier_name"
                  }
                  direction={sort}
                  onClick={(e) =>
                    handleSort("supplier_details.company_details.supplier_name")
                  }
                  sx={{ ...tableHeadSortSx }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "180px" }}>
                <TableSortLabel
                  active={sortBy === "supplier_item_name"}
                  direction={sort}
                  onClick={(e) => handleSort("supplier_item_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Supplier Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>
                <TableSortLabel
                  active={sortBy === "supplier_log_no"}
                  direction={sort}
                  onClick={(e) => handleSort("supplier_log_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Supplier Log No
                </TableSortLabel>
              </TableCell> */}
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "170px" }}>
                <TableSortLabel
                  active={sortBy === "item_name"}
                  direction={sort}
                  onClick={(e) => handleSort("item_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "item_sub_category_name"}
                  direction={sort}
                  onClick={(e) => handleSort("item_sub_category_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Sub Category
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "125px" }}>
                <TableSortLabel
                  active={sortBy === "log_formula"}
                  direction={sort}
                  onClick={(e) => handleSort("log_formula")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Log Formula
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "log_no"}
                  direction={sort}
                  onClick={(e) => handleSort("log_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Log No
                </TableSortLabel>
              </TableCell>
              {/* <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "invoice_length"}
                  direction={sort}
                  onClick={(e) => handleSort("invoice_length")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Invoice Length
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "invoice_diameter"}
                  direction={sort}
                  onClick={(e) => handleSort("invoice_diameter")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Invoice Diameter
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                <TableSortLabel
                  active={sortBy === "invoice_cmt"}
                  direction={sort}
                  onClick={(e) => handleSort("invoice_cmt")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Invoice Cmt
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                <TableSortLabel
                  active={sortBy === "indian_cmt"}
                  direction={sort}
                  onClick={(e) => handleSort("indian_cmt")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Indian Cmt
                </TableSortLabel>
              </TableCell> */}
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>
                <TableSortLabel
                  active={sortBy === "physical_length"}
                  direction={sort}
                  onClick={(e) => handleSort("physical_length")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Physical Length
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "210px" }}>
                <TableSortLabel
                  active={sortBy === "available.physical_length"}
                  direction={sort}
                  onClick={(e) => handleSort("available.physical_length")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Rejected Physical Length
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "physical_diameter"}
                  direction={sort}
                  onClick={(e) => handleSort("physical_diameter")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Physical Diameter
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "physical_cmt"}
                  direction={sort}
                  onClick={(e) => handleSort("physical_cmt")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Physical Cmt
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "190px" }}>
                <TableSortLabel
                  active={sortBy === "available.physical_cmt"}
                  direction={sort}
                  onClick={(e) => handleSort("available.physical_cmt")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Rejected Physical Cmt
                </TableSortLabel>
              </TableCell>
              {/* {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                  <TableSortLabel
                    active={sortBy === "exchange_rate"}
                    direction={sort}
                    onClick={(e) => handleSort("exchange_rate")}
                    sx={{ ...tableHeadSortSx }}
                  >
                    Exchange Rate
                  </TableSortLabel>
                </TableCell>
              )} */}
              {/* {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                  <TableSortLabel
                    active={sortBy === "rate_in_currency"}
                    direction={sort}
                    onClick={(e) => handleSort("rate_in_currency")}
                    sx={{ ...tableHeadSortSx }}
                  >
                    Rate In Currency
                  </TableSortLabel>
                </TableCell>
              )} */}
              {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                  <TableSortLabel
                    active={sortBy === "rate_in_inr"}
                    direction={sort}
                    onClick={(e) => handleSort("rate_in_inr")}
                    sx={{ ...tableHeadSortSx }}
                  >
                    Rate In Inr
                  </TableSortLabel>
                </TableCell>
              )}
              {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx }}>
                  <TableSortLabel
                    active={sortBy === "amount"}
                    direction={sort}
                    onClick={(e) => handleSort("amount")}
                    sx={{ ...tableHeadSortSx }}
                  >
                    Amount
                  </TableSortLabel>
                </TableCell>
              )}
              {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx, minWidth: "170px" }}>
                  <TableSortLabel
                    active={sortBy === "rejected_quantity.amount"}
                    direction={sort}
                    onClick={(e) => handleSort("rejected_quantity.amount")}
                    sx={{ ...tableHeadSortSx }}
                  >
                    Rejected Amount
                  </TableSortLabel>
                </TableCell>
              )}
              {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx, minWidth: "170px" }}>
                  <TableSortLabel
                    active={sortBy === "rejected_quantity.expense_amount"}
                    direction={sort}
                    onClick={(e) =>
                      handleSort("rejected_quantity.expense_amount")
                    }
                    sx={{ ...tableHeadSortSx }}
                  >
                    Rejected Expense Amt
                  </TableSortLabel>
                </TableCell>
              )}
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "remark"}
                  direction={sort}
                  onClick={(e) => handleSort("remark")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Remark
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, ...tableRowHeadSticky }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rejectedCrosscuttingReducer?.data &&
            rejectedCrosscuttingReducer?.data?.length > 0 ? (
              rejectedCrosscuttingReducer?.data?.map((e, i) => {
                return (
                  <RejectedCrosscuttingTableRow
                    key={e?._id}
                    e={e}
                    allFilterState={allFilterState}
                  />
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={14}
                  sx={{ textAlign: "center", py: 2, fontSize: 18 }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          size="medium"
          count={rejectedCrosscuttingReducer?.totalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1,
          }}
        />
      </TableContainer>
    </>
  );
};

export default React.memo(RejectedCrosscuttingTableListing);
