
import {
  Autocomplete,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ClearIcon from "@mui/icons-material/Clear";
import { Form, Formik } from "formik";
import * as yup from "yup";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  width: "100%",
  borderRight: "1px solid #7352C7",
};

const initialData = {
  item_sr_no: 0,
  supplier_item_name: "",
  supplier_log_no: "",
  item_name: "",
  item_sub_category_name: "",
  item_sub_category_id: "",
  log_no: "",
  log_formula: "",
  invoice_length: 0,
  invoice_diameter: 0,
  invoice_cmt: 0,
  indian_cmt: 0,
  physical_length: 0,
  physical_diameter: 0,
  physical_cmt: 0,
  exchange_rate: 0,
  rate_in_currency: 0,
  rate_in_inr: 0,
  amount: 0,
  remark: "",
};

const EditItemDetailsTable = ({ setAddedItemDetailsList, addedItemDetailsList, itemSubCategoryNameList, itemNameList, finalData }) => {
  //   const itemNameList = [];
  const [data, setData] = useState(initialData);

  const removeRow = (value, index) => {
    // setLoader({ ...loader, removeProduct: true });
    // setTimeout(() => {
    const updateData = [...addedItemDetailsList];
    updateData.splice(index, 1);
    for (let i = 0; i < updateData.length; i++) {
      updateData[i].item_sr_no = i + 1;
    }
    setAddedItemDetailsList(updateData);
    // setLoader({ ...loader, removeProduct: false });
    // }, 200);
  };
  const updateRow = (values, index) => {
    // setLoader({ ...loader, removeProduct: true });
    // setTimeout(() => {
    const updateData = [...addedItemDetailsList];
    updateData.splice(index, 1, values);
    setAddedItemDetailsList(updateData);
    setData(initialData)
    // setLoader({ ...loader, removeProduct: false });
    // }, 200);
  };

  const validationSchema = yup.object({
    supplier_item_name: yup.string("Enter Supplier Name").required("Supplier Name is required"),
    supplier_log_no: yup.string("Enter Supplier Name").required("Supplier Name is required"),
    item_name: yup.string().required("Required"),
    item_sub_category_name: yup.string().required("Required"),
    log_no: yup.string().required("Required"),
    log_formula: yup.string().required("Required"),
    invoice_length: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    invoice_diameter: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    invoice_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    indian_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    physical_length: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    physical_diameter: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    physical_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    // exchange_rate: yup
    //   .number()
    //   .when((currency, schema) =>
    //     finalData?.currency?.currency_name !== "INR"
    //       ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.")
    //       : schema.notRequired()
    //   ),
    rate_in_currency: yup
      .number()
      .when((currency, schema) =>
        finalData?.currency?.currency_name !== "INR"
          ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.")
          : schema.notRequired()
      ),
    rate_in_inr: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    amount: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
  });

  const handleSubmit = async (values) => { };

  const handleCalculateInvoiceCMT = (invoiceLength, invoiceDia, formula, setFieldValue) => {
    let pai = 22 / 7;
    if (formula === "A") {
      let invoiceCMT = Number(((pai * invoiceDia * invoiceDia * invoiceLength) / 4)?.toFixed(3));
      let indianCMT = Number(((invoiceCMT * 27.74) / 35.336)?.toFixed(3));
      setFieldValue("invoice_cmt", invoiceCMT);
      setFieldValue("indian_cmt", indianCMT);
    }
    if (formula === "B") {
      let invoiceCMT = Number((((invoiceDia - 4) / 4) * ((invoiceDia - 4) / 4) * invoiceLength)?.toFixed(3));
      let indianCMT = Number(((invoiceLength * 0.305 * ((invoiceDia * 3.281) / 12) * 0.305 * (((invoiceDia * 3.281) / 12) * 0.305)) / 16)?.toFixed(2));
      setFieldValue("invoice_cmt", invoiceCMT);
      setFieldValue("indian_cmt", indianCMT);
    }
  };

  const handleCalculatePhysicalCMT = (physicalLength, physicalDia, formula, setFieldValue, values) => {
    if (formula === "A") {
      let physicalCMT = Number(((physicalLength * physicalDia * physicalDia) / 16)?.toFixed(3));
      setFieldValue("physical_cmt", physicalCMT);
      let amount = Number((Number(physicalCMT) * Number(values?.rate_in_inr))?.toFixed(2));
      setFieldValue("amount", Number(amount));
    }
    if (formula === "B") {
      let physicalCMT = Number(((physicalLength * physicalDia * physicalDia) / 16)?.toFixed(3));
      setFieldValue("physical_cmt", physicalCMT);
      let amount = Number((Number(physicalCMT) * Number(values?.rate_in_inr))?.toFixed(2));
      setFieldValue("amount", Number(amount));
    }
  };
  return (
    <div>
      <Formik
        validateOnChange={false}
        initialValues={data}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ validateForm, setFieldValue, values, setFieldTouched, errors, initialValues, handleBlur, touched }) => (
          <Form noValidate autoComplete="off">
            {console.log(errors)}
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow
                    sx={{
                      bgcolor: "#7352C7",
                      color: "white",
                      "& .MuiTableCell-root": {
                        // py: 2,
                      },
                    }}
                  >
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Item Name</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Log No.</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Name</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Sub Category</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log No.</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log Formula</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Length</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Diameter</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice CMT</TableCell>

                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Indian CMT</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Physical Length</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Physical Diameter</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Physical CMT</TableCell>
                    {/* {values?.currency !== "INR" ? ( */}
                    {/* {finalData?.currency?.currency_name !== "INR" ? (
                      <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Exchange Rate</TableCell>
                    ) : (
                      ""
                    )} */}
                    {/* {values?.currency !== "INR" ? ( */}
                    {finalData?.currency?.currency_name !== "INR" ? (
                      <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in Currency</TableCell>
                    ) : (
                      ""
                    )}
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in INR</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Amount</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                        position: "sticky",
                        right: 0,
                        zIndex: 1,
                        bgcolor: "#7352C7",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                {addedItemDetailsList && addedItemDetailsList.length ? (
                  <TableBody>
                    {addedItemDetailsList &&
                      addedItemDetailsList?.map((row, i) =>
                        values?.item_sr_no !== row?.item_sr_no ? (
                          <TableRow key={i}>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_sr_no}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.supplier_item_name}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.supplier_log_no}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_name}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_sub_category_name}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.log_no}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.log_formula}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.invoice_length}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.invoice_diameter}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.invoice_cmt}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.indian_cmt}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.physical_length}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.physical_diameter}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.physical_cmt}</TableCell>

                            {/* {finalData?.currency?.currency_name !== "INR" ? (
                              <TableCell sx={{ ...tableBodyCellStyle }}>{row.exchange_rate}</TableCell>
                            ) : (
                              ""
                            )} */}

                            {finalData?.currency?.currency_name !== "INR" ? (
                              <TableCell sx={{ ...tableBodyCellStyle }}>{row.rate_in_currency}</TableCell>
                            ) : (
                              ""
                            )}
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.rate_in_inr}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.amount}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.remark}</TableCell>
                            <TableCell
                              sx={{
                                // textAlign: "center",
                                minWidth: "100%",
                                verticalAlign: "middle",
                                color: "white",
                                px: 1,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                bgcolor: "white",
                                display: "flex",
                              }}
                            >
                              {/* <LoadingButton
                        //   loading={loader.removeProduct}
                        size="small"
                        variant="outlined"
                        color="warning"
                        onClick={() => removeRow(row, i)}
                        sx={{
                          padding:"0px"
                        }}
                      >
                        <EditIcon />
                      </LoadingButton> */}
                              {/* <LoadingButton
                        //   loading={loader.removeProduct}
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={() => removeRow(row, i)}
                        sx={{
                          padding:"0px"
                        }}
                      >
                        <DeleteIcon />
                      </LoadingButton> */}
                              <IconButton color="warning" size="small" onClick={() => setData(row)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton color="error" size="small" onClick={() => removeRow(row, i)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow
                            sx={{
                              background: "rgb(115 82 199 / 20%) !important",
                            }}
                          >
                            <TableCell sx={{ ...tableBodyCellStyle }}>{values?.item_sr_no}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.supplier_item_name}
                                sx={{ width: "100%" }}
                                name="supplier_item_name"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("supplier_item_name", e.target.value)}
                                error={touched?.supplier_item_name && errors?.supplier_item_name}
                                helperText={touched?.supplier_item_name && errors?.supplier_item_name}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.supplier_log_no}
                                sx={{ width: "100%" }}
                                name="supplier_log_no"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("supplier_log_no", e.target.value)}
                                error={touched?.supplier_log_no && errors?.supplier_log_no}
                                helperText={touched?.supplier_log_no && errors?.supplier_log_no}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                options={itemNameList}
                                name="item_name"
                                value={values?.item_name || null}
                                getOptionLabel={(option) => {
                                  if (option instanceof Object && !Array.isArray(option)) {
                                    return option.item_name;
                                  } else {
                                    return option;
                                  }
                                }}
                                onBlur={() => setFieldTouched("item_name", true)}
                                onChange={(e, selectedData) => {
                                  setFieldValue("item_name", selectedData?.item_name || "");
                                  setFieldValue("item_id", selectedData?._id || "");
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.item_name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.item_name && Boolean(errors.item_name)} // Show error if touched and there's an error
                                    helperText={touched.item_name && errors.item_name}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                // disabled={values?.supplier_details?.country === "India"}
                                options={itemSubCategoryNameList}
                                name="item_sub_category_name"
                                value={values?.item_sub_category_name || null}
                                getOptionLabel={(option) => {
                                  if (option instanceof Object && !Array.isArray(option)) {
                                    return option?.name;
                                  } else {
                                    return option;
                                  }
                                }}
                                onBlur={() => setFieldTouched("item_sub_category_name", true)}
                                onChange={(e, selectedData) => {
                                  // setFinalData({ ...finalData, item_sub_category_name: selectedData });
                                  setFieldValue("item_sub_category_name", selectedData?.name || "");
                                  setFieldValue("item_sub_category_id", selectedData?._id || "");
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.item_sub_category_name && Boolean(errors.item_sub_category_name)} // Show error if touched and there's an error
                                    helperText={touched.item_sub_category_name && errors.item_sub_category_name}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.log_no}
                                sx={{ width: "100%" }}
                                name="log_no"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  // setTimeout(() => {
                                  //   const isInvalid = e.target.getAttribute("aria-invalid");
                                  //   if (isInvalid === "false") {
                                  //     setInvoiceDetails({
                                  //       ...invoiceDetails,
                                  //       log_no: e.target.value,
                                  //     });
                                  //   }
                                  // }, 0);
                                }}
                                onChange={(e) => setFieldValue("log_no", e.target.value)}
                                error={touched?.log_no && errors?.log_no}
                                helperText={touched?.log_no && errors?.log_no}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                options={["A", "B"]}
                                name="log_formula"
                                value={values?.log_formula}
                                getOptionLabel={(option) => option}
                                onBlur={() => {
                                  setFieldTouched("log_formula", true);
                                }}
                                onChange={(e, selectedData) => {
                                  setFieldValue("log_formula", selectedData || "");
                                  if (selectedData === "A" || selectedData === "B") {
                                    handleCalculateInvoiceCMT(
                                      values?.invoice_length,
                                      values?.invoice_diameter,
                                      selectedData,
                                      setFieldValue
                                    );
                                    handleCalculatePhysicalCMT(
                                      values?.physical_length,
                                      values?.physical_diameter,
                                      selectedData,
                                      setFieldValue,
                                      values
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.log_formula && Boolean(errors.log_formula)} // Show error if touched and there's an error
                                    helperText={touched.log_formula && errors.log_formula}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.invoice_length}
                                sx={{ width: "100%" }}
                                name="invoice_length"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculateInvoiceCMT(e.target.value, values?.invoice_diameter, values?.log_formula, setFieldValue);
                                }}
                                onChange={(e) => setFieldValue("invoice_length", e.target.value)}
                                error={touched?.invoice_length && errors?.invoice_length}
                                helperText={touched?.invoice_length && errors?.invoice_length}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.invoice_diameter}
                                sx={{ width: "100%" }}
                                name="invoice_diameter"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculateInvoiceCMT(values?.invoice_length, e.target.value, values?.log_formula, setFieldValue);
                                }}
                                onChange={(e) => setFieldValue("invoice_diameter", e.target.value)}
                                error={touched?.invoice_diameter && errors?.invoice_diameter}
                                helperText={touched?.invoice_diameter && errors?.invoice_diameter}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                disabled
                                type="number"
                                size="small"
                                value={values?.invoice_cmt}
                                sx={{ width: "100%" }}
                                name="invoice_cmt"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("invoice_cmt", e.target.value)}
                                error={touched?.invoice_cmt && errors?.invoice_cmt}
                                helperText={touched?.invoice_cmt && errors?.invoice_cmt}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                disabled
                                size="small"
                                value={values?.indian_cmt}
                                sx={{ width: "100%" }}
                                name="indian_cmt"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("indian_cmt", e.target.value)}
                                error={touched?.indian_cmt && errors?.indian_cmt}
                                helperText={touched?.indian_cmt && errors?.indian_cmt}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.physical_length}
                                sx={{ width: "100%" }}
                                name="physical_length"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculatePhysicalCMT(
                                    e.target.value,
                                    values?.physical_diameter,
                                    values?.log_formula,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                onChange={(e) => setFieldValue("physical_length", e.target.value)}
                                error={touched?.physical_length && errors?.physical_length}
                                helperText={touched?.physical_length && errors?.physical_length}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.physical_diameter}
                                sx={{ width: "100%" }}
                                name="physical_diameter"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculatePhysicalCMT(
                                    values?.physical_length,
                                    e.target.value,
                                    values?.log_formula,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                onChange={(e) => setFieldValue("physical_diameter", e.target.value)}
                                error={touched?.physical_diameter && errors?.physical_diameter}
                                helperText={touched?.physical_diameter && errors?.physical_diameter}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                disabled
                                type="number"
                                size="small"
                                value={values?.physical_cmt}
                                sx={{ width: "100%" }}
                                name="physical_cmt"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("physical_cmt", e.target.value)}
                                error={touched?.physical_cmt && errors?.physical_cmt}
                                helperText={touched?.physical_cmt && errors?.physical_cmt}
                              />
                            </TableCell>

                            {finalData?.currency?.currency_name !== "INR" ? (
                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                <TextField
                                  type="number"
                                  size="small"
                                  value={values?.rate_in_currency}
                                  sx={{ width: "100%" }}
                                  name="rate_in_currency"
                                  onBlur={async (e) => {
                                    handleBlur(e);
                                    let rateInINR = Number((Number(e.target.value) * Number(values?.exchange_rate))?.toFixed(2));
                                    let amount = Number((Number(values.physical_cmt) * Number(rateInINR))?.toFixed(2));
                                    setFieldValue("rate_in_inr", Number(rateInINR));
                                    setFieldValue("amount", Number(amount));
                                  }}
                                  onChange={(e) => setFieldValue("rate_in_currency", e.target.value)}
                                  error={touched?.rate_in_currency && errors?.rate_in_currency}
                                  helperText={touched?.rate_in_currency && errors?.rate_in_currency}
                                />
                              </TableCell>
                            ) : (
                              ""
                            )}
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.rate_in_inr}
                                sx={{ width: "100%" }}
                                name="rate_in_inr"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  let amount = Number((Number(values.physical_cmt) * Number(e.target.value))?.toFixed(2));
                                  setFieldValue("amount", Number(amount));
                                }}
                                onChange={(e) => setFieldValue("rate_in_inr", e.target.value)}
                                error={touched?.rate_in_inr && errors?.rate_in_inr}
                                helperText={touched?.rate_in_inr && errors?.rate_in_inr}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.amount}
                                sx={{ width: "100%" }}
                                name="amount"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("amount", e.target.value)}
                                error={touched?.amount && errors?.amount}
                                helperText={touched?.amount && errors?.amount}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.remark}
                                sx={{ width: "100%" }}
                                name="remark"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("remark", e.target.value)}
                                error={touched?.remark && errors?.remark}
                                helperText={touched?.remark && errors?.remark}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                // textAlign: "center",
                                minWidth: "100%",
                                verticalAlign: "middle",
                                color: "white",
                                px: 1,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                bgcolor: "white",
                                display: "flex",
                              }}
                            >
                              <IconButton
                                color="success"
                                size="small"
                                onClick={async () => {
                                  console.log(values, "values");
                                  // Manually validate the form
                                  const formErrors = await validateForm();
                                  console.log("Validation errors:", formErrors); // Handle errors if needed
                                  // Check if there are no validation errors
                                  if (Object.keys(formErrors).length === 0) {
                                    updateRow(values, i); // Call updateRow with values and index
                                  } else {
                                    console.log("Validation errors:", formErrors); // Handle errors if needed
                                  }
                                }}
                              >
                                <TaskAltIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => {
                                  setData(initialData);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        Data Not Found !!
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditItemDetailsTable;
