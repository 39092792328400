
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FullScreenLoader from "app/components/ListingPageLoader";
import BranchAccordionItem from "./BranchAccordionItem";
import CreatePersonModal from "./CreatePersonModal";
import { getAllBranchesBySupplierId } from "app/redux/actions/masterAction";
import { Typography } from "@mui/material";

function BranchesAccordion({ page, rowsPerPage, sort, sortBy }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { supplierMasterBranches, TotalPage, loading } = useSelector(
    (state) => state.masterReducer
  );
  const [open, setOpen] = useState(false);
  const [currentBranchId, setCurrentBranchId] = useState(null);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    dispatch(
      getAllBranchesBySupplierId("", page, rowsPerPage, sortBy, sort, id)
    );
  }, [id, page, rowsPerPage, sort, sortBy, toggle]);

  const handleOpenModal = (branchId) => {
    setCurrentBranchId(branchId);
    console.log("bid in func => ", branchId);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div>
      {loading && <FullScreenLoader />}
      {supplierMasterBranches?.length > 0 ? (
        supplierMasterBranches?.map((branch) => (
          <BranchAccordionItem
            key={branch._id}
            branch={branch}
            handleOpenModal={handleOpenModal}
            page={page}
            rowsPerPage={rowsPerPage}
            TotalPage={TotalPage}
            setToggle={setToggle}
          />
        ))
      ) : (
        <Typography variant="h2" textAlign="center">
          No Branches Found...
        </Typography>
      )}
      <CreatePersonModal
        open={open}
        handleCloseModal={handleCloseModal}
        branchId={currentBranchId}
        setToggle={setToggle}
      />
    </div>
  );
}

export default BranchesAccordion;
