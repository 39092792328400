import Div from "@jumbo/shared/Div";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Autocomplete,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import { incrementLogCode } from "app/utils/constants/incrementLogCode";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  // width: "100%",
  borderRight: "1px solid #7352C7",
};

const EditItemDetailsTable = ({
  setAddedItemDetailsList,
  addedItemDetailsList,
  machineList,
  finalData,
  physicalLength,
  actualLogData,
  isCalculated,
  setIsCalculated,
  handleCalculate,
  isChecked,
  setFinalData,
  code,
}) => {
  const { user_type } = useSelector((state) => state?.userReducer?.user?.[0]);
  const initialData = {
    item_sr_no: 0,
    // item_name: "",
    // item_id: "",
    log_inventory_item_id: actualLogData?.issuedCrossCuttingDetails?.log_inventory_item_id,
    issue_for_crosscutting_id: actualLogData?.issue_for_crosscutting_id,
    factor: 0,
    machine_name: "",
    machine_id: "",
    log_no: "",
    code: "",
    log_no_code: "",
    length: 0,
    girth: 0,
    crosscut_cmt: 0,
    per_cmt_cost: 0,
    cost_amount: 0,
    required_hours: 0,
    remarks: "",
    // crosscut_date: getCurrentDate(),
    // workers: 0,
    // shift: "",
    // working_hours: 0,
    required_workers: 0,
    worker_details: {
      crosscut_date: getCurrentDate(),
      workers: 0,
      working_hours: 0,
      shift: "",
      no_of_total_hours: 0
    },
  };

  const validationSchema = yup.object({
    machine_name: yup.string().required("Machine name is Required"),
    // log_no_code: yup.string().required("Required"),
    // code: yup.string().required("Required"),
    // log_no_code: yup.string().required("Required"),
    length: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    girth: yup
      .number()
      .positive()
      .moreThan(0, "Must be greater than 0")
      .required("Required")
      .max(
        Number(actualLogData?.issuedCrossCuttingDetails?.available_quantity?.physical_diameter),
        `Can't be greater than ${actualLogData?.issuedCrossCuttingDetails?.available_quantity?.physical_diameter} `
      ),
    crosscut_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    worker_details: yup.object().shape({
      crosscut_date: yup.date().required("Crosscut Date is required"),
      workers: yup.number().positive().moreThan(0, "Must be greater than 0").required("Number of workers is required"),
      working_hours: yup.number().positive().moreThan(0, "Must be greater than 0").required("Working hours is required"),
      shift: yup.string().required("Shift is required."),
    }),
  });
  const [data, setData] = useState(initialData);
  const formula = actualLogData?.issuedCrossCuttingDetails?.log_formula;

  const handleSubmit = async (values) => { };

  //new method
  const removeRow = (value, index) => {
    const updateData = [...addedItemDetailsList];

    updateData.splice(index, 1);

    const updatedList = updateData.map((item, idx) => {
      const newSrNo = idx + 1;

      const { newCode, newLogCode } = incrementLogCode(
        newSrNo,
        actualLogData?.log_no
        // code,
      );

      return {
        ...item,
        item_sr_no: newSrNo,
        code: newCode,
        log_no_code: newLogCode,
      };
    });

    setAddedItemDetailsList(updatedList);
    setIsCalculated(false);
  };

  const updateRow = (values, index) => {
    const updateData = [...addedItemDetailsList];
    updateData.splice(index, 1, values);
    setAddedItemDetailsList(updateData);
    // handleCalculate();
    setData(null);
    setIsCalculated(false);
  };

  const handleCalculatePhysicalCMT = (
    physicalLength,
    physicalDia,
    // formula = "A",
    setFieldValue
  ) => {
    if (formula === "A") {
      let physicalCMT = Number(((Number(physicalLength) * Number(physicalDia) * Number(physicalDia)) / 16).toFixed(3));
      setFieldValue("crosscut_cmt", physicalCMT);
    }
    if (formula === "B") {
      let physicalCMT = Number(((Number(physicalLength) * Number(physicalDia) * Number(physicalDia)) / 16).toFixed(3));
      setFieldValue("crosscut_cmt", physicalCMT);
    }
  };
  const checkLength = (value, sr_no, setFieldValue) => {
    const filteredLength = addedItemDetailsList?.filter((item) => item?.item_sr_no !== sr_no);
    // const totalLength = addedItemDetailsList.reduce(
    //   (acc, item) => Number(acc) + Number(item?.length),
    //   0
    // );
    const totalLength = filteredLength?.reduce((acc, item) => Number(acc) + Number(item?.length), 0);



    if (totalLength + Number(value) > actualLogData?.issuedCrossCuttingDetails?.physical_length) {
      let availableLength = actualLogData?.issuedCrossCuttingDetails?.physical_length - totalLength;
      setFieldValue("length", 0);
      return Swal.fire({
        timer: 5000,
        icon: "error",
        text: `Item length should be smaller than or equals to available (${availableLength?.toFixed(2)}) length.`,
        position: "center",
        // background: theme.palette.background.paper,
      });
    }
  };

  const calculateRequiredWorkers = (hours, workers, setFieldValue) => {
    const totalRequiredHours = Number((Number(hours) * Number(workers)).toFixed(2));
    setFieldValue("required_hours", totalRequiredHours);
    setFieldValue("worker_details.no_of_total_hours", totalRequiredHours);
  };
  return (
    <div>
      <Formik
        validateOnChange={false}
        initialValues={data}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ validateForm, setFieldValue, values, setFieldTouched, errors, initialValues, handleBlur, touched }) => (
          <Form noValidate autoComplete="off">
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow
                    sx={{
                      bgcolor: "#7352C7",
                      color: "white",
                    }}
                  >
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Machine Name</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log No. Code</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Crosscut Date</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Shift</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Workers</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Working Hours</TableCell>
                    {/* <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Required Hours</TableCell> */}
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>No. of Total Hours</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Length</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Diameter</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Crosscut CMT</TableCell>
                    {user_type === "ADMIN" && (
                      <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Cost Amount</TableCell>
                    )}
                    {user_type === "ADMIN" && (
                      <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Per CMT Cost</TableCell>
                    )}

                    {/* <TableCell
                      sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                    >
                      Required Workers
                    </TableCell> */}
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                        position: "sticky",
                        right: 0,
                        zIndex: 1,
                        bgcolor: "#7352C7",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                {addedItemDetailsList && addedItemDetailsList?.length ? (
                  <TableBody>
                    {addedItemDetailsList &&
                      addedItemDetailsList?.map((row, i) =>
                        values?.item_sr_no !== row?.item_sr_no ? (
                          <TableRow key={i}>
                            <TableCell className="" sx={{ ...tableBodyCellStyle }}>
                              {row?.item_sr_no}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.machine_name}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.log_no_code}</TableCell>
                            {/* <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row.code}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row.log_no}
                            </TableCell> */}
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              {moment(row?.worker_details?.crosscut_date).format("DD/MM/YYYY")}
                              {/* {row?.worker_details?.crosscut_date ? new Date(row?.worker_details?.crosscut_date).toLocaleDateString() : new Date(actualLogData?.worker_details?.crosscut_date).toLocaleDateString()} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row?.worker_details?.shift ? row?.worker_details?.shift : row?.worker_details?.shift}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row?.worker_details?.workers ? row?.worker_details?.workers : row?.required_workers}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              {/* {row?.working_hours ? row?.working_hours : actualLogData?.worker_details?.working_hours} */}
                              {row?.working_hours
                                ? row?.working_hours
                                : Number((Number(row?.required_hours) / Number(row?.worker_details?.workers)).toFixed(2))}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.required_hours}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.length}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.girth}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.crosscut_cmt}</TableCell>
                            {user_type == "ADMIN" && (
                              <TableCell sx={{ ...tableBodyCellStyle }}>{row?.cost_amount}</TableCell>
                            )}
                            {user_type == "ADMIN" && (
                              <TableCell sx={{ ...tableBodyCellStyle }}>{row?.per_cmt_cost}</TableCell>
                            )}

                            {/* <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row?.required_workers}
                            </TableCell> */}
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.remarks || "-"}</TableCell>

                            <TableCell
                              sx={{
                                // textAlign: "center",
                                minWidth: "100%",
                                verticalAlign: "middle",
                                color: "white",
                                px: 1,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                bgcolor: "white",
                                display: "flex",
                              }}
                            >
                              <IconButton
                                color="warning"
                                size="small"
                                onClick={() =>
                                  setData({
                                    ...row,
                                    worker_details: {
                                      ...row.worker_details,
                                      working_hours: Number(
                                        (Number(row?.required_hours) / Number(row?.worker_details?.workers)).toFixed(2)
                                      ),
                                    },
                                  })
                                }
                              >
                                <EditIcon />
                              </IconButton>
                              {i === addedItemDetailsList.length - 1 && (
                                <IconButton color="error" size="small" onClick={() => removeRow(row, i)}>
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{values?.item_sr_no}</TableCell>

                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                options={machineList}
                                name="machine_name"
                                value={values?.machine_name}
                                isOptionEqualToValue={(option, value) => option.machine_name === value || option === value}
                                getOptionLabel={(option) => {
                                  if (option instanceof Object && !Array.isArray(option)) {
                                    return option.machine_name;
                                  } else {
                                    return option;
                                  }
                                }}
                                onBlur={() => setFieldTouched("machine_name", true)}
                                onChange={(e, selectedData) => {
                                  setFieldValue("machine_name", selectedData?.machine_name || "");
                                  setFieldValue("machine_id", selectedData?._id || "");
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.machine_name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.machine_name && Boolean(errors.machine_name)} // Show error if touched and there's an error
                                    helperText={touched.machine_name && errors.machine_name}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                disabled
                                value={values?.log_no_code}
                                sx={{ width: "100%" }}
                                name="log_no"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("log_no", e.target.value)}
                                error={touched?.log_no && errors?.log_no}
                                helperText={touched?.log_no && errors?.log_no}
                              />
                            </TableCell>

                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  sx={{
                                    width: "100%",
                                    "& .MuiInputBase-input": {
                                      padding: 1,
                                    },
                                  }}
                                  format="DD-MM-YYYY"
                                  maxDate={dayjs()}
                                  // value={values?.worker_details?.crosscut_date}
                                  defaultValue={
                                    values?.worker_details?.crosscut_date !== null
                                      ? dayjs(values?.worker_details?.crosscut_date)
                                      : row?.worker_details?.crosscut_date
                                  }
                                  onChange={(newValue) => {
                                    // setFinalData({
                                    //   ...finalData,
                                    //   crosscut_date: moment(newValue).format(),
                                    // });
                                    // const formatedDate = newValue?.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                                    // setFieldValue(
                                    //   "worker_details.crosscut_date",
                                    //   newValue
                                    //     .startOf("day")
                                    //     .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                                    // );
                                    setFieldValue("worker_details.crosscut_date", new Date(newValue));
                                  }}
                                />
                                <Div sx={{ height: "5px" }}>
                                  <ErrorMessage name="worker_details.crosscut_date" component="div" style={{ color: "red" }} />
                                </Div>
                              </LocalizationProvider>
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                sx={{ width: "100%" }}
                                size="small"
                                name="worker_details.shift"
                                onChange={(e) => {
                                  setFieldValue("worker_details.shift", e.target.value);
                                }}
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  // setFinalData({
                                  //   ...finalData,
                                  //   shift: e.target.value,
                                  // });
                                }}
                                defaultValue={row?.worker_details?.shift}
                                value={values?.shift}
                                status={true}
                                error={touched?.worker_details?.shift && errors?.worker_details?.shift}
                                helperText={touched?.worker_details?.shift && errors?.worker_details?.shift}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                sx={{ width: "100%" }}
                                size="small"
                                type="number"
                                name="worker_details.workers"
                                onBlur={(e) => {
                                  handleBlur(e);
                                  // setFinalData({
                                  //   ...finalData,
                                  //   no_of_workers: e.target.value,
                                  // });
                                  calculateRequiredWorkers(values?.worker_details?.working_hours, e.target.value, setFieldValue);
                                }}
                                onChange={(e) => {
                                  setFieldValue("worker_details.workers", e.target.value);
                                  setFieldValue("required_workers", e.target.value);
                                }}
                                defaultValue={row?.worker_details?.workers}
                                value={values?.worker_details?.workers} //chnages to required workers from no_of-workers
                                status={true}
                                error={touched?.worker_details?.workers && errors?.worker_details?.workers}
                                helperText={touched?.worker_details?.workers && errors?.worker_details?.workers}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                sx={{ width: "100%" }}
                                size="small"
                                name="worker_details.working_hours"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  // setFinalData({
                                  //   ...finalData,
                                  //   working_hours: e.target.value,
                                  // });
                                  calculateRequiredWorkers(e.target.value, values?.worker_details?.workers, setFieldValue);
                                }}
                                onChange={(e) => {
                                  setFieldValue("worker_details.working_hours", e.target.value);
                                }}
                                defaultValue={Number((Number(row?.required_hours) / Number(row?.worker_details?.workers)).toFixed(2))}
                                value={values?.worker_details?.working_hours}
                                status={true}
                                error={touched?.worker_details?.working_hours && errors?.worker_details?.working_hours}
                                helperText={touched?.worker_details?.working_hours && errors?.worker_details?.working_hours}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                disabled
                                defaultValue={row?.required_hours}
                                value={values?.required_hours}
                                sx={{ width: "100%" }}
                                name="required_hours"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  // handleCalculatePhysicalCMT(
                                  //   e.target.value,
                                  //   values?.physical_diameter,
                                  //   values?.log_formula,
                                  //   setFieldValue
                                  // );
                                }}
                                onChange={(e) => setFieldValue("required_hours", e.target.value)}
                                error={touched?.required_hours && errors?.required_hours}
                                helperText={touched?.required_hours && errors?.required_hours}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // disabled
                                type="number"
                                size="small"
                                value={values?.length}
                                sx={{ width: "100%" }}
                                name="length"
                                onBlur={(e) => {
                                  handleBlur(e);

                                  handleCalculatePhysicalCMT(
                                    e.target.value,
                                    values?.girth,
                                    // values?.log_formula,
                                    setFieldValue
                                  );
                                  checkLength(e.target.value, values?.item_sr_no, setFieldValue);
                                  setIsCalculated(false);
                                }}
                                onChange={(e) => setFieldValue("length", e.target.value)}
                                error={touched?.length && errors?.length}
                                helperText={touched?.length && errors?.length}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                disabled
                                size="small"
                                value={values?.girth}
                                sx={{ width: "100%" }}
                                name="girth"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculatePhysicalCMT(
                                    values?.length,
                                    e.target.value,
                                    // values?.log_formula,
                                    setFieldValue
                                  );
                                  setIsCalculated(false);
                                }}
                                onChange={(e) => setFieldValue("girth", e.target.value)}
                                error={touched?.girth && errors?.girth}
                                helperText={touched?.girth && errors?.girth}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                disabled
                                type="number"
                                size="small"
                                value={values?.crosscut_cmt}
                                sx={{ width: "100%" }}
                                name="crosscut_cmt"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  // handleCalculatePhysicalCMT(
                                  //   e.target.value,
                                  //   values?.physical_diameter,
                                  //   values?.log_formula,
                                  //   setFieldValue
                                  // );
                                }}
                                onChange={(e) => setFieldValue("crosscut_cmt", e.target.value)}
                                error={touched?.crosscut_cmt && errors?.crosscut_cmt}
                                helperText={touched?.crosscut_cmt && errors?.crosscut_cmt}
                              />
                            </TableCell>
                            {user_type == "ADMIN" && (
                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                <TextField
                                  disabled
                                  type="number"
                                  size="small"
                                  value={values?.cost_amount}
                                  sx={{ width: "100%" }}
                                  name="cost_amount"
                                  onBlur={async (e) => {
                                    handleBlur(e);
                                  }}
                                  onChange={(e) => setFieldValue("cost_amount", e.target.value)}
                                  error={touched?.cost_amount && errors?.cost_amount}
                                  helperText={touched?.cost_amount && errors?.cost_amount}
                                />
                              </TableCell>
                            )}
                            {user_type == "ADMIN" && (
                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                <TextField
                                  disabled
                                  type="number"
                                  size="small"
                                  value={values?.per_cmt_cost}
                                  sx={{ width: "100%" }}
                                  name="per_cmt_cost"
                                  onBlur={async (e) => {
                                    handleBlur(e);
                                  }}
                                  onChange={(e) => setFieldValue("per_cmt_cost", e.target.value)}
                                  error={touched?.per_cmt_cost && errors?.per_cmt_cost}
                                  helperText={touched?.per_cmt_cost && errors?.per_cmt_cost}
                                />
                              </TableCell>
                            )}

                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.remarks}
                                sx={{ width: "100%" }}
                                name="remarks"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("remarks", e.target.value)}
                                error={touched?.remarks && errors?.remarks}
                                helperText={touched?.remarks && errors?.remarks}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                // textAlign: "center",
                                minWidth: "100%",
                                verticalAlign: "middle",
                                color: "white",
                                px: 1,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                bgcolor: "white",
                                display: "flex",
                              }}
                            >
                              <IconButton
                                color="success"
                                size="small"
                                onClick={async () => {
                                  // Manually validate the form
                                  const formErrors = await validateForm();
                                  console.log("Validation errors:", formErrors); // Handle errors if needed
                                  // Check if there are no validation errors
                                  if (Object.keys(formErrors).length === 0) {
                                    updateRow(values, i); // Call updateRow with values and index
                                  } else {
                                    console.log("Validation errors:", formErrors); // Handle errors if needed
                                  }
                                }}
                              >
                                <TaskAltIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => {
                                  setData(initialData);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        Data Not Found !!
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditItemDetailsTable;
