import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import AddItemModal from "./addNewItemTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ClearIcon from "@mui/icons-material/Clear";
import { Form, Formik } from "formik";
import * as yup from "yup";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  width: "100%",
  borderRight: "1px solid #7352C7",
};

const initialData = {
  item_sr_no: 0,
  supplier_item_name: "",
  supplier_code: "",
  item_name: "",
  item_id: "",
  item_sub_category_name: "",
  item_sub_category_id: "",
  log_code: "",
  bundle_number: 0,
  pallet_number: "",
  length: 0,
  width: 0,
  thickness: 0,
  number_of_leaves: 0,
  total_sq_meter: 0,
  cut_id: "",
  cut_name: "",
  series_id: "",
  series_name: "",
  grades_id: "",
  grades_name: "",
  exchange_rate: 0,
  rate_in_currency: 0,
  rate_in_inr: 0,
  amount: 0,
  remark: "",
};

const EditItemDetailsTable = ({
  setAddedItemDetailsList,
  addedItemDetailsList,
  itemSubCategoryNameList,
  itemNameList,
  finalData,
  itemDetailsListTableLoading,
  cutNameList,
  seriesNameList,
  gradeNameList,
}) => {
  const [data, setData] = useState(initialData);
  //   const itemNameList = [];
  const removeRow = (value, index) => {
    // setLoader({ ...loader, removeProduct: true });
    // setTimeout(() => {
    const updateData = [...addedItemDetailsList];
    updateData.splice(index, 1);
    for (let i = 0; i < updateData.length; i++) {
      updateData[i].item_sr_no = i + 1;
    }
    setAddedItemDetailsList(updateData);
    // setLoader({ ...loader, removeProduct: false });
    // }, 200);
  };
  const updateRow = (values, index) => {
    // setLoader({ ...loader, removeProduct: true });
    // setTimeout(() => {
    const updateData = [...addedItemDetailsList];
    updateData.splice(index, 1, values);
    setAddedItemDetailsList(updateData);
    setData(initialData)
    // setLoader({ ...loader, removeProduct: false });
    // }, 200);
  };

  const validationSchema = yup.object({
    supplier_item_name: yup.string("Enter Supplier Name").required("Supplier Name is required"),
    supplier_code: yup.string("Enter Supplier Name").required("Supplier Name is required"),
    item_name: yup.string().required("Required"),
    item_sub_category_name: yup.string().required("Required"),
    log_code: yup.string("Log code must be a string").required("Log code is required"),
    bundle_number: yup.number("Bundle Number must be a number").required("Bundle number is required"),
    pallet_number: yup.string("Pallet number must be unique").required("Pallet number is required"),
    length: yup.number("Length must be a number").positive("Length must be greater than 0").required("Length is required"),
    width: yup.number("Width must be a number").positive("Width must be greater than 0").required("Width is required"),
    thickness: yup.number("Thickness must be a number").positive("Thickness must be greater than 0").required("Thickness is required"),
    number_of_leaves: yup
      .number("Number of leaves must be a number")
      .positive("Number of leaves must be greater than 0")
      .required("Number of leaves is required"),
    total_sq_meter: yup
      .number("Total square meter must be a number")
      .positive("Total square meter must be greater than 0")
      .required("Total square meter is required"),
    cut_id: yup.string("Cut ID should be a valid ObjectId").required("Cut ID is required"),
    cut_name: yup.string("Cut Name must be a string").required("Cut name is required"),
    series_id: yup.string("Series ID should be a valid ObjectId").required("Series ID is required"),
    series_name: yup.string("Series Name must be a string").required("Series name is required"),
    grades_id: yup.string("Grades ID should be a valid ObjectId").required("Grades ID is required"),
    grades_name: yup.string("Grades Name must be a string").required("Grades name is required"),
    // exchange_rate: yup
    //   .number()
    //   // .positive()
    //   // .moreThan(0, "Must be greater than 0")
    //   // .required("Required")
    //   .when((currency, schema) =>
    //     finalData?.currency !== "INR" ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.") : schema.notRequired()
    //   ),
    rate_in_currency: yup
      .number()
      .when((currency, schema) =>
        finalData?.currency !== "INR" ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.") : schema.notRequired()
      ),
    rate_in_inr: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    amount: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
  });

  const handleSubmit = async (values) => { };

  const handleCalculateTotalSqm = (Length, width, number_of_leaves, setFieldValue, values) => {
    let totalSqm = Number((Number(Length) * Number(width) * Number(number_of_leaves))?.toFixed(3));
    setFieldValue("total_sq_meter", Number(totalSqm));
    let amount = Number((Number(totalSqm) * Number(values?.rate_in_inr))?.toFixed(2));
    setFieldValue("amount", Number(amount));
  };

  return (
    <div>
      <Formik
        validateOnChange={false}
        initialValues={data}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ validateForm, setFieldValue, values, setFieldTouched, errors, initialValues, handleBlur, touched }) => (
          <Form noValidate autoComplete="off">
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow
                    sx={{
                      bgcolor: "#7352C7",
                      color: "white",
                    }}
                  >
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Item Name</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Code</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Name</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Sub Category</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log Code</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Bundle Number</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Pallet Number</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Length</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Thickness</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>No Of Leaves</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Total Sq Meter</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Cut Name</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Series Name</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Grades Name</TableCell>
                    {/* {finalData?.currency !== "INR" ? (
                      <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Exchange Rate</TableCell>
                    ) : (
                      ""
                    )} */}
                    {finalData?.currency !== "INR" ? (
                      <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in Currency</TableCell>
                    ) : (
                      ""
                    )}
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in INR</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Amount</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                        position: "sticky",
                        right: 0,
                        zIndex: 1,
                        bgcolor: "#7352C7",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                {addedItemDetailsList && addedItemDetailsList.length ? (
                  <TableBody>
                    {addedItemDetailsList &&
                      addedItemDetailsList?.map((row, i) =>
                        values?.item_sr_no !== row?.item_sr_no ? (
                          <TableRow key={i}>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_sr_no}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.supplier_item_name}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.supplier_code}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_name}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_sub_category_name}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.log_code}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.bundle_number}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.pallet_number}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.length}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.width}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.thickness}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.number_of_leaves}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.total_sq_meter}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.cut_name}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.series_name}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.grades_name}</TableCell>

                            {/* {finalData?.currency !== "INR" ? <TableCell sx={{ ...tableBodyCellStyle }}>{row.exchange_rate}</TableCell> : ""} */}

                            {finalData?.currency !== "INR" ? (
                              <TableCell sx={{ ...tableBodyCellStyle }}>{row.rate_in_currency}</TableCell>
                            ) : (
                              ""
                            )}
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.rate_in_inr}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.amount}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.remark}</TableCell>
                            <TableCell
                              sx={{
                                // textAlign: "center",
                                minWidth: "100%",
                                verticalAlign: "middle",
                                color: "white",
                                px: 1,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                bgcolor: "white",
                                display: "flex",
                              }}
                            >
                              <IconButton color="warning" size="small" onClick={() => setData(row)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton color="error" size="small" onClick={() => removeRow(row, i)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow sx={{ background: "rgb(115 82 199 / 20%) !important" }}>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{values?.item_sr_no}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.supplier_item_name}
                                sx={{ width: "100%" }}
                                name="supplier_item_name"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("supplier_item_name", e.target.value)}
                                error={touched?.supplier_item_name && errors?.supplier_item_name}
                                helperText={touched?.supplier_item_name && errors?.supplier_item_name}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.supplier_code}
                                sx={{ width: "100%" }}
                                name="supplier_code"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("supplier_code", e.target.value)}
                                error={touched?.supplier_code && errors?.supplier_code}
                                helperText={touched?.supplier_code && errors?.supplier_code}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                options={itemNameList}
                                name="item_name"
                                value={values?.item_name}
                                getOptionLabel={(option) => {
                                  if (option instanceof Object && !Array.isArray(option)) {
                                    return option.item_name;
                                  } else {
                                    return option;
                                  }
                                }}
                                onBlur={() => setFieldTouched("item_name", true)}
                                onChange={(e, selectedData) => {
                                  setFieldValue("item_name", selectedData?.item_name || "");
                                  setFieldValue("item_id", selectedData?._id || "");
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.item_name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.item_name && Boolean(errors.item_name)} // Show error if touched and there's an error
                                    helperText={touched.item_name && errors.item_name}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                // disabled={values?.supplier_details?.country === "India"}
                                options={itemSubCategoryNameList}
                                name="item_sub_category_name"
                                value={values?.item_sub_category_name}
                                getOptionLabel={(option) => {
                                  if (option instanceof Object && !Array.isArray(option)) {
                                    return option?.name;
                                  } else {
                                    return option;
                                  }
                                }}
                                onBlur={() => setFieldTouched("item_sub_category_name", true)}
                                onChange={(e, selectedData) => {
                                  // setFinalData({ ...finalData, item_sub_category_name: selectedData });
                                  setFieldValue("item_sub_category_name", selectedData?.name || "");
                                  setFieldValue("item_sub_category_id", selectedData?._id || "");
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.item_sub_category_name && Boolean(errors.item_sub_category_name)} // Show error if touched and there's an error
                                    helperText={touched.item_sub_category_name && errors.item_sub_category_name}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.log_code}
                                sx={{ width: "100%" }}
                                name="log_code"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("log_code", e.target.value)}
                                error={touched?.log_code && errors?.log_code}
                                helperText={touched?.log_code && errors?.log_code}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.bundle_number}
                                sx={{ width: "100%" }}
                                name="bundle_number"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("bundle_number", e.target.value)}
                                error={touched?.bundle_number && errors?.bundle_number}
                                helperText={touched?.bundle_number && errors?.bundle_number}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                size="small"
                                value={values?.pallet_number}
                                sx={{ width: "100%" }}
                                name="pallet_number"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("pallet_number", e.target.value)}
                                error={touched?.pallet_number && errors?.pallet_number}
                                helperText={touched?.pallet_number && errors?.pallet_number}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.length}
                                sx={{ width: "100%" }}
                                name="length"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  // const totalSqMeter = e.target.value * values.width * values.thickness * values.number_of_leaves
                                  // const totalSqMeter = e.target.value * values.width * values.number_of_leaves;
                                  // setFieldValue("total_sq_meter", totalSqMeter);
                                  handleCalculateTotalSqm(
                                    e.target.value,
                                    values.width,
                                    values.number_of_leaves,
                                    setFieldValue,
                                    values
                                  )
                                }}
                                onChange={(e) => setFieldValue("length", e.target.value)}
                                error={touched?.length && errors?.length}
                                helperText={touched?.length && errors?.length}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.width}
                                sx={{ width: "100%" }}
                                name="width"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  // const totalSqMeter = e.target.value * values.length * values.thickness * values.number_of_leaves
                                  // const totalSqMeter = e.target.value * values.length * values.number_of_leaves;
                                  // setFieldValue("total_sq_meter", totalSqMeter);
                                  handleCalculateTotalSqm(
                                    values.length,
                                    e.target.value,
                                    values.number_of_leaves,
                                    setFieldValue,
                                    values
                                  )
                                }}
                                onChange={(e) => setFieldValue("width", e.target.value)}
                                error={touched?.width && errors?.width}
                                helperText={touched?.width && errors?.width}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.thickness}
                                sx={{ width: "100%" }}
                                name="thickness"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  // const totalSqMeter = e.target.value * values.width * values.length * values.number_of_leaves
                                  // setFieldValue("total_sq_meter", totalSqMeter)
                                }}
                                onChange={(e) => setFieldValue("thickness", e.target.value)}
                                error={touched?.thickness && errors?.thickness}
                                helperText={touched?.thickness && errors?.thickness}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.number_of_leaves}
                                sx={{ width: "100%" }}
                                name="number_of_leaves"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  // const totalSqMeter = e.target.value * values.width * values.thickness * values.length
                                  // const totalSqMeter = e.target.value * values.width * values.length;
                                  // setFieldValue("total_sq_meter", totalSqMeter);
                                  handleCalculateTotalSqm(
                                    values.length,
                                    values.width,
                                    e.target.value,
                                    setFieldValue,
                                    values
                                  )
                                }}
                                onChange={(e) => setFieldValue("number_of_leaves", e.target.value)}
                                error={touched?.number_of_leaves && errors?.number_of_leaves}
                                helperText={touched?.number_of_leaves && errors?.number_of_leaves}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                disabled
                                value={values?.total_sq_meter}
                                sx={{ width: "100%" }}
                                name="total_sq_meter"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                // onChange={(e) => setFieldValue("total_sq_meter", e.target.value)}
                                error={touched?.total_sq_meter && errors?.total_sq_meter}
                                helperText={touched?.total_sq_meter && errors?.total_sq_meter}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                // disabled={values?.supplier_details?.country === "India"}
                                options={cutNameList}
                                name="cut_name"
                                value={values?.cut_name || null}
                                getOptionLabel={(option) => {
                                  if (option instanceof Object && !Array.isArray(option)) {
                                    return option?.cut_name;
                                  } else {
                                    return option;
                                  }
                                }}
                                onBlur={() => setFieldTouched("cut_name", true)}
                                onChange={(e, selectedData) => {
                                  setFieldValue("cut_id", selectedData?._id || "");
                                  setFieldValue("cut_name", selectedData?.cut_name || "");
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.cut_name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.cut_name && Boolean(errors.cut_name)} // Show error if touched and there's an error
                                    helperText={touched.cut_name && errors.cut_name}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                // disabled={values?.supplier_details?.country === "India"}
                                options={seriesNameList}
                                name="series_name"
                                value={values?.series_name || null}
                                getOptionLabel={(option) => {
                                  if (option instanceof Object && !Array.isArray(option)) {
                                    return option?.series_name;
                                  } else {
                                    return option;
                                  }
                                }}
                                onBlur={() => setFieldTouched("series_name", true)}
                                onChange={(e, selectedData) => {
                                  setFieldValue("series_id", selectedData?._id || "");
                                  setFieldValue("series_name", selectedData?.series_name || "");
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.series_name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.series_name && Boolean(errors.series_name)} // Show error if touched and there's an error
                                    helperText={touched.series_name && errors.series_name}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                // disabled={values?.supplier_details?.country === "India"}
                                options={gradeNameList}
                                name="grades_name"
                                value={values?.grades_name || null}
                                getOptionLabel={(option) => {
                                  if (option instanceof Object && !Array.isArray(option)) {
                                    return option?.grade_name;
                                  } else {
                                    return option;
                                  }
                                }}
                                onBlur={() => setFieldTouched("grades_name", true)}
                                onChange={(e, selectedData) => {
                                  setFieldValue("grades_id", selectedData?._id || "");
                                  setFieldValue("grades_name", selectedData?.grade_name || "");
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.grade_name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.grades_name && Boolean(errors.grades_name)} // Show error if touched and there's an error
                                    helperText={touched.grades_name && errors.grades_name}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>

                            {/* {finalData?.currency !== "INR" ? (
                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                <TextField
                                  type="number"
                                  size="small"
                                  value={values?.exchange_rate}
                                  sx={{ width: "100%" }}
                                  name="exchange_rate"
                                  onBlur={async (e) => {
                                    handleBlur(e);
                                    let rateInINR = Number(e.target.value) * Number(values?.rate_in_currency);
                                    let amount = Number(values.total_sq_meter) * Number(rateInINR);
                                    setFieldValue("rate_in_inr", Number(rateInINR?.toFixed(2)));
                                    setFieldValue("amount", Number(amount?.toFixed(2)));
                                  }}
                                  onChange={(e) => setFieldValue("exchange_rate", e.target.value)}
                                  error={touched?.exchange_rate && errors?.exchange_rate}
                                  helperText={touched?.exchange_rate && errors?.exchange_rate}
                                />
                              </TableCell>
                            ) : (
                              ""
                            )} */}

                            {finalData?.currency !== "INR" ? (
                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                <TextField
                                  type="number"
                                  size="small"
                                  value={values?.rate_in_currency}
                                  sx={{ width: "100%" }}
                                  name="rate_in_currency"
                                  onBlur={async (e) => {
                                    handleBlur(e);
                                    let rateInINR = Number((Number(e.target.value) * Number(values?.exchange_rate))?.toFixed(2));
                                    let amount = Number((Number(values.total_sq_meter) * Number(rateInINR))?.toFixed(2));
                                    setFieldValue("rate_in_inr", Number(rateInINR));
                                    setFieldValue("amount", Number(amount));
                                  }}
                                  onChange={(e) => setFieldValue("rate_in_currency", e.target.value)}
                                  error={touched?.rate_in_currency && errors?.rate_in_currency}
                                  helperText={touched?.rate_in_currency && errors?.rate_in_currency}
                                />
                              </TableCell>
                            ) : (
                              ""
                            )}
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.rate_in_inr}
                                sx={{ width: "100%" }}
                                name="rate_in_inr"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  let amount = Number((Number(values.total_sq_meter) * Number(e.target.value))?.toFixed(2));
                                  setFieldValue("amount", Number(amount));
                                }}
                                onChange={(e) => setFieldValue("rate_in_inr", e.target.value)}
                                error={touched?.rate_in_inr && errors?.rate_in_inr}
                                helperText={touched?.rate_in_inr && errors?.rate_in_inr}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.amount}
                                sx={{ width: "100%" }}
                                name="amount"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("amount", e.target.value)}
                                error={touched?.amount && errors?.amount}
                                helperText={touched?.amount && errors?.amount}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.remark}
                                sx={{ width: "100%" }}
                                name="remark"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("remark", e.target.value)}
                                error={touched?.remark && errors?.remark}
                                helperText={touched?.remark && errors?.remark}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                // textAlign: "center",
                                minWidth: "100%",
                                verticalAlign: "middle",
                                color: "white",
                                px: 1,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                bgcolor: "white",
                                display: "flex",
                              }}
                            >
                              <IconButton
                                color="success"
                                size="small"
                                onClick={async () => {
                                  // Manually validate the form
                                  const formErrors = await validateForm();
                                  console.log("Validation errors:", formErrors); // Handle errors if needed
                                  // Check if there are no validation errors
                                  if (Object.keys(formErrors).length === 0) {
                                    updateRow(values, i); // Call updateRow with values and index
                                  } else {
                                    console.log("Validation errors:", formErrors); // Handle errors if needed
                                  }
                                }}
                              >
                                <TaskAltIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => {
                                  setData(initialData);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      {itemDetailsListTableLoading ? (
                        <TableCell colSpan={12} align="center">
                          <CircularProgress size="40px" />
                        </TableCell>
                      ) : (
                        <TableCell colSpan={12} align="center">
                          Data Not Found !!
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditItemDetailsTable;
