import { Box } from '@mui/material'
import React from 'react'
import Listing from './Listing'

const OtherGoodsApproval = () => {
    return (
        <Box >
            <Listing />
        </Box>
    )
}

export default OtherGoodsApproval