import Page from "@jumbo/shared/Page";
import FlitchExpenses from "app/pages/Master/ExpenseMaster/FlitchExpenses";
import AddExpenses from "app/pages/Master/ExpenseMaster/FlitchExpenses/AddExpenses";
import AccessMiddleware from "app/routes/middleware/auth/AccessMiddleware";

export const flitchExpenseRoutes = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "expense_master", "view"],
      },
    ],
    routes: [
      {
        path: "/master/expense/flitch",
        element: <Page component={FlitchExpenses} layout={"vertical-default"} />,
      },
      {
        path: "/master/expense/flitch/add/:invoiceId",
        element: <Page component={AddExpenses} layout={"vertical-default"} />,
      }
    ],
  },
];
