import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import { Form, Formik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { BorderRight, Padding } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import { getLatestCode } from "app/services/apis/getLatestCode";
import { incrementLogCode, incrementLogCodeForAdd } from "app/utils/constants/incrementLogCode";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  // width: "100%",
};

const AddItemModal = ({
  setAddedItemDetailsList,
  addedItemDetailsList,
  finalData,
  machineList,
  physicalLength,
  actualLogData,
  setIsCalculated,
  isChecked,
  setIsChecked,
  code,
  setCode,
  setInitailItemForm, initialItemForm
}) => {
  const [submitting, setSubmitting] = useState(false);

  const formula = actualLogData?.log_formula;
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    customClass: {
      container: "popupImportant",
    },
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  //initial data 
  // const stock = {
  //   item_sr_no: addedItemDetailsList.length + 1 || 1,
  //   // item_name: "",
  //   // item_id: "",
  //   log_inventory_item_id: actualLogData?.log_inventory_item_id,
  //   issue_for_crosscutting_id: actualLogData?._id,
  //   factor: 0,
  //   machine_name: "",
  //   machine_id: "",
  //   log_no: "",
  //   code: "",
  //   log_no_code: "",
  //   length: 0,
  //   girth: actualLogData?.physical_diameter || 0,
  //   crosscut_cmt: 0,
  //   per_cmt_cost: 0,
  //   cost_amount: 0,
  //   required_hours: 0,
  //   remarks: "",
  // };

  const validationSchema = yup.object({
    machine_name: yup.string().required("Machine name is Required"),
    // log_no_code: yup.string().required("Required"),
    // code: yup.string().required("Required"),
    // log_no_code: yup.string().required("Required"),
    length: yup
      .number()
      .positive()
      .moreThan(0, "Must be greater than 0")
      .required("Required"),
    girth: yup
      .number()
      .positive()
      .moreThan(0, "Must be greater than 0")
      .required("Required")
      .max(
        Number(actualLogData?.available_quantity?.physical_diameter),
        `Can't be greater than ${actualLogData?.available_quantity?.physical_diameter} `
      ),
    crosscut_cmt: yup
      .number()
      .positive()
      .moreThan(0, "Must be greater than 0")
      .required("Required"),
    // per_cmt_cost: yup
    //   .number()
    //   .positive()
    //   .moreThan(0, "Must be greater than 0")
    //   .required("Required"),
    // cost_amount: yup
    //   .number()
    //   .positive()
    //   .moreThan(0, "Must be greater than 0")
    //   .required("Required"),
  });
  useEffect(() => {
    const fetchCode = async () => {
      try {
        const latestCode = await getLatestCode(
          actualLogData?._id
        );
        setCode(latestCode?.data?.result?.code);
      } catch (error) {
        console.log("err fetching code =>", error.message);
      }
    };
    fetchCode();
  }, []);

  const handleSubmit = async (values, action) => {
    setSubmitting(true);

    const logCode = actualLogData?.log_no;

    const newItem = { ...values, log_no: logCode };

    const updatedList = [...addedItemDetailsList, newItem];
    let totalLength = 0
    updatedList?.forEach((i) => {
      totalLength += Number(i?.length)
    })

    if (
      totalLength >
      actualLogData?.available_quantity?.physical_length
    ) {
      let availableLength = actualLogData?.available_quantity?.physical_length - (totalLength - newItem?.length)
      setSubmitting(false)
      return Swal.fire({
        timer: 5000,
        icon: "error",
        text: `Item length should be smaller than or equals to available (${availableLength.toFixed(2)}) length.`,
        position: "center",
        // background: theme.palette.background.paper,
      });
    }


    const newArr = updatedList.map((item) => {
      const { newCode, newLogCode } = incrementLogCodeForAdd(
        item.item_sr_no,
        logCode,
        code
      );
      // setCode(newCode);
      return { ...item, code: newCode, log_no_code: newLogCode };
    });

    setAddedItemDetailsList(newArr);


    // Reset form and state
    // action.resetForm();
    setInitailItemForm((prev) => ({ ...values, item_sr_no: prev?.item_sr_no + 1 }))
    setSubmitting(false);

    // Display success message
    Toast.fire({
      timer: 3000,
      icon: "success",
      title: "Item Added Successfully.",
      position: "top-right",
    });
  };



  const handleCalculatePhysicalCMT = (
    physicalLength,
    physicalDia,
    // formula,
    setFieldValue
  ) => {
    if (formula === "A") {
      let physicalCMT =
        Number(((Number(physicalLength) * Number(physicalDia) * Number(physicalDia)) /
          16).toFixed(3));
      setFieldValue("crosscut_cmt", physicalCMT);
    }
    if (formula === "B") {
      let physicalCMT =
        Number(((Number(physicalLength) * Number(physicalDia) * Number(physicalDia)) /
          16).toFixed(3));
      setFieldValue("crosscut_cmt", physicalCMT);
    }
  };

  const checkLength = (value, setFieldValue) => {
    const totalLength = addedItemDetailsList?.reduce(
      (acc, item) => acc + Number(item.length),
      0
    );

    if (
      totalLength + Number(value) >
      actualLogData?.available_quantity?.physical_length
    ) {
      let availableLength = actualLogData?.available_quantity?.physical_length - totalLength
      setFieldValue("length", 0);
      return Swal.fire({
        timer: 5000,
        icon: "error",
        text: `Item length should be smaller than or equals to available (${availableLength.toFixed(2)}) length.`,
        position: "center",
        // background: theme.palette.background.paper,
      });
    }
  };

  //new initial data
  const itemDetail = {
    ...initialItemForm,
    item_sr_no: addedItemDetailsList?.length + 1 || 1,

  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={itemDetail}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        setFieldValue,
        values,
        errors,
        handleBlur,
        initialValues,
        setFieldTouched,
        touched,
      }) => (
        <Form noValidate autoComplete="off">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                  }}
                >
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "40px" }}>
                    Sr No.
                  </TableCell>
                  <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Machine Name
                  </TableCell>
                  {/* <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Log No.
                  </TableCell> */}
                  {/* <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Code
                  </TableCell> */}
                  {/* <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Log No. Code
                  </TableCell> */}
                  <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Length
                  </TableCell>
                  <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Diameter
                  </TableCell>
                  <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Crosscut CMT
                  </TableCell>
                  {/* <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Cost Amount
                  </TableCell>
                  <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Per CMT Cost
                  </TableCell> */}
                  <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Remark
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    {values?.item_sr_no}
                  </TableCell>

                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      value={values?.machine_name}
                      size="small"
                      options={machineList}
                      name="machine_name"
                      getOptionLabel={(option) => {
                        if (
                          option instanceof Object &&
                          !Array.isArray(option)
                        ) {
                          return option?.machine_name;
                        } else {
                          return option;
                        }
                      }}
                      onBlur={() => setFieldTouched("machine_name", true)}
                      onChange={(e, selectedData) => {
                        setFieldValue(
                          "machine_name",
                          selectedData?.machine_name || ""
                        );
                        setFieldValue("machine_id", selectedData?._id || "");
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.machine_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={
                            touched.machine_name && Boolean(errors.machine_name)
                          } // Show error if touched and there's an error
                          helperText={
                            touched.machine_name && errors.machine_name
                          }
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // disabled
                      type="number"
                      size="small"
                      value={values?.length || ""}
                      sx={{ width: "100%" }}
                      name="length"
                      onBlur={(e) => {
                        handleBlur(e);
                        handleCalculatePhysicalCMT(
                          e.target.value,
                          values?.girth,
                          // values?.log_formula,
                          setFieldValue
                        );
                        checkLength(e.target.value, setFieldValue);
                        setIsCalculated(false);
                      }}
                      onChange={(e) => setFieldValue("length", e.target.value)}
                      error={touched?.length && errors?.length}
                      helperText={touched?.length && errors?.length}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      disabled
                      size="small"
                      value={values?.girth}
                      sx={{ width: "100%" }}
                      name="girth"
                      onBlur={async (e) => {
                        handleBlur(e);

                        handleCalculatePhysicalCMT(
                          values?.length,
                          e.target.value,
                          // values?.log_formula,
                          setFieldValue
                        );
                        setIsCalculated(false);
                      }}
                      onChange={(e) => setFieldValue("girth", e.target.value)}
                      error={touched?.girth && errors?.girth}
                      helperText={touched?.girth && errors?.girth}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      disabled
                      value={values?.crosscut_cmt}
                      sx={{ width: "100%" }}
                      name="crosscut_cmt"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // handleCalculatePhysicalCMT(
                        //   e.target.value,
                        //   values?.physical_diameter,
                        //   values?.log_formula,
                        //   setFieldValue
                        // );
                      }}
                      onChange={(e) =>
                        setFieldValue("crosscut_cmt", e.target.value)
                      }
                      error={touched?.crosscut_cmt && errors?.crosscut_cmt}
                      helperText={touched?.crosscut_cmt && errors?.crosscut_cmt}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // disabled
                      type="number"
                      size="small"
                      value={values?.cost_amount}
                      sx={{ width: "100%" }}
                      name="cost_amount"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) =>
                        setFieldValue("cost_amount", e.target.value)
                      }
                      error={touched?.cost_amount && errors?.cost_amount}
                      helperText={touched?.cost_amount && errors?.cost_amount}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      // disabled
                      size="small"
                      value={values?.per_cmt_cost}
                      sx={{ width: "100%" }}
                      name="per_cmt_cost"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) =>
                        setFieldValue("per_cmt_cost", e.target.value)
                      }
                      error={touched?.per_cmt_cost && errors?.per_cmt_cost}
                      helperText={touched?.per_cmt_cost && errors?.per_cmt_cost}
                    />
                  </TableCell> */}

                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.remarks}
                      sx={{ width: "100%" }}
                      name="remarks"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("remarks", e.target.value)}
                      error={touched?.remarks && errors?.remarks}
                      helperText={touched?.remarks && errors?.remarks}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Div sx={{ textAlign: "right" }}>
            <LoadingButton
              loading={submitting}
              type="submit"
              size="small"
              variant="contained"
              sx={{
                marginY: 1,
                width: "150px",
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                px: 1,
                position: "sticky",
                right: "20px",
                zIndex: 1,
              }}
            // onClick={() => incrementLogCode(values?.code, actualLogData?.log_no)}
            >
              Add New Item
            </LoadingButton>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default AddItemModal;
