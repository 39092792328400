//new added formik with validation

import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, TextField, Checkbox, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, Form } from "formik";
import { withStyles } from "@mui/styles";
import * as yup from "yup";
import Swal from "sweetalert2";
import { Axios } from "index"; // Assuming Axios is properly configured
import { LoadingButton } from "@mui/lab";
import FormTextField1 from "app/components/InputField/FormTextField1";
import Div from "@jumbo/shared/Div";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";

const headingStyle = {
  // minWidth: "20%",
  fontSize: "16px",
  fontWeight: "400",
};
const moduleSelectionBoxStyle = {
  // border: "1px solid red",
  width: "250px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "green",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const validationSchema = yup.object({
  dept_name: yup.string().required("Department Name is required"),
  remark: yup.string("Enter Remarks"),
});

function EditDepartment() {
  const { state } = useLocation();
  console.log(state, "dgdfg");
  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);
  const [check, setCheck] = useState({
    user: false,
    role: false,
    supplier_master: false,
    item_category_master: false,
    item_sub_category_master: false,
    item_name_master: false,
    unit_master: false,
    grade_master: false,
    currency_master: false,
    cut_master: false,
    series_master: false,
    expense_type_master: false,
    expense_master: false,
    gst_master: false,
    department_master: false,
    machine_master: false,
    log_inventory: false,
    flitch_inventory: false,
    plywood_inventory: false,
    veneer_inventory: false,
    mdf_inventory: false,
    face_inventory: false,
    core_inventory: false,
    fleece_paper_inventory: false,
    other_goods_inventory: false,
    crosscut_factory: false,
    flitching_factory: false,
    approval_config: false,
  });

  const initialValues = {
    dept_name: state?.dept_name || "",
    remark: state?.remark || "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let isAtLeastOneDepartmentSelected = Object.entries(check).some((ele) => ele[1] === true);

      if (isAtLeastOneDepartmentSelected) {
        let finalPayload = {
          ...values,
          dept_name: values?.dept_name?.toUpperCase(),
          dept_access: {
            ...check,
          },
        };
        console.log(finalPayload, "956555");
        let res = await Axios.post(`/department-master/update-department/${state?._id}`, finalPayload);

        if (res.data.statusCode === 200) {
          Swal.fire({
            icon: "success",
            text: "Department Updated Successfully",
            timer: 2000,
            showConfirmButton: false,
          });
          navigate("/master/department");
        } else {
          Swal.fire({
            icon: "error",
            text: res.data.message,
            timer: 2000,
            // showConfirmButton: false,
          });
        }
      } else {
        Swal.fire({
          icon: "warning",
          text: "Select at least one Department.",
          timer: 2000,
          // showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error?.response?.data?.message,
        timer: 2000,
        // showConfirmButton: false,
      });
      console.log("Error adding/updating department => ", error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    let updateCheck = { ...check };
    if (e.target.checked) {
      for (const key in check) {
        updateCheck[key] = true;
      }
    } else {
      for (const key in check) {
        updateCheck[key] = false;
      }
    }
    setCheck((pre) => ({ ...pre, ...updateCheck }));
  };

  const handleChange = (event) => {
    setCheck((pre) => ({ ...pre, [event.target.name]: event.target.checked }));
  };

  useEffect(() => {
    if (state?.dept_access) {
      setCheck((pre) => ({ ...pre, ...state?.dept_access }));
    }
  }, []);

  useEffect(() => {
    //for SelectAll Checkbox
    const roles = Object.entries(check).every((ele) => ele[1] === true);
    if (roles) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [check]);

  return (
    <Div sx={{ mt: -4 }}>
      {/* <Typography variant="h2">Edit Department</Typography> */}
      <Div>
        <Formik
          validateOnChange={false}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, touched, handleBlur, setFieldTouched, isSubmitting }) => (
            <Form noValidate>
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Department Name*
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.dept_name}
                      sx={{ width: "100%" }}
                      name="dept_name"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("dept_name", e.target.value)}
                      error={touched?.dept_name && errors?.dept_name}
                      helperText={touched?.dept_name && errors?.dept_name}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Remark
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.remark}
                      sx={{ width: "100%" }}
                      name="remark"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("remark", e.target.value)}
                      error={touched?.remark && errors?.remark}
                      helperText={touched?.remark && errors?.remark}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3, alignItems: "center" }}>
                  <Typography variant="h2" sx={{ m: 0, width: "250px" }}>
                    Select Department
                  </Typography>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox checked={selectAll} onChange={handleSelectAll} />
                    <Typography sx={headingStyle}>Select All</Typography>
                  </Div>
                </Box>

                {/* Module Selection section */}
                <Div style={{ backgroundColor: "#EAE5F7", textAlign: "center", marginTop: "10px" }}>
                  <Typography sx={{ color: "#7352C7", padding: "5px 0", fontWeight: "600" }} variant="h5">
                    MASTER
                  </Typography>
                </Div>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="user" checked={check?.user} onChange={handleChange} />
                    <Typography sx={headingStyle}>User Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="role" checked={check?.role} onChange={handleChange} />
                    <Typography sx={headingStyle}>Roles Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="supplier_master" checked={check?.supplier_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Supplier Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="item_category_master" checked={check?.item_category_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Item Category Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="item_sub_category_master" checked={check?.item_sub_category_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Item Sub-category Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="item_name_master" checked={check?.item_name_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Item Name Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="unit_master" checked={check?.unit_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Unit Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="grade_master" checked={check?.grade_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Grade Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="currency_master" checked={check?.currency_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Currency Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="cut_master" checked={check?.cut_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Cut Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="series_master" checked={check?.series_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Series Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="expense_type_master" checked={check?.expense_type_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Expense Type Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="expense_master" checked={check?.expense_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Expense Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="gst_master" checked={check?.gst_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>GST Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="department_master" checked={check?.department_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Department Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="machine_master" checked={check?.machine_master} onChange={handleChange} />
                    <Typography sx={headingStyle}>Machine Master</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="approval_config" checked={check?.approval_config} onChange={handleChange} />
                    <Typography sx={headingStyle}>Approval Config</Typography>
                  </Div>
                </Box>

                <Div style={{ backgroundColor: "#EAE5F7", textAlign: "center", marginTop: "20px" }}>
                  <Typography sx={{ color: "#7352C7", padding: "5px 0", fontWeight: "600" }} variant="h5">
                    INVENTORY
                  </Typography>
                </Div>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="log_inventory" checked={check?.log_inventory} onChange={handleChange} />
                    <Typography sx={headingStyle}>Log Inventory</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="flitch_inventory" checked={check?.flitch_inventory} onChange={handleChange} />
                    <Typography sx={headingStyle}>Flitch Inventory</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="plywood_inventory" checked={check?.plywood_inventory} onChange={handleChange} />
                    <Typography sx={headingStyle}>Plywood Inventory</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="veneer_inventory" checked={check?.veneer_inventory} onChange={handleChange} />
                    <Typography sx={headingStyle}>Veneer Inventory</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="mdf_inventory" checked={check?.mdf_inventory} onChange={handleChange} />
                    <Typography sx={headingStyle}>MDF Inventory</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="face_inventory" checked={check?.face_inventory} onChange={handleChange} />
                    <Typography sx={headingStyle}>Face Inventory</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="core_inventory" checked={check?.core_inventory} onChange={handleChange} />
                    <Typography sx={headingStyle}>Core Inventory</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="fleece_paper_inventory" checked={check?.fleece_paper_inventory} onChange={handleChange} />
                    <Typography sx={headingStyle}>Fleece Paper Inventory</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="other_goods_inventory" checked={check?.other_goods_inventory} onChange={handleChange} />
                    <Typography sx={headingStyle}>Other Goods Inventory</Typography>
                  </Div>
                </Box>
                <Div style={{ backgroundColor: "#EAE5F7", textAlign: "center" }}>
                  <Typography sx={{ color: "#7352C7", padding: "5px 0", fontWeight: "600", marginTop: "20px" }} variant="h5">
                    FACTORY
                  </Typography>
                </Div>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="crosscut_factory" checked={check?.crosscut_factory} onChange={handleChange} />
                    <Typography sx={headingStyle}>Crosscut Factory</Typography>
                  </Div>
                  <Div sx={{ ...moduleSelectionBoxStyle }}>
                    <GreenCheckbox name="flitching_factory" checked={check?.flitching_factory} onChange={handleChange} />
                    <Typography sx={headingStyle}>Flitching Factory</Typography>
                  </Div>
                </Box>
              </Div>
              <Div
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 3,
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/master/department");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton size="small" variant="contained" type="submit" sx={{ width: "100px" }} loading={isSubmitting}>
                  Save
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}

export default HeaderTitleHoc(EditDepartment, "Edit Department");