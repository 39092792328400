import AddItemModal from "./addNewItemTable";
import EditItemDetailsTable from "./EditItemTable";

const ItemDetailsTable = ({ setAddedItemDetailsList, addedItemDetailsList, itemNameList, itemSubCategoryNameList, finalData, itemDetailsListTableLoading, cutNameList, seriesNameList, gradeNameList, initialItemForm, setInitailItemForm }) => {

  return (
    <div>
      <AddItemModal
        itemNameList={itemNameList}
        itemSubCategoryNameList={itemSubCategoryNameList}
        setAddedItemDetailsList={setAddedItemDetailsList}
        addedItemDetailsList={addedItemDetailsList}
        cutNameList={cutNameList}
        seriesNameList={seriesNameList}
        gradeNameList={gradeNameList}
        finalData={finalData}
        initialItemForm={initialItemForm}
        setInitailItemForm={setInitailItemForm}
      />
      <EditItemDetailsTable
        itemNameList={itemNameList}
        itemSubCategoryNameList={itemSubCategoryNameList}
        setAddedItemDetailsList={setAddedItemDetailsList}
        addedItemDetailsList={addedItemDetailsList}
        cutNameList={cutNameList}
        seriesNameList={seriesNameList}
        gradeNameList={gradeNameList}
        finalData={finalData}
        itemDetailsListTableLoading={itemDetailsListTableLoading}
      />
    </div>
  );
};

export default ItemDetailsTable;
