import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Edit } from "@mui/icons-material";
import { ApprovalStatusTableCell } from "app/components/ApprovalStatusTableCell";
import { formatAmount } from "app/utils/formatAmount";


const tableBodyCell = { textAlign: "left", px: 1 };

const tableRowBodySticky = {
  textAlign: "left",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "white",
};

const FlitchingDoneTableRow = ({ e, allFilterState }) => {
  console.log("e :", e)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);
  const { user_type, role_id } = useSelector(
    (state) => state?.userReducer?.user?.[0]
  );

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "editFlitching":
        navigate("/factory/edit-flitching", { state: menuItem?.row });
        break;
      case "rejectCrosscutting":
        // handleRejectIssueForCrosscutting(e?._id);
        break;
      case "revertItem":
        // handleRevertIssueForCrosscutting(e?._id);
        break;
      default:
        navigate("/factory/crosscut");
    }
  };

  return (
    <TableRow key={e?._id}>

      {/* <TableCell sx={{ ...tableBodyCell }}>
        {new Date(e?.worker_details?.flitching_date).toLocaleDateString() ||
          "-"}

      </TableCell> */}
      <TableCell sx={{ ...tableBodyCell }}>
        {moment(e?.worker_details?.flitching_date).format("DD-MM-YYYY") ||
          "-"}

      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.machine_name}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.log_no || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.flitch_code || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_no_code || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.flitch_formula || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.length || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.width1 || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.width2 || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.width3 || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.height || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.flitch_cmt || "-"}
      </TableCell>
      {user_type == "ADMIN" && (

        <TableCell sx={{ ...tableBodyCell }}>
          {e?.per_cmt_cost || "-"}
        </TableCell>
      )}
      {user_type == "ADMIN" && (

        <TableCell sx={{ ...tableBodyCell }}>
          {formatAmount(Math.round(e?.cost_amount)) || "-"}
        </TableCell>
      )}
      {user_type == "ADMIN" && (

        <TableCell sx={{ ...tableBodyCell }}>{formatAmount(Math.round(e?.expense_amount)) || "-"}</TableCell>
      )}
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.wastage_info?.wastage_sqm || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.required_hours || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.remarks || "-"}
      </TableCell>
      <ApprovalStatusTableCell approval_status={e?.approval_status} />
      <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky }}>
        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <Edit />,
              title: "Edit",
              action: "editFlitching",
              show: role_id?.permissions?.flitching_factory?.edit === true && !e?.approval_status?.sendForApproval?.status ? true : false,
              row: e,
            },
          ].filter((ele) => ele?.show)}
          onClickCallback={handleItemAction}
        />
      </TableCell>
    </TableRow>
  );
};

export default FlitchingDoneTableRow;
