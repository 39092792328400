import { Axios } from "index";


const addFlitchFactory = async (data, id) => {

    const res = await Axios.post('/factory/flitching/add-flitching', { newData: data, issuedFlitchId: id });
    return res;
};

export default addFlitchFactory;


export const editFlitchingDone = async (data, id) => {

    const res = await Axios.post(`/factory/flitching/edit-flitching/${id}`, { newData: data });
    return res
};

export const revertFlitchingDone = async (id) => {

    const res = await Axios.post(`/factory/flitching/revert-flitching-done-items/${id}`);
    return res
};




