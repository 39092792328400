import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import InfoIcon from '@mui/icons-material/Info';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { TableCell, TableRow } from "@mui/material";
import { ApprovalStatusTableCell } from "app/components/ApprovalStatusTableCell";
import { hideLoading, setLoading } from "app/redux/actions/Loading";
import { fetchCrosscuttingDoneApprovalList } from "app/redux/Approval/CrosscuttingDoneApproval";
import { Axios } from "index";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";


const tableBodyCell = { textAlign: "left", px: 1 };

const tableRowBodySticky = {
  textAlign: "left",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "white",
};

const CrosscuttingDoneTableRow = ({ e, allFilterState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);

  const handleItemAction = (menuItem) => {
    const id = menuItem?.row?.unique_identifier
    const issued_for_cutting_id = menuItem?.row?.issue_for_crosscutting_id
    switch (menuItem.action) {
      case "viewDetails":
        navigate(`/factory/crosscut-approval/view-details/${id}/${issued_for_cutting_id}`)
        break;
      default:
        navigate("/factory/crosscut");
    }
  };

  return (
    <TableRow key={e?._id}>

      <TableCell sx={{ ...tableBodyCell }}>
        {moment(e?.worker_details?.crosscut_date).format("DD/MM/YYYY") ||
          "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.machine_name}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.log_no || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.code || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.log_no_code || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.length || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.girth || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.crosscut_cmt || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.per_cmt_cost || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.cost_amount || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.expense_amount || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.wastage_info?.wastage_sqm || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.wastage_info?.wastage_length || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.required_hours || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.remarks || "-"}
      </TableCell>
      <ApprovalStatusTableCell approval_status={e?.approval_status} />
      <TableCell sx={{ ...tableBodyCell }}>{e?.user?.user_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky }}>

        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <InfoIcon />,
              title: "View Details",
              action: "viewDetails",
              show: true,
              row: e,
            }
          ].filter((ele) => ele?.show)}
          onClickCallback={handleItemAction}
        />
      </TableCell>
    </TableRow>
  );
};

export default CrosscuttingDoneTableRow;
