import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { TableCell, TableRow } from "@mui/material";
import { ApprovalStatusTableCell } from "app/components/ApprovalStatusTableCell";
import { fetchIssueForCrosscutting } from "app/redux/actions/Factory/Crosscutting/issuesForCrosscutting/issuesForCrosscutting";
import { hideLoading, setLoading } from "app/redux/actions/Loading";
import {
  rejectIssuesForCrosscuttingApi,
  revertIssuesForCrosscuttingApi,
} from "app/services/apis/Factory/Crosscutting/issuesForCrosscutting";
import { formatAmount } from "app/utils/formatAmount";
import { Axios } from "index";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const tableBodyCell = { textAlign: "left", px: 1 };

const tableRowBodySticky = {
  textAlign: "center",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "white",
};

const IssueForCrosscuttingTableRow = ({ e, allFilterState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_type, role_id } = useSelector(
    (state) => state?.userReducer?.user?.[0]
  );

  const handleRevertIssueForCrosscutting = async function (id) {
    try {
      dispatch(setLoading());
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const issueForCrosscutting = await Axios.post(
        revertIssuesForCrosscuttingApi(id),
        config
      );

      if (issueForCrosscutting.status === 200) {
        Swal.fire({
          title: "Revert Successfully",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(fetchIssueForCrosscutting(allFilterState));
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleRejectIssueForCrosscutting = async function (id) {
    try {
      dispatch(setLoading());
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const issueForCrosscutting = await Axios.post(
        rejectIssuesForCrosscuttingApi(id),
        config
      );

      if (issueForCrosscutting.status === 200) {
        Swal.fire({
          title: "Reject Successfully",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(fetchIssueForCrosscutting(allFilterState));
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "createCrosscutting":
        navigate("/factory/add-crosscut", { state: menuItem.row });
        break;
      case "rejectCrosscutting":
        Swal.fire({
          text: "Are you sure you want to reject ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            handleRejectIssueForCrosscutting(e?._id);
          }
        });
        break;
      case "revertItem":
        Swal.fire({
          text: "Are you sure you want to revert ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            handleRevertIssueForCrosscutting(e?._id);
          }
        });
        break;
      default:
        navigate("/factory/crosscut");
    }
  };

  const approvalStatus = function (approval_status = {}) {
    if (approval_status?.sendForApproval?.status) {
      return "Pending Approval";
    } else if (approval_status?.approved?.status) {
      return "Approved";
    } else if (approval_status?.rejected?.status) {
      return "Rejected";
    } else {
      return "-";
    }
  };

  return (
    <TableRow key={e?._id}>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.log_invoice_details?.inward_sr_no || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {moment(e?.log_invoice_details?.inward_date).format("DD/MM/YYYY") ||
          "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.log_invoice_details?.invoice_Details?.invoice_no || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.item_sr_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.item_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.item_sub_category_name || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_formula || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.physical_length || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.available_quantity?.physical_length || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.physical_diameter || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.physical_cmt || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.available_quantity?.physical_cmt || "-"}
      </TableCell>
      {user_type == "ADMIN" && (
        <TableCell sx={{ ...tableBodyCell }}>{formatAmount(e?.rate_in_inr) || "-"}</TableCell>
      )}
      {user_type == "ADMIN" && (
        <TableCell sx={{ ...tableBodyCell }}>{formatAmount(Math.round(e?.amount)) || "-"}</TableCell>
      )}
      {user_type == "ADMIN" && (
        <TableCell sx={{ ...tableBodyCell }}>
          {formatAmount(Math.round(e?.available_quantity?.amount)) || "-"}
        </TableCell>
      )}
      {user_type == "ADMIN" && (
        <TableCell sx={{ ...tableBodyCell }}>
          {formatAmount(Math.round(e?.expense_amount)) || "-"}
        </TableCell>
      )}
      {user_type == "ADMIN" && (
        <TableCell sx={{ ...tableBodyCell }}>
          {formatAmount(Math.round(e?.available_quantity?.expense_amount)) || "-"}
        </TableCell>
      )}
      <TableCell sx={{ ...tableBodyCell }}>{e?.remark || "-"}</TableCell>
      <ApprovalStatusTableCell approval_status={e?.approval_status} />
      <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky }}>
        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <AddCircleOutlineIcon sx={{ color: "green" }} />,
              title: "Create Crosscutting",
              action: "createCrosscutting",
              show:
                role_id?.permissions?.crosscut_factory?.create === true &&
                  !e?.approval_status?.sendForApproval?.status
                  ? true
                  : false,
              row: e,
            },
            {
              icon: <DeleteSweepIcon sx={{ color: "red" }} />,
              title: "Reject Crosscutting",
              action: "rejectCrosscutting",
              show:
                role_id?.permissions?.crosscut_factory?.create === true &&
                  !e?.approval_status?.sendForApproval?.status &&
                  !e.is_rejected
                  ? true
                  : false,
              row: e,
            },
            {
              icon: <SettingsBackupRestoreIcon />,
              title: "Revert Item",
              action: "revertItem",
              show:
                e?.physical_length === e?.available_quantity?.physical_length &&
                  !e?.approval_status?.sendForApproval?.status
                  ? true
                  : false,
              row: e,
            },
          ].filter((ele) => ele?.show)}
          onClickCallback={handleItemAction}
        />
      </TableCell>
    </TableRow>
  );
};

export default IssueForCrosscuttingTableRow;
