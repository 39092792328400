import Div from "@jumbo/shared/Div/Div";

import { Button, Checkbox, Grid, TextField, Tooltip, Typography, withStyles } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import { getCurrentDate } from "app/components/Function/getCurrentDate";

import ItemDetailsTable from "./itemTable";

import moment from "moment";
import { machineNameDropDown } from "app/services/apis/machineList";
import IssuedLogTable from "./issuedLogTable";

import addCrossCutFactory from "app/services/apis/addCrossCutFactory";
import { Axios } from "index";
import { LoadingButton } from "@mui/lab";
import { data } from "app/shared/metrics/SalesStatistics/data";
import editCrossCutDone from "app/services/apis/Factory/Crosscutting/ApiCall/editCrossCutDone";
import { revertCrossCuttingDone } from "app/services/apis/Factory/Crosscutting/ApiCall";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";

function EditCrossCutDone() {
  // const { state, pathname } = useLocation();
  const { state } = useLocation();
  const [code, setCode] = useState("");
  const formikRef = useRef();
  const navigate = useNavigate();
  const [totalhrs, setTotalhrs] = useState(0);
  const [totalWorker, setTotalWorker] = useState(0);

  const [actualLogDetails, setActualLogDetails] = useState(state);
  const updatedIssuedCrossCutData = actualLogDetails?.issuedCrossCuttingDetails;
  // const stock = {
  //   crosscut_date:
  //     actualLogDetails?.worker_details?.crosscut_date || getCurrentDate(),
  //   shift: actualLogDetails?.worker_details?.shift || "",
  //   no_of_workers:
  //     actualLogDetails?.worker_details?.workers || 0,
  //   working_hours: actualLogDetails?.worker_details?.working_hours || 0,
  // };
  // const stock = {
  //   inward_date:
  //     actualLogDetails?.worker_details?.crosscut_date || getCurrentDate(),
  //   no_of_workers: actualLogDetails?.worker_details?.workers || 0,
  //   shift: actualLogDetails?.worker_details?.shift || "",
  //   working_hours: actualLogDetails?.worker_details?.working_hours || "",
  // };
  const stock = {
    crosscut_date: actualLogDetails?.worker_details?.crosscut_date || getCurrentDate(),
    no_of_workers: totalWorker || 0,
    shift: actualLogDetails?.worker_details?.shift || 0,
    working_hours: totalhrs || 0,
  };
  const validationSchema = yup.object({
    crosscut_date: yup.date().required("Crosscut Date is required"),
    no_of_workers: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    shift: yup.string().required("Shift is Required"),
    working_hours: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Working hours is Required"),
  });
  const [isCalculated, setIsCalculated] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const [machineList, setMachineList] = useState([]);
  const [addedItemDetailsList, setAddedItemDetailsList] = useState([]);
  const [revertLoading, setRevertLoading] = useState(false);

  const [wastageInfo, setWastageInfo] = useState({
    wastage_sqm: 0,
    wastage_length: 0,
  });
  const [available_quantity, set_available_quantity] = useState({
    available_sqm: 0,
    available_length: 0,
    amount: 0,
    crosscutting_completed: false,
    id: actualLogDetails?._id ? actualLogDetails?._id : "",
    sqm_factor: 1,
  });
  const [finalData, setFinalData] = useState({
    no_of_workers: actualLogDetails?.worker_details?.workers || 0,

    shift: actualLogDetails?.worker_details?.shift || "",
    working_hours: actualLogDetails?.worker_details?.working_hours || 0,
    no_of_total_hours: actualLogDetails?.worker_details?.no_of_total_hours || 0,
    crosscut_date: actualLogDetails?.worker_details?.crosscut_date || getCurrentDate(),
  });

  const [initialItemForm, setInitialItemForm] = useState({
    item_sr_no: addedItemDetailsList?.length + 1 || 1,
    // item_name: "",
    // item_id: "",
    log_inventory_item_id: actualLogDetails?.issuedCrossCuttingDetails?.log_inventory_item_id,
    issue_for_crosscutting_id: actualLogDetails?.issue_for_crosscutting_id,
    factor: 0,
    machine_name: "",
    machine_id: "",
    log_no: "",
    code: "",
    log_no_code: "",
    length: 0,
    girth: actualLogDetails?.issuedCrossCuttingDetails?.physical_diameter || 0,
    crosscut_cmt: 0,
    per_cmt_cost: 0,
    cost_amount: 0,
    required_hours: 0,
    remarks: "",
    // crosscut_date: getCurrentDate(),
    // workers: 0,
    // shift: "",
    // working_hours: 0,
    required_workers: 0,
    worker_details: {
      crosscut_date: getCurrentDate(),
      workers: 0,
      working_hours: 0,
      shift: "",
      no_of_total_hours: 0
    },
  })

  useEffect(() => {
    const fetchAllCrossCuts = async () => {
      let updatedTotalWorker = totalWorker;
      let updatedTotalhrs = totalhrs;
      try {
        const res = await Axios.get(
          `/factory/cross-cutting/list-crosscuts-by-issued-crosscut-id/${actualLogDetails?.issue_for_crosscutting_id}`
        );
        const addedSrNumberList = res?.data?.result?.map((item, index) => {
          updatedTotalWorker += item?.required_workers;
          updatedTotalhrs += item?.required_hours;
          return {
            ...item,
            item_sr_no: index + 1,
          };
        });

        setTotalWorker(updatedTotalWorker);
        setTotalhrs(Number(updatedTotalhrs) / Number(updatedTotalWorker) || 0);
        setAddedItemDetailsList(addedSrNumberList);
      } catch (error) {
        console.log("err fetching all crosscuts");
      }
    };
    fetchAllCrossCuts();
  }, [actualLogDetails?.issue_for_crosscutting_id]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (addedItemDetailsList && addedItemDetailsList.length <= 0) {
        Swal.fire({
          title: "Add at least one item details.",
          icon: "warning",
          showCancelButton: false,
        });
      }

      const newData = addedItemDetailsList?.map((item) => {
        const { issuedCrossCuttingDetails, ...data } = item;
        // data.worker_details = workerDetails;
        return data;
      });

      const response = await editCrossCutDone(newData, available_quantity, actualLogDetails?.issue_for_crosscutting_id);

      if (response?.data?.success) {
        Swal.fire({
          title: response?.data?.message,
          icon: "success",
          showCancelButton: false,
        });
        navigate("/factory/crosscut");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showCancelButton: false,
      });
    }
  };
  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const res = await machineNameDropDown("");
        setMachineList(res?.data?.result);
      } catch (error) {
        console.log("err fetching machine list=> ", error.message);
      }
    };
    fetchMachines();
  }, []);

  useEffect(() => {
    const fetchAllCrossCuts = async () => {
      try {
        const res = await Axios.get(
          `/factory/cross-cutting/list-crosscuts-by-issued-crosscut-id/${actualLogDetails?.issue_for_crosscutting_id}`
        );
        const addedSrNumberList = res?.data?.result?.map((item, index) => ({
          ...item,
          item_sr_no: index + 1,
        }));

        setAddedItemDetailsList(addedSrNumberList);
      } catch (error) {
        console.log("err fetching all crosscuts");
      }
    };
    fetchAllCrossCuts();
  }, [actualLogDetails?.issue_for_crosscutting_id]);

  const handleCalculate = () => {
    // const parsedPhysicalSqm = parseFloat(
    //   actualLogDetails?.available_quantity?.physical_cmt
    // );
    const parsedPhysicalSqm = Number(updatedIssuedCrossCutData?.physical_cmt);

    // const physicalLength = Number(
    //   actualLogDetails?.available_quantity?.physical_length
    // );
    const physicalLength = Number(updatedIssuedCrossCutData?.physical_length);
    // const costAmount = Number(actualLogDetails?.available_quantity?.amount);
    // eslint-disable-next-line no-unused-vars
    const totalLength = addedItemDetailsList?.reduce((acc, val) => acc + (Number(val?.length) || 0), 0);
    const totalSqm = addedItemDetailsList?.reduce((acc, val) => acc + Number(val?.crosscut_cmt), 0);

    if (Number(physicalLength) === Number(totalLength)) {
      set_available_quantity({
        ...available_quantity,
        crosscutting_completed: true,
      });
    }
    if (Number(parsedPhysicalSqm) === Number(totalSqm)) {
      set_available_quantity({
        ...available_quantity,
        crosscutting_completed: true,
      });
    }
    const totalAmount = addedItemDetailsList?.reduce((acc, val) => acc + (Number(val?.cost_amount) || 0), 0);
    const costAmount = Number(updatedIssuedCrossCutData?.amount);
    const expenseAmount = Number(updatedIssuedCrossCutData?.expense_amount);

    const wastageSqm = Number((parsedPhysicalSqm - totalSqm).toFixed(3));

    const outputSqm = parsedPhysicalSqm - wastageSqm;

    const availableLength = Number((physicalLength - Number(totalLength)).toFixed(2));
    const totalHours = Number(finalData?.no_of_workers) * Number(finalData?.working_hours);
    // const totalHours =
    //   Number(finalData?.no_of_workers) * Number(finalData?.working_hours);

    const selectedSqm = isChecked ? outputSqm : parsedPhysicalSqm;
    // eslint-disable-next-line no-unused-vars
    const amountsForItems = addedItemDetailsList?.map((item) => {
      const sqFactor = Number((Number(item?.crosscut_cmt) / selectedSqm));
      item.sqm_factor = sqFactor;
      const itemAmount = Number((Number(costAmount) * Number(sqFactor)).toFixed(2));
      item.cost_amount = itemAmount;
      const itemExpenseAmount = Number((Number(expenseAmount) * Number(sqFactor)).toFixed(2));
      item.expense_amount = itemExpenseAmount;
      const itemPerCmtAmount = Number((Number(item?.cost_amount) / Number(item?.crosscut_cmt)).toFixed(2));
      item.per_cmt_cost = itemPerCmtAmount;
      let wastageData = { wastage_sqm: 0, wastage_length: 0 };
      if (isChecked) {
        let perWastageSqm = Number((Number(sqFactor) * Number(wastageSqm)).toFixed(3));
        wastageData.wastage_sqm = perWastageSqm;
        let wastageLength = Number((Number(sqFactor) * Number(availableLength)).toFixed(2));
        wastageData.wastage_length = wastageLength;
      }
      item.wastage_info = wastageData;
      return item;
    });
    setAddedItemDetailsList(amountsForItems);
    if (isChecked) {
      // const wastageData = {
      //   wastage_sqm: wastageSqm,
      //   wastage_length: availableLength,
      // };
      set_available_quantity({
        ...available_quantity,
        amount: 0,
        available_length: 0,
        available_sqm: 0,
        crosscutting_completed: true,
        sqm_factor: 0,
        expense_amount: 0,
      });

      // const updatedItemDetails = addedItemDetailsList?.map((item) => {
      //   item.wastage_info = wastageData;
      //   return item;
      // });
      // setWastageInfo(wastageData);
      // setAddedItemDetailsList(updatedItemDetails);
    } else {
      const updatedTotalAmount = amountsForItems?.reduce((acc, val) => acc + (Number(val?.cost_amount) || 0), 0);
      const updatedTotalExpenseAmount = amountsForItems?.reduce((acc, val) => acc + (Number(val?.expense_amount) || 0), 0);

      const availableData = {
        available_sqm: wastageSqm,
        available_length: availableLength,
        amount: Number((Number(costAmount) - Number(updatedTotalAmount)).toFixed(2)),
        expense_amount: Number((Number(expenseAmount) - Number(updatedTotalExpenseAmount)).toFixed(2)),
        // id: actualLogDetails?._id ? actualLogDetails?._id : ""
        sqm_factor: Number((Number(wastageSqm) / parsedPhysicalSqm).toFixed(2)),
      };
      set_available_quantity({
        ...available_quantity,
        available_length: availableData.available_length,
        available_sqm: availableData.available_sqm,
        amount: availableData.amount,
        expense_amount: availableData.expense_amount,
        // crosscutting_completed: false,
        sqm_factor: availableData.sqm_factor,
        crosscutting_completed: Number(physicalLength) === Number(totalLength) ? true : false,
      });
      // const updatedItemDetails = addedItemDetailsList?.map((data) => {
      //   // data.available_quantity = availableData;
      //   data.wastage_info = { wastage_sqm: 0, wastage_length: 0 };
      //   return data;
      // });
      // setAddedItemDetailsList(updatedItemDetails);
      // if (Number(physicalLength) === Number(totalLength)) {
      //   set_available_quantity({
      //     ...available_quantity,
      //     crosscutting_completed: true,
      //   });
      // }
      // if (Number(parsedPhysicalSqm) === Number(totalSqm)) {
      //   set_available_quantity({
      //     ...available_quantity,
      //     crosscutting_completed: true,
      //   });
      // }
    }
    setIsCalculated(true);
  };

  const handleRevert = async () => {
    setRevertLoading(true);
    try {
      const response = await revertCrossCuttingDone(actualLogDetails?.issue_for_crosscutting_id, isChecked);
      if (response?.data?.success) {
        Swal.fire({
          title: response?.data?.message,
          icon: "success",
          showCancelButton: false,
        });
        navigate("/factory/crosscut");
        setRevertLoading(false);
      }
    } catch (error) {
      setRevertLoading(false);
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showCancelButton: false,
      });
    }
  };

  return (
    <Div sx={{ mt: 0 }}>
      <Div>
        <IssuedLogTable actualData={addedItemDetailsList} />
      </Div>

      <Div sx={{ marginTop: 5, marginBottom: 2 }}>
        <Grid className="flex flex-row items-center justify-end m-2 ">
          <Checkbox
            checked={isChecked}
            onChange={() => {
              setIsChecked((prevState) => !prevState);
              setIsCalculated(false);
            }}
          />
          <Typography variant="h6" fontSize="14px" mb="0">
            isCompleted*
          </Typography>
        </Grid>

        <ItemDetailsTable
          actualLogData={actualLogDetails}
          setAddedItemDetailsList={setAddedItemDetailsList}
          addedItemDetailsList={addedItemDetailsList}
          finalData={finalData}
          machineList={machineList}
          isCalculated={isCalculated}
          setIsCalculated={setIsCalculated}
          handleCalculate={handleCalculate}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          wastageInfo={wastageInfo}
          code={code}
          setCode={setCode}
          setFinalData={setFinalData}
          initialItemForm={initialItemForm}
          setInitialItemForm={setInitialItemForm}
        />
        <Div className="flex flex-row items-center justify-center gap-6 mt-8">
          <div>
            {addedItemDetailsList?.length > 0 && (
              <LoadingButton
                onClick={() => {
                  Swal.fire({
                    text: "Are you sure you want to revert all Crosscutting items ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      // navigate("/factory/flitching");
                      handleRevert();
                    }
                  });
                }}
                // type="submit"
                size="small"
                variant="contained"
                color="secondary"
                loading={revertLoading}
              >
                Revert All
              </LoadingButton>
            )}
          </div>
          <div className="flex flex-row items-center justify-center gap-6">
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                Swal.fire({
                  // title: "Warning",
                  text: "Are you sure you want to cancel?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/factory/crosscut");
                  }
                });
              }}
            >
              Cancel
            </Button>
            {isCalculated ? (
              <LoadingButton
                // onClick={handleSubmit}
                onClick={() => {
                  Swal.fire({
                    text: isChecked ? "Crosscutting has been marked as completed. Are you sure you want to save?" : "Crosscutting has been marked as incompleted. Are you sure you want to save?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleSubmit()
                    }
                  });
                }}
                // type="submit"
                size="small"
                variant="contained"
                loading={loading}
              >
                Save
              </LoadingButton>
            ) : (
              <Tooltip title={addedItemDetailsList?.length !== 0 ? "" : "You need to add at least one item"}>
                <span>
                  <LoadingButton disabled={addedItemDetailsList.length === 0} onClick={handleCalculate} size="small" variant="contained">
                    Calculate
                  </LoadingButton>
                </span>
              </Tooltip>
            )}
          </div>
        </Div>
      </Div>
    </Div>
  );
}

export default HeaderTitleHoc(EditCrossCutDone, "Edit Crosscut");
