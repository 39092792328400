import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./app/App";
import "./app/config/i18n";
import axios from "axios";
import Cookies from "js-cookie";
import './index.css';
import io from 'socket.io-client';

export const socket = io(process.env.REACT_APP_BACKEND_BASE_URL);
socket.on('connect', () => {
  console.log('Connected to server, socket ID:', socket.id);  // Access socket.id
});

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_URL, withCredentials: true
});

Axios.interceptors.request.use(function (request) {
  // const token = localStorage.getItem("token");
  const token = Cookies.get("token");
  if (token) {
    request.headers.Authorization = token;
  }
  request.withCredentials = true;
  request.headers.withCredentials = true; 
  return request;
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
