// export const incrementLogCode = (code, logCode) => {
//     console.log("code got => ", code)
//     if (!code || code === "A") {
//         return {
//             newCode: code,
//             newLogCode: `${logCode}${code}`

//         }
//     }
//     let asciiCode = code?.charCodeAt();
//     asciiCode = code?.charCodeAt(0);
//     asciiCode += 1
//     const newLogCode = String.fromCharCode(asciiCode);
//     console.log("values to be sent => ", {
//         newCode: asciiCode,
//         newLogCode: `${logCode}${newLogCode}`
//     }
//     )
//     return {
//         newCode: asciiCode,
//         newLogCode: `${logCode}${newLogCode}`

//     }
// }

// export const incrementLogCodeForAdd = (item_sr_no, logCode, code) => {
//     console.log("code got => ", code)
//     const selectedCode = code || 'A'
//     // const asciiStart = 'A'.charCodeAt(0);
//     const asciiStart = selectedCode.charCodeAt(0);
//     const newCode = String.fromCharCode(asciiStart + Number(item_sr_no - 1));
//     const newLogCode = `${logCode}${newCode}`;
//     return {
//         newCode,
//         newLogCode
//     };
// };

// for add
export const incrementLogCodeForAdd = (item_sr_no, logCode, code) => {
    console.log("code got => ", item_sr_no, logCode, code);
    // const selectedCode = code || 'A'; // Default to uppercase 'A'

    let selectedCode;
    // if (code && code === 'A') {
    //     selectedCode = 'B'; // If the code from backend is 'A', increment it to 'B'
    // } else {
    //     selectedCode = code || 'A'; // Default to 'A' if no code is provided
    // }
    if (!code) {
        selectedCode = 'A';
        // } else if (code === 'A') {
        //     selectedCode = 'B'; // If the code is 'A', start from 'A'
    } else {
        // Increment the code if it's not 'A'
        const asciiCode = code.charCodeAt(0); // Get the ASCII value of the current code
        selectedCode = String.fromCharCode(asciiCode + 1); // Increment the ASCII value to get the next letter
    }
    const asciiStart = selectedCode.charCodeAt(0); // Get ASCII code of 'A'
    const newCode = String.fromCharCode(asciiStart + Number(item_sr_no - 1)); // Increment from uppercase 'A'
    const newLogCode = `${logCode}${newCode}`;
    return {
        newCode,
        newLogCode
    };
};

//new method
export const incrementLogCode = (item_sr_no, logCode, code) => {

    console.log("code got => ", code)
    console.log("item sr => ", item_sr_no)
    console.log("lg code => ", logCode)
    const selectedCode = code || 'A';


    const asciiStart = selectedCode.charCodeAt(0);

    // const serialOffset = Number(item_sr_no);


    // const newCode = String.fromCharCode(asciiStart + serialOffset);
    // const newCode = serialOffset === 1 ? selectedCode : String.fromCharCode(asciiStart + serialOffset - 1);
    const newCode = String.fromCharCode(asciiStart + Number(item_sr_no) - 1);
    // const newCode = String.fromCharCode(asciiStart + 1);
    const newLogCode = `${logCode}${newCode}`;

    return {
        newCode,
        newLogCode
    };
};






// export const incrementLogCode = (item_sr_no, logCode, code, operation) => {
//     console.log("code got => ", code);
//     const selectedCode = code || 'A';
//     const asciiStart = selectedCode.charCodeAt(0);

//     // Determine the base code to work with based on item_sr_no
//     const baseCodeIndex = item_sr_no - 1; // Convert to zero-based index

//     let newCode;
//     let newLogCode;

//     if (operation === "add") {
//         // Increment the code for addition
//         newCode = String.fromCharCode(asciiStart + baseCodeIndex);
//         newLogCode = `${logCode}${newCode}`;
//     } else if (operation === "remove") {
//         // For removal, determine the last added code to maintain the sequence
//         newCode = String.fromCharCode(asciiStart + baseCodeIndex - 1);
//         newLogCode = `${logCode}${newCode}`;
//     }

//     return {
//         newCode,
//         newLogCode
//     };
// };
