// import Div from "@jumbo/shared/Div/Div";
// import { LoadingButton } from "@mui/lab";
// import { Button, Typography } from "@mui/material";
// import ListOptions from "app/components/Dropdown/ListOptions";
// import HeaderTitleHoc from "app/components/HeaderTitleHoc";
// import FormTextField from "app/components/InputField/FormTextField";
// import { loadUser } from "app/redux/actions/userAction";
// import { codeList, genderList } from "app/utils/constants/dropdowns.js";
// import { dateFun } from "app/utils/constants/functions";
// import axios from "axios";
// import { Form, Formik } from "formik";
// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import * as yup from "yup";

// function EditUserProfile() {
//   const { user } = useSelector((state) => state.userReducer);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [isSubmitting, setSubmitting] = useState(false);
//   const [data, setData] = useState({
//     first_name: user?.first_name ? user?.first_name : "",
//     last_name: user?.last_name ? user?.last_name : "",
//     country_code: user?.country_code ? user?.country_code : "+91",
//     phone: user?.mobile_no ? user?.mobile_no : null,
//     gender: user?.gender ? user?.gender : "Select",
//     age: user?.age ? user?.age : "",
//     // date_of_birth: user?.date_of_birth ? user?.date_of_birth : formattedDate,
//   });

//   useEffect(() => {
//     setData({
//       first_name: user?.[0]?.first_name ? user?.[0]?.first_name : "",
//       last_name: user?.[0]?.last_name ? user?.[0]?.last_name : "",
//       country_code: user?.[0]?.country_code ? user?.[0]?.country_code : "+91",
//       phone: user?.[0]?.mobile_no ? user?.[0]?.mobile_no : null,
//       gender: user?.[0]?.gender ? user?.[0]?.gender : "Select",
//       age: user?.[0]?.age ? user?.[0]?.age : "",
//       // date_of_birth: user?.date_of_birth ? user?.date_of_birth : formattedDate,
//     });
//   }, [user]);

//   const validationSchema = yup.object({
//     first_name: yup
//       .string("Enter First Name")
//       .required("First Name is required")
//       .matches(
//         /^[A-Za-z\s]+$/,
//         "First Name must contain only alphabetic characters"
//       ),
//     last_name: yup
//       .string("Enter Last Name")
//       .required("Last Name is required")
//       .matches(
//         /^[A-Za-z\s]+$/,
//         "Last Name must contain only alphabetic characters"
//       ),

//     phone: yup
//       .number()
//       .typeError("Phone number must be a number"),
//     // .required("Phone number is required"),
//     gender: yup
//       .string()
//       .required("Gender is required")
//       .test(
//         "gender-not-select",
//         "Please select a valid Gender",
//         (value) => value !== "Select"
//       ),
//     age: yup
//       .number()
//       .typeError("Age must be a number")
//     // .required("Age is required"),
//     // date_of_birth: yup
//     //   .date()
//     //   .test("not-current-date", "Enter Valid Date of Birth", function (value) {
//     //     if (!value) {
//     //       // Handle case where value is not provided
//     //       return false;
//     //     }

//     //     const currentDate = new Date();
//     //     currentDate.setHours(0, 0, 0, 0); // Set time to midnight

//     //     return value < currentDate; // Change this to <= to allow the current date
//     //   })
//     //   .required("Date Of Birth is required"),
//   });

//   const onUserSave = async (values) => {
//     setSubmitting(true);
//     try {
//       const config = {
//         withCredentials: true,
//         headers: {
//           withCredentials: true,
//         },
//       };
//       const data = await axios.post(
//         `${process.env.REACT_APP_URL}/profile/update-user-profile?id=${user?.[0]?._id}`,
//         { ...values, date_of_birth: dateFun(values?.date_of_birth) },
//         config
//       );
//       if (data.status === 200) {
//         Swal.fire({ icon: "success", title: "Profile Updated" });
//         navigate("/profile");
//         dispatch(loadUser());
//       }
//     } catch (error) {
//       Swal.fire({ icon: "error", title: "Profile Not Updated" });
//     }
//     setSubmitting(false);
//   };

//   return (
//     <Div sx={{ mt: -4 }}>
//       {/* <Typography variant="h1">Edit User Profile</Typography> */}
//       <Div>
//         <Formik
//           validateOnChange={true}
//           initialValues={data}
//           enableReinitialize={true}
//           validationSchema={validationSchema}
//           onSubmit={onUserSave}
//         // onSubmit={(values) => console.log(values)}
//         >
//           {({ setFieldValue, values }) => (
//             <Form noValidate autoComplete="off">
//               <Div sx={{ mt: 4 }}>
//                 <Div
//                   sx={{
//                     display: "flex",
//                     width: "100%",
//                     flexWrap: "wrap",
//                     columnGap: 4,
//                   }}
//                 >
//                   <FormTextField name="first_name" label="First Name" />
//                   <FormTextField name="last_name" label="Last Name" />
//                   <ListOptions
//                     name="gender"
//                     label="Gender"
//                     options={genderList}
//                   />
//                   <FormTextField name="age" label="Age" />
//                   <Div sx={{ width: "45%" }}>
//                     <Typography variant="h5">Phone No*</Typography>
//                     <Div sx={{ display: "flex", mt: -1 }}>
//                       <ListOptions
//                         options={codeList}
//                         name={"country_code"}
//                         sx={{ width: "80px" }}
//                       />
//                       <FormTextField
//                         name="phone"
//                         type="number"
//                         sx={{ width: "100%" }}
//                       />
//                     </Div>
//                   </Div>

//                   {/* <Div sx={outerDiv}>
//                     <Typography variant="h5">Date of Birth</Typography>
//                     <LocalizationProvider dateAdapter={AdapterDayjs}>
//                       <DatePicker
//                         sx={{
//                           "&.MuiTextField-root": {
//                             height: "39px",
//                             flexDirection: "unset",
//                           },
//                         }}
//                         maxDate={dayjs()}
//                         format="DD/MM/YYYY"
//                         defaultValue={dayjs(values.date_of_birth)}
//                         onChange={(date) => {
//                           setFieldValue("date_of_birth", date);
//                         }}
//                       />
//                       <Div sx={{ height: "30px" }}>
//                         <ErrorMessage
//                           name="date_of_birth"
//                           component="div"
//                           style={{ color: "red" }}
//                         />
//                       </Div>
//                     </LocalizationProvider>
//                   </Div> */}
//                 </Div>

//                 <Div
//                   sx={{
//                     width: "93.5%",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     gap: 3,
//                     mt: 3,
//                   }}
//                 >
//                   <Button
//                     variant="outlined"
//                     onClick={() => {
//                       Swal.fire({
//                         title: "Are you sure you want to cancel?",
//                         icon: "warning",
//                         showCancelButton: true,
//                         confirmButtonText: "Yes",
//                         cancelButtonText: "No",
//                       }).then((result) => {
//                         if (result.isConfirmed) {
//                           navigate("/dashboard");
//                         }
//                       });
//                     }}
//                   >
//                     Cancel
//                   </Button>
//                   <LoadingButton
//                     variant="contained"
//                     type="submit"
//                     sx={{ width: "100px" }}
//                     loading={isSubmitting}
//                   >
//                     Save
//                   </LoadingButton>
//                 </Div>
//               </Div>
//             </Form>
//           )}
//         </Formik>
//       </Div>
//     </Div>
//   );
// }

// export default HeaderTitleHoc(EditUserProfile, "Edit Profile");
import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Button, Grid, InputAdornment, Switch, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AllApis from "app/Apis";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import { loadUser } from "app/redux/actions/userAction";
import { departmentNameDropDown } from "app/services/apis/department_list";
import { getRoleListForDept, getUserForApprover, updateUserDetailsAdmin } from "app/services/apis/userApis";
import { bloodGroup, codeList, genderList, outerDiv1 } from "app/utils/constants/dropdowns.js";
import { dateFun } from "app/utils/constants/functions";
import axios from "axios";
import { City, Country, State } from "country-state-city";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

const disabledStyling = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#475259",
  },
  "& .MuiInputLabel-root": {
    color: "#475259", // Change label color to blue
  },
};

function EditUserProfile() {
  const navigate = useNavigate();

  const [isSubmitting, setSubmitting] = useState(false);
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  // const data = state;
  const { user } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch()
  const data = user[0]
  const userDetails = {
    user_name: data?.user_name ? data.user_name : "",
    user_type: data?.user_type ? data.user_type : "",

    dept_name: data?.dept_name ? data.dept_name : "",
    dept_id: data?.dept_id ? data.dept_id : "",

    approver_user_name: data?.approver_user_name ? data.approver_user_name : "",
    approver_id: data?.approver_id ? data.approver_id : null,

    role_id: data?.role_id?._id ? data?.role_id?._id : "",

    first_name: data?.first_name ? data?.first_name : "",
    last_name: data?.last_name ? data?.last_name : "",
    email_id: data?.email_id ? data?.email_id : "",
    gender: data?.gender ? data?.gender : "",
    blood_group: data?.blood_group ? data?.blood_group : "",
    age: data?.age ? data?.age : "",
    mobile_no: data?.mobile_no ? data?.mobile_no : "",
    country_code: data?.country_code ? data?.country_code : "+91",
    country: data?.country ? data?.country : "",
    state: data?.state ? data?.state : "",
    city: data?.city ? data?.city : "",
    pincode: data?.pincode ? data?.pincode : "",
    address: data?.address ? data?.address : "",
    dob: data?.dob ? data?.dob : "",
    status: data?.status == false || data?.status == true ? data?.status : true,
    user_remarks: data?.user_remarks ? data?.user_remarks : "",
  };
  const validationSchema = yup.object({
    user_name: yup.string("Enter User Name.").required("User Name is required"),
    // user_type: yup.string("Select User Type.").required("User Type is required."),
    // dept_name: yup.string("Select Department.").required("Department is required."),
    // approver_user_name: yup.string("Select Approver.").required("Approver is required."),
    first_name: yup
      .string("Enter First Name")
      .required("First Name is required")
      .matches(/^[A-Za-z\s]+$/, "First Name must contain only alphabetic characters"),
    last_name: yup
      .string("Enter Last Name")
      .required("Last Name is required")
      .matches(/^[A-Za-z\s]+$/, "Last Name must contain only alphabetic characters"),
    gender: yup
      .string()
      .required("Gender is required")
      .test("gender-not-select", "Please select a valid Gender", (value) => value !== "Select"),
    role_id: yup
      .string()
      .required("Role is Required")
      .test(
        // "role-not-select",
        "",
        "Please select a valid Role",
        (value) => value !== "Select"
      ),
    email_id: yup
      .string("Enter a valid email") // Field type is string
      .email("Enter a valid email address") // Ensures it matches a valid email pattern
      .nullable() // Allows null or undefined values
      .notRequired() // Makes it optional (not required)
      .test("is-valid-email", "Enter a valid email address", (value) => {
        return !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value); // Custom regex to enforce email format
      }),
    pincode: yup
      .string() // Ensure the field is treated as a string
      .nullable() // Allows null or undefined values
      .notRequired() // Makes it optional
      .matches(/^\d{6}$/, "Pincode must be exactly 6 digits"), // Validate if entered, must be 6 digits
  });

  const onUserSave = async (values) => {
    setSubmitting(true);
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/profile/update-user-profile?id=${user?.[0]?._id}`,
        { ...values, date_of_birth: dateFun(values?.date_of_birth) },
        config
      );
      if (data.status === 200) {
        Swal.fire({ icon: "success", title: "Profile Updated" });
        navigate("/dashboard");
        dispatch(loadUser());
      }
    } catch (error) {
      Swal.fire({ icon: "error", title: "Profile Not Updated" });
    }
    setSubmitting(false);
  };

  const filterStates = (countryIsoCode) => {
    // const filteredStates = State.getAllStates().filter((state) => state?.countryCode === countryIsoCode);
    const filteredStates = State.getStatesOfCountry(countryIsoCode);
    setStateList(filteredStates);
  };

  const filterCities = (countryCode, stateIsoCode) => {
    // const filteredCities = City.getAllCities().filter((city) => city.stateCode === stateIsoCode && city.countryCode == countryCode);
    const filteredCities = City.getCitiesOfState(countryCode, stateIsoCode);
    setCityList(filteredCities);
  };

  function calculateAge(selectedDate) {
    const currentDate = new Date();
    const birthDate = new Date(selectedDate);

    // Calculate the difference in milliseconds
    const ageDifference = currentDate - birthDate;

    // Convert the difference to years
    const age = Math.floor(ageDifference / (1000 * 60 * 60 * 24 * 365.25));

    return age;
  }

  // const handleGetApproverList = async (dept_name) => {
  //   getUserForApprover({ dept_name: dept_name })
  //     .then((response) => {
  //       if (response?.data?.status) {
  //         // setApproverList([{ _id: null, user_name: "SELF APPROVED" }, ...response?.data?.result]);
  //         const approvalListData = response?.data?.result?.filter((ele) => ele?.user_name !== user?.user_name)
  //         setApproverList([{ _id: null, user_name: "SELF APPROVED" }, ...approvalListData]);
  //       }
  //     })
  //     .catch((error) => console.error("Error fetching supplier list:", error));
  // };

  // const handleGetRoleListForDept = async (dept_name) => {
  //   getRoleListForDept({ dept_name: dept_name })
  //     .then((response) => {
  //       if (response?.data?.status) {
  //         setRoles(response.data.result);
  //       }
  //     })
  //     .catch((error) => console.error("Error fetching supplier list:", error));
  // };

  // useEffect(() => {
  //   if (state) {
  //     const country = Country.getAllCountries().filter((country) => country.name === state.country);
  //     const stateList = State.getAllStates().filter((country) => country.name === state.state);
  //     filterStates(country[0]?.isoCode);
  //     filterCities(country[0]?.isoCode, stateList[0]?.isoCode);

  //     handleGetApproverList(state?.dept_name);
  //     handleGetRoleListForDept(state?.dept_name);
  //   }
  // }, []);

  // useEffect(() => {
  //   (async () => {
  //     // setRoles(await getRoles());
  //     departmentNameDropDown("")
  //       .then((response) => {
  //         if (response.data.success) {
  //           setDepartmentList(response.data.result);
  //         }
  //       })
  //       .catch((error) => console.error("Error fetching supplier list:", error));
  //   })();
  // }, []);

  return (
    <Div sx={{ mt: -4 }}>
      {/* <Typography variant="h1">{pathname === "/user/add" ? "Add New User" : "Edit User"}</Typography> */}
      <Div>
        <Formik
          validateOnChange={false}
          initialValues={userDetails}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ setFieldValue, values, errors, touched, handleBlur, setFieldTouched }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      User Name*
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.user_name}
                      sx={{ width: "100%" }}
                      name="user_name"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("user_name", e.target.value)}
                      error={touched?.user_name && errors?.user_name}
                      helperText={touched?.user_name && errors?.user_name}
                    />
                  </Grid>
                  {/* <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      User Type*
                    </Typography>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={userType}
                      name="user_type"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option;
                        } else {
                          return option;
                        }
                      }}
                      value={values?.user_type}
                      onChange={(e, value) => {
                        setFieldValue("user_type", value || "");
                        if (value === "ADMIN") {
                          setFieldValue("approver_user_name", "SELF APPROVED");
                        } else {
                          setFieldValue("approver_user_name", "");
                        }
                      }}
                      onBlur={(e) => {
                        setFieldTouched("user_type", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.user_type && Boolean(errors.user_type)} // Show error if touched and there's an error
                          helperText={touched.user_type && errors.user_type} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid> */}
                  {/* <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Department*
                    </Typography>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={departmentList}
                      name="dept_name"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.dept_name;
                        } else {
                          return option;
                        }
                      }}
                      // value={values?.dept_name}
                      value={departmentList.find((role) => role.dept_name === values.dept_name) || null}
                      onChange={(e, value) => {
                        console.log(value, "values");
                        setFieldValue("dept_name", value?.dept_name || "");
                        setFieldValue("dept_id", value?._id || "");
                        handleGetApproverList(value?.dept_name);
                        handleGetRoleListForDept(value?.dept_name);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("dept_name", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.dept_name && Boolean(errors.dept_name)} // Show error if touched and there's an error
                          helperText={touched.dept_name && errors.dept_name} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid> */}
                  {/* <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Approver*
                    </Typography>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      disabled={values?.dept_name === "" ? true : false}
                      size="small"
                      options={approverList}
                      name="approver_user_name"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.user_name;
                        } else {
                          return option;
                        }
                      }}
                      // value={values?.approver_user_name}
                      value={approverList.find((role) => role?.user_name === values?.approver_user_name) || null}
                      onChange={(e, value) => {
                        setFieldValue("approver_user_name", value?.user_name);
                        setFieldValue("approver_id", value?._id);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("approver_user_name", true);
                      }}
                      getOptionDisabled={(option) => option.user_name === "SELF APPROVED" && values.user_type === "STAFF"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.approver_user_name && Boolean(errors.approver_user_name)} // Show error if touched and there's an error
                          helperText={touched.approver_user_name && errors.approver_user_name} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid> */}
                  {/* <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Role*
                    </Typography>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={roles}
                      name="role_id"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.role_name;
                        } else {
                          return option;
                        }
                      }}
                      // value={values?.role_id}
                      value={roles.find((role) => role._id === values.role_id) || null}
                      onChange={(e, value) => {
                        setFieldValue("role_id", value?._id);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("role_id", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.role_id && Boolean(errors.role_id)} // Show error if touched and there's an error
                          helperText={touched.role_id && errors.role_id} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid> */}
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      First Name*
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.first_name}
                      sx={{ width: "100%" }}
                      name="first_name"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("first_name", e.target.value)}
                      error={touched?.first_name && errors?.first_name}
                      helperText={touched?.first_name && errors?.first_name}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Last Name*
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.last_name}
                      sx={{ width: "100%" }}
                      name="last_name"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("last_name", e.target.value)}
                      error={touched?.last_name && errors?.last_name}
                      helperText={touched?.last_name && errors?.last_name}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Email
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.email_id}
                      sx={{ width: "100%" }}
                      name="email_id"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("email_id", e.target.value)}
                      error={touched?.email_id && errors?.email_id}
                      helperText={touched?.email_id && errors?.email_id}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Country
                    </Typography>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={countryList}
                      name="country"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.name;
                        } else {
                          return option;
                        }
                      }}
                      value={values?.country || null}
                      onChange={(e, value) => {
                        setFieldValue("country", value?.name || "");
                        setFieldValue("state", "");
                        setFieldValue("city", "");
                        setFieldValue("countryCode", value?.phonecode || "");
                        filterStates(value?.isoCode);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("country", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.country && Boolean(errors.country)} // Show error if touched and there's an error
                          helperText={touched.country && errors.country} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      State
                    </Typography>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={StateList}
                      name="state"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.name;
                        } else {
                          return option;
                        }
                      }}
                      value={values?.state}
                      onChange={(e, value) => {
                        setFieldValue("state", value?.name);
                        setFieldValue("city", "");
                        filterCities(value?.countryCode, value?.isoCode);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("state", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.state && Boolean(errors.state)} // Show error if touched and there's an error
                          helperText={touched.state && errors.state} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      City
                    </Typography>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={cityList}
                      name="city"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.name;
                        } else {
                          return option;
                        }
                      }}
                      value={values?.city}
                      onChange={(e, value) => {
                        setFieldValue("city", value?.name);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("city", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.city && Boolean(errors.city)} // Show error if touched and there's an error
                          helperText={touched.city && errors.city} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Address
                    </Typography>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.address}
                      sx={{ width: "100%" }}
                      name="address"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("address", e.target.value)}
                      error={touched?.address && errors?.address}
                      helperText={touched?.address && errors?.address}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Pincode
                    </Typography>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.pincode}
                      sx={{
                        width: "100%",
                        "& input[type=number]": {
                          MozAppearance: "textfield", // Hides the spinner in Firefox
                        },
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                          WebkitAppearance: "none", // Hides the spinner in Chrome, Safari, Edge, and Opera
                          margin: 0,
                        },
                      }}
                      name="pincode"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("pincode", e.target.value)}
                      error={touched?.pincode && errors?.pincode}
                      helperText={touched?.pincode && errors?.pincode}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Gender*
                    </Typography>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={genderList}
                      name="gender"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option;
                        } else {
                          return option;
                        }
                      }}
                      value={values?.gender}
                      onChange={(e, value) => {
                        setFieldValue("gender", value || "");
                      }}
                      onBlur={(e) => {
                        setFieldTouched("gender", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.gender && Boolean(errors.gender)} // Show error if touched and there's an error
                          helperText={touched.gender && errors.gender} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Blood Group
                    </Typography>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={bloodGroup}
                      name="blood_group"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option;
                        } else {
                          return option;
                        }
                      }}
                      value={values?.blood_group}
                      onChange={(e, value) => {
                        setFieldValue("blood_group", value);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("blood_group", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.blood_group && Boolean(errors.blood_group)} // Show error if touched and there's an error
                          helperText={touched.blood_group && errors.blood_group} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Date Of Birth
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        maxDate={dayjs()}
                        format="DD-MM-YYYY"
                        defaultValue={values?.dob !== "" ? dayjs(values?.dob) : null}
                        onBlur={(e) => {
                          setFieldTouched("dob", true);
                        }}
                        onChange={(newValue) => {
                          setFieldValue("dob", newValue?.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") || "");
                          const age = calculateAge(newValue?.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                          setFieldValue("age", age || 0);
                        }}
                        error={touched.dob && Boolean(errors.dob)} // Show error if touched and there's an error
                        helperText={touched.dob && errors.dob}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Age
                    </Typography>
                    <TextField
                      // type="number"
                      disabled
                      size="small"
                      value={values?.age}
                      sx={{ width: "100%" }}
                      name="age"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("age", e.target.value)}
                      error={touched?.age && errors?.age}
                      helperText={touched?.age && errors?.age}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Phone No
                    </Typography>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.mobile_no}
                      // sx={{ width: "100%", "& .MuiInputBase-root": { paddingLeft: "0px" } }}
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": { paddingLeft: "0px" },
                        "& input[type=number]": {
                          MozAppearance: "textfield", // Hides the spinner in Firefox
                        },
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                          WebkitAppearance: "none", // Hides the spinner in Chrome, Safari, Edge, and Opera
                          margin: 0,
                        },
                      }}
                      name="mobile_no"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("mobile_no", e.target.value)}
                      error={touched?.mobile_no && errors?.mobile_no}
                      helperText={touched?.mobile_no && errors?.mobile_no}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ padding: "0px" }}>
                            <Autocomplete
                              autoSelect
                              autoHighlight
                              sx={{
                                width: "80px",
                                "& .MuiOutlinedInput-root": {
                                  padding: "0px", // Remove padding inside Autocomplete's TextField
                                },
                              }}
                              clearIcon={null}
                              size="small"
                              options={codeList}
                              name="country_code"
                              getOptionLabel={(option) => option}
                              value={values?.country_code}
                              onChange={(e, value) => {
                                setFieldValue("country_code", value);
                              }}
                              onBlur={(e) => {
                                setFieldTouched("country_code", true);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  error={touched.country_code && Boolean(errors.country_code)} // Show error if touched and there's an error
                                  helperText={touched.country_code && errors.country_code} // Display helper text for the error
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Div sx={outerDiv1}>
                      <Typography variant="h6" fontSize="14px">
                        Remarks
                      </Typography>
                      <TextField
                        multiline
                        size="small"
                        value={values?.user_remarks}
                        sx={{ width: "100%" }}
                        name="user_remarks"
                        onBlur={async (e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => setFieldValue("user_remarks", e.target.value)}
                        error={touched?.user_remarks && errors?.user_remarks}
                        helperText={touched?.user_remarks && errors?.user_remarks}
                      />
                    </Div>
                  </Grid>
                </Grid>
                {/* <Div sx={{ mt: 3 }}>
                  <Typography variant="h6" fontSize="14px">
                    Status
                  </Typography>
                  <Switch
                    onChange={(e) => {
                      setFieldValue("status", values.status == true ? false : true);
                    }}
                    defaultChecked={values.status == true ? true : false}
                    sx={{
                      p: 0,
                      width: "70px",
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: values.status === true ? "green" : "red",
                        width: "90%",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                        backgroundColor: values.status === true ? "green" : "red",
                      },
                    }}
                  />
                </Div> */}
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/user");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton variant="contained" type="submit" sx={{ width: "100px" }} loading={isSubmitting}>
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}

export default HeaderTitleHoc(EditUserProfile, "Edit Profile");
