import React, { useEffect, useState } from "react";
import ListingInvoiceItems from "./ListingCoreItems";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoading, setLoading } from "app/redux/actions/Loading";
import { Axios } from "index";
import Swal from "sweetalert2";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import { Box, Button, Typography } from "@mui/material";
import ListingSupplierDetails from "./ListingSupplierDetails";
import ListingInvoiceDetails from "./ListingInvoiceDetails";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { coreApproveInvoiceApi, coreRejectInvoiceApi, fetchCoreItemsApprovalApi } from "app/services/apis/Inventory/Core/constantsApi";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ViewDetails = () => {
  const [coreInvoiceItems, setCoreInvoiceItems] = useState({
    items: [],
    invoice: {},
  });
  const isApprovalPending = coreInvoiceItems.invoice?.approval_status?.sendForApproval?.status || false;
  const isApproved = coreInvoiceItems.invoice?.approval_status?.approved?.status || false;
  const isRejected = coreInvoiceItems.invoice?.approval_status?.rejected?.status || false;
  const { id, invoiceId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function fetchCoreItemsList() {
    try {
      dispatch(setLoading());
      const response = await Axios.get(fetchCoreItemsApprovalApi(id, invoiceId));
      if (response?.data?.status === "success") {
        const data = response?.data?.data;
        setCoreInvoiceItems(data);
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error?.response?.data?.message || error?.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      dispatch(hideLoading());
    }
  }
  async function handleApprove() {
    try {
      dispatch(setLoading());
      const response = await Axios.post(coreApproveInvoiceApi(id, invoiceId));
      if (response?.data?.status === "success") {
        const data = response?.data?.data;
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Approved",
          text: data?.message,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/inventory/core");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error?.response?.data?.message || error?.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      dispatch(hideLoading());
    }
  }
  async function handleReject() {
    try {
      dispatch(setLoading());
      const response = await Axios.post(coreRejectInvoiceApi(id, invoiceId), {
        remark: "Rejected",
      });
      if (response?.data?.status === "success") {
        const data = response?.data?.data;
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Rejected",
          text: data?.message,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/inventory/core");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error?.response?.data?.message || error?.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      dispatch(hideLoading());
    }
  }

  useEffect(() => {
    fetchCoreItemsList();
  }, []);
  return (
    <Box sx={{ mt: -4 }}>
      <ListingInvoiceDetails invoiceData={coreInvoiceItems?.invoice} isApprovalPending={isApprovalPending} />
      <ListingSupplierDetails
        supplier_details={coreInvoiceItems?.invoice?.supplier_details}
        previous_data={coreInvoiceItems?.invoice?.previous_data?.supplier_details}
        isApprovalPending={isApprovalPending}
      />
      <ListingInvoiceItems invoiceItems={coreInvoiceItems?.items} isApprovalPending={isApprovalPending}/>
      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 5,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            Swal.fire({
              title: "Warning",
              text: "Are you sure you want to Cancel?",
              icon: "error",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/inventory/core");
              }
            });
          }}
        >
          Cancel
        </Button>
        {!isApproved && !isRejected && (
          <>
            <LoadingButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                Swal.fire({
                  title: "Reject ?",
                  text: "Are you sure you want to Reject?",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleReject();
                  }
                });
              }}
              disabled={isRejected}
            >
              Reject
            </LoadingButton>
            <LoadingButton
              color="success"
              size="small"
              variant="contained"
              onClick={() => {
                Swal.fire({
                  title: "Approve ?",
                  text: "Are you sure you want to Approve?",
                  icon: "success",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleApprove();
                  }
                });
              }}
              disabled={isApproved}
            >
              Approve
            </LoadingButton>
          </>
        )}
        {isApproved && (
          <Typography variant="h4">
            <CheckCircleIcon color="success" sx={{ fontSize: "30px" }} /> Approved
          </Typography>
        )}
        {isRejected && (
          <Typography variant="h4">
            <CancelIcon color="error" sx={{ fontSize: "30px" }} /> Rejected
          </Typography>
        )}
      </Div>
    </Box>
  );
};

export default HeaderTitleHoc(ViewDetails, "Core Approval Details");
