import Div from '@jumbo/shared/Div';
import { Box, Button, Grid } from '@mui/material';
import FilterAccordian from 'app/components/FilterAccordian';
import { veneerInventoryFilter } from 'app/utils/constants/sessionFilter';
import React, { useEffect, useMemo, useState } from 'react';
import { ApprovalList, CustomDatePicker, InwardSrNo, ItemName, SupplierList } from './FiltersComponents';

const supplier_name =
  "veneer_invoice_details.supplier_details.company_details.supplier_name";

const approvalPending = 'veneer_invoice_details.approval_status.sendForApproval.status'
const approvalApproved = 'veneer_invoice_details.approval_status.approved.status'
const approvalRejected = 'veneer_invoice_details.approval_status.rejected.status'

const filterObj = {
  item_sr_no: "",
  "veneer_invoice_details.inward_sr_no": "",
  item_name: "",
  [supplier_name]: "",
  [approvalPending]: null,
  [approvalApproved]: null,
  [approvalRejected]: null,
  range: {
    date: {
      "veneer_invoice_details.inward_date": {
        from: "",
        to: "",
      },
    },
  },
};

function VeneerFilters({ handleSubmit, handleClear, sx }) {
  const [filters, setFilters] = useState(JSON.parse(JSON.stringify(filterObj)))

  const handleFilter = useMemo(() => {
    return setFilters
  }, [])

  useEffect(() => {
    let retrieveFilter = sessionStorage.getItem(veneerInventoryFilter);
    if (retrieveFilter) {
      let filtersValues = JSON.parse(retrieveFilter)
      if (filtersValues?.filter) {
        setFilters(filtersValues?.filter)
      }
    }
  }, [])

  return (
    <Box sx={{ ...sx }}>
      <FilterAccordian
        actions={
          <Div>
            <Button variant="contained" size="small" sx={{ marginRight: 1 }} onClick={() => {
              handleSubmit(filters)
            }}>
              Apply
            </Button>

            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setFilters(JSON.parse(JSON.stringify(filterObj)));
                handleClear(null);
              }}
            >
              Clear
            </Button>
          </Div>
        }
      >
        <Grid container spacing={3}>
          {/* <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <ItemSrNo value={filters?.item_sr_no} setFilters={handleFilter}/>
                        </Grid> */}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <InwardSrNo
              value={filters?.["veneer_invoice_details.inward_sr_no"]}
              name={"veneer_invoice_details.inward_sr_no"}
              setFilters={handleFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <ItemName value={filters?.item_name} setFilters={handleFilter} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <SupplierList
              name={supplier_name}
              value={filters?.[supplier_name]}
              setFilters={handleFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <CustomDatePicker
              title={"Inward Date From"}
              name="veneer_invoice_details.inward_date"
              position={"from"}
              value={
                filters?.range?.date?.["veneer_invoice_details.inward_date"]?.from
              }
              setFilters={handleFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <CustomDatePicker
              title={"Inward Date To"}
              name="veneer_invoice_details.inward_date"
              position={"to"}
              value={
                filters?.range?.date?.["veneer_invoice_details.inward_date"]?.to
              }
              setFilters={handleFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <ApprovalList
              filters={filters}
              setFilters={handleFilter}
              pending={approvalPending}
              approved={approvalApproved}
              rejected={approvalRejected}
            />
          </Grid>
        </Grid>
      </FilterAccordian>
    </Box>
  );
}

export default React.memo(VeneerFilters);
