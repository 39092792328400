import { Axios } from "index";

export const departmentNameDropDown = async (type) => {
  try {
    const data = await Axios.get(
      `${process.env.REACT_APP_URL}/department-master/dropdown-department-master?type=${type}`
    );

    return data;
  } catch (error) {
    return error?.response;
  }
};
