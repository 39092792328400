import React from "react";
import { Box, Modal, Typography, } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { Axios } from "index"; // Assuming Axios is properly configured
import { LoadingButton } from "@mui/lab";
import FormTextField1 from "app/components/InputField/FormTextField1";
import { useDispatch } from "react-redux";
import { listItemSubCatgory } from "app/redux/actions/masterAction";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  name: yup
    .string("Enter Subcategory Name")
    .required("Subcategory Name is required"),
  remark: yup.string("Enter remarks"),
});

function SubcategoryModal({ open, handleClose, subcategoryData, setToggle }) {
  const initialValues = {
    name: subcategoryData?.data?.name ? subcategoryData?.data?.name : "",
    remark: subcategoryData?.data?.remark ? subcategoryData?.data?.remark : "",
  };
  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let res;
      values.name = values.name.toUpperCase();

      if (subcategoryData) {
        res = await Axios.post(
          `/item-subcategory/update/${subcategoryData.id}`,
          values
        );
      } else {
        res = await Axios.post("/item-subcategory/add", values);
      }

      if (res.data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          text: subcategoryData
            ? "Subcategory Updated Successfully"
            : "Subcategory Created Successfully",
          timer: 2000,
          showConfirmButton: false,
        });
        setToggle((prevState) => !prevState);
        dispatch(listItemSubCatgory("", "desc", "updated_at", 1));
        handleClose();
      } else {
        handleClose();
        Swal.fire({
          icon: "error",
          text: res.data.message,
          timer: 2000,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      handleClose();
      Swal.fire({
        icon: "error",
        text: error?.response?.data.message,
        timer: 2000,
        showConfirmButton: true,
      });
      console.log("Error adding/updating subcategory => ", error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <Box sx={style} className="lg:w-1/3 w-[20rem]">
        <div className="flex flex-row items-center justify-between">
          <Typography variant="h2">
            {subcategoryData ? "Edit Subcategory" : "Add Subcategory"}
          </Typography>
          <Close onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, isSubmitting }) => (
            <Form noValidate>
              <div className="grid grid-flow-row lg:grid-cols-2 grid-cols-1 gap-5 mt-8">
                <FormTextField1 name="name" label="Subcategory Name*" />
                <FormTextField1 name="remark" label="Remark" />
              </div>

              <div className="flex items-center justify-center mt-5">
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  {subcategoryData ? "Update" : "Add"}
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default SubcategoryModal;
