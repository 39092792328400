import { Box, Button, Typography } from "@mui/material";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import { hideLoading, setLoading } from "app/redux/actions/Loading";
import { addLogExpensesApi, fetchLogItemsExpensesApi } from "app/services/apis/Master/Expenses";
import { Axios } from "index";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ListingInvoiceItems from "./ListingLogItems";
import OtherExpensesForm from "./OtherExpensesForm";
import ListingExpenses from "./ListingExpenses";
import { expenseTypeDropDownList } from "app/services/apis/expenseTypeApis";
import { calculateInvoiceExpenseAmount } from "../../utils/calculateInvoiceExpenseAmount";

const AddExpenses = () => {
  const [expenseType, setExpenseType] = useState([]);
  const [logInvoiceItems, setLogInvoiceItems] = useState({
    items: [],
    invoice: {},
  });
  const invoiceExpenseAmount = calculateInvoiceExpenseAmount(logInvoiceItems?.invoice?.invoice_Details)
  console.log(invoiceExpenseAmount)
  const [totalExpenseAmount, setTotalExpenseAmount] = useState(0);
  const [otherExpensesList, setOtherExpensesList] = React.useState([]);
  const [isCalculate, setIsCalculate] = React.useState(true);
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function fetchLogItemsList() {
    try {
      dispatch(setLoading());
      const response = await Axios.get(fetchLogItemsExpensesApi(invoiceId));
      if (response?.data?.status === "success") {
        const data = response?.data?.data
        setLogInvoiceItems(data);
        const otherExpensesList = data?.invoice?.expenses || [];
        const getTotalExpensesAmount = data?.invoice?.totalExpenseAmount || 0;
        setOtherExpensesList(otherExpensesList);
        setTotalExpenseAmount(getTotalExpensesAmount);
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error?.response?.data?.message || error?.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      dispatch(hideLoading());
    }
  }

  const handleCalculate = function () {
    let totalExpenses = otherExpensesList?.reduce((acc, elm, index) => {
      acc += Number(elm?.amount);
      return acc;
    }, invoiceExpenseAmount);
    totalExpenses = Number(totalExpenses?.toFixed(2))
    const invoiceAmount = logInvoiceItems?.invoice?.invoice_Details?.total_item_amount || 0;
    const updateExpensesAmount = logInvoiceItems?.items?.map((elm, index) => {
      // const amount_factor = elm?.amount * (100 / invoiceAmount);
      const amount_factor = elm?.amount / invoiceAmount;
      elm.amount_factor = amount_factor;
      // elm.expense_amount = Number((amount_factor * (totalExpenses / 100))?.toFixed(3));
      elm.expense_amount = Number((amount_factor * totalExpenses)?.toFixed(2));
      return elm;
    });
    setLogInvoiceItems((prev) => ({ ...prev, items: updateExpensesAmount }));
    setTotalExpenseAmount(totalExpenses);
    setIsCalculate(false);
  };

  const addOtherExpensesApi = async function () {
    try {
      dispatch(setLoading());
      const response = await Axios.patch(addLogExpensesApi(invoiceId), {
        otherExpensesList,
        totalExpenseAmount,
      });
      if (response?.data?.statusCode === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Success",
          text: "Expenses Added Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate('/master/expense/log')
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Failed",
        text: error?.response?.data?.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      dispatch(hideLoading());
      setIsCalculate(true);
    }
  };

  const handleSubmit = function () {
    if (otherExpensesList?.length <= 0) {
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Failed",
        text: "Add atleast one expense",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    addOtherExpensesApi();
  };

  useEffect(() => {
    expenseTypeDropDownList().then((data) => {
      setExpenseType(data?.data?.result || []);
    });
    fetchLogItemsList();
  }, []);

  return (
    <Box sx={{ mt: -4 }}>
      <ListingInvoiceItems
        invoiceItems={logInvoiceItems?.items}
        totalAmount={logInvoiceItems?.invoice?.invoice_Details?.total_item_amount || 0}
        totalExpenseAmount={totalExpenseAmount}
      />
      <OtherExpensesForm setOtherExpensesList={setOtherExpensesList} expenseType={expenseType} setIsCalculate={setIsCalculate} />
      <Typography variant="h4">Invoice Expenses amount :- {invoiceExpenseAmount}</Typography>
      <Box>
        <ListingExpenses
          otherExpensesList={otherExpensesList}
          setOtherExpensesList={setOtherExpensesList}
          expenseType={expenseType}
          setIsCalculate={setIsCalculate}
        />
        <Box sx={{ textAlign: "center", m: 4 }}>
          <Button
            size="small"
            variant="outlined"
            sx={{ mr: 1 }}
            onClick={() => {
              Swal.fire({
                text: "Are you sure you want to cancel?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/master/expense/log");
                }
              });
            }}
          // onClick={() => navigate("/master/expense/log")}
          >
            Cancel
          </Button>
          {isCalculate ? (
            <Button size="small" variant="contained" onClick={handleCalculate}>
              Calculate
            </Button>
          ) : (
            <Button size="small" variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderTitleHoc(AddExpenses, "Manage Log Expenses");
