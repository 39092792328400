import Div from "@jumbo/shared/Div/Div";
import { useLocation, useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getApprovalConfig, updateApprovalConfig } from "app/services/apis/approvalConfigApis";
import { useSelector } from "react-redux";

export default function ConfigList() {
  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);
  const { state } = useLocation();
  const GreenCheckbox = withStyles({
    root: {
      "&$checked": {
        color: "green",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const headingStyle = {
    minWidth: "300px",
    fontSize: "1rem",
    fontWeight: "500",
  };
  const checkboxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 4,
    width: "100px",
  };

  const tableHeadCellSx = {
    textAlign: "left",
    minWidth: "100px",
    verticalAlign: "middle",
    px: 1,
    borderRight: "1px solid white",
  };

  const tableHeadSortSx = {
    color: "white",
    "&:hover": { color: "white" },
    "&.MuiTableSortLabel-root.Mui-active": {
      color: "white !important",
    },
    "& .MuiTableSortLabel-icon": {
      color: "white !important", // Set the color for the sorting icon
    },
    "&:hover": {
      color: "white !important", // Set the color when hovering
    },
  };

  const tableBodyCell = { textAlign: "left", px: 1 };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [configData, setConfigData] = useState({});

  const [check, setCheck] = useState({
    log_inventory: {
      edit: false,
    },
    flitch_inventory: {
      edit: false,
    },
    plywood_inventory: {
      edit: false,
    },
    veneer_inventory: {
      edit: false,
    },
    mdf_inventory: {
      edit: false,
    },
    face_inventory: {
      edit: false,
    },
    core_inventory: {
      edit: false,
    },
    fleece_inventory: {
      edit: false,
    },
    otherGoods_inventory: {
      edit: false,
    },
    crosscut_factory: {
      edit: false,
    },
    flitching_factory: {
      edit: false,
    },
  });

  const initialValues = {
    role_name: state?.role_name || "",
    roles_remarks: state?.roles_remarks || "",
    dept_name: state?.dept_name || "",
    dept_id: state?.dept_id || "",
    status: state?.status || true,
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const [parent, child] = name.split(".");
    setCheck((prev) => ({
      ...prev,
      [parent]: {
        ...prev[parent],
        [child]: checked,
      },
    }));
  };

  const handleSubmit = async (value) => {
    try {
      const details = {
        configuration: { ...check },
        id: configData?._id,
      };

      const data = await updateApprovalConfig(details);

      if (data.status == 200) {
        Swal.fire({
          icon: "success",
          title: data.data.message,
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/master/approval-config");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      let data = await getApprovalConfig();
      if (data?.status) {
        // console.log(data?.data?.result?.[0]?.configuration,"kkdgsfuibiubdvdn sksiu");
        setConfigData({ ...data?.data?.result?.[0] });
        setCheck({ ...data?.data?.result?.[0]?.configuration });
        setLoading(false);
      } else {
        setLoading(false);
        Swal.fire({
          text: "Something went wrong",
          icon: "error",
          showConfirmButton: true,
        });
      }
    })();
  }, []);

  return (
    <>
      {loading && <FullScreenLoader />}
      <Div sx={{ mt: -4 }}>
        <Div>
          <Formik
            validateOnChange={false}
            initialValues={initialValues}
            enableReinitialize
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, errors, touched, handleBlur, setFieldTouched, isSubmitting }) => (
              <Form noValidate>
                <Div sx={{ mt: 4 }}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow
                          sx={{
                            bgcolor: "#7352C7",
                            color: "white",
                            "& .MuiTableCell-root": {
                              // py: 2,
                            },
                          }}
                        >
                          <TableCell sx={{ ...tableHeadCellSx, width: "250px" }}>
                            <TableSortLabel sx={{ ...tableHeadSortSx }}>Module Name</TableSortLabel>
                          </TableCell>
                          <TableCell colSpan={1} sx={{ ...tableHeadCellSx, minWidth: "130px", textAlign: "center" }}>
                            <TableSortLabel sx={{ ...tableHeadSortSx }}>Actions</TableSortLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Log Inventory</TableCell>
                          <TableCell sx={{ ...tableBodyCell }}>
                            <GreenCheckbox checked={check?.log_inventory?.edit} onChange={handleChange} name="log_inventory.edit" />
                            Edit Inward
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Flitch Inventory</TableCell>
                          <TableCell sx={{ ...tableBodyCell }}>
                            <GreenCheckbox checked={check?.flitch_inventory?.edit} onChange={handleChange} name="flitch_inventory.edit" />
                            Edit Inward
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Plywood Inventory</TableCell>
                          <TableCell sx={{ ...tableBodyCell }}>
                            <GreenCheckbox checked={check?.plywood_inventory?.edit} onChange={handleChange} name="plywood_inventory.edit" />
                            Edit Inward
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Veneer Inventory</TableCell>
                          <TableCell sx={{ ...tableBodyCell }}>
                            <GreenCheckbox checked={check?.veneer_inventory?.edit} onChange={handleChange} name="veneer_inventory.edit" />
                            Edit Inward
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>MDF Inventory</TableCell>
                          <TableCell sx={{ ...tableBodyCell }}>
                            <GreenCheckbox checked={check?.mdf_inventory?.edit} onChange={handleChange} name="mdf_inventory.edit" />
                            Edit Inward
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Face Inventory</TableCell>
                          <TableCell sx={{ ...tableBodyCell }}>
                            <GreenCheckbox checked={check?.face_inventory?.edit} onChange={handleChange} name="face_inventory.edit" />
                            Edit Inward
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Core Inventory</TableCell>
                          <TableCell sx={{ ...tableBodyCell }}>
                            <GreenCheckbox checked={check?.core_inventory?.edit} onChange={handleChange} name="core_inventory.edit" />
                            Edit Inward
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Fleece Paper Inventory</TableCell>
                          <TableCell sx={{ ...tableBodyCell }}>
                            <GreenCheckbox checked={check?.fleece_inventory?.edit} onChange={handleChange} name="fleece_inventory.edit" />
                            Edit Inward
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Other Goods Inventory</TableCell>
                          <TableCell sx={{ ...tableBodyCell }}>
                            <GreenCheckbox
                              checked={check?.otherGoods_inventory?.edit}
                              onChange={handleChange}
                              name="otherGoods_inventory.edit"
                            />
                            Edit Inward
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>CrossCut Factory</TableCell>
                          <TableCell sx={{ ...tableBodyCell }}>
                            <GreenCheckbox checked={check?.crosscut_factory?.edit} onChange={handleChange} name="crosscut_factory.edit" />
                            Edit Inward
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Flitching Factory</TableCell>
                          <TableCell sx={{ ...tableBodyCell }}>
                            <GreenCheckbox checked={check?.flitching_factory?.edit} onChange={handleChange} name="flitching_factory.edit" />
                            Edit Inward
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Div>
                {permissions?.approval_config?.edit && <Div
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <LoadingButton size="small" variant="contained" type="submit" sx={{ width: "100px" }} loading={isSubmitting}>
                    Save
                  </LoadingButton>
                </Div>}
              </Form>
            )}
          </Formik>
        </Div>
      </Div>
    </>
  );
}
