import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button, Typography, Autocomplete, Box, TextField } from "@mui/material";
import { Formik, Form } from "formik";
import FormTextField1 from "app/components/InputField/FormTextField1";
import Swal from "sweetalert2";
import { editItemCategory } from "app/services/apis/editItemCategory";
import { Axios } from "index";
import { LoadingButton } from "@mui/lab";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";

function EditCatgeoryPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [unitList, setUnitList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [rowValues, setRowValues] = useState(state.rowData);

  const initialValues = {
    category: rowValues?.category || "",
    product_hsn_code: rowValues?.product_hsn_code || "",
    calculate_unit: rowValues?.calculate_unit || "",
  };
  const validationSchema = Yup.object({
    category: Yup.string().required("Category is required"),
    product_hsn_code: Yup.string()
      .required("HSN Code is required")
      .matches(/^[0-9]+$/, "HSN Code must be numeric")
      .min(6, "HSN Code must be at least 6 digits"),
    calculate_unit: Yup.string("Please select a unit").required("Please select a unit"),
  });
  // const calculateUnitOptions = [
  //   { label: "Kilograms", value: "unit one" },
  //   { label: "Liters", value: "Unit two" },
  //   { label: "Units", value: "unit three" },
  // ];
  const onSubmit = async (values) => {
    setSubmitting(true);
    try {
      values.category = values.category?.toUpperCase();
      const response = await editItemCategory(values, rowValues?._id);
      if (response?.data?.statusCode === 200) {
        Swal.fire({
          icon: "success",
          text: "Category updated Successfully",
          // text: "",
          timer: 2000,
          showConfirmButton: false,
        });
        setSubmitting(false);
        navigate("/master/item-category/list");
      }
    } catch (error) {
      console.log("err => ", error.response.message);
      setSubmitting(false);
      Swal.fire({
        icon: "error",
        text: error?.response?.data?.message,
        // text: "",
        timer: 2000,
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get("/unit-master/dropdown-unit-master");
        setUnitList(res.data.result);
      } catch (error) {
        console.log("err fetching all units => ", error.message);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      {/* <Typography variant="h2">Edit Category</Typography> */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors, setFieldTouched, touched }) => (
          <Form>
            <div className="grid grid-flow-row lg:grid-cols-3  gap-6 mt-6">
              {console.log("missing => ", errors)}
              <FormTextField1
                name="category"
                label="Category*"
                value={values?.category}
              />

              <div className="flex flex-col ">
                <Typography variant="h5">Calculate Unit*</Typography>

                <Autocomplete
                  autoSelect
                  autoHighlight
                  options={unitList}
                  getOptionLabel={(option) => option?.unit_name || ""}
                  onBlur={() => setFieldTouched("calculate_unit", true)}
                  name="calculate_unit"
                  value={unitList?.find((unit) => unit.unit_name === values.calculate_unit) || null}

                  size="small"
                  onChange={(e, selectedVal) => {
                    setFieldValue("calculate_unit", selectedVal?.unit_name || "");
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option?.unit_name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={touched?.calculate_unit && Boolean(errors?.calculate_unit)}
                      helperText={touched?.calculate_unit && errors?.calculate_unit}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <FormTextField1
                name="product_hsn_code"
                label="Product HSN Code*"
                value={values?.product_hsn_code}
              />
            </div>
            <div className="flex flex-row items-center justify-center gap-5">
              <Button
                // type="submit"
                variant="outlined"
                size="small"
                // color="secondary"
                sx={{ py: 1 }}
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure you want to cancel?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/master/item-category/list");
                    }
                  });
                }}
              >
                Cancel
              </Button>
              {/* <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
              >
                Edit Category
              </Button> */}
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ width: "100px" }}
                loading={submitting}
              >
                Save
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default HeaderTitleHoc(EditCatgeoryPage, "Edit Category");
