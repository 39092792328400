import Page from "@jumbo/shared/Page";
import OtherGoodsInventoryList from "app/pages/Inventory/other goods/ListOtherGoods";
import AddOtherGoodsStock from "app/pages/Inventory/other goods/AddOtherGoods";
import EditOtherGoodsStock from "app/pages/Inventory/other goods/EditOtherGoods";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";
import ViewDetails from "app/pages/Inventory/other goods/ListOtherGoods/Approval/ViewDetails";

export const otherGoodsRoutes = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "other_goods_inventory", "view"],
      },
    ],
    routes: [
      {
        path: "inventory/other-goods",
        element: <Page component={OtherGoodsInventoryList} layout={"vertical-default"} />,
      },

      {
        path: "inventory/other-goods/add",
        element: <Page component={AddOtherGoodsStock} layout={"vertical-default"} />,
      },
      {
        path: "inventory/other-goods/edit",
        element: <Page component={EditOtherGoodsStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/otherGoods-approval/view-details/:id/:invoiceId",
        element: <Page component={ViewDetails} layout={"vertical-default"} />,
      },
    ],
  },
];
