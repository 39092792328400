import React from 'react'
import Listing from './Listing'
import { Box } from '@mui/material'
import HeaderTitleHoc from 'app/components/HeaderTitleHoc'

const OtherGoodsExpense = () => {
  return (
    <Box sx={{mt:-4}}>
        <Listing/>
    </Box>
  )
}

export default HeaderTitleHoc(OtherGoodsExpense,"OtherGoods Expenses")