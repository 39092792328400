import Page from "@jumbo/shared/Page";
import FaceExpenses from "app/pages/Master/ExpenseMaster/FaceExpenses";
import AddExpenses from "app/pages/Master/ExpenseMaster/FaceExpenses/AddExpenses";
import AccessMiddleware from "app/routes/middleware/auth/AccessMiddleware";

export const faceExpenseRoutes = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "expense_master", "view"],
      },
    ],
    routes: [
      {
        path: "/master/expense/face",
        element: <Page component={FaceExpenses} layout={"vertical-default"} />,
      },
      {
        path: "/master/expense/face/add/:invoiceId",
        element: <Page component={AddExpenses} layout={"vertical-default"} />,
      }
    ],
  },
];
