import Div from "@jumbo/shared/Div/Div";
import { Suspense, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Box, Button, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { getAllUsers } from "app/redux/actions/userAction";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListUserTable from "./usertable";
import axios from "axios";
import { handleLogs } from "app/components/Function/logsDownloadFunction";
import { LoadingButton } from "@mui/lab";
import FilterAccordian from "app/components/FilterAccordian";
import { getAllRoles } from "app/redux/actions/roleAction";
import AllApis from "app/Apis";
import { Axios } from "index";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import { departmentNameDropDown } from "app/services/apis/department_list";

function ListUser() {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("updated_at");
  const [roles, setRoles] = useState([]);
  const [logLoader, setLogLoader] = useState(false);
  const [resetInputField, setResetInputField] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);
  const { allUsers, TotalPage, loading } = useSelector((state) => state.userReducer);
  const [filters, setFilters] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);

  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllUsers(value, sort, sortBy, 1));
  };

  const handleFilter = () => {
    console.log(filters);
    sessionStorage.setItem("userMasterFilter", JSON.stringify({ page, filters }));
    setPage(1);
    dispatch(getAllUsers(searchTerm, sort, sortBy, 1, filters));
  };

  const handleClear = () => {
    setFilters(null);
    setResetInputField((prev) => !prev);
    setSearchTerm("");
    sessionStorage.removeItem("userMasterFilter");
    dispatch(getAllUsers("", sort, sortBy, page, {}));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(getAllUsers(searchTerm, sort, sortBy, page, filters));
    (async () => {
      try {
        const rolesList = await Axios.get(`${AllApis.dropdownList.roles}`);
        setRoles(rolesList?.data?.result);

        departmentNameDropDown("")
          .then((response) => {
            if (response.data.success) {
              setDepartmentList(response.data.result);
            }
          })
          .catch((error) => console.error("Error fetching supplier list:", error));
      } catch (error) {
        console.log(error)
      }
    })();
  }, [sort, page]);

  useEffect(() => {
    const allKeys = Object.keys(sessionStorage);
    const filterKeys = allKeys.filter((key) => key.includes("Filter"));

    console.log(filterKeys, "76666");

    let retrieveFilter = sessionStorage.getItem("userMasterFilter");
    if (retrieveFilter) {
      let parseFilterData = JSON.parse(retrieveFilter);
      setFilters(parseFilterData?.filters);
      setPage(parseFilterData?.page);
      dispatch(getAllUsers(searchTerm, sort, sortBy, parseFilterData?.page, parseFilterData?.filters));
    }
  }, []);

  return (
    <>
      <Div sx={{ mt: -4 }}>
        {/* <Typography variant="h1">User Management </Typography> */}
        <FilterAccordian
          actions={
            <Div>
              <Button variant="contained" size="small" sx={{ marginRight: 1 }} onClick={handleFilter}>
                Apply
              </Button>

              <Button variant="outlined" size="small" onClick={handleClear}>
                Clear
              </Button>
            </Div>
          }
        >
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={6} md={4} lg={3} xl={2}>
              <Autocomplete
                autoSelect
                autoHighlight
                size="small"
                options={departmentList}
                name="dept_name"
                getOptionLabel={(option) => {
                  if (option instanceof Object && !Array.isArray(option)) {
                    return option?.dept_name;
                  } else {
                    return option;
                  }
                }}
                // value={values?.dept_name}
                value={departmentList.find((dept) => dept?.dept_name === filters?.dept_name) || null}
                onChange={(e, newValue) => {
                  setFilters((pre) => ({ ...pre, dept_name: newValue?.dept_name }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Department"
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={3} xl={2}>
              <Autocomplete
                autoSelect
                autoHighlight
                key={filters}
                sx={{ width: "100%" }}
                size="small"
                value={roles.find((role) => role?._id === filters?.role_id) || null}
                options={roles}
                getOptionLabel={(option) => {
                  if (option instanceof Object && !Array.isArray(option)) {
                    return option?.role_name;
                  } else {
                    return option;
                  }
                }}
                onChange={(e, newValue) => {
                  console.log(newValue, "sdbuib");
                  setFilters((pre) => ({ ...pre, role_id: newValue?._id }));
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Role" />}
              />
            </Grid>
          </Grid>
        </FilterAccordian>
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px 0",
          }}
        >
          <TextField
            size="small"
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value == "") {
                setSort("desc");
                setSortBy("updated_at");
                dispatch(getAllUsers("", "desc", "updated_at", 1));
              }
            }}
            sx={{ width: 300 }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
          <Div>
            {/* {permissions?.user_view == true && (
              <LoadingButton
                variant="contained"
                sx={{
                  mr: 2,
                  p: 1,
                  pl: 4,
                  pr: 4,
                }}
                onClick={() => handleLogs("user/user-logs", "users")}
              >
                Log
              </LoadingButton>
            )} */}

            {permissions?.user?.create === true && (
              <Button variant="contained" size="small" onClick={() => navigate("/user/add")}>
                Add User
              </Button>
            )}
          </Div>
        </Div>
        <Suspense fallback={<div style={{ backgroundColor: "grey", border: "10px solid red" }}>Loading...</div>}>
          <ListUserTable
            searchTerm={searchTerm}
            page={page}
            setPage={setPage}
            sort={sort}
            sortBy={sortBy}
            setSort={setSort}
            setSortBy={setSortBy}
          />
        </Suspense>
      </Div>
    </>
  );
}

export default HeaderTitleHoc(ListUser, "User Management");
