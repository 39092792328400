import Div from '@jumbo/shared/Div';
import { Box, Button, Grid } from '@mui/material';
import FilterAccordian from 'app/components/FilterAccordian';
import { flitchingDoneApprovalFilter } from 'app/utils/constants/sessionFilter';
import React, { useEffect, useMemo, useState } from 'react';
import { ApprovalList, CustomDatePicker, InwardSrNo, ItemName, } from './FiltersComponents';

const approvalPending = 'approval_status.sendForApproval.status'
const approvalApproved = 'approval_status.approved.status'
const approvalRejected = 'approval_status.rejected.status'

const filterObj = {
  "log_no": "",
  machine_name: "",
  [approvalPending]: null,
  [approvalApproved]: null,
  [approvalRejected]: null,
  range: {
    date: {
      "createdAt": {
        from: "",
        to: "",
      },
    },
  },
};

function FlitchingDoneFilters({ handleSubmit, handleClear, sx }) {
  const [filters, setFilters] = useState(JSON.parse(JSON.stringify(filterObj)))

  const handleFilter = useMemo(() => {
    return setFilters
  }, [])

  useEffect(() => {
    let retrieveFilter = sessionStorage.getItem(flitchingDoneApprovalFilter);
    if (retrieveFilter) {
      let filtersValues = JSON.parse(retrieveFilter)
      if (filtersValues?.filter) {
        setFilters(filtersValues?.filter)
      }
    }
  }, [])

  return (
    <Box sx={{ ...sx }}>
      <FilterAccordian
        actions={
          <Div>
            <Button variant="contained" size="small" sx={{ marginRight: 1 }} onClick={() => {
              handleSubmit(filters)
            }}>
              Apply
            </Button>

            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setFilters(JSON.parse(JSON.stringify(filterObj)));
                handleClear(null);
              }}
            >
              Clear
            </Button>
          </Div>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <InwardSrNo
              value={filters?.["log_no"]}
              setFilters={handleFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <ItemName value={filters?.machine_name} setFilters={handleFilter} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <CustomDatePicker
              title={"Flitching  Date From"}
              name="createdAt"
              position={"from"}
              value={
                filters?.range?.date?.["createdAt"]?.from
              }
              setFilters={handleFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <CustomDatePicker
              title={"Flitching  Date To"}
              name="createdAt"
              position={"to"}
              value={
                filters?.range?.date?.["createdAt"]?.to
              }
              setFilters={handleFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <ApprovalList
              filters={filters}
              setFilters={handleFilter}
              pending={approvalPending}
              approved={approvalApproved}
              rejected={approvalRejected}
            />
          </Grid>
        </Grid>
      </FilterAccordian>
    </Box>
  );
}

export default React.memo(FlitchingDoneFilters);
