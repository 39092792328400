import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { TableCell, TableRow } from "@mui/material";
import { ApprovalStatusTableCell } from "app/components/ApprovalStatusTableCell";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const tableBodyCell = { textAlign: "left", px: 1 };

const tableRowBodySticky = {
  textAlign: "left",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "white",
};

const FaceApprovalTableRow = ({ e }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);

  const handleItemAction = (menuItem) => {
    const invoiceId = menuItem?.row?.invoice_id;
    const id = menuItem?.row?._id;
    switch (menuItem.action) {
      case "viewDetails":
        navigate(`/inventory/face-approval/view-details/${id}/${invoiceId}`);
        break;
      default:
        navigate("/inventory/face");
    }
  };

  return (
    <TableRow key={e?._id}>
      <TableCell sx={{ ...tableBodyCell }}>{e?.inward_sr_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{moment(e?.inward_date).format("DD/MM/YYYY") || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.invoice_Details?.invoice_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{moment(e?.invoice_Details?.invoice_date).format("DD/MM/YYYY") || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.supplier_details?.company_details?.supplier_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.supplier_details?.branch_detail?.branch_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.invoice_Details?.invoice_value_with_gst || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.totalExpenseAmount || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.invoice_Details?.remark || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.user?.user_name || "-"}</TableCell>
      <ApprovalStatusTableCell approval_status={e?.approval_status} />
      <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky }}>
        {/* <Button size='small' type='submit' variant="contained" startIcon={<AccountBalanceWalletIcon />}>
          Manage Expense
        </Button> */}
        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <InfoIcon />,
              title: "View Details",
              action: "viewDetails",
              show: true,
              row: e,
            },
          ].filter((ele) => ele?.show)}
          onClickCallback={handleItemAction}
        />
      </TableCell>
    </TableRow>
  );
};

export default FaceApprovalTableRow;
