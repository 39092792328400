import { useSelector } from 'react-redux'
import FullScreenLoader from '../ListingPageLoader'

const LoadingPage = ({ children }) => {
    const loading = useSelector((state) => state?.loadingReducer)
    return (
        <>
            {loading?.loading && <FullScreenLoader />}
            {children}
        </>
    )
}

export default LoadingPage