import React, { useState } from 'react'
import HeaderTitleContext from './HeaderAppContext';

const HeaderApp = ({ children }) => {
    const [title, setTitle] = useState("Dashboard");
    return (
        <HeaderTitleContext.Provider value={{ title: title, setTitle: setTitle }}>
            {children}
        </HeaderTitleContext.Provider>
    )
}

export default HeaderApp