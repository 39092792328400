import Page from "@jumbo/shared/Page";
import PlywoodInventoryList from "app/pages/Inventory/Plywood/ListPlywood";
import AddPlywoodStock from "app/pages/Inventory/Plywood/AddPlywood";
import EditPlywoodStock from "app/pages/Inventory/Plywood/EditPlywood";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";
import ViewDetails from "app/pages/Inventory/Plywood/ListPlywood/Approval/ViewDetails";

export const plywoodInventoryRoute = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "plywood_inventory", "view"],
      },
    ],
    routes: [
      {
        path: "/inventory/plywood",
        element: <Page component={PlywoodInventoryList} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/plywood/add",
        element: <Page component={AddPlywoodStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/plywood/edit",
        element: <Page component={EditPlywoodStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/plywood-approval/view-details/:id/:invoiceId",
        element: <Page component={ViewDetails} layout={"vertical-default"} />,
      },
    ],
  },
];
