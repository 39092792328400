import Page from "@jumbo/shared/Page";
import FleeceInventoryList from "app/pages/Inventory/fleeceInventory/List";
import AddFleeceStock from "app/pages/Inventory/fleeceInventory/Add";
import EditFleeceStock from "app/pages/Inventory/fleeceInventory/Edit";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";
import ViewDetails from "app/pages/Inventory/fleeceInventory/List/Approval/ViewDetails";

export const fleeceInventoryRoute = [
  {
    middleware: [
      {
        element: AccessMiddleware,
       fallbackPath: ["/dashboard","fleece_paper_inventory","view"],
      },
    ],
    routes: [
      {
        path: "/inventory/fleece",
        element: (
          <Page component={FleeceInventoryList} layout={"vertical-default"} />
        ),
      },
      {
        path: "/inventory/fleece/add",
        element: <Page component={AddFleeceStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/fleece/edit",
        element: <Page component={EditFleeceStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/fleece-approval/view-details/:id/:invoiceId",
        element: <Page component={ViewDetails} layout={"vertical-default"} />,
      },
    ],
  },
];
