import Page from "@jumbo/shared/Page";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";
import ListApprovalConfig from "app/pages/ApprovalConfigMaster/ListApprovalConfig";

export const approvalConfigMasterRoute = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard","approval_config","view"],
      },
    ],
    routes: [
      {
        path: "/master/approval-config",
        element: (
          <Page component={ListApprovalConfig} layout={"vertical-default"} />
        ),
      },
    ],
  },
];