import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { ApprovalStatusTableCell } from "app/components/ApprovalStatusTableCell";
import { formatAmount } from "app/utils/formatAmount";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const tableBodyCell = { textAlign: "left", px: 1 };

const tableRowBodySticky = {
  textAlign: "center",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "white",
  width: "50px",
};

const CoreTableRow = ({ e, selectedIds, setSelectedIds, setItemDetailsForModal, handleOpenDetailModal }) => {
  const navigate = useNavigate();
  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);

  const handleCheckSelectedId = function (event, id) {
    const checked = event.target.checked;
    if (checked) {
      setSelectedIds((prev) => [...prev, id]);
    } else {
      setSelectedIds((prev) => {
        const restOfIds = prev?.filter((e) => e !== id);
        return restOfIds;
      });
    }
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/inventory/core/edit", { state: { data: menuItem?.row } });
        break;
      default:
        navigate("/inventory/core");
    }
  };

  return (
    <TableRow key={e?._id}>
      <TableCell sx={{ ...tableBodyCell, textAlign: "center" }}>
        <Checkbox
          {...label}
          size="small"
          checked={selectedIds.includes(e?._id)}
          onChange={(event) => handleCheckSelectedId(event, e?._id)}
        />
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.core_invoice_details?.inward_sr_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{moment(e?.core_invoice_details?.inward_date).format("DD/MM/YYYY") || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.core_invoice_details?.invoice_Details?.invoice_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.item_sr_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.supplier_item_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.core_invoice_details?.supplier_details?.company_details?.supplier_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.item_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.grade_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.length || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.width || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.thickness || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.number_of_sheets || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.total_sq_meter || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.core_invoice_details?.currency || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.exchange_rate || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.rate_in_currency || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{formatAmount(e?.rate_in_inr) || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{formatAmount(Math.round(e?.amount)) || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{formatAmount(Math.round(e?.expense_amount)) || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.remark || "-"}</TableCell>
      <ApprovalStatusTableCell approval_status={e?.core_invoice_details?.approval_status} />
      <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky, right: "50px" }}>
        <InfoIcon
          sx={{ "&:hover": { cursor: "pointer", color: "black" } }}
          onClick={() => {
            setItemDetailsForModal(e || {});
            handleOpenDetailModal(e);
          }}
        />
      </TableCell>
      <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky }}>
        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <EditIcon />,
              title: "Edit",
              action: "edit",
              show: permissions?.core_inventory?.edit === true && !e?.core_invoice_details?.approval_status?.sendForApproval?.status ? true : false,
              row: e,
            },
          ].filter((ele) => ele?.show)}
          onClickCallback={handleItemAction}
        />
      </TableCell>
    </TableRow>
  );
};

export default CoreTableRow;
