import React, { useState } from "react";
import AddItemModal from "./addNewItemTable";

import EditItemDetailsTable from "./EditItemTable";

const ItemDetailsTable = ({
  actualLogData,
  setAddedItemDetailsList,
  addedItemDetailsList,
  finalData,
  machineList,
  isCalculated,
  setIsCalculated,
  handleCalculate,
  isChecked,
  setIsChecked,
  wastageInfo,
  code,
  setCode,
  setFinalData, initialItemForm,
  setInitialItemForm
}) => {
  return (
    <div>
      <AddItemModal
        setAddedItemDetailsList={setAddedItemDetailsList}
        addedItemDetailsList={addedItemDetailsList}
        finalData={finalData}
        machineList={machineList}
        physicalLength={actualLogData?.physical_length}
        actualLogData={actualLogData}
        setIsCalculated={setIsCalculated}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        code={code}
        setCode={setCode}
        setFinalData={setFinalData}
        initialItemForm={initialItemForm}
        setInitialItemForm={setInitialItemForm}
      />
      <EditItemDetailsTable
        // itemNameList={itemNameList}
        // itemSubCategoryNameList={itemSubCategoryNameList}
        setAddedItemDetailsList={setAddedItemDetailsList}
        addedItemDetailsList={addedItemDetailsList}
        finalData={finalData}
        machineList={machineList}
        physicalLength={actualLogData?.physical_length}
        actualLogData={actualLogData}
        setIsCalculated={setIsCalculated}
        isCalculated={isCalculated}
        handleCalculate={handleCalculate}
        code={code}
        setCode={setCode}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        setFinalData={setFinalData}
      />
    </div>
  );
};

export default ItemDetailsTable;
