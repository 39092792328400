import { Autocomplete, Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { expenseTypeDropDownList } from 'app/services/apis/expenseTypeApis';
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import moment from 'moment';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ClearIcon from "@mui/icons-material/Clear";
import { supplierBranchDropDownList, supplierDropDownList } from 'app/services/apis/supplierList';

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  borderRight: "1px solid #7352C7",
};

const initialData = {
  expenseType: "",
  expenseTypeId: "",
  invoiceDate: "",
  invoiceNo: "",
  serviceProviderName: "",
  amount: 0,
}

const validationSchema = yup.object().shape({
  expenseType: yup.string()
    .required("Expense type is required"),
  expenseTypeId: yup.string()
    .required("Expense type ID is required"),
  invoiceDate: yup.date()
    .required("Invoice date is required")
    .nullable(),
  invoiceNo: yup.string()
    .required("Invoice number is required"),
  serviceProviderName: yup.string()
    .required("Service provider name is required"),
  amount: yup.number()
    .typeError("Amount must be a number")
    .positive("Amount must be positive")
    .required("Amount is required"),
});

const ListingExpenses = ({ otherExpensesList = [], setOtherExpensesList, expenseType, setIsCalculate }) => {
  const [data, setData] = useState(initialData);
  const [supplierNameList, setSupplierNameList] = useState([]);
  const removeRow = (value, index) => {
    const updateData = [...otherExpensesList];
    updateData.splice(index, 1);
    setOtherExpensesList(updateData);
    setIsCalculate(true)
  };
  const updateRow = (values, index) => {
    const updateData = [...otherExpensesList];
    updateData.splice(index, 1, values);
    setOtherExpensesList(updateData);
    setData(initialData)
    setIsCalculate(true)
  };

  const getSupplierBranchList = async (supplierId, setFieldValue) => {
    const supplerBranchList = await supplierBranchDropDownList(supplierId);
    if (supplerBranchList?.data?.success) {
      let branchData = supplerBranchList?.data?.result;
      let mainBranch = branchData?.filter((ele) => ele?.is_main_branch === true);
      //   setSupplierBranch(mainBranch[0]);
      setFieldValue("serviceProviderDetails", mainBranch[0] || "");
    }
  };

  useEffect(() => {
    supplierDropDownList("SERVICE PROVIDER")
      .then((response) => {
        if (response.data.success) {
          setSupplierNameList(response.data.result);
        }
      })
      .catch((error) => console.error("Error fetching supplier list:", error));
  }, []);

  const handleSubmit = function (values, actions) {
  }

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={data}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ validateForm, values, setFieldValue, handleBlur, handleChange, errors, touched, setFieldTouched }) => (
        <Form>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                  }}
                >
                  <TableCell sx={{ ...tableHeaderCellStyle }}>Expense Type</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle }}>Invoice Date</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle }}>Invoice No</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle }}>Service Provider Name</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle }}>Amount</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle }}>Action</TableCell>
                </TableRow>
              </TableHead>
              {
                otherExpensesList?.length > 0 ? <TableBody>
                  {
                    otherExpensesList && otherExpensesList?.map((row, i) => (
                      data?.index !== i ? <TableRow key={i}>
                        <TableCell sx={{ ...tableBodyCellStyle }}>{row?.expenseType}</TableCell>
                        <TableCell sx={{ ...tableBodyCellStyle }}>{moment(new Date(row?.invoiceDate)).format("DD/MM/YYYY")}</TableCell>
                        <TableCell sx={{ ...tableBodyCellStyle }}>{row?.invoiceNo}</TableCell>
                        <TableCell sx={{ ...tableBodyCellStyle }}>{row?.serviceProviderName}</TableCell>
                        <TableCell sx={{ ...tableBodyCellStyle }}>{row?.amount}</TableCell>
                        <TableCell
                          sx={{
                            // textAlign: "center",
                            minWidth: "100%",
                            textAlign: "center",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                            position: "sticky",
                            right: 0,
                            zIndex: 1,
                            bgcolor: "white",
                          }}
                        >
                          <IconButton color="warning" size="small" onClick={() => {
                            setData({
                              ...row,
                              index: i,
                            })
                          }}>
                            <EditIcon />
                          </IconButton>
                          <IconButton color="error" size="small" onClick={() => removeRow(row, i)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow> : <TableRow key={i}>
                        <TableCell sx={{ ...tableBodyCellStyle }}>
                          <Autocomplete
                            autoSelect
                            autoHighlight
                            size="small"
                            options={expenseType}
                            name="expenseType"
                            value={values?.expenseType || null}
                            getOptionLabel={(option) => {
                              if (option instanceof Object && !Array.isArray(option)) {
                                return option.expense_type_name;
                              } else {
                                return option;
                              }
                            }}
                            isOptionEqualToValue={(option, value) => option?.expense_type_name === value || false}
                            onChange={(e, selectedData) => {
                              setFieldValue("expenseType", selectedData?.expense_type_name || "");
                              setFieldValue("expenseTypeId", selectedData?._id || "");
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {option?.expense_type_name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                name='expenseType'
                                error={touched.expenseType && Boolean(errors.expenseType)}
                                helperText={touched.expenseType && errors.expenseType}
                                sx={{
                                  width: "100%",
                                }}
                                onBlur={handleBlur}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ ...tableBodyCellStyle }}>
                          <TextField
                            size='small'
                            sx={{
                              width: "100%",
                            }}
                            type='Date'
                            name="invoiceDate"
                            value={values?.invoiceDate}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={touched?.invoiceDate && errors?.invoiceDate}
                            helperText={touched?.invoiceDate && errors?.invoiceDate}
                          />
                        </TableCell>
                        <TableCell sx={{ ...tableBodyCellStyle }}>
                          <TextField
                            size='small'
                            sx={{
                              width: "100%",
                            }}
                            name='invoiceNo'
                            value={values?.invoiceNo}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={touched?.invoiceNo && errors?.invoiceNo}
                            helperText={touched?.invoiceNo && errors?.invoiceNo}
                          />
                        </TableCell>
                        <TableCell sx={{ ...tableBodyCellStyle }}>
                          {/* <TextField
                            size='small'
                            sx={{
                              width: "100%",
                            }}
                            name="serviceProviderName"
                            value={values?.serviceProviderName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={touched?.serviceProviderName && errors?.serviceProviderName}
                            helperText={touched?.serviceProviderName && errors?.serviceProviderName}
                          /> */}
                          <Autocomplete
                            autoSelect
                            autoHighlight
                            size="small"
                            options={supplierNameList}
                            name="serviceProviderName"
                            value={supplierNameList.find((sup) => sup?.supplier_name === values?.serviceProviderName) || null}
                            getOptionLabel={(option) => {
                              if (option instanceof Object && !Array.isArray(option)) {
                                return option?.supplier_name;
                              } else {
                                return option;
                              }
                            }}
                            onBlur={() => setFieldTouched("serviceProviderName", true)}
                            onChange={(e, selectedData) => {
                              getSupplierBranchList(selectedData?._id, setFieldValue);
                              setFieldValue("serviceProviderName", selectedData?.supplier_name);
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.supplier_name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                error={touched.serviceProviderName && Boolean(errors.serviceProviderName)} // Show error if touched and there's an error
                                helperText={touched.serviceProviderName && errors.serviceProviderName}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ ...tableBodyCellStyle }}>
                          <TextField
                            size='small'
                            sx={{
                              width: "100%",
                            }}
                            type='number'
                            name="amount"
                            value={values?.amount}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={touched?.amount && errors?.amount}
                            helperText={touched?.amount && errors?.amount}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            // textAlign: "center",
                            minWidth: "100%",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                            position: "sticky",
                            right: 0,
                            zIndex: 1,
                            bgcolor: "white",
                            display: "flex",
                          }}
                        >
                          <IconButton color="success" size="small"
                            onClick={async () => {
                              // Manually validate the form
                              const formErrors = await validateForm();
                              console.log("Validation errors:", formErrors); // Handle errors if needed
                              // Check if there are no validation errors
                              if (Object.keys(formErrors).length === 0) {
                                updateRow(values, i); // Call updateRow with values and index
                              } else {
                                console.log("Validation errors:", formErrors); // Handle errors if needed
                              }
                            }}>
                            <TaskAltIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => {
                              setData(initialData);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody> : <TableBody>
                  <TableRow >
                    <TableCell colSpan={6} sx={{ textAlign: "center" }}>No Data Found</TableCell>
                  </TableRow>
                </TableBody>
              }
            </Table>
          </TableContainer>
        </Form>
      )}
    </Formik>
  )
}

export default ListingExpenses