export const formatAmount = (number, currency = 'INR', maximumFractionDigits = 2) => {
    try {
        // Check if number is valid
        if (isNaN(number)) {
            throw new Error('Invalid number provided for formatting');
        }

        // Attempt to format the amount with the given currency
        return new Intl.NumberFormat('en-IN', {
            // style: 'currency',
            currency: currency,
            maximumFractionDigits: maximumFractionDigits,
        }).format(number);

    } catch (error) {
        // Fallback: return the original number if there's an error (e.g., invalid currency)
        console.warn(`Error formatting amount: ${error.message}`);
        return number
    }
};