export const flitchingDonelogNoDropdownApi = "/factory/flitching/log-no-dropdown";
export const machineNameDropdownApi =
    "/factory/cross-cutting/machine-name-dropdown";

export const fetchFlitchingDoneApprovalApi = '/approval/flitching-approval-listing';
export const fetchFlitchingDoneItemsApprovalApi = function (id, issued_for_flitching_id) {
    return `/approval/flitching-approval-item-listing-by-unique-id/${id}/${issued_for_flitching_id}`;
};
export const flitchingDoneApproveInvoiceApi = function (id, issued_for_flitching_id) {
    return `/approval/flitching-approve-details/${id}/${issued_for_flitching_id}`;
};
export const flitchingDoneRejectInvoiceApi = function (id, issued_for_flitching_id) {
    return `/approval/flitching-reject_details/${id}/${issued_for_flitching_id}`;
};