
import { TableCell, TableRow } from "@mui/material";

import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "app/utils/formatAmount";



const tableBodyCell = { textAlign: "left", px: 1 };

const tableRowBodySticky = {
  textAlign: "left",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "white",
};

const LogTableRow = ({ e, selectedIds, setSelectedIds }) => {
  const navigate = useNavigate();

  const handleCheckLogId = function (event, id) {
    const checked = event.target.checked;
    if (checked) {
      setSelectedIds((prev) => [...prev, id]);
    } else {
      setSelectedIds((prev) => {
        const restOfIds = prev?.filter((e) => e !== id);
        return restOfIds;
      });
    }
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/inventory/log/edit", { state: { data: menuItem?.row } });
        break;
      case "issueForCrosscutting":
        break;
      case "issueForFlitching":
        break;
      default:
        navigate("/inventory/log");
    }
  };

  return (
    <TableRow key={e?._id}>
      {/* <TableCell sx={{ ...tableBodyCell, textAlign: "center" }}>
        <Checkbox
          {...label}
          size="small"
          checked={selectedIds.includes(e?._id)}
          onChange={(event) => handleCheckLogId(event, e?._id)}
        />
      </TableCell> */}
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.log_invoice_details?.inward_sr_no || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {moment(e?.log_invoice_details?.inward_date).format("DD/MM/YYYY") ||
          "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.log_invoice_details?.invoice_Details?.invoice_no || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.item_sr_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.log_invoice_details?.supplier_details?.company_details
          ?.supplier_name || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.supplier_item_name || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.supplier_log_no || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.item_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.item_sub_category_name || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_formula || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.issue_status?.toUpperCase() || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.invoice_length || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.invoice_diameter || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.invoice_cmt || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.indian_cmt || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.physical_length || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.physical_diameter || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.physical_cmt || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.exchange_rate || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.rate_in_currency || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{formatAmount(e?.rate_in_inr) || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{formatAmount(Math.round(e?.amount)) || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.remark || "-"}</TableCell>
      {/* <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky }}>
        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <EditIcon />,
              title: "Edit",
              action: "edit",
              show: true,
              row: e,
            },
            {
              icon: <AddCircleOutlineIcon />,
              title: "Issue For Crosscutting",
              action: "issueForCrosscutting",
              show: true,
            },
            {
              icon: <AddCircleOutlineIcon />,
              title: "Issue For Flitching",
              action: "issueForFlitching",
              show: true,
            },
          ].filter((ele) => ele?.show)}
          onClickCallback={handleItemAction}
        />
      </TableCell> */}
    </TableRow>
  );
};

export default LogTableRow;
