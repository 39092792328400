export const logItemSrnoDropdownApi = "/log-inventory/item-srno-dropdown";
export const logInwardSrnoDropdownApi = "/log-inventory/inward-srno-dropdown";

//Approval
export const fetchLogApprovalApi = `/approval/log-approval-invoice-listing`;
export const fetchLogItemsApprovalApi = function (id, invoice_id) {
  return `/approval/log-approval-item-listing-by-invoice/${id}/${invoice_id}`;
};
export const logApproveInvoiceApi = function (id, invoice_id) {
  return `/approval/log-approve_invoice_details/${id}/${invoice_id}`;
};
export const logRejectInvoiceApi = function (id, invoice_id) {
  return `/approval/log-reject_invoice_details/${id}/${invoice_id}`;
};