import Page from "@jumbo/shared/Page";
import VeneerInventoryList from "app/pages/Inventory/veneerInventory/List";
import AddVeneerStock from "app/pages/Inventory/veneerInventory/Add";
import EditVeneerStock from "app/pages/Inventory/veneerInventory/Edit";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";
import ViewDetails from "app/pages/Inventory/veneerInventory/List/Approval/ViewDetails";

export const veneerInventoryRoute = [
  {
    middleware: [
      {
        element: AccessMiddleware,
       fallbackPath: ["/dashboard","veneer_inventory","view"],
      },
    ],
    routes: [
      {
        path: "/inventory/veneer",
        element: (
          <Page component={VeneerInventoryList} layout={"vertical-default"} />
        ),
      },
      {
        path: "/inventory/veneer/add",
        element: <Page component={AddVeneerStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/veneer/edit",
        element: <Page component={EditVeneerStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/veneer-approval/view-details/:id/:invoiceId",
        element: <Page component={ViewDetails} layout={"vertical-default"} />,
      },
    ],
  },
];
