import { ISSUES_FOR_FLITCHING_FAILED, ISSUES_FOR_FLITCHING_REQUEST, ISSUES_FOR_FLITCHING_SUCCESS } from "app/redux/actions/Factory/Flitching/issuesForFlitching/constant";

const INIT_STATE = {
  loading: false,
  totalPage: 0,
  data: [],
  error: {
      isError: false,
      message: null
  }
};

export const issuesForFlitchingReducer = function (state = INIT_STATE, action) {
  switch (action?.type) {
      case ISSUES_FOR_FLITCHING_REQUEST:
          return {
              loading: true,
              totalPage: 0,
              data: [],
              error: {
                  isError: false,
                  message: null
              }
          }
      case ISSUES_FOR_FLITCHING_SUCCESS:
          return {
              loading: false,
              data: action?.payload?.data,
              totalPage: action?.payload?.totalPage,
              error: {
                  isError: false,
                  message: null
              }
          }
      case ISSUES_FOR_FLITCHING_FAILED:
          const { isError = true, message = "Something Went Wrong" } = action?.payload?.error || {}
          return {
              loading: false,
              totalPage: 0,
              data: [],
              error: {
                  isError: isError,
                  message: message
              }
          }
      default:
          return state;
  }
};