import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import { Form, Formik } from "formik";
import * as yup from "yup";
import React from "react";
import Swal from "sweetalert2";

import Div from "@jumbo/shared/Div";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  width: "100%",
};
const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 3000,
  customClass: {
    container: "popupImportant",
  },
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const AddItemModal = ({ setAddedItemDetailsList, addedItemDetailsList, itemSubCategoryNameList, itemNameList, finalData, initialItemForm,
  setInitailItemForm }) => {

  // const stock = {
  //   item_sr_no: addedItemDetailsList.length + 1 || 1,
  //   supplier_item_name: "",
  //   supplier_flitch_no: "",
  //   item_name: "",
  //   item_id: "",
  //   item_sub_category_name: "",
  //   item_sub_category_id: "",
  //   log_no: "",
  //   flitch_code: "",
  //   flitch_formula: "",
  //   length: 0,
  //   width1: 0,
  //   width2: 0,
  //   width3: 0,
  //   height: 0,
  //   flitch_cmt: "",
  //   exchange_rate: finalData?.exchange_rate || 0,
  //   rate_in_currency: 0,
  //   rate_in_inr: 0,
  //   amount: 0,
  //   remark: "",
  // };

  const itemDetails = {
    ...initialItemForm,
    item_sr_no: addedItemDetailsList.length + 1 || 1,
    exchange_rate: finalData?.exchange_rate || 0,
  }

  const validationSchema = yup.object({
    supplier_item_name: yup.string("Enter Supplier Name").required("Supplier Name is required"),
    supplier_flitch_no: yup.string("Enter Supplier Flitch Number").required("Supplier Flitch Number is required"),
    item_name: yup.string().required("Required"),
    item_sub_category_name: yup.string().required("Required"),
    log_no: yup.string().required("Required"),
    flitch_formula: yup.string().required("Required"),
    flitch_code: yup.string().required("Required"),
    length: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    width1: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    width2: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    width3: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    height: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    // flitch_cmt: yup
    //   .number()
    //   .positive()
    //   .moreThan(0, "Must be greater than 0")
    //   .required("Required"),
    // exchange_rate: yup
    //   .number()
    //   // .positive()
    //   // .moreThan(0, "Must be greater than 0")
    //   // .required("Required")
    //   .when((currency, schema) =>
    //     finalData?.currency?.currency_name !== "INR"
    //       ? schema
    //         .positive()
    //         .moreThan(0, "Must be greater than 0")
    //         .required("Required.")
    //       : schema.notRequired()
    //   ),
    rate_in_currency: yup
      .number()
      .when((currency, schema) =>
        finalData?.currency?.currency_name !== "INR"
          ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.")
          : schema.notRequired()
      ),
    rate_in_inr: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    amount: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
  });

  const handleSubmit = async (values) => {

    setAddedItemDetailsList([...addedItemDetailsList, { ...values }]);
    Toast.fire({
      timer: 3000,
      icon: "success",
      title: "Item Added Successfully.",
      position: "top-right",
      // background: theme.palette.background.paper,
    });
    setInitailItemForm((prev) => ({ ...values, item_sr_no: prev?.item_sr_no + 1 }))
  };

  const calculateDIA = (width, height) => {
    const widthNum = Number(width);
    const heightNum = Number(height);
    const smaller = Math.min(widthNum, heightNum);
    const larger = Math.max(widthNum, heightNum);

    const DIA = (smaller * 2 + larger) / 2;

    return DIA;
  };
  // const handleCalculateFlitchCmt = (width1, width2, width3, height, length, formula, setFieldValue) => {
  //   let pie = 22 / 7;

  //   let maxWidth = Math.max(Number(width1), Number(width2), Number(width3));

  //   if (formula === "TQF") {
  //     let dia = Number(maxWidth) + Number(height);
  //     let flitch_cmt = Number(((pie * dia * dia * Number(length)) / 16)?.toFixed(3));
  //     setFieldValue("flitch_cmt", Number(flitch_cmt));
  //   } else if (formula === "FHF") {
  //     const dia = calculateDIA(maxWidth, height);
  //     // let dia = (Number(width) * 2 + Number(height)) / 2;
  //     let flitch_cmt = Number(((pie * dia * dia * Number(length)) / 8)?.toFixed(3));
  //     setFieldValue("flitch_cmt", Number(flitch_cmt));
  //   } else {
  //     let dia = (Number(maxWidth) + Number(height)) / 2;
  //     let flitch_cmt = Number(((pie * dia * dia * Number(length)) / 4)?.toFixed(3));
  //     setFieldValue("flitch_cmt", Number(flitch_cmt.toFixed(3)));
  //   }
  // }; previous functionality

  const handleCalculateFlitchCmt = (width1, width2, width3, height, length, formula, setFieldValue, values) => {
    let pie = 22 / 7;
    let maxWidth = Math.max(Number(width1), Number(width2), Number(width3));

    if (formula === "TQF") {
      let dia = Number(maxWidth) + Number(height);

      let flitch_cmt = Number(((pie * dia * dia * Number(length)) / 16)?.toFixed(3));
      setFieldValue("flitch_cmt", Number(flitch_cmt));
      let amount = Number((Number(flitch_cmt) * Number(values?.rate_in_inr))?.toFixed(2));
      setFieldValue("amount", Number(amount));
    } else if (formula === "FHF") {
      // let dia = (Number(maxWidth) * 2 + Number(height)) / 2;
      const dia = calculateDIA(maxWidth, height);
      let flitch_cmt = Number(((pie * dia * dia * Number(length)) / 8)?.toFixed(3));
      setFieldValue("flitch_cmt", Number(flitch_cmt));
      let amount = Number((Number(flitch_cmt) * Number(values?.rate_in_inr))?.toFixed(2));
      setFieldValue("amount", Number(amount));
    } else {
      let dia = (Number(maxWidth) + Number(height)) / 2;
      let flitch_cmt = Number(((pie * dia * dia * Number(length)) / 4)?.toFixed(3));
      setFieldValue("flitch_cmt", Number(flitch_cmt));
      let amount = Number((Number(flitch_cmt) * Number(values?.rate_in_inr))?.toFixed(2));
      setFieldValue("amount", Number(amount));
    }
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={itemDetails}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors, handleBlur, initialValues, setFieldTouched, touched, isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                  }}
                >
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Item Name</TableCell>

                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Flitch No.</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Name</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Sub Category</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log No.</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch Formula</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch Code</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Length</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width1</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width2</TableCell>

                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width3</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Height</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch CMT</TableCell>
                  {finalData?.currency?.currency_name !== "INR" ? (
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in Currency</TableCell>
                  ) : (
                    ""
                  )}
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in INR</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Amount</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ ...tableBodyCellStyle }}>{values?.item_sr_no}</TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.supplier_item_name}
                      sx={{ width: "100%" }}
                      name="supplier_item_name"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("supplier_item_name", e.target.value)}
                      error={touched?.supplier_item_name && errors?.supplier_item_name}
                      helperText={touched?.supplier_item_name && errors?.supplier_item_name}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.supplier_flitch_no}
                      sx={{ width: "100%" }}
                      name="supplier_flitch_no"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("supplier_flitch_no", e.target.value)}
                      error={touched?.supplier_flitch_no && errors?.supplier_flitch_no}
                      helperText={touched?.supplier_flitch_no && errors?.supplier_flitch_no}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={itemNameList}
                      name="item_name"
                      value={values?.item_name || null}
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option.item_name;
                        } else {
                          return option;
                        }
                      }}
                      onBlur={() => setFieldTouched("item_name", true)}
                      onChange={(e, selectedData) => {
                        setFieldValue("item_name", selectedData?.item_name || "");
                        setFieldValue("item_id", selectedData?._id || "");
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.item_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.item_name && Boolean(errors.item_name)} // Show error if touched and there's an error
                          helperText={touched.item_name && errors.item_name}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      // disabled={values?.supplier_details?.country === "India"}
                      options={itemSubCategoryNameList}
                      name="item_sub_category_name"
                      value={values?.item_sub_category_name || null}
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.name;
                        } else {
                          return option;
                        }
                      }}
                      onBlur={() => setFieldTouched("item_sub_category_name", true)}
                      onChange={(e, selectedData) => {
                        // setFinalData({ ...finalData, item_sub_category_name: selectedData });
                        setFieldValue("item_sub_category_name", selectedData?.name || "");
                        setFieldValue("item_sub_category_id", selectedData?._id || "");
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.item_sub_category_name && Boolean(errors.item_sub_category_name)} // Show error if touched and there's an error
                          helperText={touched.item_sub_category_name && errors.item_sub_category_name}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.log_no}
                      sx={{ width: "100%" }}
                      name="log_no"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("log_no", e.target.value)}
                      error={touched?.log_no && errors?.log_no}
                      helperText={touched?.log_no && errors?.log_no}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={["TQF", "FHF", "BF"]}
                      name="flitch_formula"
                      value={values?.flitch_formula || null}
                      getOptionLabel={(option) => option}
                      onBlur={() => {
                        setFieldTouched("flitch_formula", true);
                      }}
                      onChange={(e, formula) => {
                        setFieldValue("flitch_formula", formula || "");
                        handleCalculateFlitchCmt(
                          values?.width1,
                          values?.width2,
                          values?.width3,
                          values?.height,
                          values?.length,
                          formula,
                          setFieldValue,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.flitch_formula && Boolean(errors.flitch_formula)} // Show error if touched and there's an error
                          helperText={touched.flitch_formula && errors.flitch_formula}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.flitch_code}
                      sx={{ width: "100%" }}
                      name="flitch_code"
                      onChange={(e) => setFieldValue("flitch_code", e.target.value)}
                      error={touched?.flitch_code && errors?.flitch_code}
                      helperText={touched?.flitch_code && errors?.flitch_code}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.length || ""}
                      sx={{ width: "100%" }}
                      name="length"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateFlitchCmt(
                          values?.width1,
                          values?.width2,
                          values?.width3,
                          values?.height,
                          e.target.value,
                          values?.flitch_formula,
                          setFieldValue,
                          values
                        );
                      }}
                      onChange={(e) => setFieldValue("length", e.target.value)}
                      error={touched?.length && errors?.length}
                      helperText={touched?.length && errors?.length}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.width1 || ""}
                      sx={{ width: "100%" }}
                      name="width1"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateFlitchCmt(
                          e.target.value,
                          values?.width2,
                          values?.width3,
                          values?.height,
                          values?.length,
                          values?.flitch_formula,
                          setFieldValue,
                          values
                        );
                      }}
                      onChange={(e) => setFieldValue("width1", e.target.value)}
                      error={touched?.width1 && errors?.width1}
                      helperText={touched?.width1 && errors?.width1}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // disabled
                      type="number"
                      size="small"
                      value={values?.width2 || ""}
                      sx={{ width: "100%" }}
                      name="width2"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateFlitchCmt(
                          values?.width1,
                          e.target.value,
                          values?.width3,
                          values?.height,
                          values?.length,
                          values?.flitch_formula,
                          setFieldValue,
                          values
                        );
                      }}
                      onChange={(e) => setFieldValue("width2", e.target.value)}
                      error={touched?.width2 && errors?.width2}
                      helperText={touched?.width2 && errors?.width2}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      // disabled
                      size="small"
                      value={values?.width3 || ""}
                      sx={{ width: "100%" }}
                      name="width3"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateFlitchCmt(
                          values?.width1,
                          values?.width2,
                          e.target.value,
                          values?.height,
                          values?.length,
                          values?.flitch_formula,
                          setFieldValue,
                          values
                        );
                      }}
                      onChange={(e) => setFieldValue("width3", e.target.value)}
                      error={touched?.width3 && errors?.width3}
                      helperText={touched?.width3 && errors?.width3}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.height || ""}
                      sx={{ width: "100%" }}
                      name="height"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateFlitchCmt(
                          values?.width1,
                          values?.width2,
                          values?.width3,
                          e.target.value,
                          values?.length,
                          values?.flitch_formula,
                          setFieldValue,
                          values
                        );
                      }}
                      onChange={(e) => setFieldValue("height", e.target.value)}
                      error={touched?.height && errors?.height}
                      helperText={touched?.height && errors?.height}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      disabled
                      type="number"
                      size="small"
                      value={values?.flitch_cmt}
                      sx={{ width: "100%" }}
                      name="flitch_cmt"
                      onChange={(e) => setFieldValue("flitch_cmt", e.target.value)}
                      error={touched?.flitch_cmt && errors?.flitch_cmt}
                      helperText={touched?.flitch_cmt && errors?.flitch_cmt}
                    />
                  </TableCell>
                  {finalData?.currency?.currency_name !== "INR" ? (
                    <TableCell sx={{ ...tableBodyCellStyle }}>
                      <TextField
                        type="number"
                        size="small"
                        value={values?.rate_in_currency || ""}
                        sx={{ width: "100%" }}
                        name="rate_in_currency"
                        onBlur={async (e) => {
                          handleBlur(e);
                          let rateInINR = Number((Number(e.target.value) * Number(values?.exchange_rate))?.toFixed(2));
                          let amount = Number((Number(values.flitch_cmt) * Number(rateInINR))?.toFixed(2));
                          console.log(rateInINR)
                          setFieldValue("rate_in_inr", Number(rateInINR));
                          setFieldValue("amount", Number(amount));
                        }}
                        onChange={(e) => setFieldValue("rate_in_currency", e.target.value)}
                        error={touched?.rate_in_currency && errors?.rate_in_currency}
                        helperText={touched?.rate_in_currency && errors?.rate_in_currency}
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.rate_in_inr || ""}
                      sx={{ width: "100%" }}
                      name="rate_in_inr"
                      onBlur={async (e) => {
                        handleBlur(e);
                        let amount = Number((Number(values.flitch_cmt) * Number(e.target.value))?.toFixed(2));
                        setFieldValue("amount", Number(amount));
                      }}
                      onChange={(e) => setFieldValue("rate_in_inr", e.target.value)}
                      error={touched?.rate_in_inr && errors?.rate_in_inr}
                      helperText={touched?.rate_in_inr && errors?.rate_in_inr}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.amount || ""}
                      sx={{ width: "100%" }}
                      name="amount"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("amount", e.target.value)}
                      error={touched?.amount && errors?.amount}
                      helperText={touched?.amount && errors?.amount}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.remark}
                      sx={{ width: "100%" }}
                      name="remark"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("remark", e.target.value)}
                      error={touched?.remark && errors?.remark}
                      helperText={touched?.remark && errors?.remark}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Div sx={{ textAlign: "right" }}>
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              size="small"
              variant="contained"
              sx={{
                marginY: 1,
                width: "150px",
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                px: 1,
                position: "sticky",
                right: "20px",
                zIndex: 1,
              }}
            >
              Add New Item
            </LoadingButton>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default AddItemModal;
