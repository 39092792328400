import { VENEER_BULK_UPLOAD_DATA, VENEER_INVENTORY_FAILED, VENEER_INVENTORY_REQUEST, VENEER_INVENTORY_SUCCESS } from "app/redux/actions/Inventory/VeneerInventory/constant";

const INIT_STATE = {
    loading: false,
    totalPage: 0,
    data: [],
    error: {
        isError: false,
        message: null
    }
};

const PROGRESS_DATA = {
    fileName: "",
    progress: 0,
    status: null,
    success: {
        status: false,
        message: null,
    },
    error: {
        status: false,
        message: null,
    },
    validationErrors: null
}

export const veneerInventoryReducer = function (state = INIT_STATE, action) {
    switch (action?.type) {
        case VENEER_INVENTORY_REQUEST:
            return {
                loading: true,
                totalPage: 0,
                data: [],
                error: {
                    isError: false,
                    message: null
                }
            }
        case VENEER_INVENTORY_SUCCESS:
            return {
                loading: false,
                data: action?.payload?.data,
                totalPage: action?.payload?.totalPage,
                error: {
                    isError: false,
                    message: null
                }
            }
        case VENEER_INVENTORY_FAILED:
            const { isError = true, message = "Something Went Wrong" } = action?.payload?.error || {}
            return {
                loading: false,
                totalPage: 0,
                data: [],
                error: {
                    isError: isError,
                    message: message
                }
            }
        default:
            return state;
    }
};

export const veneerBlukUploadDataReducer = function (state = PROGRESS_DATA,action){
    switch (action?.type) {
        case VENEER_BULK_UPLOAD_DATA:
            return action?.payload?.data
        default:
            return state;
    }
}