import { Box, Tab, Tabs } from "@mui/material";
import ListingPlywood from "./ListingPlywood";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import React from "react";
import { plywoodInventoryTabFilter } from "app/utils/constants/sessionFilter";
import PlywoodApproval from "./Approval";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const PlywoodInventoryList = () => {
  const userReducer = useSelector((state) => state.userReducer);
  const user = userReducer?.user?.[0];

  const [value, setValue] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem(plywoodInventoryTabFilter, newValue);
  };

  React.useEffect(() => {
    let newValue = Number(sessionStorage.getItem(plywoodInventoryTabFilter) || 0);
    setValue(newValue);
  }, []);
  return (
    // <Box sx={{mt:-4}}>
    //   <ListingPlywood />
    // </Box>
     <Box sx={{ width: "100%", mt: -4 }}>
     <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
       <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
         <Tab label="Plywood Inventory" {...a11yProps(0)} />
         {user?.user_type === "ADMIN" && <Tab label="Plywood Approval" {...a11yProps(1)} />}
       </Tabs>
     </Box>
     <CustomTabPanel value={value} index={0}>
       <ListingPlywood />
     </CustomTabPanel>
     {user?.user_type === "ADMIN" && (
       <CustomTabPanel value={value} index={1}>
         <PlywoodApproval />
       </CustomTabPanel>
     )}
   </Box>
  );
};

export default HeaderTitleHoc(PlywoodInventoryList,"Plywood Inventory");
