import { combineReducers } from "redux";
import { dispatchReducer } from "./dispatchReducer";
import { factoryReducer } from "./factoryReducer";
import { groupingReducer } from "./groupingReducer";
import { inventoryReducer } from "./inventoryReducer";
import { masterReducer } from "./masterReducer";
import { orderReducer } from "./orderReducer";
import { purchaseReducer } from "./purchaseReducer";
import { roleReducer } from "./roleReducer";
import { userReducer } from "./userReducer";
import { logHistoryInventoryReducer, logInventoryReducer } from "./Inventory/LogReducer";
import { flitchInventoryReducer } from "./Inventory/flitchReducer";
import loadingReducer from "./loadingReducer";
import { faceInventoryReducer } from "./Inventory/FaceReducer";
import { veneerBlukUploadDataReducer, veneerInventoryReducer } from "./Inventory/VeneerReducer";
import { fleeceInventoryReducer } from "./Inventory/FleeceReducer";
import { coreInventoryReducer } from "./Inventory/CoreReducer";
import { mdfInventoryReducer } from "./Inventory/MdfReducer";
import { plywoodInventoryReducer } from "./Inventory/plywoodReducer";
import { otherGoodsInventoryReducer } from "./Inventory/otherGoodsReducer";
import { issuesForCrosscuttingReducer } from "./Factory/Crosscutting/issuesForCrosscutting";
import { rejectedCrosscuttingReducer } from "./Factory/Crosscutting/rejectedCrosscutting";
import { issuesForFlitchingReducer } from "./Factory/Flitching/issuesForFlitching";
import { crossCutDoneListingReducer } from "./Factory/Crosscutting/crosscutDoneListing/crosscutDoneListing";
import { logExpensesReducer } from "./Master/Expenses/logExpensesReducer";
import { FlitchingDoneReducer } from "./Factory/Flitching/flitchingDone";
import { flitchExpensesReducer } from "./Master/Expenses/flitchExpensesReducer";
import { faceExpensesReducer } from "./Master/Expenses/faceExpensesReducer";
import { coreExpensesReducer } from "./Master/Expenses/coreExpensesReducer";
import { plywoodExpensesReducer } from "./Master/Expenses/plywoodExpensesReducer";
import { veneerExpensesReducer } from "./Master/Expenses/veneerExpensesReducer";
import { mdfExpensesReducer } from "./Master/Expenses/mdfExpensesReducer";
import { fleeceExpensesReducer } from "./Master/Expenses/fleeceExpensesReducer";
import { otherGoodsExpensesReducer } from "./Master/Expenses/otherGoodsExpensesReducer";
import { logApprovalReducer } from "./Approval/logApprovalReducer";
import { flitchApprovalReducer } from "./Approval/flitchApprovalReducer";
import { plywoodApprovalReducer } from "./Approval/plywoodApprovalReducer";
import { veneerApprovalReducer } from "./Approval/veneerApprovalReducer copy";
import { mdfApprovalReducer } from "./Approval/mdfApprovalReducer";
import { faceApprovalReducer } from "./Approval/faceApprovalReducer";
import { coreApprovalReducer } from "./Approval/coreApprovalReducer";
import { fleeceApprovalReducer } from "./Approval/fleeceApprovalReducer";
import { otherGoodsApprovalReducer } from "./Approval/otherGoodsApprovalReducer";
import { crossCutDoneApprovalListingReducer } from "./Approval/crosscuttingDoneApprovalReducer";
import { flitchingDoneApprovalReducer } from "./Approval/flitchingDoneApprovalReducer";

const exportReducers = (history) => {
  return combineReducers({
    userReducer: userReducer,
    roleReducer: roleReducer,
    masterReducer: masterReducer,
    purchaseReducer: purchaseReducer,
    inventoryReducer: inventoryReducer,
    groupingReducer: groupingReducer,
    factoryReducer: factoryReducer,
    dispatchReducer: dispatchReducer,
    orderReducer: orderReducer,
    //expenses
    logExpensesReducer: logExpensesReducer,
    flitchExpensesReducer: flitchExpensesReducer,
    faceExpensesReducer: faceExpensesReducer,
    coreExpensesReducer: coreExpensesReducer,
    plywoodExpensesReducer: plywoodExpensesReducer,
    veneerExpensesReducer: veneerExpensesReducer,
    mdfExpensesReducer: mdfExpensesReducer,
    fleeceExpensesReducer: fleeceExpensesReducer,
    otherGoodsExpensesReducer: otherGoodsExpensesReducer,
    //Inventory
    logInventoryReducer: logInventoryReducer,
    logHistoryInventoryReducer: logHistoryInventoryReducer,
    flitchInventoryReducer: flitchInventoryReducer,
    veneerInventoryReducer: veneerInventoryReducer,
    veneerBlukUploadDataReducer: veneerBlukUploadDataReducer,
    faceInventoryReducer: faceInventoryReducer,
    fleeceInventoryReducer: fleeceInventoryReducer,
    coreInventoryReducer: coreInventoryReducer,
    mdfInventoryReducer: mdfInventoryReducer,
    plywoodInventoryReducer: plywoodInventoryReducer,
    otherGoodsInventoryReducer: otherGoodsInventoryReducer,
    //Factory
    //crosscuting
    issuesForCrosscuttingReducer: issuesForCrosscuttingReducer,
    rejectedCrosscuttingReducer: rejectedCrosscuttingReducer,
    crossCutDoneListingReducer: crossCutDoneListingReducer,
    //Flitching
    issuesForFlitchingReducer: issuesForFlitchingReducer,
    FlitchingDoneReducer: FlitchingDoneReducer,
    //Approval
    logApprovalReducer:logApprovalReducer,
    flitchApprovalReducer:flitchApprovalReducer,
    plywoodApprovalReducer:plywoodApprovalReducer,
    veneerApprovalReducer:veneerApprovalReducer,
    mdfApprovalReducer:mdfApprovalReducer,
    faceApprovalReducer:faceApprovalReducer,
    coreApprovalReducer:coreApprovalReducer,
    fleeceApprovalReducer:fleeceApprovalReducer,
    otherGoodsApprovalReducer:otherGoodsApprovalReducer,
    crossCutDoneApprovalListingReducer:crossCutDoneApprovalListingReducer,
    flitchingDoneApprovalReducer:flitchingDoneApprovalReducer,
    //basics
    loadingReducer: loadingReducer,
  });
};

export default exportReducers;
