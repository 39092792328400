import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Slide,
    Snackbar,
    TextField
} from "@mui/material";
import { flitchingDoneApprovalFilter } from "app/utils/constants/sessionFilter";
import { Axios } from "index";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import IssuesForCrosscuttingFilters from "./Filters";

import { fetchFlitchingDoneApprovalList } from "app/redux/Approval/flitchingDoneApproval";
import FlitchingDoneApprovalTableListing from "./flitchingDoneApprovalTableListing";

const ListFlitchingDoneApproval = () => {
    const permissions = useSelector(
        (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
    );
    const [allFilterState, setAllFilterState] = useState({
        searchTerm: "",
        sort: "desc",
        sortBy: "updatedAt",
        page: 1,
        limit: 10,
        filter: null,
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
            container: "popupImportant",
        },
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const handleSearch = (search) => {
        const filter = {
            ...allFilterState,
            searchTerm: search,
            page: 1,
        };
        setAllFilterState(filter);
        dispatch(fetchFlitchingDoneApprovalList(filter));
        sessionStorage.setItem(flitchingDoneApprovalFilter, JSON.stringify(filter));
    };

    const handleChangeLimit = (event) => {
        const filter = {
            ...allFilterState,
            limit: parseInt(event.target.value),
            page: 1,
        };
        setAllFilterState(filter);
        dispatch(fetchFlitchingDoneApprovalList(filter));
        sessionStorage.setItem(flitchingDoneApprovalFilter, JSON.stringify(filter));
    };

    const handleApplyFilter = useCallback(
        (filtersData) => {
            // setPage(1);
            const filter = {
                ...allFilterState,
                sort: "desc",
                sortBy: "updatedAt",
                page: 1,
                filter: filtersData,
            };
            dispatch(fetchFlitchingDoneApprovalList(filter));
            setAllFilterState(filter);
            sessionStorage.setItem(
                flitchingDoneApprovalFilter,
                JSON.stringify(filter)
            );
        },
        [allFilterState?.limit, allFilterState?.searchTerm]
    );

    const handleClearFilter = useCallback(
        (filtersData) => {
            const filter = {
                ...allFilterState,
                sort: "desc",
                sortBy: "updatedAt",
                page: 1,
                filter: filtersData,
            };
            dispatch(fetchFlitchingDoneApprovalList(filter));
            setAllFilterState(filter);
            sessionStorage.removeItem(flitchingDoneApprovalFilter);
        },
        [allFilterState?.limit, allFilterState?.searchTerm]
    );

    const debouncedHandleSearch = debounce(handleSearch, 1000);

    useEffect(() => {
        //to deal with search lagging
        if (allFilterState?.searchTerm !== "") {
            debouncedHandleSearch(allFilterState?.searchTerm);
        }
        return () => {
            debouncedHandleSearch.cancel();
        };
    }, [allFilterState?.searchTerm]);

    useEffect(() => {
        let retrieveFilter = sessionStorage.getItem(flitchingDoneApprovalFilter);
        let filtersValues = allFilterState;
        if (retrieveFilter) {
            filtersValues = JSON.parse(retrieveFilter);
            setAllFilterState(filtersValues);
        }
        dispatch(fetchFlitchingDoneApprovalList(filtersValues));
    }, []);

    return (
        <Box>
            <Div>
                <Box>
                    <IssuesForCrosscuttingFilters
                        handleSubmit={handleApplyFilter}
                        handleClear={handleClearFilter}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        gap: 2,
                        my: 2,
                        justifyContent: "space-between",
                        flexWrap: "wrap-reverse",
                    }}
                >
                    <Box>
                        <TextField
                            size="small"
                            id="search"
                            type="search"
                            label="Search"
                            value={allFilterState?.searchTerm}
                            onChange={(e) => {
                                setAllFilterState((prev) => ({
                                    ...prev,
                                    searchTerm: e.target.value,
                                }));
                                if (!e.target.value) {
                                    const filter = {
                                        ...allFilterState,
                                        searchTerm: "",
                                        sort: "desc",
                                        sortBy: "updatedAt",
                                        page: 1,
                                    };
                                    setAllFilterState(filter);
                                    dispatch(fetchFlitchingDoneApprovalList(filter));
                                    sessionStorage.setItem(
                                        flitchingDoneApprovalFilter,
                                        JSON.stringify(filter)
                                    );
                                }
                            }}
                            sx={{
                                width: 300,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <Div sx={{ cursor: "pointer" }}>
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    </Div>
                                ),
                            }}
                        />
                    </Box>

                    <Box display={"flex"} gap={2} flexWrap={"wrap"}>
                        <TextField
                            sx={{ width: "100px" }}
                            type="number"
                            size="small"
                            inputProps={{
                                min: 1,
                            }}
                            label="Page No"
                            onKeyDown={(e) => {
                                const inputValue = parseInt(e.target.value + e.key, 10);
                                if (isNaN(inputValue) || inputValue < 0) {
                                    e.preventDefault();
                                }
                            }}
                            value={allFilterState?.page}
                            onChange={(e) => {
                                let value = parseInt(e.target.value);
                                const filter = {
                                    ...allFilterState,
                                    page: value,
                                };
                                setAllFilterState(filter);
                                dispatch(fetchFlitchingDoneApprovalList(filter));
                                sessionStorage.setItem(
                                    flitchingDoneApprovalFilter,
                                    JSON.stringify(filter)
                                );
                            }}
                        ></TextField>

                        <FormControl size="small">
                            <InputLabel id="limit-label">Limit</InputLabel>
                            <Select
                                autoSelect
                                autoHighlight
                                labelId="limit-label"
                                id="limit"
                                value={allFilterState?.limit}
                                label="Limit"
                                onChange={handleChangeLimit}
                                sx={{ width: "80px" }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={75}>75</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <FlitchingDoneApprovalTableListing
                    allFilterState={allFilterState}
                    setAllFilterState={setAllFilterState}
                />
            </Div>
        </Box>
    );
};

export default ListFlitchingDoneApproval;
