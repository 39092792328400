import Page from "@jumbo/shared/Page";
import AddFlitchStock from "app/pages/Inventory/Flitch-Inventory/AddFlitch";
import FlitchInventoryList from "app/pages/Inventory/Flitch-Inventory/ListFlitch";
import EditFlitchStock from "app/pages/Inventory/Flitch-Inventory/EditFlitch";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";
import ViewDetails from "app/pages/Inventory/Flitch-Inventory/ListFlitch/Approval/ViewDetails";

export const flitchInventoryRoute = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "flitch_inventory", "view"],
      },
    ],
    routes: [
      {
        path: "/inventory/flitch",
        element: <Page component={FlitchInventoryList} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/flitch/add",
        element: <Page component={AddFlitchStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/flitch/edit",
        element: <Page component={EditFlitchStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/flitch-approval/view-details/:id/:invoiceId",
        element: <Page component={ViewDetails} layout={"vertical-default"} />,
      },
    ],
  },
];
