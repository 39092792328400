import Div from "@jumbo/shared/Div/Div";

import { Button, Checkbox, Grid, TextField, Tooltip, Typography, withStyles } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import { getCurrentDate } from "app/components/Function/getCurrentDate";

import ItemDetailsTable from "./itemTable";

import moment from "moment";
import { machineNameDropDown } from "app/services/apis/machineList";
import IssuedLogTable from "./issuedLogTable";

import addCrossCutFactory from "app/services/apis/addCrossCutFactory";
import { LoadingButton } from "@mui/lab";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import { useSelector } from "react-redux";

function CreateCrossCut() {
  // const { state, pathname } = useLocation();
  const { user_type, role_id } = useSelector(
    (state) => state?.userReducer?.user?.[0]
  );
  const { state } = useLocation();
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const [actualLogDetails, setActualLogDetails] = useState(state);
  const [isCalculated, setIsCalculated] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const [machineList, setMachineList] = useState([]);
  const [addedItemDetailsList, setAddedItemDetailsList] = useState([]);
  const formikRef = useRef();
  const [wastageInfo, setWastageInfo] = useState({
    wastage_sqm: 0,
    wastage_length: 0,
  });

  const [available_quantity, set_available_quantity] = useState({
    available_sqm: 0,
    available_length: 0,
    amount: 0,
    crosscutting_completed: false,
    id: actualLogDetails?._id ? actualLogDetails?._id : "",
    sqm_factor: 1,
    expense_amount: 0,
  });

  const [finalData, setFinalData] = useState({
    // company_details: {},
    // branch_detail: {},
    no_of_workers: 0,
    shift: "",
    working_hours: 0,
    crosscut_date: getCurrentDate(),
    no_of_total_hours: 0
  });

  const [initialItemForm, setInitailItemForm] = useState({
    item_sr_no: addedItemDetailsList.length + 1 || 1,
    // item_name: "",
    // item_id: "",
    log_inventory_item_id: actualLogDetails?.log_inventory_item_id,
    issue_for_crosscutting_id: actualLogDetails?._id,
    factor: 0,
    machine_name: "",
    machine_id: "",
    log_no: "",
    code: "",
    log_no_code: "",
    length: 0,
    girth: actualLogDetails?.physical_diameter || 0,
    crosscut_cmt: 0,
    per_cmt_cost: 0,
    cost_amount: 0,
    required_hours: 0,
    remarks: "",
  })

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (addedItemDetailsList && addedItemDetailsList.length <= 0) {
        Swal.fire({
          title: "Add at least one item details.",
          icon: "warning",
          showCancelButton: false,
        });
      }
      formikRef.current.setTouched({
        crosscut_date: true,
        no_of_workers: true,
        shift: true,
        working_hours: true,
      });

      const errors = await formikRef.current.validateForm();
      if (Object.keys(errors).length > 0) {
        setLoading(false);
        return;
      }

      const workerDetails = {
        working_hours: finalData?.working_hours,
        crosscut_date: finalData?.crosscut_date,
        shift: finalData?.shift,
        workers: finalData?.no_of_workers,
        no_of_total_hours: finalData?.no_of_total_hours
      };
      const newData = addedItemDetailsList.map((item) => {
        item.worker_details = workerDetails;
        return item;
      });
      const response = await addCrossCutFactory(newData, available_quantity);
      if (response?.data?.success) {
        Swal.fire({
          title: response?.data?.message,
          icon: "success",
          showCancelButton: false,
        });
        setLoading(false);
        navigate("/factory/crosscut");
      }
    } catch (error) {
      setLoading(false);
      console.log("err adding cross cutting done => ", error.message);
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showCancelButton: false,
      });
    }
  };

  const stock = {
    crosscut_date: getCurrentDate(),
    // invoice_no: "",
    // currency: "",
    // supplier_name: "",
    // branch_name: "",
    no_of_workers: 0,
    shift: "",
    working_hours: "",
    no_of_total_hours: 0
  };

  const validationSchema = yup.object({
    crosscut_date: yup.date().required("Date of Inward is required"),
    no_of_workers: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    no_of_total_hours: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    shift: yup.string().required("Shift is Required"),
    working_hours: yup.number().positive().moreThan(0, "Must be greater than 0").required("Working hours is Required"),
  });

  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const res = await machineNameDropDown("");
        setMachineList(res?.data?.result);
      } catch (error) {
        console.log("err fetching machine list=> ", error.message);
      }
    };
    fetchMachines();
  }, []);

  const handleCalculate = () => {
    const parsedPhysicalSqm = Number(actualLogDetails?.available_quantity?.physical_cmt);
    const physicalLength = Number(actualLogDetails?.available_quantity?.physical_length);

    const costAmount = Number(actualLogDetails?.available_quantity?.amount);
    const expenseAmount = Number(actualLogDetails?.available_quantity?.expense_amount);

    // eslint-disable-next-line no-unused-vars
    const totalLength = addedItemDetailsList?.reduce((acc, val) => acc + (parseFloat(val?.length) || 0), 0);
    const totalSqm = addedItemDetailsList?.reduce((acc, val) => acc + Number(val?.crosscut_cmt), 0);

    if (Number(physicalLength) === Number(totalLength)) {
      set_available_quantity({ ...available_quantity, crosscutting_completed: true });
    }
    if (Number(parsedPhysicalSqm) === Number(totalSqm)) {
      set_available_quantity({ ...available_quantity, crosscutting_completed: true });
    }
    const totalAmount = addedItemDetailsList?.reduce((acc, val) => acc + (Number(val?.cost_amount) || 0), 0);

    const wastageSqm = Number((parsedPhysicalSqm - totalSqm).toFixed(3));

    const outputSqm = Number((parsedPhysicalSqm - wastageSqm).toFixed(3));

    const availableLength = Number((physicalLength - Number(totalLength)).toFixed(2));

    const totalHours = Number((Number(finalData?.no_of_workers) * Number(finalData?.working_hours).toFixed(2)));

    const selectedSqm = isChecked ? outputSqm : parsedPhysicalSqm;
    // eslint-disable-next-line no-unused-vars
    const amountsForItems = addedItemDetailsList?.map((item) => {
      const sqFactor = Number(Number(item?.crosscut_cmt) / selectedSqm);
      item.sqm_factor = sqFactor;
      const itemCostAmount = Number((Number(costAmount) * Number(sqFactor)).toFixed(2));
      item.cost_amount = itemCostAmount;
      const expenseAmount = Number((Number(actualLogDetails?.available_quantity?.expense_amount) * Number(sqFactor)).toFixed(2));
      item.expense_amount = expenseAmount;
      const itemPerCmtAmount = Number((Number(item?.cost_amount) / Number(item?.crosscut_cmt)).toFixed(2));
      item.per_cmt_cost = itemPerCmtAmount;
      // item.required_hours = Math.round(Number(totalHours) * Number(sqFactor));
      // item.required_workers = Number(finalData?.no_of_workers) / Number(item?.crosscut_cmt)
      item.required_hours = totalSqm > 0 ? Math.round((Number(item?.crosscut_cmt) / totalSqm) * Number(totalHours)) : 0;
      // item.required_workers = totalSqm > 0
      //   ? Math.round((Number(item?.crosscut_cmt) / totalSqm) * Number(finalData?.no_of_workers))
      //   : 0;
      item.required_workers =
        totalSqm > 0 ? Number(((Number(item?.crosscut_cmt) / totalSqm) * Number(finalData?.no_of_workers)).toFixed(2)) : 0;
      let wastageData = { wastage_sqm: 0, wastage_length: 0 };
      if (isChecked) {
        let perWastageSqm = Number((Number(sqFactor) * Number(wastageSqm)).toFixed(3));
        wastageData.wastage_sqm = perWastageSqm;
        let wastageLength = Number((Number(sqFactor) * Number(availableLength)).toFixed(2));
        wastageData.wastage_length = wastageLength;
      }
      item.wastage_info = wastageData;
      return item;
    });
    setAddedItemDetailsList(amountsForItems);
    if (isChecked) {
      // const wastageData = {
      //   wastage_sqm: wastageSqm,
      //   wastage_length: availableLength,
      // };
      set_available_quantity({
        ...available_quantity,
        amount: 0,
        expense_amount: 0,
        available_length: 0,
        available_sqm: 0,
        sqm_factor: 0,
        crosscutting_completed: true,
      });

      // const updatedItemDetails = addedItemDetailsList?.map((item) => {
      //   item.wastage_info = wastageData;
      //   return item;
      // });
      // console.log("wastage => ", wastageData)
      // setWastageInfo(wastageData);
      // setAddedItemDetailsList(updatedItemDetails);
    } else {
      const updatedTotalAmount = amountsForItems?.reduce((acc, val) => acc + (Number(val?.cost_amount) || 0), 0);
      const updatedTotalExpenseAmount = amountsForItems?.reduce((acc, val) => acc + (Number(val?.expense_amount) || 0), 0);

      const availableData = {
        available_sqm: wastageSqm,
        available_length: availableLength,
        amount: Number((Number(costAmount) - Number(updatedTotalAmount)).toFixed(2)),
        sqm_factor: Number((Number(wastageSqm) / parsedPhysicalSqm).toFixed(2)),
        expense_amount: Number((Number(expenseAmount) - Number(updatedTotalExpenseAmount)).toFixed(2)),
        // id: actualLogDetails?._id ? actualLogDetails?._id : ""
      };

      set_available_quantity({
        ...available_quantity,
        available_length: availableData.available_length,
        available_sqm: availableData.available_sqm,
        amount: availableData.amount,
        expense_amount: availableData.expense_amount,
        sqm_factor: availableData.sqm_factor,
        crosscutting_completed: Number(physicalLength) === Number(totalLength) ? true : false,
      });
    }
    setIsCalculated(true);
  };

  const handleCalculateTotalHours = (workers, hours, setFieldValue) => {
    const no_of_total_hours = Number((workers * hours)?.toFixed(2));

    setFieldValue("no_of_total_hours", no_of_total_hours);
    setFinalData({
      ...finalData,
      no_of_total_hours: no_of_total_hours,
    });
  }

  return (
    <Div sx={{ mt: -4 }}>
      {/* <Typography variant="h1">Create Crosscut</Typography> */}
      <Div>
        <IssuedLogTable actualData={actualLogDetails} />
      </Div>

      <Div sx={{ marginTop: 5, marginBottom: 2 }}>
        <Formik
          validateOnChange={false}
          initialValues={stock}
          enableReinitialize={true}
          validationSchema={validationSchema}
          // onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {({ setFieldValue, values, errors, handleBlur, setFieldError, setFieldTouched, touched, handleSubmit, validateForm }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4, width: "100%" }}>
                <Grid
                  // sx={{
                  //   display: tabValue === 1 ? "none" : "",
                  // }}
                  container
                  rowSpacing={2}
                  columnSpacing={4}
                >
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Crosscut Date*
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                        defaultValue={values?.crosscut_date !== "" ? dayjs(values?.crosscut_date) : null}
                        onChange={(newValue) => {
                          setFinalData({
                            ...finalData,
                            crosscut_date: new Date(newValue),
                          });
                          // setFieldValue("inward_date", newValue.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                          setFieldValue("crosscut_date", new Date(newValue));
                          setIsCalculated(false);
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage name="crosscut_date" component="div" style={{ color: "red" }} />
                      </Div>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Shift*
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="shift"
                      onChange={(e) => {
                        setFieldValue("shift", e.target.value);
                        setIsCalculated(false);
                      }}
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({ ...finalData, shift: e.target.value });
                      }}
                      value={values?.shift}
                      status={true}
                      error={touched?.shift && errors?.shift}
                      helperText={touched?.shift && errors?.shift}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Workers*
                    </Typography>
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="no_of_workers"
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          no_of_workers: e.target.value,
                        });
                      }}
                      onChange={(e) => {
                        setFieldValue("no_of_workers", e.target.value);
                        handleCalculateTotalHours(e.target.value, values?.working_hours, setFieldValue)
                        setIsCalculated(false);
                      }}
                      value={values?.no_of_workers || ""}
                      status={true}
                      error={touched?.no_of_workers && errors?.no_of_workers}
                      helperText={touched?.no_of_workers && errors?.no_of_workers}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      No. of  Working Hours*
                    </Typography>
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="working_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          working_hours: e.target.value,
                        });
                      }}
                      onChange={(e) => {
                        setFieldValue("working_hours", e.target.value);
                        handleCalculateTotalHours(values?.no_of_workers, e.target.value, setFieldValue)
                        setIsCalculated(false);
                      }}
                      value={values?.working_hours}
                      status={true}
                      error={touched?.working_hours && errors?.working_hours}
                      helperText={touched?.working_hours && errors?.working_hours}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      No. of Total Hours*
                    </Typography>
                    <TextField
                      disabled
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"

                      name="no_of_total_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          no_of_total_hours: e.target.value,
                        });
                      }}
                      onChange={(e) => {
                        setFieldValue("no_of_total_hours", e.target.value);
                        setIsCalculated(false);
                      }}
                      value={values?.no_of_total_hours}
                      status={true}
                      error={touched?.working_hours && errors?.no_of_total_hours}
                      helperText={touched?.no_of_total_hours && errors?.no_of_total_hours}
                    />
                  </Grid>
                  <Grid className="flex flex-row items-center  justify-center ">
                    <Checkbox
                      checked={isChecked}
                      onChange={() => {
                        setIsChecked((prevState) => !prevState);
                        setIsCalculated(false);
                      }}
                    />
                    <Typography variant="h6" fontSize="14px">
                      isComplete*
                    </Typography>
                  </Grid>
                </Grid>
              </Div>
            </Form>
          )}
        </Formik>

        <ItemDetailsTable
          actualLogData={actualLogDetails}
          setAddedItemDetailsList={setAddedItemDetailsList}
          addedItemDetailsList={addedItemDetailsList}
          finalData={finalData}
          machineList={machineList}
          isCalculated={isCalculated}
          setIsCalculated={setIsCalculated}
          handleCalculate={handleCalculate}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          wastageInfo={wastageInfo}
          code={code}
          setCode={setCode}
          setInitailItemForm={setInitailItemForm}
          initialItemForm={initialItemForm}
        />
        <Div className="flex flex-row items-center justify-center gap-6 mt-8">
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              Swal.fire({
                text: "Are you sure you want to cancel?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/factory/crosscut");
                }
              });
            }}
          >
            Cancel
          </Button>
          {isCalculated ? (
            <LoadingButton onClick={() => {
              Swal.fire({
                text: isChecked ? "Crosscutting has been marked as completed. Are you sure you want to save?" : "Crosscutting has been marked as incompleted. Are you sure you want to save?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  handleSubmit()
                }
              });
            }} type="submit" size="small" variant="contained" loading={loading}>
              Save
            </LoadingButton>
          ) : (
            <Tooltip title={addedItemDetailsList?.length !== 0 ? "" : "You need to add at least one item"}>
              <span>
                <LoadingButton disabled={addedItemDetailsList.length === 0} onClick={handleCalculate} size="small" variant="contained">
                  Calculate
                </LoadingButton>
              </span>
            </Tooltip>
          )}
        </Div>
      </Div>
    </Div>
  );
}

export default HeaderTitleHoc(CreateCrossCut, "Create Crosscut");