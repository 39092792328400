import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Slide,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { supplierBranchDropDownList, supplierDropDownList } from "app/services/apis/supplierList";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import { Box } from "@mui/material";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import FullScreenLoader from "app/components/ListingPageLoader";
import { itemNameDropDown } from "app/services/apis/addItemName";
import { currencyDropDownList } from "app/services/apis/currencyApis";
import { dropdownCutName } from "app/services/apis/cutApis";
import { dropdownGradeName } from "app/services/apis/gradeApis";
import { gstDropdownList } from "app/services/apis/gstApis";
import { dropdownSeriesName } from "app/services/apis/seriesApi";
import { itemSubCategoryListDropDown } from "app/services/apis/subCategoryApis";
import { Axios, socket } from "index";
import InvoiceDetailsTable from "./invoiceDetailTable";
import ItemDetailsTable from "./itemTable";

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 3000,
  customClass: {
    container: "popupImportant",
  },
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ pt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function AddVeneerStock() {
  const [isImportData, setIsImportData] = useState(false);
  const [importDataFile, setImportDataFile] = useState(null);

  const navigate = useNavigate();
  const validFileTypes = [".csv", ".xlsx", ".xls"];
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarBulkUploadOpen, setSnackbarBulkUploadOpen] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [gstNameList, setGstNameList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);
  const [cutNameList, setCutNameList] = useState([]);
  const [seriesNameList, setSeriesNameList] = useState([]);
  const [gradeNameList, setGradeNameList] = useState([]);
  const [itemSubCategoryNameList, setItemSubCategoryNameList] = useState([]);


  const [tabValue, setTabValue] = useState(0);
  const [newTabValue, setNewTabValue] = useState(0);
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [supplierBranchList, setSupplierBranchList] = useState([]);
  const [addedItemDetailsList, setAddedItemDetailsList] = useState([]);

  const [finalData, setFinalData] = useState({
    company_details: {},
    branch_detail: {},
    no_of_workers: 0,
    exchange_rate: 0,
    shift: "",
    working_hours: "",
    total_hours: "",
    inward_date: getCurrentDate(),
    currency: null,
    invoice_no: null,
  });

  const [initialItemForm, setInitailItemForm] = useState({
    item_sr_no: addedItemDetailsList.length + 1 || 1,
    supplier_item_name: "",
    supplier_code: "",
    item_name: "",
    item_id: "",
    item_sub_category_name: "",
    item_sub_category_id: "",
    log_code: "",
    bundle_number: "",
    pallet_number: "",
    length: "",
    width: "",
    thickness: "",
    number_of_leaves: "",
    total_sq_meter: "",
    cut_id: "",
    cut_name: "",
    series_id: "",
    series_name: "",
    grades_id: "",
    grades_name: "",
    exchange_rate: finalData?.exchange_rate || "",
    rate_in_currency: 0,
    rate_in_inr: "",
    amount: "",
    remark: "",
  })

  const [initialInvoiceData, setInitialInvoiceData] = useState({
    invoice_date: getCurrentDate(),
    total_item_amount: 0,
    transporter_details: "",
    port_of_loading: "",
    port_of_discharge: "",
    bill_of_landing: "",
    freight: "",
    isFreightInclude: false,
    load_unload: "",
    isLoadUnloadInclude: false,
    insurance: "",
    isInsuranceInclude: false,
    other: "",
    isOtherInclude: false,
    other_for_import: "",
    gst_percentage: "",
    igst_percentage: 0,
    sgst_percentage: 0,
    cgst_percentage: 0,
    gst_value: 0,
    invoice_value_with_gst: 0,
    remark: "",
  });

  const getSupplierBranchList = async (supplierId) => {
    const supplerBranchList = await supplierBranchDropDownList(supplierId);
    if (supplerBranchList.data.success) {
      setSupplierBranchList(supplerBranchList?.data?.result);
    }
  };

  const handleSubmit = (values) => {
    if (addedItemDetailsList && addedItemDetailsList.length <= 0) {
      Swal.fire({
        text: "Add at least one item details.",
        icon: "warning",
        showCancelButton: false,
      });
    } else {
      setTabValue(newTabValue);
      // setInitailItemForm({
      //   item_sr_no: addedItemDetailsList.length + 1 || 1,
      //   supplier_item_name: "",
      //   supplier_code: "",
      //   item_name: "",
      //   item_id: "",
      //   item_sub_category_name: "",
      //   item_sub_category_id: "",
      //   log_code: "",
      //   bundle_number: "",
      //   pallet_number: "",
      //   length: "",
      //   width: "",
      //   thickness: "",
      //   number_of_leaves: "",
      //   total_sq_meter: "",
      //   cut_id: "",
      //   cut_name: "",
      //   series_id: "",
      //   series_name: "",
      //   grades_id: "",
      //   grades_name: "",
      //   exchange_rate: finalData?.exchange_rate || "",
      //   rate_in_currency: "",
      //   rate_in_inr: "",
      //   amount: "",
      //   remark: "",
      // })
    }
  };

  const handleImportDataSubmit = function (values) {
    if (!importDataFile) {
      Swal.fire({
        title: "please select file",
        icon: "warning",
        showCancelButton: false,
      });
      return;
    }
    handleImportVeneerData();
  };

  const stock = {
    inward_date: getCurrentDate(),
    invoice_no: "",
    currency: "",
    exchange_rate: "",
    supplier_name: "",
    branch_name: "",
    no_of_workers: "",
    shift: "",
    working_hours: "",
    total_hours: "",
  };

  const validationSchema = yup.object({
    inward_date: yup.date().required("Date of Inward is required"),
    currency: yup.string().required("Currency is required").nullable(),
    exchange_rate: yup
      .number()
      // .positive()
      // .moreThan(0, "Must be greater than 0")
      // .required("Required")
      .when((currency, schema) =>
        finalData?.currency?.currency_name !== "INR"
          ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.")
          : schema.notRequired()
      ),
    invoice_no: yup.string().required("Invoice No. is required"),
    supplier_name: yup.string().required("Supplier Name is required"),
    branch_name: yup.string().required("Branch Name is required"),
    no_of_workers: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    shift: yup.string().required("Required"),
    working_hours: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    total_hours: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
  });

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name.toLowerCase();
      const isValidType = validFileTypes.some((type) => fileName.endsWith(type));

      if (!isValidType) {
        Swal.fire({
          title: "Invalid file type. Please select a CSV or Excel file.",
          icon: "warning",
          showCancelButton: false,
        });
        return;
      } else {
        setImportDataFile(file); // valid file
      }
    }
  };

  const handleDonwloadFormat = async () => {
    try {
      setSnackbarOpen(true);
      const res = await Axios.get("/veneer-inventory/donwload-format");
      if (res.data.success) {
        window.open(res?.data?.result);
        Toast.fire({
          timer: 3000,
          icon: "success",
          title: "CSV format download successfully.",
          position: "top-right",
          // background: theme.palette.background.paper,
        });
        setSnackbarOpen(false);
      } else {
        Toast.fire({
          timer: 3000,
          icon: "error",
          title: "CSV  Downloading failed..",
          position: "top-right",
          // background: theme.palette.background.paper,
        });
        setSnackbarOpen(false);
      }
    } catch (error) {
      setSnackbarOpen(false);
      Toast.fire({
        timer: 3000,
        icon: "error",
        title: error.response?.data.message || "An error occured while downloading csv",
        position: "top-right",
        // background: theme.palette.background.paper,
      });
    }
  };

  const handleImportVeneerData = async function () {
    try {
      setSnackbarBulkUploadOpen(true);
      let finalLogData = {
        inward_date: finalData?.inward_date,
        invoice_no: finalData?.invoice_no,
        currency: finalData?.currency?.currency_name,
        exchange_rate: finalData?.exchange_rate,
        workers_details: {
          no_of_workers: finalData?.no_of_workers,
          shift: finalData?.shift,
          working_hours: finalData?.working_hours,
          total_hours: finalData?.total_hours,
        },
        supplier_details: {
          company_details: {
            ...finalData?.company_details,
            supplier_company_id: finalData?.company_details?._id,
          },
          branch_detail: {
            ...finalData?.branch_detail,
            branch_id: finalData?.branch_detail?._id,
          },
        },
      };
      const formData = new FormData();
      formData.append('finalData', JSON.stringify(finalLogData));
      formData.append('file', importDataFile);
      formData.append('socketId', socket.id);

      const response = await Axios.post('/veneer-inventory/importData', formData);
      if (response.status === 202) {
        Toast.fire({
          timer: 1500,
          icon: "success",
          title: response?.data?.message || "Bulk insert successfully completed.",
          position: "top-right",
          // background: theme.palette.background.paper,
        });
        setSnackbarBulkUploadOpen(false);
        navigate('/inventory/veneer');
      }
    } catch (error) {
      console.log(error);
      setSnackbarBulkUploadOpen(false);
      Toast.fire({
        timer: 1500,
        icon: "error",
        title: error.response?.data?.message || "An error occured while uploading csv data",
        position: "top-right",
        // background: theme.palette.background.paper,
      });
    } finally {
      // setImportDataFile(null)
    }
  };

  const handleExpenseChange = (name, exchangeValue) => {
    if (addedItemDetailsList?.length > 0) {
      let updatedAddedItemDetailsList = [...addedItemDetailsList];
      if (name === "expense") {
        for (let i = 0; i < updatedAddedItemDetailsList.length; i++) {
          let updateRateInINR = Number((Number(updatedAddedItemDetailsList[i]?.rate_in_currency) * Number(exchangeValue))?.toFixed(2));
          let updateAmount = Number((Number(updatedAddedItemDetailsList[i]?.total_sq_meter) * updateRateInINR)?.toFixed(2));

          updatedAddedItemDetailsList[i].exchange_rate = Number(exchangeValue);
          updatedAddedItemDetailsList[i].rate_in_inr = Number(updateRateInINR);
          updatedAddedItemDetailsList[i].amount = Number(updateAmount);
        }
      } else if (name === "currency") {
        for (let i = 0; i < updatedAddedItemDetailsList.length; i++) {
          // let updateRateInINR = Number(updatedAddedItemDetailsList[i]?.rate_in_currency) * Number(exchangeValue);
          // let updateAmount = Number(updatedAddedItemDetailsList[i]?.physical_cmt) * updateRateInINR;

          updatedAddedItemDetailsList[i].exchange_rate = 0;
          updatedAddedItemDetailsList[i].rate_in_currency = 0;
          updatedAddedItemDetailsList[i].rate_in_inr = 0;
          updatedAddedItemDetailsList[i].amount = 0;
        }
      }
      setAddedItemDetailsList(updatedAddedItemDetailsList);
    }
  };

  const calculateTotalHours = function (no_of_workers = 0, working_hours = 0, setFieldValue) {
    const worker = Number(no_of_workers);
    const workingHours = Number(working_hours);
    const totalHours = Number((worker * workingHours)?.toFixed(2))
    setFieldValue("total_hours", totalHours)
    setFinalData({
      ...finalData,
      no_of_workers: worker,
      working_hours: workingHours,
      total_hours: totalHours,
    });
  }

  useEffect(() => {
    (async () => {
      try {
        supplierDropDownList("VENEER")
          .then((response) => {
            if (response.data.success) {
              setSupplierNameList(response.data.result);
            }
          })
          .catch((error) => console.error("Error fetching supplier list:", error));

        itemNameDropDown("VENEER")
          .then((response) => {
            setItemNameList(response?.data?.result);
          })
          .catch((error) => console.error("Error fetching item names:", error));

        itemSubCategoryListDropDown("")
          .then((response) => {
            setItemSubCategoryNameList(response?.data?.result);
          })
          .catch((error) => console.error("Error fetching item subcategories:", error));

        currencyDropDownList()
          .then((response) => {
            setCurrencyList(response?.data?.result);
          })
          .catch((error) => console.error("Error fetching currencies:", error));

        gstDropdownList()
          .then((response) => {
            setGstNameList(response?.data?.result);
          })
          .catch((error) => console.error("Error fetching GST list:", error));

        dropdownCutName()
          .then((e) => {
            setCutNameList(e?.data?.result);
          })
          .catch((error) => console.error("Error Cut Name list:", error));

        dropdownGradeName()
          .then((e) => {
            setGradeNameList(e?.data?.result);
          })
          .catch((error) => console.error("Error fetching Grade list:", error));

        dropdownSeriesName()
          .then((e) => {
            setSeriesNameList(e?.data?.result);
          })
          .catch((error) => console.error("Error fetching Series list:", error));

        // const gradeList = await Axios.get(`${AllApis.dropdownList.grade}`);
        // setGradeList(gradeList?.data?.result);

        // const palleteList = await Axios.get(`${AllApis.dropdownList.pallete}`);
        // setPalleteList(palleteList?.data?.result);
      } catch (error) { }
    })();
    return () => { };
  }, []);



  return (
    <Div sx={{ mt: -4 }}>
      {/* <Typography variant="h1">
        Add Veneer Stock
      </Typography> */}
      <Div>
        <Formik
          validateOnChange={false}
          initialValues={stock}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={isImportData ? handleImportDataSubmit : handleSubmit}
        >
          {({ setFieldValue, values, errors, handleBlur, setFieldError, setFieldTouched, touched, handleSubmit, validateForm }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4, width: "100%" }}>
                <Grid
                  sx={{
                    display: tabValue === 1 ? "none" : "",
                  }}
                  container
                  rowSpacing={2}
                  columnSpacing={3}
                >
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Inward Date*
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                        defaultValue={values?.inward_date !== "" ? dayjs(values?.inward_date) : null}
                        onChange={(newValue) => {
                          setFinalData({
                            ...finalData,
                            inward_date: newValue,
                          });
                          // setFieldValue("inward_date", newValue.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                          setFieldValue("inward_date", newValue);
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage name="inward_date" component="div" style={{ color: "red" }} />
                      </Div>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Supplier Name*
                    </Typography>
                    {/* <Select
                      autoSelect
                      autoHighlight
                      size="small"
                      sx={{ width: "100%" }}
                      name="supplier_name"
                      onChange={(e, options) => {
                        getSupplierBranchList(options?.props?.action?._id);
                        setFieldValue("supplier_name", options?.props?.action?._id);
                        setFinalData({
                          ...finalData,
                          company_details: options?.props?.action || {},
                        });
                      }}
                      defaultValue={values?._id}
                    >
                      {supplierNameList?.map((item) => {
                        return (
                          <MenuItem value={item._id} action={item}>
                            {item?.supplier_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage name="supplier_name" component={"div"} style={{ color: "red" }}></ErrorMessage> */}
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={supplierNameList}
                      name="supplier_name"
                      value={values?.supplier_name || ''}
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.supplier_name;
                        } else {
                          return option;
                        }
                      }}
                      onBlur={() => setFieldTouched("supplier_name", true)}

                      onChange={(e, selectedData) => {
                        if (selectedData) {
                          getSupplierBranchList(selectedData?._id);
                          setFieldValue("supplier_name", selectedData?.supplier_name);
                          setFieldValue("branch_name", "");
                          setFinalData({
                            ...finalData,
                            company_details: selectedData || {},
                          });
                        } else {
                          setFieldValue("supplier_name", "");
                          setFieldValue("branch_name", "");
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.supplier_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.supplier_name && Boolean(errors.supplier_name)} // Show error if touched and there's an error
                          helperText={touched.supplier_name && errors.supplier_name}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Branch Name*
                    </Typography>
                    {/* <Select
                      autoSelect
                      autoHighlight
                      size="small"
                      sx={{ width: "100%" }}
                      name="branch_name"
                      onChange={(e, options) => {
                        setFieldValue("branch_name", options?.props?.action?._id);
                        setBranchDetails({ ...options?.props?.action });
                        setFinalData({
                          ...finalData,
                          branch_detail: options?.props?.action || {},
                        });
                      }}
                      defaultValue={values?._id}
                    >
                      {supplierBranchList?.map((item) => {
                        return (
                          <MenuItem value={item._id} action={item}>
                            {item?.branch_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage name="branch_name" component={"div"} style={{ color: "red" }}></ErrorMessage> */}
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={supplierBranchList}
                      name="branch_name"
                      value={(supplierBranchList && supplierBranchList.find((sup) => sup?.branch_name === values?.branch_name)) || null}
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.branch_name;
                        } else {
                          return option;
                        }
                      }}
                      onBlur={() => setFieldTouched("branch_name", true)}
                      onChange={(e, selectedData) => {
                        setFieldValue("branch_name", selectedData?.branch_name);
                        setFinalData({
                          ...finalData,
                          branch_detail: selectedData || {},
                        });
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.branch_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.branch_name && Boolean(errors.branch_name)} // Show error if touched and there's an error
                          helperText={touched.branch_name && errors.branch_name}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Invoice No.*
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="invoice_no"
                      onChange={(e) => {
                        setFieldValue("invoice_no", e.target.value);
                      }}
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          invoice_no: e.target.value,
                        });
                      }}
                      value={values?.invoice_no}
                      status={true}
                      error={touched?.invoice_no && errors?.invoice_no}
                      helperText={touched?.invoice_no && errors?.invoice_no}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Currency*
                    </Typography>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      // disabled={values?.supplier_details?.country === "India"}
                      options={currencyList}
                      name="currency"
                      // value={values?.currency}
                      getOptionLabel={(option) => option?.currency_name}
                      onBlur={() => setFieldTouched("currency", true)}
                      onChange={(e, selectedData) => {
                        setFinalData({ ...finalData, currency: selectedData, exchange_rate: 0 });
                        setFieldValue("currency", selectedData?._id);
                        setFieldValue("exchange_rate", 0);
                        handleExpenseChange("currency", e.target.value);
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.currency_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.currency && Boolean(errors.currency)} // Show error if touched and there's an error
                          helperText={touched.currency && errors.currency}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Exchange Rate*
                    </Typography>
                    <TextField
                      disabled={finalData?.currency?.currency_name !== "INR" ? false : true}
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="exchange_rate"
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          exchange_rate: e.target.value,
                        });
                        handleExpenseChange("expense", e.target.value);
                      }}
                      onChange={(e) => {
                        setFieldValue("exchange_rate", e.target.value);
                      }}
                      value={values?.exchange_rate || ""}
                      status={true}
                      error={touched?.exchange_rate && errors?.exchange_rate}
                      helperText={touched?.exchange_rate && errors?.exchange_rate}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Shift*
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="shift"
                      onChange={(e) => {
                        setFieldValue("shift", e.target.value);
                      }}
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({ ...finalData, shift: e.target.value });
                      }}
                      value={values?.shift}
                      status={true}
                      error={touched?.shift && errors?.shift}
                      helperText={touched?.shift && errors?.shift}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Workers*
                    </Typography>
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="no_of_workers"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // setFinalData({
                        //   ...finalData,
                        //   no_of_workers: e.target.value,
                        // });
                        calculateTotalHours(e.target.value, values?.working_hours, setFieldValue)
                      }}
                      onChange={(e) => {
                        setFieldValue("no_of_workers", e.target.value);
                      }}
                      value={values?.no_of_workers || ""}
                      status={true}
                      error={touched?.no_of_workers && errors?.no_of_workers}
                      helperText={touched?.no_of_workers && errors?.no_of_workers}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      No. Of Working Hours*
                    </Typography>
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="working_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // setFinalData({
                        //   ...finalData,
                        //   working_hours: e.target.value,
                        // });
                        calculateTotalHours(values?.no_of_workers, e.target.value, setFieldValue)
                      }}
                      onChange={(e) => {
                        setFieldValue("working_hours", e.target.value);
                      }}
                      value={values?.working_hours}
                      status={true}
                      error={touched?.working_hours && errors?.working_hours}
                      helperText={touched?.working_hours && errors?.working_hours}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      No Of Total Hours*
                    </Typography>
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      disabled
                      name="total_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      value={values?.total_hours}
                      status={true}
                      error={touched?.total_hours && errors?.total_hours}
                      helperText={touched?.total_hours && errors?.total_hours}
                    />
                  </Grid>
                </Grid>
              </Div>
              <Div sx={{ mt: 2, display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", gap: 2 }}>
                <Div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={isImportData}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setIsImportData(checked);
                        }}
                      />
                    }
                    label={<Typography sx={{ fontSize: "1.2rem" }}>Bulk Upload</Typography>}
                  />
                </Div>
                {isImportData && (
                  <Div sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", flexWrap: "wrap", gap: 2 }}>
                    <Div>
                      <LoadingButton
                        loading={snackbarOpen}
                        disabled={snackbarOpen ? true : false}
                        size="small"
                        variant="contained"
                        onClick={() => handleDonwloadFormat()}
                      >
                        Download Format
                      </LoadingButton>
                      <Snackbar
                        TransitionComponent={Slide}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={snackbarOpen}
                        message="Downloading format in progress..."
                        action={snackbarOpen && <CircularProgress color="info" size={24} />}
                      />
                    </Div>
                  </Div>
                )}
              </Div>
              {isImportData && (
                <Div sx={{ mt: 3 }}>
                  <TextField
                    name={`importVeneerData`}
                    type="file"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                      accept: ".csv, .xlsx, .xls",
                    }}
                    label="Import Data"
                    sx={{ mr: 2 }}
                    onChange={handleFileChange}
                  />
                  <LoadingButton
                    loading={snackbarBulkUploadOpen}
                    disabled={snackbarBulkUploadOpen ? true : false}
                    // size="medium"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Save
                  </LoadingButton>
                  {/* <form>
                      <input
                        type="file"
                        onClick={handleSubmit}
                        onChange={(e) => handleImportVeneerData(e)}
                        style={{ display: "none" }}
                        id="fileInput"
                      />
                      <label htmlFor="fileInput">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          size="small"

                        >
                          Import File
                        </Button>
                      </label>
                    </form> */}
                </Div>
              )}
              {!isImportData && (
                <Tabs
                  value={tabValue}
                  onChange={(event, newValue) => {
                    handleSubmit();
                    setNewTabValue(newValue);
                  }}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="Item Details" {...a11yProps(0)} />
                  <Tab label="Invoice Details" {...a11yProps(4)} />
                </Tabs>
              )}
            </Form>
          )}
        </Formik>
        {!isImportData && (
          <>
            <CustomTabPanel value={tabValue} index={0}>
              {true ? (
                <ItemDetailsTable
                  itemSubCategoryNameList={itemSubCategoryNameList}
                  itemNameList={itemNameList}
                  cutNameList={cutNameList}
                  seriesNameList={seriesNameList}
                  gradeNameList={gradeNameList}
                  setAddedItemDetailsList={setAddedItemDetailsList}
                  addedItemDetailsList={addedItemDetailsList}
                  finalData={finalData}
                  initialItemForm={initialItemForm}
                  setInitailItemForm={setInitailItemForm}
                />
              ) : (
                <FullScreenLoader />
              )}
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              {true ? (
                <InvoiceDetailsTable
                  setAddedItemDetailsList={setAddedItemDetailsList}
                  addedItemDetailsList={addedItemDetailsList}
                  // setInvoiceDetails={setInvoiceDetails}
                  finalData={finalData}
                  tabValue={tabValue}
                  gstNameList={gstNameList}
                  initialInvoiceData={initialInvoiceData}
                  setInitialInvoiceData={setInitialInvoiceData}
                />
              ) : (
                <FullScreenLoader />
              )}
            </CustomTabPanel>
          </>
        )}
      </Div>
    </Div>
  );
}
export default HeaderTitleHoc(AddVeneerStock, "Add Veneer Stock");
