import { Button, Grid, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import React from "react";
import FormTextField from "app/components/InputField/FormTextField";
import Div from "@jumbo/shared/Div/Div";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";

function ChangePassword() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const user = {
    username: state.first_name + " " + state.last_name,
    user_name: state.user_name,
    new_password: "",
    confirm_password: "",
  };
  const validationSchema = yup.object({
    new_password: yup.string().required("New Password is required"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const onPasswordSave = async (values) => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.patch(
        `${process.env.REACT_APP_URL}/user/admin-change-password?id=${state?._id}`,
        {
          new_password: values?.new_password,
        },
        config
      );
      if (data.status === 200) {
        Swal.fire({
          icon: "success",
          title: data.data.message,
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/user");
      }
    } catch (error) {
      Swal.fire({ icon: "error", title: error.message });
      // navigate("/user");
    }
  };
  return (
    <Div sx={{ mt: -4 }}>
      {/* <Typography variant="h1">Change User Password</Typography> */}
      <Formik
        validateOnChange={true}
        initialValues={user}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onPasswordSave}
      >
        {({
          isSubmitting,
          setFieldValue,
          values,
          handleBlur,
          touched,
          errors,
        }) => (
          <Form noValidate autoComplete="off">
            <Div
              sx={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                columnGap: 5,
                mt: 4,
              }}
            >
              <Div sx={{ width: "100%", display: "flex", columnGap: 5, mb: 4 }}>
                <Div sx={{ display: "flex", width: "45%" }}>
                  <Typography variant="h5">Name:</Typography>
                  <Typography variant="h5" sx={{ fontWeight: "600", pl: 2 }}>
                    {user.username}
                  </Typography>
                </Div>
                <Div sx={{ display: "flex", width: "45%" }}>
                  <Typography variant="h5">User Name:</Typography>
                  <Typography variant="h5" sx={{ fontWeight: "600", pl: 2 }}>
                    {user.user_name}
                  </Typography>
                </Div>
              </Div>
              <Grid container rowSpacing={3} columnSpacing={3}>
                <Grid item xs={6} md={4}>
                  <Typography variant="h6" fontSize="14px">
                    New Password*
                  </Typography>
                  <TextField
                    // type="number"
                    size="small"
                    value={values?.new_password}
                    sx={{ width: "100%" }}
                    name="new_password"
                    onBlur={async (e) => {
                      handleBlur(e);
                    }}
                    onChange={(e) =>
                      setFieldValue("new_password", e.target.value)
                    }
                    error={touched?.new_password && errors?.new_password}
                    helperText={touched?.new_password && errors?.new_password}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography variant="h6" fontSize="14px">
                    Confirm Password*
                  </Typography>
                  <TextField
                    // type="number"
                    size="small"
                    value={values?.confirm_password}
                    sx={{ width: "100%" }}
                    name="confirm_password"
                    onBlur={async (e) => {
                      handleBlur(e);
                    }}
                    onChange={(e) =>
                      setFieldValue("confirm_password", e.target.value)
                    }
                    error={
                      touched?.confirm_password && errors?.confirm_password
                    }
                    helperText={
                      touched?.confirm_password && errors?.confirm_password
                    }
                  />
                </Grid>
              </Grid>
            </Div>
            <Div
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
                mt: 4,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  navigate("/user");
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ width: "100px" }}
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </Div>
          </Form>
        )}
      </Formik>
    </Div>
  );
}

export default HeaderTitleHoc(ChangePassword,"Change User Password");
