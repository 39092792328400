import useHeaderApp from '@jumbo/hooks/useHeaderApp'
import React, { useEffect } from 'react'

const HeaderTitleHoc = (Component,headerTitle) => {
  return function WrappedComponent(props){
    const {title,setTitle} = useHeaderApp();

    useEffect(()=>{
        setTitle(headerTitle);
    },[headerTitle])

    return <Component {...props} headerTitle={title}/>
  }
}

export default HeaderTitleHoc