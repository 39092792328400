import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import { CompareValue } from "app/components/CompareValue";
import React from "react";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  width: "100%",
  borderRight: "1px solid #7352C7",
};

const ListingInvoiceItems = ({ invoiceItems = [], isApprovalPending }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h5">Items Details</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
              }}
            >
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Item Name</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Name</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Grade</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Length</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Thickness</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Number of Sheets</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Total Sq.m.</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Exchange Rate</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate In Currency</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate In Inr</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Amount</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Expense Amount</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceItems &&
              invoiceItems?.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ background: !row?.previous_data && isApprovalPending ? "rgb(255 0 0 / 15%) !important" : "inherit" }}
                >
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.item_sr_no}
                      new_data={row?.item_sr_no}
                    />
                    {/* {row?.item_sr_no} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.supplier_item_name}
                      new_data={row?.supplier_item_name}
                    />
                    {/* {row?.supplier_item_name} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.item_name}
                      new_data={row?.item_name}
                    />
                    {/* {row?.item_name} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.grade_name}
                      new_data={row?.grade_name}
                    />
                    {/* {row?.grade_name} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending} previous_data={row?.previous_data?.length} new_data={row?.length} />
                    {/* {row?.length} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending} previous_data={row?.previous_data?.width} new_data={row?.width} />
                    {/* {row?.width} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.thickness}
                      new_data={row?.thickness}
                    />
                    {/* {row?.thickness} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.number_of_sheets}
                      new_data={row?.number_of_sheets}
                    />
                    {/* {row?.number_of_sheets} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.total_sq_meter}
                      new_data={row?.total_sq_meter}
                    />
                    {/* {row?.total_sq_meter} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.exchange_rate}
                      new_data={row?.exchange_rate}
                    />
                    {/* {row?.exchange_rate} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.rate_in_currency}
                      new_data={row?.rate_in_currency}
                    />
                    {/* {row?.rate_in_currency} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.rate_in_inr}
                      new_data={row?.rate_in_inr}
                    />
                    {/* {row?.rate_in_inr} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending} previous_data={row?.previous_data?.amount} new_data={row?.amount} />
                    {/* {row?.amount} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.expense_amount || 0}
                      new_data={row?.expense_amount}
                    />
                    {/* {row?.expense_amount} */}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue
                      isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.remark}
                      new_data={row?.remark}
                    />
                    {/* {row?.remark} */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ListingInvoiceItems;
