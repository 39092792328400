import axios from "axios";

export const addGrade = async (details) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_URL}/grade-master/add-grade-master`,
      details,
      config
    );
    // console.log(data);
    return data;
  } catch (error) {
    // console.log(error);
    return error.response;
  }
};

export const updateGrade = async (details, id) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_URL}/grade-master/update-grade-master?id=${id}`,
      details,
      config
    );
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const dropdownGradeName = async () => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.get(`${process.env.REACT_APP_URL}/grade-master/dropdown-grade-master`,config);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
