import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { socket } from 'index';
import TaskIcon from '@mui/icons-material/Task';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { veneerBulkUploadData } from 'app/redux/actions/Inventory/VeneerInventory/veneerDispatch';

function LinearProgressWithLabel(props) {
    const success = props?.data?.success?.status || false
    const successMessage = props?.data?.success?.message || false
    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {success ? <TaskIcon sx={{ color: "green" }} /> : <InsertDriveFileIcon />}
                <Typography>{props.fileName}</Typography>
            </Box>
            {!success && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" sx={{ color: props.status === 'error' ? "red" : "green" }} {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>}
            <Typography sx={{ my: 1 }}>{success ? successMessage : props.status}</Typography>
        </>
    );
}
function ErrorWithLabel(props) {
    const error = props?.data?.error?.status || false
    const errorMessage = props?.data?.error?.message || false
    console.log(errorMessage,"Error occured while uploading file") // Don't remove this console
    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {error ? <TaskIcon sx={{ color: "red" }} /> : <InsertDriveFileIcon />}
                <Typography>{props.fileName}</Typography>
            </Box>
            <Typography sx={{ my: 1, color: "red" }}>{error ? errorMessage : props.status}</Typography>
        </>
    );
}
function SuccessWithLabel(props) {
    const success = props?.data?.success?.status || false
    const successMessage = props?.data?.success?.message || false
    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {success ? <TaskIcon sx={{ color: "green" }} /> : <InsertDriveFileIcon />}
                <Typography>{props.fileName}</Typography>
            </Box>
            <Typography sx={{ my: 1 }}>{success ? successMessage : props.status}</Typography>
        </>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
    fileName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    data: PropTypes.object
};

export default function VeneerProgressBar() {
    const progressData = useSelector((state) => state?.veneerBlukUploadDataReducer);
    console.log(progressData)

    return (
        <Box sx={{ width: '100%' }}>
            {Boolean(progressData?.progress) && <LinearProgressWithLabel
                value={progressData?.progress}
                fileName={progressData?.fileName}
                status={progressData?.status}
                data={progressData}
            />}
            {progressData?.error?.status && <ErrorWithLabel
                value={progressData?.progress}
                fileName={progressData?.fileName}
                status={progressData?.status}
                data={progressData}
            />}
            {progressData?.success?.status && <SuccessWithLabel
                value={progressData?.progress}
                fileName={progressData?.fileName}
                status={progressData?.status}
                data={progressData}
            />}
        </Box>
    );
}
