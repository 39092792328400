import AddItemModal from "./addNewItemTable";
import EditItemDetailsTable from "./EditItemTable";

const ItemDetailsTable = ({ setAddedItemDetailsList, addedItemDetailsList, itemNameList, itemSubCategoryNameList, finalData, initialItemForm, setInitailItemForm }) => {

  return (
    <div>
      <AddItemModal
        itemNameList={itemNameList}
        itemSubCategoryNameList={itemSubCategoryNameList}
        setAddedItemDetailsList={setAddedItemDetailsList}
        addedItemDetailsList={addedItemDetailsList}
        finalData={finalData}
        initialItemForm={initialItemForm}
        setInitailItemForm={setInitailItemForm}
      />
      <EditItemDetailsTable
        itemNameList={itemNameList}
        itemSubCategoryNameList={itemSubCategoryNameList}
        setAddedItemDetailsList={setAddedItemDetailsList}
        addedItemDetailsList={addedItemDetailsList}
        finalData={finalData}
      />
    </div>
  );
};

export default ItemDetailsTable;
