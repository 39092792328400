import Div from "@jumbo/shared/Div";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import ConfigList from "./configList";

function ListApprovalConfig() {

  return (
    <Div sx={{ mt: -4 }}>
      <ConfigList/>
    </Div>
  );
}

export default HeaderTitleHoc(ListApprovalConfig,"Approval Configuration");
