import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { formatAmount } from "app/utils/formatAmount";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const tableBodyCell = { textAlign: "left", px: 1 };

const tableRowBodySticky = {
  textAlign: "left",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "white",
};

const FleeceExpensesTableRow = ({ e, allFilterState, selectedIds, setSelectedIds }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions)

  const handleItemAction = (menuItem) => {
    const invoiceId = menuItem?.row?._id
    switch (menuItem.action) {
      case "manageExpenses":
        navigate(`/master/expense/fleece/add/${invoiceId}`)
        break;
      default:
        navigate("/master/expense/fleece");
    }
  };

  return (
    <TableRow key={e?._id}>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.inward_sr_no || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {moment(e?.inward_date).format("DD/MM/YYYY") ||
          "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.invoice_Details?.invoice_no || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{moment(e?.invoice_Details?.invoice_date).format("DD/MM/YYYY") || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.supplier_details?.company_details?.supplier_name || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.supplier_details?.branch_detail?.branch_name || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {formatAmount(Math.round(e?.invoice_Details?.invoice_value_with_gst)) || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {formatAmount(e?.totalExpenseAmount) || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.invoice_Details?.remark || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky }}>
        {/* <Button size='small' type='submit' variant="contained" startIcon={<AccountBalanceWalletIcon />}>
          Manage Expense
        </Button> */}
        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <AddCircleOutlineIcon />,
              title: "Manage Expenses",
              action: "manageExpenses",
              show: ((permissions?.expense_master?.create === true || permissions?.expense_master?.edit === true) ? true : false),
              row: e,
            }
          ].filter((ele) => ele?.show)}
          onClickCallback={handleItemAction}
        />
      </TableCell>
    </TableRow>
  );
};

export default FleeceExpensesTableRow;
