import Page from "@jumbo/shared/Page";
import LogExpences from "app/pages/Master/ExpenseMaster/LogExpenses";
import AddExpenses from "app/pages/Master/ExpenseMaster/LogExpenses/AddExpenses";
import AccessMiddleware from "app/routes/middleware/auth/AccessMiddleware";

export const logExpenseRoutes = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "expense_master", "view"],
      },
    ],
    routes: [
      {
        path: "/master/expense/log",
        element: <Page component={LogExpences} layout={"vertical-default"} />,
      },
      {
        path: "/master/expense/log/add/:invoiceId",
        element: <Page component={AddExpenses} layout={"vertical-default"} />,
      }
    ],
  },
];
