export const flitchItemSrnoDropdownApi = "/flitch-inventory/item-srno-dropdown";
export const flitchInwardSrnoDropdownApi = "/flitch-inventory/inward-srno-dropdown";

//Approval
export const fetchFlitchApprovalApi = `/approval/flitch-approval-invoice-listing`;
export const fetchFlitchItemsApprovalApi = function (id, invoice_id) {
  return `/approval/flitch-approval-item-listing-by-invoice/${id}/${invoice_id}`;
};
export const flitchApproveInvoiceApi = function (id, invoice_id) {
  return `/approval/flitch-approve_invoice_details/${id}/${invoice_id}`;
};
export const flitchRejectInvoiceApi = function (id, invoice_id) {
  return `/approval/flitch-reject_invoice_details/${id}/${invoice_id}`;
};