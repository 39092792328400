const { MDF_INVENTORY_REQUEST, MDF_INVENTORY_SUCCESS, MDF_INVENTORY_FAILED } = require("app/redux/actions/Inventory/MdfInventory/constant");

const INIT_STATE = {
    loading: false,
    totalPage: 0,
    data: [],
    error: {
        isError: false,
        message: null
    }
};

export const mdfInventoryReducer = function (state = INIT_STATE, action) {
    switch (action?.type) {
        case MDF_INVENTORY_REQUEST:
            return {
                loading: true,
                totalPage: 0,
                data: [],
                error: {
                    isError: false,
                    message: null
                }
            }
        case MDF_INVENTORY_SUCCESS:
            return {
                loading: false,
                data: action?.payload?.data,
                totalPage: action?.payload?.totalPage,
                error: {
                    isError: false,
                    message: null
                }
            }
        case MDF_INVENTORY_FAILED:
            const { isError = true, message = "Something Went Wrong" } = action?.payload?.error || {}
            return {
                loading: false,
                totalPage: 0,
                data: [],
                error: {
                    isError: isError,
                    message: message
                }
            }
        default:
            return state;
    }
};