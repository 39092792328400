import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  width: "100%",
  borderRight: "1px solid #7352C7",
};

const ListingInvoiceItems = ({ invoiceItems = [], totalAmount, totalExpenseAmount }) => {
  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow
            sx={{
              bgcolor: "#7352C7",
              color: "white",
            }}
          >
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Item Name</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Flitch No.</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Name</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Sub Category</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log No.</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch Formula</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch Code</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Length</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width1</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width2</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width3</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Height</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch CMT</TableCell>
            {/* <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Exchange Rate</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in Currency</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in INR</TableCell> */}
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Amount</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Expense Amount</TableCell>
            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceItems &&
            invoiceItems?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_sr_no}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.supplier_item_name}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.supplier_flitch_no}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_name}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_sub_category_name}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.log_no}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.flitch_formula}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.flitch_code}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.length}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.width1}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.width2}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.width3}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.height}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.flitch_cmt}</TableCell>
                {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row.exchange_rate}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.rate_in_currency}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.rate_in_inr}</TableCell> */}
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.amount}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.expense_amount}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row.remark}</TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={13} align="center"></TableCell>
            <TableCell align="center">
              <Typography fontSize="14px" color="black" m={0}>
                Total:
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontSize="14px" color="black" m={0}>
                {totalAmount}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontSize="14px" color="black" m={0}>
                {totalExpenseAmount}
              </Typography>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ListingInvoiceItems;
