import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { TableCell, TableRow } from "@mui/material";
import { fetchIssueForFlitching } from "app/redux/actions/Factory/Flitching/issuesForFlitching/issuesForFlitching";
import { hideLoading, setLoading } from "app/redux/actions/Loading";
import { revertIssuesForFlitchingApi } from "app/services/apis/Factory/Flitching/issuesForFlitching";
import { formatAmount } from "app/utils/formatAmount";
import { Axios } from "index";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const tableBodyCell = { textAlign: "left", px: 1 };

const tableRowBodySticky = {
  textAlign: "left",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "white",
};

const IssueForFlitchingTableRow = ({ e, allFilterState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);
  const { user_type, role_id } = useSelector(
    (state) => state?.userReducer?.user?.[0]
  );
  const handleRevertIssueForFlitching = async function (id) {
    try {
      dispatch(setLoading())
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const issueForFlitching = await Axios.post(
        revertIssuesForFlitchingApi(id),
        config
      );

      if (issueForFlitching.status === 200) {
        Swal.fire({
          title: "Revert Successfully",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(fetchIssueForFlitching(allFilterState));
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      dispatch(hideLoading())
    }
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "createFlitching":
        navigate("/factory/add-flitching", { state: menuItem.row });
        break;
      case "revertItem":
        Swal.fire({
          text: "Are you sure you want to revert ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            handleRevertIssueForFlitching(e?._id);
          }
        });
        break;
      default:
        navigate("/factory/flitching");
    }
  };

  return (
    <TableRow key={e?._id}>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.log_invoice_details?.inward_sr_no || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {moment(e?.log_invoice_details?.inward_date).format("DD/MM/YYYY") ||
          "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.log_invoice_details?.invoice_Details?.invoice_no || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.item_sr_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.item_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.item_sub_category_name || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_formula || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.length || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.diameter || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.cmt || "-"}</TableCell>
      {user_type == "ADMIN" && (
        <TableCell sx={{ ...tableBodyCell }}>{e?.rate || "-"}</TableCell>
      )}
      {user_type == "ADMIN" && (
        <TableCell sx={{ ...tableBodyCell }}>{formatAmount(Math.round(e?.amount)) || "-"}</TableCell>
      )}
      {user_type == "ADMIN" && (
        <TableCell sx={{ ...tableBodyCell }}>{formatAmount(Math.round(e?.expense_amount)) || "-"}</TableCell>
      )}
      <TableCell sx={{ ...tableBodyCell }}>{e?.remark || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky }}>
        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <AddCircleOutlineIcon sx={{ color: "green" }} />,
              title: "Create Flitching",
              action: "createFlitching",
              show: role_id?.permissions?.flitching_factory?.create === true ? true : false,
              row: e,
            },
            {
              icon: <SettingsBackupRestoreIcon />,
              title: "Revert Item",
              action: "revertItem",
              show:
                e?.physical_length === e?.available_quantity?.physical_length,
              row: e,
            },
          ].filter((ele) => ele?.show)}
          onClickCallback={handleItemAction}
        />
      </TableCell>
    </TableRow>
  );
};

export default IssueForFlitchingTableRow;
