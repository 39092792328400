import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { flitchingDoneFilter } from "app/utils/constants/sessionFilter";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import FlitchingDoneTableRow from "../Components/FlitchingDoneTableRow";
import { fetchFlitchingDoneList } from "app/redux/actions/Factory/Flitching/flitchingDoneListing/flitchingDoneListing";

const tableHeadCellSx = {
  textAlign: "left",
  minWidth: "100px",
  verticalAlign: "middle",
  px: 1,
  fontWeight: "400",
  color:"white"
};

const tableHeadSortSx = {
  color: "white",
  "&:hover": { color: "white" },
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white !important",
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important", // Set the color for the sorting icon
  },
  "&:hover": {
    color: "white !important", // Set the color when hovering
  },
};

const tableRowHeadSticky = {
  textAlign: "center",
  minWidth: "60px",
  verticalAlign: "middle",
  color: "white",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "#7352C7",
};

const FlitchingDoneTableListing = ({
  allFilterState,
  setAllFilterState,
}) => {
  const FlitchingDoneReducer = useSelector(
    (state) => state?.FlitchingDoneReducer
  );
  const userDetails = useSelector((state) => state?.userReducer?.user?.[0]);
  console.log("FlitchingDoneReducer => ", FlitchingDoneReducer)
  const dispatch = useDispatch();
  const sort = allFilterState?.sort;
  const sortBy = allFilterState?.sortBy;
  const page = allFilterState?.page;

  const handleSort = function (name) {
    const filter = {
      ...allFilterState,
      sort: allFilterState.sort === "desc" ? "asc" : "desc",
      sortBy: name,
      page: 1,
    };
    setAllFilterState(filter);
    dispatch(fetchFlitchingDoneList(filter));
    sessionStorage.setItem(flitchingDoneFilter, JSON.stringify(filter));
  };

  const handleChangePage = (event, newPage) => {
    const filter = {
      ...allFilterState,
      page: newPage,
    };
    setAllFilterState(filter);
    dispatch(fetchFlitchingDoneList(filter));
    sessionStorage.setItem(flitchingDoneFilter, JSON.stringify(filter));
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
                "& .MuiTableCell-root": {
                  // py: 2,
                },
              }}
            >
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "flitching_date"}
                  direction={sort}
                  onClick={(e) =>
                    handleSort("flitching_date")
                  }
                  sx={{ ...tableHeadSortSx }}
                >
                  Flitching Date
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "machine_name"}
                  direction={sort}
                  onClick={(e) => handleSort("machine_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Machine
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={
                    sortBy === "log_no"
                  }
                  direction={sort}
                  onClick={(e) =>
                    handleSort("log_no")
                  }
                  sx={{ ...tableHeadSortSx }}
                >
                  Log No.
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "flitch_code"}
                  direction={sort}
                  onClick={(e) => handleSort("flitch_code")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Flitch Code
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "log_no_code"}
                  direction={sort}
                  onClick={(e) => handleSort("log_no_code")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Log No.Code
                </TableSortLabel>
              </TableCell>

              <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "flitch_formula"}
                  direction={sort}
                  onClick={(e) => handleSort("flitch_formula")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Flitch Formula
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "125px" }}>
                <TableSortLabel
                  active={sortBy === "length"}
                  direction={sort}
                  onClick={(e) => handleSort("length")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Length
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "width1"}
                  direction={sort}
                  onClick={(e) => handleSort("width1")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Width1
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "width2"}
                  direction={sort}
                  onClick={(e) => handleSort("width2")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Width2
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "width3"}
                  direction={sort}
                  onClick={(e) => handleSort("width3")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Width3
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "height"}
                  direction={sort}
                  onClick={(e) => handleSort("height")}
                  sx={{ ...tableHeadSortSx }}
                >
                  height
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>
                <TableSortLabel
                  active={sortBy === "flitch_cmt"}
                  direction={sort}
                  onClick={(e) => handleSort("flitch_cmt")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Flitch CMT
                </TableSortLabel>
              </TableCell>
              {userDetails?.user_type == "ADMIN" && (

                <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "per_cmt_cost"}
                  direction={sort}
                  onClick={(e) => handleSort("per_cmt_cost")}
                  sx={{ ...tableHeadSortSx }}
                  >
                  Per CMT Cost
                </TableSortLabel>
              </TableCell>
                )}
                {userDetails?.user_type == "ADMIN" && (

                  <TableCell sx={{ ...tableHeadCellSx, minWidth: "170px" }}>
                <TableSortLabel
                  active={sortBy === "cost_amount"}
                  direction={sort}
                  onClick={(e) =>
                    handleSort("cost_amount")
                  }
                  sx={{ ...tableHeadSortSx }}
                  >
                  Cost Amount
                </TableSortLabel>
              </TableCell>
                )}
                {userDetails?.user_type == "ADMIN" && (

                  <TableCell sx={{ ...tableHeadCellSx, minWidth: "170px" }}>
                <TableSortLabel
                  active={sortBy === "expense_amount"}
                  direction={sort}
                  onClick={(e) =>
                    handleSort("expense_amount")
                  }
                  sx={{ ...tableHeadSortSx }}
                  >
                  Expense Amount
                </TableSortLabel>
              </TableCell>
                )}
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "170px" }}>
                <TableSortLabel
                  active={sortBy === "wastage_info.wastage_sqm "}
                  direction={sort}
                  onClick={(e) => handleSort("wastage_info.wastage_sqm ")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Wastage CMT
                </TableSortLabel>
              </TableCell>
              {/* <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "wastage_info.wastage_length "}
                  direction={sort}
                  onClick={(e) => handleSort("wastage_info.wastage_length ")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Wastage Length
                </TableSortLabel>
              </TableCell> */}
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "required_hours"}
                  direction={sort}
                  onClick={(e) => handleSort("required_hours")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Required Hours
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                <TableSortLabel
                  active={sortBy === "remarks"}
                  direction={sort}
                  onClick={(e) => handleSort("remarks")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Remark
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>
                Approval Status
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, ...tableRowHeadSticky }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {FlitchingDoneReducer?.data &&
              FlitchingDoneReducer?.data?.length > 0 ? (
              FlitchingDoneReducer?.data?.map((e, i) => {
                return (
                  <FlitchingDoneTableRow
                    key={e?._id}
                    e={e}
                    allFilterState={allFilterState}
                  />
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={14}
                  sx={{ textAlign: "center", py: 2, fontSize: 18 }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          size="medium"
          count={FlitchingDoneReducer?.totalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1,
          }}
        />
      </TableContainer>
    </>
  );
};

export default React.memo(FlitchingDoneTableListing);
