import Page from "@jumbo/shared/Page";
import AddCoreStock from "app/pages/Inventory/Core Inventory/AddCore";
import EditCoreStock from "app/pages/Inventory/Core Inventory/EditCore";
import CoreInventoryList from "app/pages/Inventory/Core Inventory/ListCore";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";
import ViewDetails from "app/pages/Inventory/Core Inventory/ListCore/Approval/ViewDetails";

export const coreInventoryRoute = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "core_inventory", "view"],
      },
    ],
    routes: [
      {
        path: "/inventory/core",
        element: <Page component={CoreInventoryList} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/core/add",
        element: <Page component={AddCoreStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/core/edit",
        element: <Page component={EditCoreStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/core-approval/view-details/:id/:invoiceId",
        element: <Page component={ViewDetails} layout={"vertical-default"} />,
      },
    ],
  },
];
