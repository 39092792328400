import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import InfoIcon from "@mui/icons-material/Info";
import { CompareValue } from 'app/components/CompareValue';

const tableHeaderCellStyle = {
    textAlign: "center",
    fontWeight: 400,
    verticalAlign: "middle",
    color: "white",
    padding: "5px 5px",
    borderRight: "1px solid white",
};
const tableBodyCellStyle = {
    textAlign: "center",
    fontWeight: 400,
    verticalAlign: "middle",
    // padding: "5px 5px",
    width: "100%",
    borderRight: "1px solid #7352C7",
};

const ListingCrossCutItems = ({ itemDetails, isApprovalPending }) => {
    return (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table size="small">
                <TableHead>
                    <TableRow
                        sx={{
                            bgcolor: "#7352C7",
                            color: "white",
                        }}
                    >
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Crosscut Date</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Machine Name</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log No.</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Code</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log No. Code</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Shift</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Workers</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Working Hours</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Required Hours</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>No. of Total Hours</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Length</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Diameter</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Crosscut CMT</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Cost Amount</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Per CMT Cost</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "170px" }}>Wastage CMT</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Wastage Length</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {itemDetails &&
                        itemDetails?.map((row, i) =>
                            <TableRow key={i} sx={{ background: !row?.previous_data && isApprovalPending ? "rgb(255 0 0 / 15%) !important" : 'inherit' }}>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {moment(row?.worker_details?.crosscut_date).format("DD/MM/YYYY")} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={moment(row?.previous_data?.worker_details?.crosscut_date).format("DD/MM/YYYY")}
                                        new_data={moment(row?.worker_details?.crosscut_date).format("DD/MM/YYYY")}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.machine_name} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.machine_name}
                                        new_data={row?.machine_name}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.log_no} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.log_no}
                                        new_data={row?.log_no}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.code} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.code}
                                        new_data={row?.code}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.log_no_code} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.log_no_code}
                                        new_data={row?.log_no_code}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.worker_details?.shift} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.worker_details?.shift}
                                        new_data={row?.worker_details?.shift}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.worker_details?.workers} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.worker_details?.workers}
                                        new_data={row?.worker_details?.workers}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.worker_details?.working_hours} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.worker_details?.working_hours}
                                        new_data={row?.worker_details?.working_hours}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.required_hours} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.required_hours}
                                        new_data={row?.required_hours}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.required_hours} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.worker_details?.no_of_total_hours}
                                        new_data={row?.worker_details?.no_of_total_hours || "-"}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.length} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.length}
                                        new_data={row?.length}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.girth} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.girth}
                                        new_data={row?.girth}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.crosscut_cmt} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.crosscut_cmt}
                                        new_data={row?.crosscut_cmt}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.cost_amount} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.cost_amount}
                                        new_data={row?.cost_amount}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.per_cmt_cost} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.per_cmt_cost}
                                        new_data={row?.per_cmt_cost}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {Number(row?.wastage_info?.wastage_sqm).toFixed(2)} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={Number(row?.previous_data?.wastage_info?.wastage_sqm || 0).toFixed(3)}
                                        new_data={Number(row?.wastage_info?.wastage_sqm || 0).toFixed(3)}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {Number(row?.wastage_info?.wastage_length).toFixed(2)} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={Number(row?.previous_data?.wastage_info?.wastage_length || 0).toFixed(3)}
                                        new_data={Number(row?.wastage_info?.wastage_length || 0).toFixed(3)}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {row?.remarks} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={row?.previous_data?.remarks}
                                        new_data={row?.remarks}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                </TableBody>

            </Table>
        </TableContainer>
    )
}

export default (ListingCrossCutItems)