export const faceItemSrnoDropdownApi = "/face-inventory/item-srno-dropdown";
export const faceInwardSrnoDropdownApi = "/face-inventory/inward-srno-dropdown";

//Approval
export const fetchFaceApprovalApi = `/approval/face-approval-invoice-listing`;
export const fetchFaceItemsApprovalApi = function (id, invoice_id) {
  return `/approval/face-approval-item-listing-by-invoice/${id}/${invoice_id}`;
};
export const faceApproveInvoiceApi = function (id, invoice_id) {
  return `/approval/face-approve_invoice_details/${id}/${invoice_id}`;
};
export const faceRejectInvoiceApi = function (id, invoice_id) {
  return `/approval/face-reject_invoice_details/${id}/${invoice_id}`;
};