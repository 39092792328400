import Div from '@jumbo/shared/Div';
import { Box, Button, Grid } from '@mui/material';
import FilterAccordian from 'app/components/FilterAccordian';
import { faceExpensesFilter } from 'app/utils/constants/sessionFilter';
import React, { useEffect, useMemo, useState } from 'react';
import { CustomDatePicker, InwardSrNo, SupplierList } from './FiltersComponents';

const supplier_name =
  "supplier_details.company_details.supplier_name";

const filterObj = {
  "inward_sr_no": "",
  [supplier_name]: "",
  range: {
    date: {
      "inward_date": {
        from: "",
        to: "",
      },
      "invoice_Details.invoice_date": {
        from: "",
        to: "",
      },
    },
  },
};

function FaceFilters({ handleSubmit, handleClear, sx }) {
  const [filters, setFilters] = useState(JSON.parse(JSON.stringify(filterObj)))

  const handleFilter = useMemo(() => {
    return setFilters
  }, [])

  useEffect(() => {
    let retrieveFilter = sessionStorage.getItem(faceExpensesFilter);
    if (retrieveFilter) {
      let filtersValues = JSON.parse(retrieveFilter)
      if (filtersValues?.filter) {
        setFilters(filtersValues?.filter)
      }
    }
  }, [])

  return (
    <Box sx={{ ...sx }}>
      <FilterAccordian
        actions={
          <Div>
            <Button variant="contained" size="small" sx={{ marginRight: 1 }} onClick={() => {
              handleSubmit(filters)
            }}>
              Apply
            </Button>

            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setFilters(JSON.parse(JSON.stringify(filterObj)));
                handleClear(null);
              }}
            >
              Clear
            </Button>
          </Div>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <InwardSrNo
              value={filters?.["inward_sr_no"]}
              setFilters={handleFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <SupplierList
              name={supplier_name}
              value={filters?.[supplier_name]}
              setFilters={handleFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <CustomDatePicker
              title={"Inward Date From"}
              name="inward_date"
              position={"from"}
              value={
                filters?.range?.date?.["inward_date"]?.from
              }
              setFilters={handleFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <CustomDatePicker
              title={"Inward Date To"}
              name="inward_date"
              position={"to"}
              value={
                filters?.range?.date?.["inward_date"]?.to
              }
              setFilters={handleFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <CustomDatePicker
              title={"Invoice Date From"}
              name="invoice_Details.invoice_date"
              position={"from"}
              value={
                filters?.range?.date?.["invoice_Details.invoice_date"]?.from
              }
              setFilters={handleFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <CustomDatePicker
              title={"Invoice Date To"}
              name="invoice_Details.invoice_date"
              position={"to"}
              value={
                filters?.range?.date?.["invoice_Details.invoice_date"]?.to
              }
              setFilters={handleFilter}
            />
          </Grid>
        </Grid>
      </FilterAccordian>
    </Box>
  );
}

export default React.memo(FaceFilters);
