import {
  Autocomplete,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ClearIcon from "@mui/icons-material/Clear";
import { Form, Formik } from "formik";
import * as yup from "yup";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  width: "100%",
  borderRight: "1px solid #7352C7",
};

const initailData = {
  item_sr_no: 0,
  supplier_item_name: "",
  item_id: "",
  item_name: "",
  item_sub_category_name: "",
  item_sub_category_id: "",
  plywood_type: "",
  length: 0,
  width: 0,
  thickness: 0,
  sheets: 0,
  total_sq_meter: 0,
  rate_in_currency: 0,
  exchange_rate: 0,
  rate_in_inr: 0,
  amount: 0,
  remark: "",
};

const EditItemDetailsTable = ({ setAddedItemDetailsList, addedItemDetailsList, itemSubCategoryNameList, itemNameList, finalData }) => {
  //   const itemNameList = [];
  const [data, setData] = useState(initailData);
  const removeRow = (value, index) => {
    // setLoader({ ...loader, removeProduct: true });
    // setTimeout(() => {
    const updateData = [...addedItemDetailsList];
    updateData.splice(index, 1);
    for (let i = 0; i < updateData.length; i++) {
      updateData[i].item_sr_no = i + 1;
    }
    setAddedItemDetailsList(updateData);
    // setLoader({ ...loader, removeProduct: false });
    // }, 200);
  };
  const updateRow = (values, index) => {
    // setLoader({ ...loader, removeProduct: true });
    // setTimeout(() => {
    const updateData = [...addedItemDetailsList];
    updateData.splice(index, 1, values);
    setAddedItemDetailsList(updateData);
    setData(initailData)
    // setLoader({ ...loader, removeProduct: false });
    // }, 200);
  };

  const validationSchema = yup.object({
    supplier_item_name: yup.string("Enter Supplier Name").required("Supplier Name is required"),
    item_sr_no: yup.number().positive().integer().required("Item Serial Number is required"),
    item_name: yup.string().required("Item Name is required"),
    item_sub_category_name: yup.string().required("Item Sub Category Name is required"),
    plywood_type: yup.string().required("Plywood Type is required"),
    // plywood_sub_type: yup.string().required("Plywood Sub Type is required"),
    // pallet_number: yup
    //   .number()
    //   .positive()
    //   .required("Pallet Number is required"),
    length: yup.number().positive().moreThan(0, "Must be greater than 0").required("Length is required"),
    width: yup.number().positive().moreThan(0, "Must be greater than 0").required("Width is required"),
    thickness: yup.number().positive().moreThan(0, "Must be greater than 0").required("Thickness is required"),
    sheets: yup.number().positive().moreThan(0, "Must be greater than 0").required("Sheets is required"),
    total_sq_meter: yup.number().positive().moreThan(0, "Must be greater than 0").required("Total Square Meter is required"),
    // rate_in_currency: yup.number().positive().moreThan(0, "Must be greater than 0").required("Rate in Currency is required"),
    rate_in_currency: yup
      .number()
      // .positive()
      // .moreThan(0, "Must be greater than 0")
      // .required("Rate in Currency is required"),
      .when((currency, schema) =>
        finalData?.currency?.currency_name !== "INR"
          ? schema
            .positive()
            .moreThan(0, "Must be greater than 0")
            .required("Required.")
          : schema.notRequired()
      ),

    // exchange_rate: yup
    //   .number()
    //   .positive()
    //   .moreThan(0, "Must be greater than 0")
    //   .when("rate_in_currency", {
    //     is: (value) => value > 0,
    //     then: yup.number().required("Exchange Rate is required"),
    //     otherwise: yup.number().notRequired(),
    //   }),
    rate_in_inr: yup.number().positive().moreThan(0, "Must be greater than 0").required("Rate in INR is required"),
    amount: yup.number().positive().moreThan(0, "Must be greater than 0").required("Amount is required"),
    remark: yup.string().nullable().default(null),
  });
  const handleSubmit = async (values) => { };

  const handleCalculateTotlSqMeter = (width, sheet, length, setFieldValue, values) => {
    const total = Number((Number(length) * Number(width) * Number(sheet))?.toFixed(3));
    setFieldValue("total_sq_meter", Number(total));
    let amount = Number((Number(total) * Number(values?.rate_in_inr))?.toFixed(2));
    setFieldValue("amount", Number(amount));
  };
  return (
    <div>
      <Formik
        validateOnChange={false}
        initialValues={data}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ validateForm, setFieldValue, values, setFieldTouched, errors, initialValues, handleBlur, touched }) => (
          <Form noValidate autoComplete="off">
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow
                    sx={{
                      bgcolor: "#7352C7",
                      color: "white",
                      "& .MuiTableCell-root": {
                        // py: 2,
                      },
                    }}
                  >
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Item Name</TableCell>
                    {/* <TableCell
                      sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                    >
                      Supplier Flitch No.
                    </TableCell> */}
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Name</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Sub Category</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Plywood Type</TableCell>
                    {/* <TableCell
                      sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                    >
                      Plywood Sub Type
                    </TableCell> */}
                    {/* <TableCell
                      sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                    >
                      Pallet Number
                    </TableCell> */}
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Length</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Thickness</TableCell>

                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Sheets</TableCell>
                    {/* <TableCell
                      sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                    >
                      Height
                    </TableCell> */}
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Total Sq. Meter</TableCell>
                    {/* {values?.currency !== "INR" ? ( */}
                    {/* {finalData?.currency?.currency_name !== "INR" ? (
                      <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Exchange Rate</TableCell>
                    ) : (
                      ""
                    )} */}
                    {/* {values?.currency !== "INR" ? ( */}
                    {finalData?.currency?.currency_name !== "INR" ? (
                      <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in Currency</TableCell>
                    ) : (
                      ""
                    )}
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in INR</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Amount</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                        position: "sticky",
                        right: 0,
                        zIndex: 1,
                        bgcolor: "#7352C7",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                {addedItemDetailsList && addedItemDetailsList.length ? (
                  <TableBody>
                    {addedItemDetailsList &&
                      addedItemDetailsList?.map((row, i) =>
                        values?.item_sr_no !== row?.item_sr_no ? (
                          <TableRow key={i}>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_sr_no}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.supplier_item_name}</TableCell>
                            {/* <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row.supplier_flitch_no}
                            </TableCell> */}
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_name}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_sub_category_name}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.plywood_type}</TableCell>
                            {/* <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row?.plywood_sub_type}
                            </TableCell> */}
                            {/* <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row.pallet_number}
                            </TableCell> */}
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.length}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.width}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.thickness}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.sheets}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.total_sq_meter}</TableCell>
                            {/* <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row.flitch_cmt}
                            </TableCell> */}

                            {/* {finalData?.currency?.currency_name !== "INR" ? (
                              <TableCell sx={{ ...tableBodyCellStyle }}>{row.exchange_rate}</TableCell>
                            ) : (
                              ""
                            )} */}

                            {finalData?.currency?.currency_name !== "INR" ? (
                              <TableCell sx={{ ...tableBodyCellStyle }}>{row.rate_in_currency}</TableCell>
                            ) : (
                              ""
                            )}
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.rate_in_inr}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.amount}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.remark}</TableCell>
                            <TableCell
                              sx={{
                                // textAlign: "center",
                                minWidth: "100%",
                                verticalAlign: "middle",
                                color: "white",
                                px: 1,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                bgcolor: "white",
                                display: "flex",
                              }}
                            >
                              {/* <LoadingButton
                        //   loading={loader.removeProduct}
                        size="small"
                        variant="outlined"
                        color="warning"
                        onClick={() => removeRow(row, i)}
                        sx={{
                          padding:"0px"
                        }}
                      >
                        <EditIcon />
                      </LoadingButton> */}
                              {/* <LoadingButton
                        //   loading={loader.removeProduct}
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={() => removeRow(row, i)}
                        sx={{
                          padding:"0px"
                        }}
                      >
                        <DeleteIcon />
                      </LoadingButton> */}
                              <IconButton color="warning" size="small" onClick={() => setData(row)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton color="error" size="small" onClick={() => removeRow(row, i)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow
                            sx={{
                              background: "rgb(115 82 199 / 20%) !important",
                            }}
                          >
                            <TableCell sx={{ ...tableBodyCellStyle }}>{values?.item_sr_no}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.supplier_item_name}
                                sx={{ width: "100%" }}
                                name="supplier_item_name"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("supplier_item_name", e.target.value)}
                                error={touched?.supplier_item_name && errors?.supplier_item_name}
                                helperText={touched?.supplier_item_name && errors?.supplier_item_name}
                              />
                            </TableCell>
                            {/* <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.supplier_flitch_no}
                                sx={{ width: "100%" }}
                                name="supplier_flitch_no"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "supplier_flitch_no",
                                    e.target.value
                                  )
                                }
                                error={
                                  touched?.supplier_flitch_no &&
                                  errors?.supplier_flitch_no
                                }
                                helperText={
                                  touched?.supplier_flitch_no &&
                                  errors?.supplier_flitch_no
                                }
                              />
                            </TableCell> */}
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                options={itemNameList}
                                name="item_name"
                                value={values?.item_name}
                                getOptionLabel={(option) => {
                                  if (option instanceof Object && !Array.isArray(option)) {
                                    return option.item_name;
                                  } else {
                                    return option;
                                  }
                                }}
                                onBlur={() => setFieldTouched("item_name", true)}
                                onChange={(e, selectedData) => {
                                  setFieldValue("item_name", selectedData?.item_name || "");
                                  setFieldValue("item_id", selectedData?._id || "");
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.item_name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.item_name && Boolean(errors.item_name)} // Show error if touched and there's an error
                                    helperText={touched.item_name && errors.item_name}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete

                                autoSelect
                                autoHighlight
                                size="small"
                                // disabled={values?.supplier_details?.country === "India"}
                                options={itemSubCategoryNameList}
                                name="item_sub_category_name"
                                value={values?.item_sub_category_name}
                                getOptionLabel={(option) => {
                                  if (option instanceof Object && !Array.isArray(option)) {
                                    return option?.name;
                                  } else {
                                    return option;
                                  }
                                }}
                                onBlur={() => setFieldTouched("item_sub_category_name", true)}
                                onChange={(e, selectedData) => {
                                  // setFinalData({ ...finalData, item_sub_category_name: selectedData });
                                  setFieldValue("item_sub_category_name", selectedData?.name || "");
                                  setFieldValue("item_sub_category_id", selectedData?._id || "");
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.item_sub_category_name && Boolean(errors.item_sub_category_name)} // Show error if touched and there's an error
                                    helperText={touched.item_sub_category_name && errors.item_sub_category_name}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.plywood_type}
                                sx={{ width: "100%" }}
                                name="plywood_type"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  // setTimeout(() => {
                                  //   const isInvalid = e.target.getAttribute("aria-invalid");
                                  //   if (isInvalid === "false") {
                                  //     setInvoiceDetails({
                                  //       ...invoiceDetails,
                                  //       log_no: e.target.value,
                                  //     });
                                  //   }
                                  // }, 0);
                                }}
                                onChange={(e) => setFieldValue("plywood_type", e.target.value)}
                                error={touched?.plywood_type && errors?.plywood_type}
                                helperText={touched?.plywood_type && errors?.plywood_type}
                              />
                            </TableCell>

                            {/* <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                disabled
                                value={values?.pallet_number}
                                sx={{ width: "100%" }}
                                name="pallet_number"
                                onChange={(e) =>
                                  setFieldValue("pallet_number", e.target.value)
                                }
                                error={
                                  touched?.pallet_number &&
                                  errors?.pallet_number
                                }
                                helperText={
                                  touched?.pallet_number &&
                                  errors?.pallet_number
                                }
                              />
                            </TableCell> */}
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.length}
                                sx={{ width: "100%" }}
                                name="length"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculateTotlSqMeter(values?.width, values?.sheets, e.target.value, setFieldValue, values);
                                }}
                                onChange={(e) => setFieldValue("length", e.target.value)}
                                error={touched?.length && errors?.length}
                                helperText={touched?.length && errors?.length}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.width}
                                sx={{ width: "100%" }}
                                name="width"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculateTotlSqMeter(e.target.value, values?.sheets, values?.length, setFieldValue, values);
                                }}
                                onChange={(e) => setFieldValue("width", e.target.value)}
                                error={touched?.width && errors?.width}
                                helperText={touched?.width && errors?.width}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // disabled
                                type="number"
                                size="small"
                                value={values?.thickness}
                                sx={{ width: "100%" }}
                                name="thickness"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("thickness", e.target.value)}
                                error={touched?.thickness && errors?.thickness}
                                helperText={touched?.thickness && errors?.thickness}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.sheets}
                                sx={{ width: "100%" }}
                                name="sheets"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculateTotlSqMeter(values?.width, e.target.value, values?.length, setFieldValue, values);
                                }}
                                onChange={(e) => {
                                  setFieldValue("sheets", e.target.value);
                                  console.log("sheet");
                                }}
                                error={touched?.sheets && errors?.sheets}
                                helperText={touched?.sheets && errors?.sheets}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                disabled
                                type="number"
                                size="small"
                                value={values?.total_sq_meter}
                                sx={{ width: "100%" }}
                                name="total_sq_meter"
                                onChange={(e) => setFieldValue("total_sq_meter", e.target.value)}
                                error={touched?.total_sq_meter && errors?.total_sq_meter}
                                helperText={touched?.total_sq_meter && errors?.total_sq_meter}
                              />
                            </TableCell>

                            {/* {finalData?.currency?.currency_name !== "INR" ? (
                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                <TextField
                                  type="number"
                                  size="small"
                                  value={values?.exchange_rate}
                                  sx={{ width: "100%" }}
                                  name="exchange_rate"
                                  onBlur={async (e) => {
                                    handleBlur(e);
                                    let rateInINR = Number(e.target.value) * Number(values?.rate_in_currency);
                                    let amount = Number(values.total_sq_meter) * Number(rateInINR);
                                    setFieldValue("rate_in_inr", Number(rateInINR?.toFixed(2)));
                                    setFieldValue("amount", Number(amount?.toFixed(2)));
                                  }}
                                  onChange={(e) => setFieldValue("exchange_rate", e.target.value)}
                                  error={touched?.exchange_rate && errors?.exchange_rate}
                                  helperText={touched?.exchange_rate && errors?.exchange_rate}
                                />
                              </TableCell>
                            ) : (
                              ""
                            )} */}

                            {finalData?.currency?.currency_name !== "INR" ? (
                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                <TextField
                                  type="number"
                                  size="small"
                                  value={values?.rate_in_currency}
                                  sx={{ width: "100%" }}
                                  name="rate_in_currency"
                                  onBlur={async (e) => {
                                    handleBlur(e);
                                    let rateInINR = Number((Number(e.target.value) * Number(values?.exchange_rate))?.toFixed(2));
                                    let amount = Number((Number(values.total_sq_meter) * Number(rateInINR))?.toFixed(2));
                                    setFieldValue("rate_in_inr", Number(rateInINR));
                                    setFieldValue("amount", Number(amount));
                                  }}
                                  onChange={(e) => setFieldValue("rate_in_currency", e.target.value)}
                                  error={touched?.rate_in_currency && errors?.rate_in_currency}
                                  helperText={touched?.rate_in_currency && errors?.rate_in_currency}
                                />
                              </TableCell>
                            ) : (
                              ""
                            )}
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.rate_in_inr}
                                sx={{ width: "100%" }}
                                name="rate_in_inr"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  let amount = Number((Number(values.total_sq_meter) * Number(e.target.value))?.toFixed(2));
                                  setFieldValue("amount", Number(amount));
                                }}
                                onChange={(e) => setFieldValue("rate_in_inr", e.target.value)}
                                error={touched?.rate_in_inr && errors?.rate_in_inr}
                                helperText={touched?.rate_in_inr && errors?.rate_in_inr}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                value={values?.amount}
                                sx={{ width: "100%" }}
                                name="amount"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("amount", e.target.value)}
                                error={touched?.amount && errors?.amount}
                                helperText={touched?.amount && errors?.amount}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.remark}
                                sx={{ width: "100%" }}
                                name="remark"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("remark", e.target.value)}
                                error={touched?.remark && errors?.remark}
                                helperText={touched?.remark && errors?.remark}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                // textAlign: "center",
                                minWidth: "100%",
                                verticalAlign: "middle",
                                color: "white",
                                px: 1,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                bgcolor: "white",
                                display: "flex",
                              }}
                            >
                              <IconButton
                                color="success"
                                size="small"
                                onClick={async () => {
                                  // Manually validate the form
                                  const formErrors = await validateForm();
                                  console.log("Validation errors:", formErrors); // Handle errors if needed
                                  // Check if there are no validation errors
                                  if (Object.keys(formErrors).length === 0) {
                                    updateRow(values, i); // Call updateRow with values and index
                                  } else {
                                    console.log("Validation errors:", formErrors); // Handle errors if needed
                                  }
                                }}
                              >
                                <TaskAltIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => {
                                  setData(initailData);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        Data Not Found !!
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditItemDetailsTable;
