export const veneerItemSrnoDropdownApi = "/veneer-inventory/item-srno-dropdown";
export const veneerInwardSrnoDropdownApi = "/veneer-inventory/inward-srno-dropdown";

//Approval
export const fetchVeneerApprovalApi = `/approval/veneer-approval-invoice-listing`;
export const fetchVeneerItemsApprovalApi = function (id, invoice_id) {
  return `/approval/veneer-approval-item-listing-by-invoice/${id}/${invoice_id}`;
};
export const veneerApproveInvoiceApi = function (id, invoice_id) {
  return `/approval/veneer-approve_invoice_details/${id}/${invoice_id}`;
};
export const veneerRejectInvoiceApi = function (id, invoice_id) {
  return `/approval/veneer-reject_invoice_details/${id}/${invoice_id}`;
};
