const { HIDE_LOADING, SET_LOADING } = require("../actions/Loading/constants");

const INIT_STATE = {
    loading: false,
    message: null
}

const loadingReducer = function (state = INIT_STATE, action) {
    const message = action.payload?.message || null;
    switch (action.type) {
        case SET_LOADING:
            return {
                loading: true,
                message: message
            };
        case HIDE_LOADING:
            return {
                loading: false,
                message: message
            };
        default:
            return {...state};
    }
}

export default loadingReducer