import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Button, Checkbox, Grid, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import axios from "axios";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

const InvoiceDetailsTable = ({ setAddedItemDetailsList, addedItemDetailsList, invoiceDetails, finalData, gstNameList, initialInvoiceData, setInitialInvoiceData }) => {
  const { state } = useLocation();
  const InvoiceDetails = state?.data?.mdf_invoice_details?.invoice_Details;
  const [isSubmitting, setSubmitting] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const navigate = useNavigate();


  const GreenCheckbox = withStyles({
    root: {
      borderRadius: "0px",
      height: "20px",
      padding: "0px",
      "&$checked": {
        color: "green",
        borderRadius: "0px",
        padding: "0px",
        // height: "15px",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const [initialState, setInitialState] = useState({
    invoice_date: InvoiceDetails?.invoice_date || getCurrentDate(),
    total_item_amount: InvoiceDetails?.total_item_amount || 0,
    transporter_details: InvoiceDetails?.transporter_details || "",
    port_of_loading: InvoiceDetails?.port_of_loading || "",
    port_of_discharge: InvoiceDetails?.port_of_discharge || "",
    bill_of_landing: InvoiceDetails?.bill_of_landing || "",
    freight: InvoiceDetails?.freight || 0,
    isFreightInclude: InvoiceDetails?.isFreightInclude || false,
    load_unload: InvoiceDetails?.load_unload || 0,
    isLoadUnloadInclude: InvoiceDetails?.isLoadUnloadInclude || false,
    insurance: InvoiceDetails?.insurance || 0,
    isInsuranceInclude: InvoiceDetails?.isInsuranceInclude || false,
    other: InvoiceDetails?.other || 0,
    isOtherInclude: InvoiceDetails?.isOtherInclude || false,
    other_for_import: InvoiceDetails?.other_for_import || 0,
    gst_percentage: InvoiceDetails?.gst_percentage || 0,
    igst_percentage: InvoiceDetails?.igst_percentage || 0,
    sgst_percentage: InvoiceDetails?.sgst_percentage || 0,
    cgst_percentage: InvoiceDetails?.cgst_percentage || 0,
    gst_value: InvoiceDetails?.gst_value || 0,
    invoice_value_with_gst: InvoiceDetails?.invoice_value_with_gst || 0,
    remark: InvoiceDetails?.remark || "",
  });

  const validationSchema = yup.object({
    invoice_date: yup.date("Invalid date").required("Date of Invoice is required"),
    total_item_amount: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    transporter_details: yup.string("Enter Supplier Name").required("Required"),
    port_of_loading: yup.string().required("Required"),
    port_of_discharge: yup.string().required("Required"),
    bill_of_landing: yup.string().required("Required"),
    freight: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    load_unload: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    insurance: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    other: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    other_for_import: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    gst_percentage: yup.number().nullable().nullable().required("Please Select the GST"),
    igst_percentage: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    sgst_percentage: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    cgst_percentage: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    invoice_value_with_gst: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    // remark: yup.string().required("Required"),
  });

  const handleSubmit = async (values) => {
    // console.log(values, "987345");
    // console.log(addedItemDetailsList, "987345");
    // console.log(finalData, "987345");
    var finalMdfData = {
      inventory_invoice_details: {
        ...state?.data?.mdf_invoice_details,
        inward_date: finalData?.inward_date,
        currency: finalData?.currency,
        workers_details: {
          no_of_workers: finalData?.no_of_workers,
          shift: finalData?.shift,
          working_hours: finalData?.working_hours,
          total_hours: finalData?.total_hours,
        },
        supplier_details: {
          company_details: {
            ...finalData?.company_details,
            supplier_company_id: finalData?.company_details?._id || finalData?.company_details?.supplier_company_id,
          },
          branch_detail: {
            ...finalData?.branch_detail,
            branch_id: finalData?.branch_detail?._id || finalData?.branch_detail?.branch_id,
          },
        },
        invoice_Details: {
          ...values,
          invoice_no: finalData?.invoice_no,
        },
      },
      inventory_items_details: addedItemDetailsList,
    };

    try {
      setSubmitting(true);
      // setAddedItemDetailsList([...addedItemDetailsList, { ...values }]);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.patch(
        `${process.env.REACT_APP_URL}/mdf-inventory/edit-invoice-item-inventory/${state?.data?.invoice_id}`,
        finalMdfData,
        config
      );

      if (data?.data?.success) {
        Swal.fire({
          title: "Success",
          text: data?.data?.message || "Inventory Added",
          icon: "success",
          showConfirmButton: true,
        });
        navigate("/inventory/mdf");
      }
    } catch (error) {
      Swal.fire({
        title: "Failed",
        text: error?.response?.data?.message || 'Something went wrong',
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleSetValuesInStorage = async (fieldName, fieldValue) => {
    // let savedData = JSON.parse(localStorage.getItem("invoiceDetails"));
    // savedData[fieldName] = fieldValue;
    // localStorage.setItem("invoiceDetails", JSON.stringify(savedData));
  };
  const handleCalculateFinalInvoiceValue = async (values, setFieldValue) => {
    var invoiceValueWithoutGst = Number(values?.total_item_amount);

    if (values?.isFreightInclude) invoiceValueWithoutGst += Number(values?.freight);
    if (values?.isLoadUnloadInclude) invoiceValueWithoutGst += Number(values?.load_unload);
    if (values?.isInsuranceInclude) invoiceValueWithoutGst += Number(values?.insurance);
    if (values?.isOtherInclude) invoiceValueWithoutGst += Number(values?.other);

    let invoiceValueWithGst = invoiceValueWithoutGst;

    if (values?.gst_percentage) {
      let gstPercentage = Number(values?.gst_percentage);
      let gstAmount = Number((invoiceValueWithoutGst * (gstPercentage * 0.01))?.toFixed(2));
      invoiceValueWithGst = Number((invoiceValueWithoutGst + gstAmount));
      setFieldValue("gst_value", Number(gstAmount));
      handleInputChange("gst_value", Number(gstAmount));

    }

    if (!values?.isFreightInclude) invoiceValueWithGst += Number(values?.freight);
    if (!values?.isLoadUnloadInclude) invoiceValueWithGst += Number(values?.load_unload);
    if (!values?.isInsuranceInclude) invoiceValueWithGst += Number(values?.insurance);
    if (!values?.isOtherInclude) invoiceValueWithGst += Number(values?.other);
    invoiceValueWithGst = Number((invoiceValueWithGst)?.toFixed(2))

    setFieldValue("invoice_value_with_gst", Number(invoiceValueWithGst));
    handleInputChange("invoice_value_with_gst", Number(invoiceValueWithGst));
    setIsCalculated(true);
  };

  useEffect(() => {
    if (addedItemDetailsList && addedItemDetailsList.length > 0) {
      let calculatedTotalItemAmount = addedItemDetailsList.reduce((acc, ele) => {
        acc += Number(ele.amount);
        return acc;
      }, 0);
      setInitialInvoiceData({
        ...initialInvoiceData,
        total_item_amount: Number(calculatedTotalItemAmount?.toFixed(2)),
        invoice_value_with_gst: Number(calculatedTotalItemAmount?.toFixed(2))
      });
      // localStorage.setItem("invoiceDetails", JSON.stringify({ ...initialState, total_item_amount: Number(calculatedTotalItemAmount?.toFixed(2)) }));
    } else {
      // localStorage.setItem("invoiceDetails", JSON.stringify({ ...initialState }));
    }
    // console.log(localStorage.getItem("invoiceDetails"), "12333333");
  }, []);

  const handleInputChange = (fieldName, fieldValue) => {
    setInitialInvoiceData((pre) => ({ ...pre, [fieldName]: fieldValue }));
  };
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={initialInvoiceData}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors, setFieldTouched, handleBlur, touched }) => (
        <Form noValidate autoComplete="off">
          <Div sx={{ mt: 2 }}>
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  Invoice Date*
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": {
                        padding: 1,
                      },
                    }}
                    format="DD-MM-YYYY"
                    maxDate={dayjs()}
                    defaultValue={values?.invoice_date !== "" ? dayjs(values?.invoice_date) : null}
                    onChange={(newValue) => {
                      setFieldValue("invoice_date", newValue);
                      handleInputChange("invoice_date", newValue);
                    }}
                  />
                  <Div sx={{ height: "10px" }}>
                    <ErrorMessage name="invoice_date" component="div" style={{ color: "red" }} />
                  </Div>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  Total Item Amount
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  value={Number(values?.total_item_amount) || ""}
                  sx={{ width: "100%" }}
                  name="total_item_amount"
                  onBlur={async (e) => {
                    handleBlur(e);
                    setIsCalculated(false);
                    setTimeout(() => {
                      const isInvalid = e.target.getAttribute("aria-invalid");
                      if (isInvalid === "false") {
                        handleSetValuesInStorage("total_item_amount", e.target.value);
                      }
                    }, 0);
                  }}
                  onChange={({ target }) => {
                    setFieldValue("total_item_amount", Number(target.value));
                    setFieldValue("invoice_value_with_gst", Number(target.value));
                    handleInputChange("total_item_amount", Number(target.value));
                    handleInputChange("invoice_value_with_gst", Number(target.value));
                  }}
                  error={touched?.total_item_amount && errors?.total_item_amount}
                  helperText={touched?.total_item_amount && errors?.total_item_amount}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  Transporter Details*
                </Typography>
                <TextField
                  // type="number"
                  size="small"
                  value={values?.transporter_details}
                  sx={{ width: "100%" }}
                  name="transporter_details"
                  onBlur={async (e) => {
                    handleBlur(e);
                  }}
                  onChange={(e) => { setFieldValue("transporter_details", e.target.value); handleInputChange("transporter_details", e.target.value) }}
                  error={touched?.transporter_details && errors?.transporter_details}
                  helperText={touched?.transporter_details && errors?.transporter_details}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  Port Of Loading*
                </Typography>
                <TextField
                  // type="number"
                  size="small"
                  value={values?.port_of_loading}
                  sx={{ width: "100%" }}
                  name="port_of_loading"
                  onBlur={async (e) => {
                    handleBlur(e);
                  }}
                  onChange={(e) => { setFieldValue("port_of_loading", e.target.value); handleInputChange("port_of_loading", e.target.value) }}
                  error={touched?.port_of_loading && errors?.port_of_loading}
                  helperText={touched?.port_of_loading && errors?.port_of_loading}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  Port Of Discharge*
                </Typography>
                <TextField
                  // type="number"
                  size="small"
                  value={values?.port_of_discharge}
                  sx={{ width: "100%" }}
                  name="port_of_discharge"
                  onBlur={async (e) => {
                    handleBlur(e);
                  }}
                  onChange={(e) => { setFieldValue("port_of_discharge", e.target.value); handleInputChange("port_of_discharge", e.target.value) }}
                  error={touched?.port_of_discharge && errors?.port_of_discharge}
                  helperText={touched?.port_of_discharge && errors?.port_of_discharge}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  Bill Of Landing*
                </Typography>
                <TextField
                  // type="number"
                  size="small"
                  value={values?.bill_of_landing}
                  sx={{ width: "100%" }}
                  name="bill_of_landing"
                  onBlur={async (e) => {
                    handleBlur(e);
                  }}
                  onChange={(e) => { setFieldValue("bill_of_landing", e.target.value); handleInputChange("bill_of_landing", e.target.value) }}
                  error={touched?.bill_of_landing && errors?.bill_of_landing}
                  helperText={touched?.bill_of_landing && errors?.bill_of_landing}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  Freight
                  <GreenCheckbox
                    sx={{ marginLeft: "10px" }}
                    checked={values?.isFreightInclude}
                    onChange={() => {
                      setFieldValue("isFreightInclude", !values?.isFreightInclude);
                      handleInputChange("isFreightInclude", !values?.isFreightInclude);
                      setIsCalculated(false);
                    }}
                    name="isFreightInclude"
                  />
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  value={values?.freight}
                  sx={{ width: "100%" }}
                  name="freight"
                  onBlur={async (e) => {
                    handleBlur(e);
                    setIsCalculated(false);
                  }}
                  onChange={(e) => {
                    setFieldValue("freight", e.target.value);
                    handleInputChange("freight", e.target.value);
                  }}
                  error={touched?.freight && errors?.freight}
                  helperText={touched?.freight && errors?.freight}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  Load Unload
                  <GreenCheckbox
                    sx={{ marginLeft: "10px" }}
                    checked={values?.isLoadUnloadInclude}
                    onChange={() => {
                      setFieldValue("isLoadUnloadInclude", !values?.isLoadUnloadInclude);
                      handleInputChange("isLoadUnloadInclude", !values?.isLoadUnloadInclude);
                      setIsCalculated(false);
                    }}
                    name="isLoadUnloadInclude"
                  />
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  value={values?.load_unload}
                  sx={{ width: "100%" }}
                  name="load_unload"
                  onBlur={async (e) => {
                    handleBlur(e);
                    setIsCalculated(false);
                  }}
                  onChange={(e) => { setFieldValue("load_unload", e.target.value); handleInputChange("load_unload", e.target.value) }}
                  error={touched?.load_unload && errors?.load_unload}
                  helperText={touched?.load_unload && errors?.load_unload}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  Insurance
                  <GreenCheckbox
                    sx={{ marginLeft: "10px" }}
                    checked={values?.isInsuranceInclude}
                    onChange={() => {
                      setFieldValue("isInsuranceInclude", !values?.isInsuranceInclude);
                      handleInputChange("isInsuranceInclude", !values?.isInsuranceInclude);
                      setIsCalculated(false);
                    }}
                    name="isInsuranceInclude"
                  />
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  value={values?.insurance}
                  sx={{ width: "100%" }}
                  name="insurance"
                  onBlur={async (e) => {
                    handleBlur(e);
                    setIsCalculated(false);
                  }}
                  onChange={(e) => { setFieldValue("insurance", e.target.value); handleInputChange("insurance", e.target.value) }}
                  error={touched?.insurance && errors?.insurance}
                  helperText={touched?.insurance && errors?.insurance}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  Other
                  <GreenCheckbox
                    sx={{ marginLeft: "10px" }}
                    checked={values?.isOtherInclude}
                    onChange={() => {
                      setFieldValue("isOtherInclude", !values?.isOtherInclude);
                      handleInputChange("isOtherInclude", !values?.isOtherInclude);
                      setIsCalculated(false);
                    }}
                    name="isOtherInclude"
                  />
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  value={values?.other}
                  sx={{ width: "100%" }}
                  name="other"
                  onBlur={async (e) => {
                    handleBlur(e);
                    setIsCalculated(false);
                  }}
                  onChange={(e) => { setFieldValue("other", e.target.value); handleInputChange("other", e.target.value) }}
                  error={touched?.other && errors?.other}
                  helperText={touched?.other && errors?.other}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  Other For Import
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  value={values?.other_for_import}
                  sx={{ width: "100%" }}
                  name="other_for_import"
                  onBlur={async (e) => {
                    handleBlur(e);
                  }}
                  onChange={(e) => { setFieldValue("other_for_import", e.target.value); handleInputChange("other_for_import", e.target.value) }}
                  error={touched?.other_for_import && errors?.other_for_import}
                  helperText={touched?.other_for_import && errors?.other_for_import}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  GST Percentage*
                </Typography>
                <Autocomplete
                  autoSelect
                  autoHighlight
                  size="small"
                  options={gstNameList}
                  name="gst_percentage"
                  getOptionLabel={(option) => {
                    if (option instanceof Object && !Array.isArray(option)) {
                      return option.gst_percentage;
                    } else {
                      return option;
                    }
                  }}
                  value={values?.gst_percentage}
                  onChange={(e, value) => { setFieldValue("gst_percentage", value?.gst_percentage); handleInputChange("gst_percentage", value?.gst_percentage) }}
                  onBlur={(e) => {
                    setFieldTouched("gst_percentage", true);
                    setFieldValue("igst_percentage", Number(e.target.value));
                    setFieldValue("sgst_percentage", Number(e.target.value) / 2);
                    setFieldValue("cgst_percentage", Number(e.target.value) / 2);
                    handleInputChange("igst_percentage", Number(e.target.value));
                    handleInputChange("sgst_percentage", Number(e.target.value) / 2);
                    handleInputChange("cgst_percentage", Number(e.target.value) / 2);
                    setIsCalculated(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={touched.gst_percentage && Boolean(errors.gst_percentage)} // Show error if touched and there's an error
                      helperText={touched.gst_percentage && errors.gst_percentage} // Display helper text for the error
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              {finalData?.branch_detail?.state !== "Maharashtra" ? (
                <Grid item xs={6} md={4} lg={3} xl={2}>
                  <Typography variant="h6" fontSize="14px">
                    IGST Percentage*
                  </Typography>
                  <TextField
                    disabled
                    type="number"
                    size="small"
                    value={values?.igst_percentage}
                    sx={{ width: "100%" }}
                    name="igst_percentage"
                    onBlur={async (e) => {
                      handleBlur(e);
                      // setTimeout(() => {
                      //   const isInvalid = e.target.getAttribute("aria-invalid");
                      //   if (isInvalid === "false") {
                      //     setInvoiceDetails({
                      //       ...invoiceDetails,
                      //       igst_percentage: Number(e.target.value),
                      //     });
                      //   }
                      // }, 0);
                    }}
                    onChange={(e) => { setFieldValue("igst_percentage", e.target.value); handleInputChange("igst_percentage", e.target.value) }}
                    error={touched?.igst_percentage && errors?.igst_percentage}
                    helperText={touched?.igst_percentage && errors?.igst_percentage}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      SGST Percentage*
                    </Typography>
                    <TextField
                      disabled
                      type="number"
                      size="small"
                      value={values?.sgst_percentage}
                      sx={{ width: "100%" }}
                      name="sgst_percentage"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // setTimeout(() => {
                        //   const isInvalid = e.target.getAttribute("aria-invalid");
                        //   if (isInvalid === "false") {
                        //     setInvoiceDetails({
                        //       ...invoiceDetails,
                        //       sgst_percentage: Number(e.target.value),
                        //     });
                        //   }
                        // }, 0);
                      }}
                      onChange={(e) => { setFieldValue("sgst_percentage", e.target.value); handleInputChange("sgst_percentage", e.target.value) }}
                      error={touched?.sgst_percentage && errors?.sgst_percentage}
                      helperText={touched?.sgst_percentage && errors?.sgst_percentage}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      CGST Percentage*
                    </Typography>
                    <TextField
                      disabled
                      type="number"
                      size="small"
                      value={values?.cgst_percentage}
                      sx={{ width: "100%" }}
                      name="cgst_percentage"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // setTimeout(() => {
                        //   const isInvalid = e.target.getAttribute("aria-invalid");
                        //   if (isInvalid === "false") {
                        //     setInvoiceDetails({
                        //       ...invoiceDetails,
                        //       cgst_percentage: Number(e.target.value),
                        //     });
                        //   }
                        // }, 0);
                      }}
                      onChange={(e) => { setFieldValue("cgst_percentage", e.target.value); handleInputChange("cgst_percentage", e.target.value) }}
                      error={touched?.cgst_percentage && errors?.cgst_percentage}
                      helperText={touched?.cgst_percentage && errors?.cgst_percentage}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  GST Value
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  disabled
                  value={values?.gst_value}
                  sx={{ width: "100%" }}
                  name="gst_value"
                  onBlur={async (e) => {
                    handleBlur(e);
                  }}
                  onChange={(e) => { setFieldValue("gst_value", e.target.value); handleInputChange("gst_value", e.target.value) }}
                  error={touched?.gst_value && errors?.gst_value}
                  helperText={touched?.gst_value && errors?.gst_value}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={3}>
                <Typography variant="h6" fontSize="14px">
                  Invoice Value
                  {/* <Button variant="contained" size="small" sx={{ fontSize: "10px", padding: "0px 5px", marginLeft: "10px" }}
                  onClick={()=>handleCalculateFinalInvoiceValue(values)}
                  >
                    Calculate
                  </Button> */}
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  value={values?.invoice_value_with_gst}
                  sx={{ width: "100%" }}
                  name="invoice_value_with_gst"
                  onBlur={async (e) => {
                    handleBlur(e);
                    // setTimeout(() => {
                    //   const isInvalid = e.target.getAttribute("aria-invalid");
                    //   if (isInvalid === "false") {
                    //     setInvoiceDetails({
                    //       ...invoiceDetails,
                    //       invoice_value_with_gst: Number(e.target.value),
                    //     });
                    //   }
                    // }, 0);
                  }}
                  onChange={(e) => { setFieldValue("invoice_value_with_gst", e.target.value); handleInputChange("invoice_value_with_gst", e.target.value) }}
                  error={touched?.invoice_value_with_gst && errors?.invoice_value_with_gst}
                  helperText={touched?.invoice_value_with_gst && errors?.invoice_value_with_gst}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ paddingRight: "0px" }}>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ fontSize: "10px", padding: "5px 5px", margin: "0px" }}
                          onClick={() => handleCalculateFinalInvoiceValue(values, setFieldValue)}
                        >
                          Calculate
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3} xl={2}>
                <Typography variant="h6" fontSize="14px">
                  Remark
                </Typography>
                <TextField
                  size="small"
                  value={values?.remark}
                  sx={{ width: "100%" }}
                  name="remark"
                  onBlur={async (e) => {
                    handleBlur(e);
                  }}
                  onChange={(e) => { setFieldValue("remark", e.target.value); handleInputChange("remark", e.target.value) }}
                // error={touched?.remark && errors?.remark}
                // helperText={touched?.remark && errors?.remark}
                />
              </Grid>
            </Grid>
            <Div
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
                mt: 5,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  Swal.fire({
                    text: "Are you sure you want to cancel?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/inventory/mdf");
                    }
                  });
                }}
              >
                Cancel
              </Button>
              <Tooltip title={isCalculated ? "" : "You need to calculate first"}>
                {/* Wrapping the button in the Tooltip component */}
                <span>
                  <LoadingButton variant="contained" disabled={!isCalculated} type="submit" sx={{ width: "100px" }} loading={isSubmitting}>
                    Save
                  </LoadingButton>
                </span>
              </Tooltip>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default InvoiceDetailsTable;
