import { CROSSCUTTING_DONE_APPROVAL_LIST_FAILED, CROSSCUTTING_DONE_APPROVAL_LIST_REQUEST, CROSSCUTTING_DONE_APPROVAL_LIST_SUCCESS } from "app/redux/Approval/CrosscuttingDoneApproval/constant";

const INIT_STATE = {
    loading: false,
    totalPage: 0,
    data: [],
    error: {
        isError: false,
        message: null
    }
};

export const crossCutDoneApprovalListingReducer = function (state = INIT_STATE, action) {
    switch (action?.type) {
        case CROSSCUTTING_DONE_APPROVAL_LIST_REQUEST:
            return {
                loading: true,
                totalPage: 0,
                data: [],
                error: {
                    isError: false,
                    message: null
                }
            }
        case CROSSCUTTING_DONE_APPROVAL_LIST_SUCCESS:
            return {
                loading: false,
                data: action?.payload?.data,
                totalPage: action?.payload?.totalPage,
                error: {
                    isError: false,
                    message: null
                }
            }
        case CROSSCUTTING_DONE_APPROVAL_LIST_FAILED:
            const { isError = true, message = "Something Went Wrong" } = action?.payload?.error || {}
            return {
                loading: false,
                totalPage: 0,
                data: [],
                error: {
                    isError: isError,
                    message: message
                }
            }
        default:
            return state;
    }
};