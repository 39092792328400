import apiUrl from "app/utils/Axios.Config";
import { Axios } from "index";
// import { Axios } from "index"

export const editSupplierData = async (details, id) => {
  const data = await Axios.post(
    `/supplier-master/update-supplier-master?id=${id}`,
    details
  );
  return data;
};

export const editSupplierMainBranch = async (details, id) => {
  const data = await Axios.post(
    `/supplier-master/update-supplier-branch?id=${id}`,
    details
  );
  return data;
};


export const editSupplierAndBranch = async (supplierId, branchId, supplierData, branchData) => {
  const data = await Axios.post(
    `/supplier-master/update-supplier-and-branch-new?supplierId=${supplierId}&branchId=${branchId}`, { supplierData: supplierData, branchData: branchData }
  );
  return data;
}