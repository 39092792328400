import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Edit } from "@mui/icons-material";
import Swal from "sweetalert2";
import { hideLoading, setLoading } from "app/redux/actions/Loading";
import { Axios } from "index";
import { fetchCrosscuttingDoneList } from "app/redux/actions/Factory/Crosscutting/crosscutDoneListing/crosscutDoneListing";
import { ApprovalStatusTableCell } from "app/components/ApprovalStatusTableCell";
import { formatAmount } from "app/utils/formatAmount";

const tableBodyCell = { textAlign: "left", px: 1 };

const tableRowBodySticky = {
  textAlign: "left",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "white",
};

const CrosscuttingDoneTableRow = ({ e, allFilterState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);
  const { user_type, role_id } = useSelector(
    (state) => state?.userReducer?.user?.[0]
  );

  const handleIsssueForFlitching = async function (crosscutId) {
    try {
      dispatch(setLoading());
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const issueForFliching = await Axios.post(
        `/factory/cross-cutting/add-crosscut-issue-for-flitching/${crosscutId}`,
        config
      );

      if (issueForFliching.status === 200) {
        Swal.fire({
          title: "Issued for flitching",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(fetchCrosscuttingDoneList(allFilterState));
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "createCrosscutting":
        navigate("/factory/edit-crosscut", { state: menuItem?.row });
        break;
      case "issueForFlitching":
        Swal.fire({
          text: "Are you sure you want to Issue for Flitching?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            handleIsssueForFlitching(menuItem?.row?._id);
          }
        });
        break;
      default:
        navigate("/factory/crosscut");
    }
  };

  return (
    <TableRow key={e?._id}>
      <TableCell sx={{ ...tableBodyCell }}>
        {moment(e?.worker_details?.crosscut_date).format("DD/MM/YYYY") || "-"}
        {/* {new Date(e?.worker_details?.crosscut_date).toLocaleDateString() ||
          "-"} */}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.machine_name}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.code || "-"}</TableCell>

      {/* <TableCell sx={{ ...tableBodyCell }}>{e?.item_name || "-"}</TableCell> */}
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_no_code || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.length || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.girth || "-"}</TableCell>

      <TableCell sx={{ ...tableBodyCell }}>{e?.crosscut_cmt || "-"}</TableCell>
      {user_type == "ADMIN" && (
        <TableCell sx={{ ...tableBodyCell }}>
          {e?.per_cmt_cost || "-"}
        </TableCell>
      )}
      {user_type == "ADMIN" && (
        <TableCell sx={{ ...tableBodyCell }}>{formatAmount(Math.round(e?.cost_amount)) || "-"}</TableCell>
      )}
      {user_type == "ADMIN" && (
        <TableCell sx={{ ...tableBodyCell }}>
          {formatAmount(Math.round(e?.expense_amount)) || "-"}
        </TableCell>
      )}

      {/* <TableCell sx={{ ...tableBodyCell }}>{e?.factor || "-"}</TableCell> */}
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.wastage_info?.wastage_sqm || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.wastage_info?.wastage_length || "-"}
      </TableCell>
      {/* <TableCell sx={{ ...tableBodyCell }}>
        {e?.rate_in_currency || "-"}
      </TableCell> */}
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.required_hours || "-"}
      </TableCell>
      {/* <TableCell sx={{ ...tableBodyCell }}>{e?.amount || "-"}</TableCell> */}
      <TableCell sx={{ ...tableBodyCell }}>{e?.remarks || "-"}</TableCell>
      <ApprovalStatusTableCell approval_status={e?.approval_status} />
      {/* <TableCell sx={{ ...tableBodyCell }}>{e?.remark || "-"}</TableCell> */}
      <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky }}>
        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <Edit />,
              title: "Edit",
              action: "createCrosscutting",
              show:
                role_id?.permissions?.crosscut_factory?.create === true &&
                  e?.isEditable &&
                  !e?.approval_status?.sendForApproval?.status
                  ? true
                  : false && e?.isEditable,
              row: e,
            },
            {
              icon: <AddCircleOutlineIcon />,
              title: "Issue For Flitching",
              action: "issueForFlitching",
              show: !e?.approval_status?.sendForApproval?.status,
              row: e,
            },
          ].filter((ele) => ele?.show)}
          onClickCallback={handleItemAction}
        />
      </TableCell>
    </TableRow>
  );
};

export default CrosscuttingDoneTableRow;
