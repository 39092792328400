import Div from "@jumbo/shared/Div/Div";

import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import { getCurrentDate } from "app/components/Function/getCurrentDate";

import ItemDetailsTable from "./itemTable";

import moment from "moment";
import { machineNameDropDown } from "app/services/apis/machineList";
import IssuedLogTable from "./issuedLogTable";

import addCrossCutFactory from "app/services/apis/addCrossCutFactory";
import { LoadingButton } from "@mui/lab";
import addFlitchFactory from "app/services/apis/Factory/Flitching/flitchingDone";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";

function CreateFlitching() {
  // const { state, pathname } = useLocation();
  const { state } = useLocation();
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const [actualLogDetails, setActualLogDetails] = useState(state);
  const [isCalculated, setIsCalculated] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const [machineList, setMachineList] = useState([]);
  const [addedItemDetailsList, setAddedItemDetailsList] = useState([]);
  const formikRef = useRef();
  const [wastageInfo, setWastageInfo] = useState({
    wastage_sqm: 0,
    wastage_length: 0,
  });

  const totalPhysicalCmt = actualLogDetails?.available_quantity?.cmt;
  const [finalData, setFinalData] = useState({
    // company_details: {},
    // branch_detail: {},
    no_of_workers: 0,
    shift: "",
    working_hours: 0,
    flitching_date: getCurrentDate(),
    no_of_total_hours: 0
  });
  const [initialItemForm, setInitialItemForm] = useState({
    item_sr_no: addedItemDetailsList.length + 1 || 1,
    // item_name: "",
    // item_id: "",
    log_inventory_item_id: actualLogDetails?.log_inventory_item_id,
    issue_for_flitching_id: actualLogDetails?._id,
    crosscut_done_id: actualLogDetails?.crosscut_done_id,
    sqm_factor: 0,
    machine_name: "",
    machine_id: "",
    log_no: "",
    flitch_code: "",
    log_no_code: "",
    flitch_formula: "",
    length: actualLogDetails?.available_quantity?.length || 0,
    width1: 0,
    width2: 0,
    width3: 0,
    height: 0,
    flitch_cmt: 0,
    per_cmt_cost: 0,
    cost_amount: 0,
    required_hours: 0,
    remarks: "",
  })
  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (addedItemDetailsList && addedItemDetailsList.length <= 0) {
        Swal.fire({
          title: "Add at least one item details.",
          icon: "warning",
          showCancelButton: false,
        });
      }
      formikRef.current.setTouched({
        flitching_date: true,
        no_of_workers: true,
        shift: true,
        working_hours: true,
      });

      const errors = await formikRef.current.validateForm();
      if (Object.keys(errors).length > 0) {
        setLoading(false);
        return;
      }

      const workerDetails = {
        working_hours: finalData?.working_hours,
        flitching_date: finalData?.flitching_date,
        shift: finalData?.shift,
        workers: finalData?.no_of_workers,
        no_of_total_hours: finalData?.no_of_total_hours
      };
      const newData = addedItemDetailsList.map((item) => {
        item.worker_details = workerDetails;
        return item;
      });

      const response = await addFlitchFactory(newData, actualLogDetails?._id);
      if (response?.data?.success) {
        Swal.fire({
          title: response?.data?.message,
          icon: "success",
          showCancelButton: false,
        });
        setLoading(false);
        navigate("/factory/flitching");
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showCancelButton: false,
      });
    }
  };

  const stock = {
    flitching_date: getCurrentDate(),
    no_of_workers: "",
    shift: "",
    working_hours: "",
    no_of_total_hours: 0
  };

  const validationSchema = yup.object({
    flitching_date: yup.date().required("Date of Inward is required"),
    no_of_workers: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    no_of_total_hours: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Required"),
    shift: yup.string().required("Shift is Required"),
    working_hours: yup.number().nullable().positive().moreThan(0, "Must be greater than 0").required("Working hours is Required"),
  });

  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const res = await machineNameDropDown("");
        setMachineList(res?.data?.result);
      } catch (error) {
        console.log("err fetching machine list=> ", error.message);
      }
    };
    fetchMachines();
  }, []);

  const handleCalculate = () => {
    const parsedPhysicalSqm = parseFloat(actualLogDetails?.available_quantity?.cmt);
    const physicalLength = Number(actualLogDetails?.available_quantity?.length);

    const costAmount = Number(actualLogDetails?.available_quantity?.amount);

    // eslint-disable-next-line no-unused-vars
    const totalLength = addedItemDetailsList?.reduce((acc, val) => acc + (Number(val?.length) || 0), 0);
    const totalSqm = addedItemDetailsList?.reduce((acc, val) => acc + Number(val?.flitch_cmt), 0);

    const totalAmount = addedItemDetailsList?.reduce((acc, val) => acc + (Number(val?.cost_amount) || 0), 0);

    const wastageSqm = parsedPhysicalSqm - totalSqm;

    const outputSqm = parsedPhysicalSqm - wastageSqm;

    const availableLength = physicalLength - Number(totalLength);

    if (Number(totalSqm) > Number(totalPhysicalCmt)) {
      // ("length", 0);
      return Swal.fire({
        timer: 3000,
        icon: "error",
        title: "Total Flitch Cmt can't be greater than physical cmt...",
        position: "center",
        // background: theme.palette.background.paper,
      });
    }

    const totalHours = Number(finalData?.no_of_workers) * Number(finalData?.working_hours);

    // eslint-disable-next-line no-unused-vars
    const amountsForItems = addedItemDetailsList?.map((item) => {
      const sqFactor = Number(item?.flitch_cmt) / outputSqm;
      item.sqm_factor = sqFactor;

      const itemCostAmount = Number(costAmount) * Number(sqFactor);
      item.cost_amount = Number(itemCostAmount.toFixed(2));
      const expenseAmount = Number(actualLogDetails?.expense_amount) * Number(sqFactor);
      item.expense_amount = Number(expenseAmount).toFixed(2);
      const itemPerCmtAmount = Number(item?.cost_amount) / Number(item?.flitch_cmt);
      item.per_cmt_cost = Number(itemPerCmtAmount.toFixed(2));
      item.required_hours = totalSqm > 0 ? Math.round((Number(item?.flitch_cmt) / totalSqm) * Number(totalHours)) : 0;
      item.required_workers =
        totalSqm > 0 ? Number(((Number(item?.flitch_cmt) / totalSqm) * Number(finalData?.no_of_workers)).toFixed(2)) : 0;
      let wastageData = { wastage_sqm: 0, wastage_length: 0 };
      let perWastageSqm = Number(Number(sqFactor) * Number(wastageSqm));
      wastageData.wastage_sqm = Number(perWastageSqm.toFixed(2));
      let wastageLength = Number(Number(sqFactor) * Number(availableLength));
      wastageData.wastage_length = Number(wastageLength.toFixed(2));
      item.wastage_info = wastageData;
      item.flitching_completed = true;
      return item;
    });
    setAddedItemDetailsList(amountsForItems);
    setWastageInfo(wastageInfo);
    setIsCalculated(true);
  };
  const handleCalculateTotalHours = (workers, hours, setFieldValue) => {
    const no_of_total_hours = Number((workers * hours)?.toFixed(2));

    setFieldValue("no_of_total_hours", no_of_total_hours);
    setFinalData({
      ...finalData,
      no_of_total_hours: no_of_total_hours,
    });
  }
  return (
    <Div sx={{ mt: -4 }}>
      <Div>
        <IssuedLogTable actualData={actualLogDetails} />
      </Div>

      <Div sx={{ marginTop: 5, marginBottom: 2 }}>
        <Formik
          validateOnChange={false}
          initialValues={stock}
          enableReinitialize={true}
          validationSchema={validationSchema}
          // onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {({ setFieldValue, values, errors, handleBlur, setFieldError, setFieldTouched, touched, handleSubmit, validateForm }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4, width: "100%" }}>
                <Grid
                  // sx={{
                  //   display: tabValue === 1 ? "none" : "",
                  // }}
                  container
                  rowSpacing={2}
                  columnSpacing={4}
                >
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Flitching Date*
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                        defaultValue={values?.flitching_date !== "" ? dayjs(values?.flitching_date) : null}
                        onChange={(newValue) => {
                          setFinalData({
                            ...finalData,
                            flitching_date: new Date(newValue),
                          });
                          // setFieldValue("inward_date", newValue.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                          setFieldValue("flitching_date", new Date(newValue));
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage name="flitching_date" component="div" style={{ color: "red" }} />
                      </Div>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Shift*
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="shift"
                      onChange={(e) => {
                        setFieldValue("shift", e.target.value);
                      }}
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({ ...finalData, shift: e.target.value });
                      }}
                      value={values?.shift}
                      status={true}
                      error={touched?.shift && errors?.shift}
                      helperText={touched?.shift && errors?.shift}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Workers*
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="no_of_workers"
                      type="number"
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          no_of_workers: e.target.value,
                        });
                      }}
                      onChange={(e) => {
                        setFieldValue("no_of_workers", e.target.value);
                        handleCalculateTotalHours(e.target.value, values?.working_hours, setFieldValue)
                      }}
                      value={values?.no_of_workers}
                      status={true}
                      error={touched?.no_of_workers && errors?.no_of_workers}
                      helperText={touched?.no_of_workers && errors?.no_of_workers}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Working Hours*
                    </Typography>
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="working_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          working_hours: e.target.value,
                        });
                      }}
                      onChange={(e) => {
                        setFieldValue("working_hours", e.target.value);
                        handleCalculateTotalHours(values?.no_of_workers, e.target.value, setFieldValue)
                      }}
                      value={values?.working_hours}
                      status={true}
                      error={touched?.working_hours && errors?.working_hours}
                      helperText={touched?.working_hours && errors?.working_hours}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      No. of Total Hours*
                    </Typography>
                    <TextField
                      disabled
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="no_of_total_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          no_of_total_hours: e.target.value,
                        });
                      }}
                      onChange={(e) => {
                        setFieldValue("no_of_total_hours", e.target.value);
                        setIsCalculated(false);
                      }}
                      value={values?.no_of_total_hours}
                      status={true}
                      error={touched?.working_hours && errors?.no_of_total_hours}
                      helperText={touched?.no_of_total_hours && errors?.no_of_total_hours}
                    />
                  </Grid>
                </Grid>
              </Div>
            </Form>
          )}
        </Formik>

        <ItemDetailsTable
          actualLogData={actualLogDetails}
          setAddedItemDetailsList={setAddedItemDetailsList}
          addedItemDetailsList={addedItemDetailsList}
          finalData={finalData}
          machineList={machineList}
          isCalculated={isCalculated}
          setIsCalculated={setIsCalculated}
          handleCalculate={handleCalculate}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          wastageInfo={wastageInfo}
          code={code}
          setCode={setCode}
          totalPhysicalCmt={totalPhysicalCmt}
          initialItemForm={initialItemForm}
          setInitialItemForm={setInitialItemForm}
        />
        <Div className="flex flex-row items-center justify-center gap-6 mt-8">
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              Swal.fire({
                text: "Are you sure you want to cancel?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/factory/flitching");
                }
              });
            }}
          >
            Cancel
          </Button>
          {isCalculated ? (
            <LoadingButton onClick={handleSubmit} type="submit" size="small" variant="contained" loading={loading}>
              Save
            </LoadingButton>
          ) : (
            <Tooltip title={addedItemDetailsList?.length !== 0 ? "" : "You need to add at least one item"}>
              <span>
                <LoadingButton disabled={addedItemDetailsList.length === 0} onClick={handleCalculate} size="small" variant="contained">
                  Calculate
                </LoadingButton>
              </span>
            </Tooltip>
          )}
        </Div>
      </Div>
    </Div>
  );
}

export default HeaderTitleHoc(CreateFlitching, "Create Flitching");