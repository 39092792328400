import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { EditOutlined } from "@mui/icons-material";
import { updateSupplier } from "app/services/apis/updateSupplier";
import Swal from "sweetalert2";
import SeriesModal from "../AddSeries/AddSeries";
import { listSeries } from "app/redux/actions/masterAction";
// import SubcategoryModal from '../AddSubcategory/AddSubcategoryPage';

function ListSeries({
  searchTerm,
  setPage,
  page,
  sort,
  sortBy,
  setSort,
  setSortBy,
  rowsPerPage,
}) {
  const { seriesList, TotalPage, loading } = useSelector(
    (state) => state.masterReducer
  );
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false); // For modal open/close
  const [series, setSeries] = useState({
    data: null,
    id: null,
  });

  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(true);
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    // setPage(1);
  };
  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  useEffect(() => {
    dispatch(listSeries(searchTerm, page, rowsPerPage, sortBy, sort));
  }, [sort, page, sortBy, toggle, rowsPerPage]);

  const handleStatusChange = async (row) => {
    try {
      setLoader(true);
      const data = await updateSupplier(
        {
          status: row.status === "active" ? "inactive" : "active",
        },
        row._id
      );
      if (data?.status === 200) {
        dispatch(seriesList("", "desc", "updated_at", 1));
        Swal.fire({
          icon: "success",
          title: "Status Updated",
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const handleItemAction = (menuItem) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case "edit":
        // navigate(`/master/item-subcategory/edit/${menuItem.id}`);
        setSeries({ data: menuItem.data, id: menuItem.id });
        setOpen(true);
        break;
      case "delete":
        Swal.fire({
          title: `Are you sure you want to ${
            menuItem.data.status === "active" ? "Deactivate ?" : "Activate ?"
          }`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            handleStatusChange(menuItem.data);
          }
        });
        break;
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <>
      {loader && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
                "& .MuiTableCell-root": {},
              }}
            >
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  position: "sticky",
                  left: 0,
                  //   height: "58px",
                  zIndex: 1,
                  bgcolor: "#7352C7",
                  //   padding: "0 10px",
                }}
              >
                <TableSortLabel
                  active={sortBy === "sr_no"}
                  onClick={() => handleSort("sr_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sr.No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "series_name"}
                  onClick={() => handleSort("series_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Series Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "remark"}
                  onClick={() => handleSort("remark")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Remark
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "userDetails.user_name"}
                  onClick={() => handleSort("userDetails.user_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "createdAt"}
                  onClick={() => handleSort("createdAt")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                  position: "sticky",
                  right: 0,
                  // height: "58px",
                  zIndex: 1,
                  bgcolor: "#7352C7",
                  padding: "0 10px",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {seriesList?.length > 0 ? (
              seriesList?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "40px",
                      verticalAlign: "middle",
                      // color: "white",
                      position: "sticky",
                      left: 0,
                      //   height: "58px",
                      zIndex: 1,
                      // bgcolor: "#7352C7",
                      //   padding: "0 10px",
                    }}
                  >
                    {row?.sr_no}
                  </TableCell>
                  <TableCell>{row?.series_name}</TableCell>
                  <TableCell>
                    {row?.remark !== "" ? row.remark : "N/A"}
                  </TableCell>
                  <TableCell>
                    {row?.userDetails?.user_name}
                  </TableCell>
                  <TableCell>
                    {new Date(row.createdAt).toLocaleDateString()}
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}
                  >
                    <JumboDdMenu
                      icon={<MoreHoriz />}
                      menuItems={
                        permissions?.series_master?.edit === true
                          ? [
                              {
                                icon: <EditOutlined />,
                                title: "Edit ",
                                action: "edit",
                                data: row,
                                id: row._id,
                              },

                              // {
                              //   icon: <DeleteOutlineOutlined />,
                              //   title: "Delete",
                              //   action: "delete",
                              //   data: row,
                              // },
                            ]
                          : [{ title: "No Actions" }]
                      }
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{
                  color: "white",
                  "& .MuiTableCell-root": {
                    py: 2,
                  },
                }}
              >
                <TableCell colSpan={10} align="center">
                  No Data Found...!!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {/* {TotalPage > 0 && ( */}
        <Pagination
          size="small"
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1,
          }}
        />
        {/* )} */}
      </TableContainer>
      <SeriesModal
        open={open}
        handleClose={() => setOpen(false)}
        seriesData={series}
        setToggle={setToggle}
      />
    </>
  );
}

export default ListSeries;
