import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import InfoIcon from "@mui/icons-material/Info";
import { CompareValue } from 'app/components/CompareValue';

const tableHeaderCellStyle = {
    textAlign: "center",
    fontWeight: 400,
    verticalAlign: "middle",
    color: "white",
    padding: "5px 5px",
    borderRight: "1px solid white",
};
const tableBodyCellStyle = {
    textAlign: "center",
    fontWeight: 400,
    verticalAlign: "middle",
    // padding: "5px 5px",
    width: "100%",
    borderRight: "1px solid #7352C7",
};

const ListingCrossCutItems = ({ itemDetails, isApprovalPending }) => {
    return (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table size="small">
                <TableHead>
                    <TableRow
                        sx={{
                            bgcolor: "#7352C7",
                            color: "white",
                        }}
                    >
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitching Date</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Machine</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log No.</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch Code</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log No.Code</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch Formula</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Length</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width1</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width2</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width3</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>height</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch CMT</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Per CMT Cost</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Cost Amount</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Expense Amount</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Wastage CMT</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Required Hours</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>No. of Total Hours</TableCell>
                        <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {itemDetails &&
                        itemDetails?.map((e, i) =>
                            <TableRow key={e?._id} sx={{ background: !e?.previous_data && isApprovalPending ? "rgb(255 0 0 / 15%) !important" : 'inherit' }}>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {moment(e?.worker_details?.flitching_date).format("DD-MM-YYYY") || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={moment(e?.previous_data?.worker_details?.flitching_date).format("DD-MM-YYYY") || "-"}
                                        new_data={moment(e?.worker_details?.flitching_date).format("DD-MM-YYYY") || "-"}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.machine_name} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.machine_name}
                                        new_data={e?.machine_name}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.log_no || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.log_no}
                                        new_data={e?.log_no}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.flitch_code || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.flitch_code}
                                        new_data={e?.flitch_code}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.log_no_code || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.log_no_code}
                                        new_data={e?.log_no_code}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.flitch_formula || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.flitch_formula}
                                        new_data={e?.flitch_formula}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.length || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.length}
                                        new_data={e?.length}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.width1 || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.width1}
                                        new_data={e?.width1}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.width2 || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.width2}
                                        new_data={e?.width2}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.width3 || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.width3}
                                        new_data={e?.width3}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.height || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.height}
                                        new_data={e?.height}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.flitch_cmt || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.flitch_cmt}
                                        new_data={e?.flitch_cmt}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.per_cmt_cost || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.per_cmt_cost}
                                        new_data={e?.per_cmt_cost}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.cost_amount || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.cost_amount}
                                        new_data={e?.cost_amount}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {(e?.expense_amount)?.toFixed(3) || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={Number(e?.previous_data?.expense_amount || 0)?.toFixed(3)}
                                        new_data={Number(e?.expense_amount || 0)?.toFixed(3)}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {Number(e?.wastage_info?.wastage_sqm).toFixed(2) || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={Number(e?.previous_data?.wastage_info?.wastage_sqm || 0).toFixed(3)}
                                        new_data={Number(e?.wastage_info?.wastage_sqm || 0).toFixed(3)}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.required_hours || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.required_hours}
                                        new_data={e?.required_hours}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.required_hours || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.worker_details?.no_of_total_hours}
                                        new_data={e?.worker_details?.no_of_total_hours || "-"}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableBodyCellStyle }}>
                                    {/* {e?.remarks || "-"} */}
                                    <CompareValue isApprovalPending={isApprovalPending}
                                        previous_data={e?.previous_data?.remarks}
                                        new_data={e?.remarks}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                </TableBody>

            </Table>
        </TableContainer>
    )
}

export default ListingCrossCutItems