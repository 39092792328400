import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import InfoIcon from '@mui/icons-material/Info';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ApprovalStatusTableCell } from "app/components/ApprovalStatusTableCell";

const tableBodyCell = { textAlign: "left", px: 1 };

const tableRowBodySticky = {
  textAlign: "left",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "white",
};

const FlitchingDoneTableRow = ({ e, allFilterState }) => {
  console.log("e :", e)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);

  const handleItemAction = (menuItem) => {
    const id = menuItem?.row?.unique_identifier
    const issue_for_flitching_id = menuItem?.row?.issue_for_flitching_id
    switch (menuItem.action) {
      case "viewDetails":
        navigate(`/factory/flitching-approval/view-details/${id}/${issue_for_flitching_id}`)
        break;
      default:
        navigate("/factory/flitching");
    }
  };

  return (
    <TableRow key={e?._id}>
      <TableCell sx={{ ...tableBodyCell }}>
        {moment(e?.worker_details?.flitching_date).format("DD-MM-YYYY") ||
          "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.machine_name}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.log_no || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.flitch_code || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_no_code || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.flitch_formula || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.length || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.width1 || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.width2 || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.width3 || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.height || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.flitch_cmt || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.per_cmt_cost || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.cost_amount || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.expense_amount || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.wastage_info?.wastage_sqm || "-"}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.required_hours || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.worker_details?.no_of_total_hours || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>
        {e?.remarks || "-"}
      </TableCell>
      <ApprovalStatusTableCell approval_status={e?.approval_status} />
      <TableCell sx={{ ...tableBodyCell }}>{e?.user?.user_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky }}>
        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <InfoIcon />,
              title: "View Details",
              action: "viewDetails",
              show: true,
              row: e,
            }
          ].filter((ele) => ele?.show)}
          onClickCallback={handleItemAction}
        />
      </TableCell>
    </TableRow>
  );
};

export default FlitchingDoneTableRow;
