import Div from "@jumbo/shared/Div";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { CompareCheckboxValue, CompareValue } from "app/components/CompareValue";
import moment from "moment";
const tableHeaderCellStyle = {
    textAlign: "center",
    fontWeight: 400,
    verticalAlign: "middle",
    color: "white",
    padding: "5px 5px",
    borderRight: "1px solid white",
};
const tableBodyCellStyle = {
    textAlign: "center",
    fontWeight: 400,
    verticalAlign: "middle",
    padding: "5px 5px",
    // width: "100%",
    borderRight: "1px solid #7352C7",
};
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxHeight: "90vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
};
const disabledCheckBoxStyling = {
    "& .css-jxecqt-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled": {
        color: "#7352C7",
    },
    "& .MuiFormControlLabel-label": {
        color: "#475259 !important", // Change label color to blue
    },
};

const ListingInvoiceDetails = ({ invoiceData, isApprovalPending }) => {
    const invoiceDetails = invoiceData?.invoice_Details
    const previousInvoiceDetails = invoiceData?.previous_data?.invoice_Details
    return (
        <Div>
            <Typography variant="h5" mb={0}>
                Invoice Details
            </Typography>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow
                            sx={{
                                bgcolor: "#7352C7",
                                color: "white",
                            }}
                        >
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "100px" }}>Inward Sr.no</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Inward Date</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "80px" }}>Invoice No</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Date</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>No Of Workers</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Shift</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>No Of Working hours</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>No Of Total hours</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Currency</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Transporter Details</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Port Of Loading</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Port Of Discharge</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Bill Of Landing</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Freight</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Load Unload</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Insurance</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Other</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Other For Import</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>GST Percentage</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Total Item Amt</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>GST Amt</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Amt</TableCell>
                            <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={invoiceData?.previous_data?.inward_sr_no}
                                    new_data={invoiceData?.inward_sr_no}
                                />
                                {/* {invoiceData?.inward_sr_no} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={moment(invoiceData?.previous_data?.inward_date).format("DD/MM/YYYY") || "-"}
                                    new_data={moment(invoiceData?.inward_date).format("DD/MM/YYYY") || "-"}
                                />
                                {/* {moment(invoiceData?.inward_date).format("DD/MM/YYYY") || "-"} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={previousInvoiceDetails?.invoice_no}
                                    new_data={invoiceDetails?.invoice_no}
                                />
                                {/* {invoiceDetails?.invoice_no} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={moment(previousInvoiceDetails?.invoice_date).format("DD/MM/YYYY") || "-"}
                                    new_data={moment(invoiceDetails?.invoice_date).format("DD/MM/YYYY") || "-"}
                                />
                                {/* {moment(invoiceDetails?.invoice_date).format("DD/MM/YYYY") || "-"} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={invoiceData?.previous_data?.workers_details?.no_of_workers}
                                    new_data={invoiceData?.workers_details?.no_of_workers}
                                />
                                {/* {invoiceData?.workers_details?.no_of_workers} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={invoiceData?.previous_data?.workers_details?.shift}
                                    new_data={invoiceData?.workers_details?.shift}
                                />
                                {/* {invoiceData?.workers_details?.shift} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={invoiceData?.previous_data?.workers_details?.working_hours}
                                    new_data={invoiceData?.workers_details?.working_hours}
                                />
                                {/* {invoiceData?.workers_details?.working_hours} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={invoiceData?.previous_data?.workers_details?.total_hours}
                                    new_data={invoiceData?.workers_details?.total_hours}
                                />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={invoiceData?.previous_data?.currency}
                                    new_data={invoiceData?.currency}
                                />
                                {/* {invoiceData?.currency} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={previousInvoiceDetails?.transporter_details}
                                    new_data={invoiceDetails?.transporter_details}
                                />
                                {/* {invoiceDetails?.transporter_details} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={previousInvoiceDetails?.port_of_loading}
                                    new_data={invoiceDetails?.port_of_loading}
                                />
                                {/* {invoiceDetails?.port_of_loading} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={previousInvoiceDetails?.port_of_discharge}
                                    new_data={invoiceDetails?.port_of_discharge}
                                />
                                {/* {invoiceDetails?.port_of_discharge} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={previousInvoiceDetails?.bill_of_landing}
                                    new_data={invoiceDetails?.bill_of_landing}
                                />
                                {/* {invoiceDetails?.bill_of_landing} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                {/* {
                                    invoiceDetails?.isFreightInclude ?
                                        <FormControlLabel disabled sx={{ ...disabledCheckBoxStyling }} control={<Checkbox size="small" checked={invoiceDetails?.isFreightInclude} />} label={invoiceDetails?.freight} /> :
                                        invoiceDetails?.freight
                                } */}
                                <CompareCheckboxValue
                                    isApprovalPending={isApprovalPending}
                                    previous_data={{
                                        check: previousInvoiceDetails?.isFreightInclude,
                                        value: previousInvoiceDetails?.freight
                                    }}
                                    new_data={{
                                        check: invoiceDetails?.isFreightInclude,
                                        value: invoiceDetails?.freight
                                    }}
                                />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                {/* {
                                    invoiceDetails?.isLoadUnloadInclude ?
                                        <FormControlLabel disabled sx={{ ...disabledCheckBoxStyling }} control={<Checkbox size="small" checked={invoiceDetails?.isLoadUnloadInclude} />} label={invoiceDetails?.load_unload} /> :
                                        invoiceDetails?.load_unload
                                } */}
                                <CompareCheckboxValue
                                    isApprovalPending={isApprovalPending}

                                    previous_data={{
                                        check: previousInvoiceDetails?.isLoadUnloadInclude,
                                        value: previousInvoiceDetails?.load_unload
                                    }}
                                    new_data={{
                                        check: invoiceDetails?.isLoadUnloadInclude,
                                        value: invoiceDetails?.load_unload
                                    }}
                                />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                {/* {
                                    invoiceDetails?.isInsuranceInclude ?
                                        <FormControlLabel disabled sx={{ ...disabledCheckBoxStyling }} control={<Checkbox size="small" checked={invoiceDetails?.isInsuranceInclude} />} label={invoiceDetails?.insurance} /> :
                                        invoiceDetails?.insurance
                                } */}
                                <CompareCheckboxValue
                                    isApprovalPending={isApprovalPending}
                                    previous_data={{
                                        check: previousInvoiceDetails?.isInsuranceInclude,
                                        value: previousInvoiceDetails?.insurance
                                    }}
                                    new_data={{
                                        check: invoiceDetails?.isInsuranceInclude,
                                        value: invoiceDetails?.insurance
                                    }}
                                />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                {/* {
                                    invoiceDetails?.isOtherInclude ?
                                        <FormControlLabel disabled sx={{ ...disabledCheckBoxStyling }} control={<Checkbox size="small" checked={invoiceDetails?.isOtherInclude} />} label={invoiceDetails?.other} /> :
                                        invoiceDetails?.other
                                } */}
                                <CompareCheckboxValue
                                    isApprovalPending={isApprovalPending}
                                    previous_data={{
                                        check: previousInvoiceDetails?.isOtherInclude,
                                        value: previousInvoiceDetails?.other
                                    }}
                                    new_data={{
                                        check: invoiceDetails?.isOtherInclude,
                                        value: invoiceDetails?.other
                                    }}
                                />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={previousInvoiceDetails?.other_for_import}
                                    new_data={invoiceDetails?.other_for_import}
                                />
                                {/* {invoiceDetails?.other_for_import} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={previousInvoiceDetails?.gst_percentage}
                                    new_data={invoiceDetails?.gst_percentage}
                                />
                                {/* {invoiceDetails?.gst_percentage} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={previousInvoiceDetails?.total_item_amount}
                                    new_data={invoiceDetails?.total_item_amount}
                                />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={previousInvoiceDetails?.gst_value}
                                    new_data={invoiceDetails?.gst_value}
                                />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={previousInvoiceDetails?.invoice_value_with_gst}
                                    new_data={invoiceDetails?.invoice_value_with_gst}
                                />
                                {/* {invoiceDetails?.invoice_value_with_gst} */}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                                <CompareValue isApprovalPending={isApprovalPending}
                                    previous_data={previousInvoiceDetails?.remark}
                                    new_data={invoiceDetails?.remark}
                                />
                                {/* {invoiceDetails?.remark} */}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Div>
    )
}

export default ListingInvoiceDetails