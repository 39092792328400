import styled from "@emotion/styled";
import InfoIcon from "@mui/icons-material/Info";
import { Checkbox, FormControlLabel, Tooltip, tooltipClasses, Typography } from "@mui/material";

const disabledCheckBoxStyling = {
    "& .css-jxecqt-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled": {
        color: "#7352C7",
    },
    "& .MuiFormControlLabel-label": {
        color: "#475259 !important", // Change label color to blue
    },
};

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export const CompareValue = function ({ previous_data, new_data, isApprovalPending }) {
    return (
        <>
            {isApprovalPending && (previous_data !== null && previous_data !== undefined) && previous_data !== new_data ?
                <HtmlTooltip
                    title={
                        <Typography sx={{ fontSize: '15px' }}>{previous_data}</Typography>
                    }
                    placement="top"
                    arrow={true}
                >
                    <Typography sx={{ color: "red" }}>{new_data}</Typography>
                </HtmlTooltip> : new_data}
            {/* <div>
                {new_data}
                {previous_data && previous_data !== new_data && <HtmlTooltip title={previous_data} placement="right">
                    <InfoIcon sx={{ fontSize: "20px", ml: 1, color: "red" }} />
                </HtmlTooltip>}
            </div> */}
        </>
    )
}

export const CompareCheckboxValue = function ({ previous_data, new_data, isApprovalPending }) {
    return (
        <>
            {isApprovalPending && previous_data && (previous_data?.check !== new_data?.check || previous_data?.value !== new_data?.value) ?
                <HtmlTooltip
                    title={
                        <>
                            <FormControlLabel disabled sx={{ ...disabledCheckBoxStyling }} control={<Checkbox size="small" checked={previous_data?.check} />} label={previous_data?.value} />
                        </>
                    }
                    placement="top"
                    arrow={true}
                >
                    <Typography sx={{ color: "red" }}>{
                        new_data?.check ?
                            <FormControlLabel disabled sx={{ ...disabledCheckBoxStyling, color: "red" }} control={<Checkbox size="small" checked={new_data?.check} />} label={<span style={{ color: "red" }}>{new_data?.value}</span>} />
                            :
                            new_data?.value
                    }</Typography>
                </HtmlTooltip> : new_data?.check ?
                    <FormControlLabel disabled sx={{ ...disabledCheckBoxStyling }} control={<Checkbox size="small" checked={new_data?.check} />} label={new_data?.value} /> :
                    new_data?.value
            }
        </>
    )
}