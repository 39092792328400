import Div from "@jumbo/shared/Div/Div";

import { Button, Tooltip, Typography } from "@mui/material";

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import { getCurrentDate } from "app/components/Function/getCurrentDate";

import ItemDetailsTable from "./itemTable";
import { machineNameDropDown } from "app/services/apis/machineList";
import IssuedLogTable from "./issuedLogTable";
import { Axios } from "index";
import { LoadingButton } from "@mui/lab";
import editCrossCutDone from "app/services/apis/Factory/Crosscutting/ApiCall/editCrossCutDone";
import { editFlitchingDone, revertFlitchingDone } from "app/services/apis/Factory/Flitching/flitchingDone";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";

function EditFlitchingDone() {
  // const { state, pathname } = useLocation();
  const { state } = useLocation();
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const [totalhrs, setTotalhrs] = useState(0);
  const [totalWorker, setTotalWorker] = useState(0);

  const [actualLogDetails, setActualLogDetails] = useState(state);
  const updatedIssuedFlitchingData = actualLogDetails?.issueForFlitchingDetails;
  // const stock = {
  //   flitching_date_date:
  //     actualLogDetails?.worker_details?.flitching_date || getCurrentDate(),
  //   no_of_workers: totalWorker || 0,
  //   shift: actualLogDetails?.worker_details?.shift || 0,
  //   working_hours: totalhrs || 0,
  // };
  // const validationSchema = yup.object({
  //   flitching_date: yup.date().required("Date of Inward is required"),
  //   no_of_workers: yup
  //     .number()
  //     .nullable()
  //     .positive()
  //     .moreThan(0, "Must be greater than 0")
  //     .required("Required"),
  //   shift: yup.string().required("Shift is Required"),
  //   working_hours: yup
  //     .number()
  //     .nullable()
  //     .positive()
  //     .moreThan(0, "Must be greater than 0")
  //     .required("Working hours is Required"),
  // });
  const [isCalculated, setIsCalculated] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const [machineList, setMachineList] = useState([]);
  const [addedItemDetailsList, setAddedItemDetailsList] = useState([]);
  const [revertLoading, setRevertLoading] = useState(false)

  const [wastageInfo, setWastageInfo] = useState({
    wastage_sqm: 0,
    wastage_length: 0,
  });

  // console.log("satet => ", updatedIssuedFlitchingData)
  const [finalData, setFinalData] = useState({
    no_of_workers: actualLogDetails?.worker_details?.workers || 0,

    shift: actualLogDetails?.worker_details?.shift || "",
    working_hours: actualLogDetails?.worker_details?.working_hours || 0,

    flitching_date:
      actualLogDetails?.worker_details?.flitching_date || getCurrentDate(),
  });

  const [initialItemForm, setInitialItemForm] = useState({
    item_sr_no: addedItemDetailsList.length + 1 || 1,
    // item_name: "",
    // item_id: "",
    log_inventory_item_id: actualLogDetails?.log_inventory_item_id,
    issue_for_flitching_id: actualLogDetails?.issue_for_flitching_id,
    log_no_code: "",
    sqm_factor: 0,
    machine_name: "",
    machine_id: "",
    log_no: "",
    flitch_code: "",
    flitch_formula: "",
    length: actualLogDetails?.issueForFlitchingDetails?.available_quantity?.length || 0,
    width1: 0,
    width2: 0,
    width3: 0,
    height: 0,
    flitch_cmt: 0,
    per_cmt_cost: 0,
    cost_amount: 0,
    required_hours: 0,
    remarks: "",
    // flitching_date: getCurrentDate(),
    // no_of_workers: 0,
    // shift: "",
    // working_hours: 0,
    required_workers: 0,
    worker_details: {
      flitching_date: getCurrentDate(),
      workers: 0,
      working_hours: 0,
      shift: "",
      no_of_total_hours: 0
    },
  })

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (addedItemDetailsList && addedItemDetailsList.length <= 0) {
        Swal.fire({
          title: "Add at least one item details.",
          icon: "warning",
          showCancelButton: false,
        });
      }
      const workerDetails = {
        working_hours: finalData?.working_hours,
        flitching_date: finalData?.flitching_date,
        shift: finalData?.shift,
        workers: finalData?.no_of_workers,
      };
      // const newData = addedItemDetailsList.map((item) => {
      //   item.worker_details = workerDetails
      //   return item
      // })
      const newData = addedItemDetailsList?.map((item) => {
        const { issueForFlitchingDetails, created_user, ...data } = item;
        // data.worker_details = workerDetails;
        return data;
      });

      const response = await editFlitchingDone(
        newData,
        actualLogDetails?.issue_for_flitching_id
      );

      if (response?.data?.success) {
        Swal.fire({
          title: "Success",
          text: response?.data?.message,
          icon: "success",
          showCancelButton: false,
        });
        navigate("/factory/flitching");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showCancelButton: false,
      });
    }
  };
  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const res = await machineNameDropDown("");
        setMachineList(res?.data?.result);
      } catch (error) {
        console.log("err fetching machine list=> ", error.message);
      }
    };
    fetchMachines();
  }, []);

  useEffect(() => {
    const fetchAllFlitchings = async () => {
      try {
        const res = await Axios.get(
          `/factory/flitching/list-flitchings-by-issued-flitching-id/${actualLogDetails?.issue_for_flitching_id}`
        );
        const addedSrNumberList = res?.data?.result?.map((item, index) => ({
          ...item,
          item_sr_no: index + 1,
        }));

        setAddedItemDetailsList(addedSrNumberList);
      } catch (error) {
        console.log("err fetching all crosscuts");
      }
    };
    fetchAllFlitchings();
  }, [actualLogDetails?.issue_for_flitching_id]);

  const handleCalculate = () => {
    const parsedPhysicalSqm = Number(
      updatedIssuedFlitchingData?.available_quantity?.cmt
    );
    const physicalLength = Number(
      updatedIssuedFlitchingData?.available_quantity?.length
    );

    const costAmount = Number(updatedIssuedFlitchingData?.available_quantity?.amount);

    // eslint-disable-next-line no-unused-vars
    const totalLength = addedItemDetailsList?.reduce(
      (acc, val) => acc + (Number(val?.length) || 0),
      0
    );
    const totalSqm = addedItemDetailsList?.reduce(
      (acc, val) => acc + Number(val?.flitch_cmt),
      0
    );
    const wastageSqm = Number((parsedPhysicalSqm - totalSqm).toFixed(3));

    const outputSqm = Number((parsedPhysicalSqm - wastageSqm).toFixed(3));

    const availableLength = physicalLength - Number(totalLength);

    console.log(`wastage sqm => ${wastageSqm} , outputSqm => ${outputSqm} `)
    if (Number(totalSqm) > Number(updatedIssuedFlitchingData?.available_quantity?.cmt)) {
      // ("length", 0);
      return Swal.fire({
        timer: 3000,
        icon: "error",
        title: "Total Flitch Cmt can't be greater than physical cmt...",
        position: "center",
        // background: theme.palette.background.paper,
      });
    }
    // const wastageData = {
    //   wastage_sqm: wastageSqm,
    //   wastage_length: availableLength,
    // };

    const totalHours =
      Number(finalData?.no_of_workers) * Number(finalData?.working_hours);

    // eslint-disable-next-line no-unused-vars
    const amountsForItems = addedItemDetailsList?.map((item) => {
      const sqFactor = Number((Number(item?.flitch_cmt) / outputSqm));
      item.sqm_factor = sqFactor;
      const itemCostAmount = Number((Number(costAmount) * Number(sqFactor)).toFixed(2));
      item.cost_amount = itemCostAmount;
      // const expenseAmount = Number(actualLogDetails?.expense_amount || 0) * Number(sqFactor);old
      const expenseAmount = Number((Number(updatedIssuedFlitchingData?.available_quantity?.expense_amount || 0) * Number(sqFactor)).toFixed(2)); // new way
      item.expense_amount = expenseAmount
      const itemPerCmtAmount =
        Number((Number(item?.cost_amount) / Number(item?.flitch_cmt)).toFixed(2));
      item.per_cmt_cost = itemPerCmtAmount;
      // item.required_hours =
      //   totalSqm > 0
      //     ? Math.round(
      //       (Number(item?.flitch_cmt) / totalSqm) * Number(totalHours)
      //     )
      //     : 0;
      // item.required_workers =
      //   totalSqm > 0
      //     ? Number(
      //       (
      //         (Number(item?.flitch_cmt) / totalSqm) *
      //         Number(finalData?.no_of_workers)
      //       ).toFixed(2)
      //     )
      //     : 0;
      let wastageData = { wastage_sqm: 0, wastage_length: 0 }
      let perWastageSqm = Number((Number(sqFactor) * Number(wastageSqm)).toFixed(3))
      wastageData.wastage_sqm = perWastageSqm;
      let wastageLength = Number((Number(sqFactor) * Number(availableLength)).toFixed(2))
      wastageData.wastage_length = wastageLength
      item.wastage_info = wastageData;
      item.wastage_info = wastageData;
      item.flitching_completed = true
      return item;
    });
    setAddedItemDetailsList(amountsForItems);
    setWastageInfo(wastageInfo);
    setIsCalculated(true);

  };

  const handleRevert = async () => {
    setRevertLoading(true)
    try {
      const response = await revertFlitchingDone(actualLogDetails?.issue_for_flitching_id);
      if (response?.data?.success) {
        Swal.fire({
          title: response?.data?.message,
          icon: "success",
          showCancelButton: false,
        });
        navigate("/factory/flitching");
        setRevertLoading(false);
      }
    } catch (error) {
      setRevertLoading(false);
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showCancelButton: false,
      });
    }
  }

  return (
    <Div sx={{ mt: -4 }}>
      {/* <Typography variant="h1">Edit Flitching</Typography> */}
      <Div>
        <IssuedLogTable actualData={addedItemDetailsList} />
      </Div>

      <Div sx={{ marginTop: 5, marginBottom: 2 }}>
        <ItemDetailsTable
          actualLogData={actualLogDetails}
          setAddedItemDetailsList={setAddedItemDetailsList}
          addedItemDetailsList={addedItemDetailsList}
          finalData={finalData}
          machineList={machineList}
          isCalculated={isCalculated}
          setIsCalculated={setIsCalculated}
          handleCalculate={handleCalculate}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          wastageInfo={wastageInfo}
          code={code}
          setCode={setCode}
          setFinalData={setFinalData}
          initialItemForm={initialItemForm}
          setInitialItemForm={setInitialItemForm}
        />
        <Div className="flex flex-row items-center justify-center gap-6 mt-8">
          <div>
            {addedItemDetailsList?.length > 0 && (
              <LoadingButton
                onClick={() => {
                  Swal.fire({

                    text: "Are you sure you want to revert all flitching items ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      // navigate("/factory/flitching");
                      handleRevert()
                    }
                  });
                }}
                // type="submit"
                size="small"
                variant="contained"
                color="secondary"
                loading={revertLoading}
              >
                Revert All
              </LoadingButton>
            )}
          </div>
          <div className="flex flex-row items-center justify-center gap-6">

            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                Swal.fire({
                  text: "Are you sure you want to cancel?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/factory/flitching");
                  }
                });
              }}
            >
              Cancel
            </Button>


            {isCalculated ? (
              <LoadingButton
                onClick={handleSubmit}
                // type="submit"
                size="small"
                variant="contained"
                loading={loading}
              >
                Save
              </LoadingButton>
            ) : (
              <Tooltip
                title={
                  addedItemDetailsList?.length !== 0
                    ? ""
                    : "You need to add at least one item"
                }
              >
                <span>
                  <LoadingButton
                    disabled={addedItemDetailsList.length === 0}
                    onClick={handleCalculate}
                    size="small"
                    variant="contained"
                  >
                    Calculate
                  </LoadingButton>
                </span>
              </Tooltip>
            )}
          </div>

        </Div>
      </Div>
    </Div>
  );
}

export default HeaderTitleHoc(EditFlitchingDone, "Edit Flitching");
