import Div from "@jumbo/shared/Div";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CompareValue } from "app/components/CompareValue";
import moment from "moment";
const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  // width: "100%",
  borderRight: "1px solid #7352C7",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "90vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};
const ListingSupplierDetails = ({ supplier_details, previous_data, isApprovalPending }) => {
  const invoiceSupplierDetails = supplier_details;
  return (
    <Box>
      <Div sx={{ mt: 2 }}>
        <Typography variant="h5">Supplier Details</Typography>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: "#7352C7",
                  color: "white",
                }}
              >
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Name</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Branch Name</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>GST No.</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "200px" }}>Address</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>City</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>State</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Country</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Pincode</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Web Url</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ ...tableBodyCellStyle }}>
                  <CompareValue
                    isApprovalPending={isApprovalPending}
                    previous_data={previous_data?.company_details?.supplier_name}
                    new_data={invoiceSupplierDetails?.company_details?.supplier_name}
                  />
                  {/* {invoiceSupplierDetails?.company_details?.supplier_name} */}
                </TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>
                  <CompareValue
                    isApprovalPending={isApprovalPending}
                    previous_data={previous_data?.branch_detail?.branch_name}
                    new_data={invoiceSupplierDetails?.branch_detail?.branch_name}
                  />
                  {/* {invoiceSupplierDetails?.branch_detail?.branch_name} */}
                </TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>
                  <CompareValue
                    isApprovalPending={isApprovalPending}
                    previous_data={previous_data?.branch_detail?.gst_number}
                    new_data={invoiceSupplierDetails?.branch_detail?.gst_number}
                  />
                  {/* {invoiceSupplierDetails?.branch_detail?.gst_number} */}
                </TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>
                  <CompareValue
                    isApprovalPending={isApprovalPending}
                    previous_data={previous_data?.branch_detail?.address}
                    new_data={invoiceSupplierDetails?.branch_detail?.address}
                  />
                  {/* {invoiceSupplierDetails?.branch_detail?.address} */}
                </TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>
                  <CompareValue
                    isApprovalPending={isApprovalPending}
                    previous_data={previous_data?.branch_detail?.city}
                    new_data={invoiceSupplierDetails?.branch_detail?.city}
                  />
                  {/* {invoiceSupplierDetails?.branch_detail?.city} */}
                </TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>
                  <CompareValue
                    isApprovalPending={isApprovalPending}
                    previous_data={previous_data?.branch_detail?.state}
                    new_data={invoiceSupplierDetails?.branch_detail?.state}
                  />
                  {/* {invoiceSupplierDetails?.branch_detail?.state} */}
                </TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>
                  <CompareValue
                    isApprovalPending={isApprovalPending}
                    previous_data={previous_data?.branch_detail?.country}
                    new_data={invoiceSupplierDetails?.branch_detail?.country}
                  />
                  {/* {invoiceSupplierDetails?.branch_detail?.country} */}
                </TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>
                  <CompareValue
                    isApprovalPending={isApprovalPending}
                    previous_data={previous_data?.branch_detail?.pincode}
                    new_data={invoiceSupplierDetails?.branch_detail?.pincode}
                  />
                  {/* {invoiceSupplierDetails?.branch_detail?.pincode} */}
                </TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>
                  <CompareValue
                    isApprovalPending={isApprovalPending}
                    previous_data={previous_data?.branch_detail?.web_url}
                    new_data={invoiceSupplierDetails?.branch_detail?.web_url}
                  />
                  {/* {invoiceSupplierDetails?.branch_detail?.web_url} */}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Div>
    </Box>
  );
};

export default ListingSupplierDetails;
