import Div from "@jumbo/shared/Div/Div";
import { useLocation, useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Button, Checkbox, Grid, Switch, TextField, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Axios } from "index";
import { departmentNameDropDown } from "app/services/apis/department_list";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import { addRole } from "app/services/apis/roleApis";

function AddRole() {
  const GreenCheckbox = withStyles({
    root: {
      "&$checked": {
        color: "green",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const headingStyle = {
    minWidth: "300px",
    fontSize: "1rem",
    fontWeight: "500",
  };
  const checkboxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 4,
    width: "100px",
  };

  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [departmentList, setDepartmentList] = useState([]);
  const [deptPermission, setDeptPermission] = useState({});
  const [selectAll, setSelectAll] = useState({
    edit: false,
    view: false,
    create: false,
  });

  // const [check, setCheck] = useState({
  //   user_view: true,
  //   user_edit: false,
  //   user_create: false,

  //   role_view: true,
  //   role_edit: false,
  //   role_create: false,

  //   supplier_master_view: false,
  //   supplier_master_edit: false,
  //   supplier_master_create: false,

  //   item_category_master_view: false,
  //   item_category_master_edit: false,
  //   item_category_master_create: false,

  //   item_sub_category_master_view: false,
  //   item_sub_category_master_edit: false,
  //   item_sub_category_master_create: false,

  //   item_name_master_view: false,
  //   item_name_master_edit: false,
  //   item_name_master_create: false,

  //   unit_master_view: false,
  //   unit_master_edit: false,
  //   unit_master_create: false,

  //   grade_master_view: false,
  //   grade_master_edit: false,
  //   grade_master_create: false,

  //   currency_master_view: false,
  //   currency_master_edit: false,
  //   currency_master_create: false,

  //   cut_master_view: false,
  //   cut_master_edit: false,
  //   cut_master_create: false,

  //   series_master_view: false,
  //   series_master_edit: false,
  //   series_master_create: false,

  //   expense_type_master_view: false,
  //   expense_type_master_edit: false,
  //   expense_type_master_create: false,

  //   expense_master_view: false,
  //   expense_master_edit: false,
  //   expense_master_create: false,

  //   gst_master_view: false,
  //   gst_master_edit: false,
  //   gst_master_create: false,

  //   department_master_view: false,
  //   department_master_edit: false,
  //   department_master_create: false,

  //   machine_master_view: false,
  //   machine_master_edit: false,
  //   machine_master_create: false,

  //   log_inventory_view: false,
  //   log_inventory_edit: false,
  //   log_inventory_create: false,

  //   flitch_inventory_view: false,
  //   flitch_inventory_edit: false,
  //   flitch_inventory_create: false,

  //   plywood_inventory_view: false,
  //   plywood_inventory_edit: false,
  //   plywood_inventory_create: false,

  //   veneer_inventory_view: false,
  //   veneer_inventory_edit: false,
  //   veneer_inventory_create: false,

  //   mdf_inventory_view: false,
  //   mdf_inventory_edit: false,
  //   mdf_inventory_create: false,

  //   face_inventory_view: false,
  //   face_inventory_edit: false,
  //   face_inventory_create: false,

  //   core_inventory_view: false,
  //   core_inventory_edit: false,
  //   core_inventory_create: false,

  //   fleece_paper_inventory_view: false,
  //   fleece_paper_inventory_edit: false,
  //   fleece_paper_inventory_create: false,

  //   other_goods_inventory_view: false,
  //   other_goods_inventory_edit: false,
  //   other_goods_inventory_create: false,

  //   crosscut_factory_view: false,
  //   crosscut_factory_edit: false,
  //   crosscut_factory_create: false,

  //   flitching_factory_view: false,
  //   flitching_factory_edit: false,
  //   flitching_factory_create: false,

  //   // grouped_veneer_view: false,
  //   // grouped_veneer_edit: false,
  //   // grouped_veneer_create: false,

  //   // other_goods_inventory_view: false,
  //   // other_goods_inventory_edit: false,
  //   // other_goods_inventory_create: false,

  //   // inventory_view: false,
  //   // inventory_edit: false,
  //   // inventory_create: false,

  //   // grouping_view: false,
  //   // grouping_edit: false,
  //   // grouping_create: false,

  //   // smoking_view: false,
  //   // smoking_edit: false,
  //   // smoking_create: false,

  //   // dying_view: false,
  //   // dying_edit: false,
  //   // dying_create: false,

  //   // cutting_view: false,
  //   // cutting_edit: false,
  //   // cutting_create: false,

  //   // tapping_view: false,
  //   // tapping_edit: false,
  //   // tapping_create: false,

  //   // ready_sheet_form_view: false,
  //   // ready_sheet_form_edit: false,
  //   // ready_sheet_form_create: false,

  //   // pressing_view: false,
  //   // pressing_edit: false,
  //   // pressing_create: false,

  //   // finishing_view: false,
  //   // finishing_edit: false,
  //   // finishing_create: false,

  //   // qc_view: false,
  //   // qc_edit: false,
  //   // qc_create: false,

  //   // orders_view: false,
  //   // orders_edit: false,
  //   // orders_create: false,

  //   // dispatch_view: false,
  //   // dispatch_edit: false,
  //   // dispatch_create: false,
  // });
  const [check, setCheck] = useState({
    user: {
      view: false,
      edit: false,
      create: false,
    },
    role: {
      view: false,
      edit: false,
      create: false,
    },
    supplier_master: {
      view: false,
      edit: false,
      create: false,
    },
    item_category_master: {
      view: false,
      edit: false,
      create: false,
    },
    item_sub_category_master: {
      view: false,
      edit: false,
      create: false,
    },
    item_name_master: {
      view: false,
      edit: false,
      create: false,
    },
    unit_master: {
      view: false,
      edit: false,
      create: false,
    },
    grade_master: {
      view: false,
      edit: false,
      create: false,
    },
    currency_master: {
      view: false,
      edit: false,
      create: false,
    },
    cut_master: {
      view: false,
      edit: false,
      create: false,
    },
    series_master: {
      view: false,
      edit: false,
      create: false,
    },
    expense_type_master: {
      view: false,
      edit: false,
      create: false,
    },
    expense_master: {
      view: false,
      edit: false,
      create: false,
    },
    gst_master: {
      view: false,
      edit: false,
      create: false,
    },
    department_master: {
      view: false,
      edit: false,
      create: false,
    },
    machine_master: {
      view: false,
      edit: false,
      create: false,
    },
    approval_config: {
      view: false,
      edit: false,
      create: false,
    },
    log_inventory: {
      view: false,
      edit: false,
      create: false,
    },
    flitch_inventory: {
      view: false,
      edit: false,
      create: false,
    },
    plywood_inventory: {
      view: false,
      edit: false,
      create: false,
    },
    veneer_inventory: {
      view: false,
      edit: false,
      create: false,
    },
    mdf_inventory: {
      view: false,
      edit: false,
      create: false,
    },
    face_inventory: {
      view: false,
      edit: false,
      create: false,
    },
    core_inventory: {
      view: false,
      edit: false,
      create: false,
    },
    fleece_paper_inventory: {
      view: false,
      edit: false,
      create: false,
    },
    other_goods_inventory: {
      view: false,
      edit: false,
      create: false,
    },
    crosscut_factory: {
      view: false,
      edit: false,
      create: false,
    },
    flitching_factory: {
      view: false,
      edit: false,
      create: false,
    },
  });

  const initialValues = {
    role_name: "",
    roles_remarks: "",
    dept_name: "",
    dept_id: "",
    status: true,
  };

  const validationSchema = yup.object({
    role_name: yup.string().required("Role Name is required"),
    dept_name: yup.string().required("Department is required"),
    roles_remarks: yup.string("Enter Remarks"),
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const [parent, child] = name.split(".");
    setCheck((prev) => ({
      ...prev,
      [parent]: {
        ...prev[parent],
        [child]: checked,
      },
    }));
  };

  const handleAllView = (e) => {
    const updatedObj = { ...check }; // Create a copy of the original object
    setSelectAll({ ...selectAll, view: e.target.checked });
    // Iterate through the keys of the object
    for (const key in updatedObj) {
      if (key.endsWith("_view")) {
        updatedObj[key] = e.target.checked;
      }
    }
    setCheck(updatedObj);
  };

  const handleAllEdit = (e) => {
    const isChecked = e.target.checked;

    setCheck((prevCheck) => {
      const updatedCheck = {};

      Object.entries(prevCheck).forEach(([category, permissions]) => {
        if (permissions.view) {
          updatedCheck[category] = {
            ...permissions,
            edit: isChecked, // Only update the edit permission
          };
        } else {
          updatedCheck[category] = { ...permissions };
        }
      });

      return updatedCheck;
    });

    setSelectAll((prev) => ({ ...prev, edit: isChecked }));
  };

  const handleAllCreate = (e) => {
    const isChecked = e.target.checked;

    setCheck((prevCheck) => {
      const updatedCheck = {};

      Object.entries(prevCheck).forEach(([category, permissions]) => {
        if (permissions.view) {
          updatedCheck[category] = {
            ...permissions,
            create: isChecked, // Only update the edit permission
          };
        } else {
          updatedCheck[category] = { ...permissions };
        }
      });

      return updatedCheck;
    });

    setSelectAll((prev) => ({ ...prev, create: isChecked }));
  };

  const handleSubmit = async (values) => {

    setSubmitting(true);

    const details = {
      ...values,
      permissions: check,
    };

    const data = await addRole(details);

    if (data.status === 200) {
      Swal.fire({
        icon: "success",
        title: data.data.message,
        text: "",
        timer: 1000,
        showConfirmButton: false,
      });
      navigate("/roles");
    } else {
      setSubmitting(false);

      Swal.fire({
        icon: "error",
        title: data.message,
        text: "",
        timer: 1000,
        showConfirmButton: false,
      });
    }

    setSubmitting(false);
  };

  useEffect(() => {
    let updatedObj = {};
    for (let key in deptPermission) {
      if (deptPermission[key] === true) {
        updatedObj[key] = {
          ...updatedObj[key],
          view: true,
          edit: false,
          create: false,
        };
      } else {
        updatedObj[key] = {
          view: false,
          edit: false,
          create: false,
        };
      }
    }

    // console.log(updatedObj, "updatedObj");
    setCheck({ ...updatedObj });
    setSelectAll((prev) => ({ ...prev, edit: false, create: false }));
  }, [deptPermission]);

  useEffect(() => {
    const fetchAllCategories = async () => {
      try {
        const res = await departmentNameDropDown("");
        setDepartmentList(res?.data?.result);
      } catch (error) {
        console.log("er fetching all categories -> ", error.message);
      }
    };
    fetchAllCategories();
  }, []);

  useEffect(() => {
    // Function to check permissions only for roles where 'view' is true
    const checkAllPermissions = (permissionType) => {
      return Object.entries(check)
        .filter(([_, permissions]) => permissions.view === true) // Only consider roles where view is true
        .every(([_, permissions]) => permissions[permissionType] === true);
    };

    // Update selectAll for 'edit' and 'create' only if 'view' is true
    setSelectAll((prev) => ({
      ...prev,
      edit: checkAllPermissions("edit"),
      create: checkAllPermissions("create"),
    }));
  }, [check]);

  return (
    <Div sx={{ mt: -4 }}>
      {/* <Typography variant="h2">Add Roles</Typography> */}
      <Div>
        <Formik
          validateOnChange={false}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, touched, handleBlur, setFieldTouched, isSubmitting }) => (
            <Form noValidate>
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Role Name*
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.role_name}
                      sx={{ width: "100%" }}
                      name="role_name"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("role_name", e.target.value)}
                      error={touched?.role_name && errors?.role_name}
                      helperText={touched?.role_name && errors?.role_name}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Department*
                    </Typography>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={departmentList}
                      name="dept_name"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.dept_name;
                        } else {
                          return option;
                        }
                      }}
                      // value={values?.dept_name}
                      value={departmentList.find((role) => role.dept_name === values.dept_name) || null}
                      onChange={(e, value) => {
                        setFieldValue("dept_name", value?.dept_name);
                        setFieldValue("dept_id", value?._id);
                        setDeptPermission(value?.dept_access);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("dept_name", true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.dept_name && Boolean(errors.dept_name)} // Show error if touched and there's an error
                          helperText={touched.dept_name && errors.dept_name} // Display helper text for the error
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Remark
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.roles_remarks}
                      sx={{ width: "100%" }}
                      name="roles_remarks"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("roles_remarks", e.target.value)}
                      error={touched?.roles_remarks && errors?.roles_remarks}
                      helperText={touched?.roles_remarks && errors?.roles_remarks}
                    />
                  </Grid>
                </Grid>
              </Div>

              <Div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  mt: 3,
                }}
              >
                <Typography sx={headingStyle}>Select</Typography>
                <Div sx={checkboxStyle}>
                  {/* <GreenCheckbox checked={selectAll.view} onChange={handleAllView} />
                  <Typography>View</Typography> */}
                </Div>
                <Div sx={checkboxStyle}>
                  <GreenCheckbox checked={selectAll.edit} onChange={handleAllEdit} />
                  <Typography>Edit</Typography>
                </Div>
                <Div sx={checkboxStyle}>
                  <GreenCheckbox checked={selectAll.create} onChange={handleAllCreate} />
                  <Typography>Create</Typography>
                </Div>
              </Div>

              {check?.user?.view && (
                <Div
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    width: "100%",
                    mt: 3,
                  }}
                >
                  <Typography sx={headingStyle}>User Management</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.user?.view} onChange={handleChange} name="user.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.user?.edit} onChange={handleChange} name="user.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.user?.create} onChange={handleChange} name="user.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}

              {check?.role?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Roles & Permissions</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.role?.view} onChange={handleChange} name="role.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.role?.edit} onChange={handleChange} name="role.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.role?.create} onChange={handleChange} name="role.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}

              <Div style={{ backgroundColor: "#EAE5F7", textAlign: "center" }}>
                <Typography sx={{ color: "#7352C7", padding: "5px 0", fontWeight: "600" }} variant="h3">
                  MASTER
                </Typography>
              </Div>

              {check?.supplier_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Supplier Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.supplier_master?.view} onChange={handleChange} name="supplier_master.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.supplier_master?.edit} onChange={handleChange} name="supplier_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.supplier_master?.create} onChange={handleChange} name="supplier_master.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.item_category_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Item Category Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      disabled
                      checked={check?.item_category_master?.view}
                      onChange={handleChange}
                      name="item_category_master.view"
                    />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.item_category_master?.edit} onChange={handleChange} name="item_category_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      checked={check?.item_category_master?.create}
                      onChange={handleChange}
                      name="item_category_master.create"
                    />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.item_sub_category_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Item Sub-category Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      disabled
                      checked={check?.item_sub_category_master?.view}
                      onChange={handleChange}
                      name="item_sub_category_master.view"
                    />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      checked={check?.item_sub_category_master?.edit}
                      onChange={handleChange}
                      name="item_sub_category_master.edit"
                    />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      checked={check?.item_sub_category_master?.create}
                      onChange={handleChange}
                      name="item_sub_category_master.create"
                    />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.item_name_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Item Name Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.item_name_master?.view} onChange={handleChange} name="item_name_master.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.item_name_master?.edit} onChange={handleChange} name="item_name_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.item_name_master?.create} onChange={handleChange} name="item_name_master.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.unit_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Unit Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.unit_master?.view} onChange={handleChange} name="unit_master.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.unit_master?.edit} onChange={handleChange} name="unit_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.unit_master?.create} onChange={handleChange} name="unit_master.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.grade_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Grade Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.grade_master?.view} onChange={handleChange} name="grade_master.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.grade_master?.edit} onChange={handleChange} name="grade_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.grade_master?.create} onChange={handleChange} name="grade_master.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.currency_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Currency Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.currency_master?.view} onChange={handleChange} name="currency_master.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.currency_master?.edit} onChange={handleChange} name="currency_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.currency_master?.create} onChange={handleChange} name="currency_master.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.cut_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Cut Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.cut_master?.view} onChange={handleChange} name="cut_master.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.cut_master?.edit} onChange={handleChange} name="cut_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.cut_master?.create} onChange={handleChange} name="cut_master.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.series_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Series Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.series_master?.view} onChange={handleChange} name="series_master.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.series_master?.edit} onChange={handleChange} name="series_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.series_master?.create} onChange={handleChange} name="series_master.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.expense_type_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Expense Type Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      disabled
                      checked={check?.expense_type_master?.view}
                      onChange={handleChange}
                      name="expense_type_master.view"
                    />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.expense_type_master?.edit} onChange={handleChange} name="expense_type_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.expense_type_master?.create} onChange={handleChange} name="expense_type_master.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.expense_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Expense Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.expense_master?.view} onChange={handleChange} name="expense_master.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.expense_master?.edit} onChange={handleChange} name="expense_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.expense_master?.create} onChange={handleChange} name="expense_master.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.gst_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>GST Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.gst_master?.view} onChange={handleChange} name="gst_master.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.gst_master?.edit} onChange={handleChange} name="gst_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.gst_master?.create} onChange={handleChange} name="gst_master.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.department_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Department Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      disabled
                      checked={check?.department_master?.view}
                      onChange={handleChange}
                      name="department_master.view"
                    />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.department_master?.edit} onChange={handleChange} name="department_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.department_master?.create} onChange={handleChange} name="department_master.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.machine_master?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Machine Master</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.machine_master?.view} onChange={handleChange} name="machine_master.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.machine_master?.edit} onChange={handleChange} name="machine_master.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.machine_master?.create} onChange={handleChange} name="machine_master.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.approval_config?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Approval Config</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.approval_config?.view} onChange={handleChange} name="approval_config.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.approval_config?.edit} onChange={handleChange} name="approval_config.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.approval_config?.create} onChange={handleChange} name="approval_config.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              <Div style={{ backgroundColor: "#EAE5F7", textAlign: "center" }}>
                <Typography sx={{ color: "#7352C7", padding: "5px 0", fontWeight: "600" }} variant="h3">
                  INVENTORY
                </Typography>
              </Div>
              {check?.log_inventory?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Log Inventory</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.log_inventory?.view} onChange={handleChange} name="log_inventory.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.log_inventory?.edit} onChange={handleChange} name="log_inventory.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.log_inventory?.create} onChange={handleChange} name="log_inventory.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.flitch_inventory?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Flitch Inventory</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.flitch_inventory?.view} onChange={handleChange} name="flitch_inventory.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.flitch_inventory?.edit} onChange={handleChange} name="flitch_inventory.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.flitch_inventory?.create} onChange={handleChange} name="flitch_inventory.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.plywood_inventory?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Plywood Inventory</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      disabled
                      checked={check?.plywood_inventory?.view}
                      onChange={handleChange}
                      name="plywood_inventory.view"
                    />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.plywood_inventory?.edit} onChange={handleChange} name="plywood_inventory.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.plywood_inventory?.create} onChange={handleChange} name="plywood_inventory.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.veneer_inventory?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Veneer Inventory</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.veneer_inventory?.view} onChange={handleChange} name="veneer_inventory.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.veneer_inventory?.edit} onChange={handleChange} name="veneer_inventory.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.veneer_inventory?.create} onChange={handleChange} name="veneer_inventory.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.mdf_inventory?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>MDF Inventory</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.mdf_inventory?.view} onChange={handleChange} name="mdf_inventory.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.mdf_inventory?.edit} onChange={handleChange} name="mdf_inventory.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.mdf_inventory?.create} onChange={handleChange} name="mdf_inventory.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.face_inventory?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Face Inventory</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.face_inventory?.view} onChange={handleChange} name="face_inventory.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.face_inventory?.edit} onChange={handleChange} name="face_inventory.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.face_inventory?.create} onChange={handleChange} name="face_inventory.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.core_inventory?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Core Inventory</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.core_inventory?.view} onChange={handleChange} name="core_inventory.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.core_inventory?.edit} onChange={handleChange} name="core_inventory.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.core_inventory?.create} onChange={handleChange} name="core_inventory.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.fleece_paper_inventory?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Fleece Paper Inventory</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      disabled
                      checked={check?.fleece_paper_inventory?.view}
                      onChange={handleChange}
                      name="fleece_paper_inventory.view"
                    />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      checked={check?.fleece_paper_inventory?.edit}
                      onChange={handleChange}
                      name="fleece_paper_inventory.edit"
                    />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      checked={check?.fleece_paper_inventory?.create}
                      onChange={handleChange}
                      name="fleece_paper_inventory.create"
                    />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.other_goods_inventory?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Other Goods Inventory</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      disabled
                      checked={check?.other_goods_inventory?.view}
                      onChange={handleChange}
                      name="other_goods_inventory.view"
                    />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.other_goods_inventory?.edit} onChange={handleChange} name="other_goods_inventory.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      checked={check?.other_goods_inventory?.create}
                      onChange={handleChange}
                      name="other_goods_inventory.create"
                    />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              <Div style={{ backgroundColor: "#EAE5F7", textAlign: "center" }}>
                <Typography sx={{ color: "#7352C7", padding: "5px 0", fontWeight: "600" }} variant="h3">
                  FACTORY
                </Typography>
              </Div>
              {check?.crosscut_factory?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Crosscut Factory</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox disabled checked={check?.crosscut_factory?.view} onChange={handleChange} name="crosscut_factory.view" />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.crosscut_factory?.edit} onChange={handleChange} name="crosscut_factory.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.crosscut_factory?.create} onChange={handleChange} name="crosscut_factory.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              {check?.flitching_factory?.view && (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={headingStyle}>Flitching Factory</Typography>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox
                      disabled
                      checked={check?.flitching_factory?.view}
                      onChange={handleChange}
                      name="flitching_factory.view"
                    />
                    <Typography>View</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.flitching_factory?.edit} onChange={handleChange} name="flitching_factory.edit" />
                    <Typography>Edit</Typography>
                  </Div>
                  <Div sx={checkboxStyle}>
                    <GreenCheckbox checked={check?.flitching_factory?.create} onChange={handleChange} name="flitching_factory.create" />
                    <Typography>Create</Typography>
                  </Div>
                </Div>
              )}
              <Div
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 3,
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/roles");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton size="small" variant="contained" type="submit" sx={{ width: "100px" }} loading={isSubmitting}>
                  Save
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}

export default HeaderTitleHoc(AddRole, "Add Role");
