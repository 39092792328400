import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AccessMiddleware = ({ fallbackPath }) => {
  const [fallbackPathRoute, moduleName, modulePermission] = fallbackPath;
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  if (permissions?.[moduleName] && permissions?.[moduleName]?.[modulePermission] === true) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPathRoute} />;
  }
};

export default AccessMiddleware;
