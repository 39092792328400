import Page from "@jumbo/shared/Page";
import InventoryList from "app/pages/Inventory/List Inventory";
import AddStock from "app/pages/Inventory/AddStock";
import EditStock from "app/pages/Inventory/EditStock";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";

export const rawMaterialRoute = [
  {
    middleware: [
      {
        element: AccessMiddleware,
       fallbackPath: ["/dashboard","raw_inventory","view"],
      },
    ],
    routes: [
      {
        path: "inventory/rawmaterial",
        element: <Page component={InventoryList} layout={"vertical-default"} />,
      },
      {
        path: "inventory/add",
        element: <Page component={AddStock} layout={"vertical-default"} />,
      },
      {
        path: "inventory/edit",
        element: <Page component={EditStock} layout={"vertical-default"} />,
      },
    ],
  },
];
