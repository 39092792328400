import { Axios } from "index";

export const machineNameDropDown = async (id) => {
  try {
    const data = await Axios.get(
      `${process.env.REACT_APP_URL}/machine-master/dropdown-machine-master?id=${id}`
    );

    return data;
  } catch (error) {
    return error.response;
  }
};



export const machineNameDropDownByDepartMentId = async (id) => {
  try {
    const data = await Axios.get(
      `${process.env.REACT_APP_URL}/machine-master/dropdown-machine-master-by-deptartment?id=${id}`
    );

    return data;
  } catch (error) {
    return error.response;
  }
};