import AddItemModal from "./addNewItemTable";
import EditItemDetailsTable from "./EditItemTable";

const ItemDetailsTable = ({ gradeNameList, setAddedItemDetailsList, addedItemDetailsList, itemNameList, itemSubCategoryNameList, finalData, itemDetailsListTableLoading, initialItemForm, setInitailItemForm }) => {

  return (
    <div>
      <AddItemModal
        itemNameList={itemNameList}
        setAddedItemDetailsList={setAddedItemDetailsList}
        addedItemDetailsList={addedItemDetailsList}
        finalData={finalData}
        gradeNameList={gradeNameList}
        initialItemForm={initialItemForm}
        setInitailItemForm={setInitailItemForm}
      />
      <EditItemDetailsTable
        itemNameList={itemNameList}
        setAddedItemDetailsList={setAddedItemDetailsList}
        addedItemDetailsList={addedItemDetailsList}
        finalData={finalData}
        itemDetailsListTableLoading={itemDetailsListTableLoading}
        gradeNameList={gradeNameList}
      />
    </div>
  );
};

export default ItemDetailsTable;
