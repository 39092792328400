// import React, { useEffect, useState } from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Modal from "@mui/material/Modal";
// import { Close } from "@mui/icons-material";

// import { TextField, Typography } from "@mui/material";

// import { useNavigate } from "react-router-dom";
// import { Axios } from "index";
// import ToastAlerts from "app/pages/extensions/sweetalert/components/ToastAlerts";
// import Swal from "sweetalert2";
// const style = {
//   position: "absolute",
//   top: "40%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   // width: 400,
//   bgcolor: "background.paper",
//   border: "1px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

// function ContactPersonModal({
//   open,
//   handleClose,
//   onAddContact,
//   values,
//   id,
//   setToggle,
// }) {
//   const [contact, setContact] = useState({});

//   useEffect(() => {
//     if (open && id) {
//       const fetchData = async () => {
//         const res = await Axios.get(`/supplier-master/contact-person/${id}`);
//         setContact(res?.data?.result);
//         console.log("cotact data => ", res.data.result);
//       };
//       fetchData();
//     } else if (open && !id) {
//       setContact({
//         name: "",
//         email: "",
//         designation: "",
//         mobile_number: "",
//       });
//     }
//   }, [id, open]);
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setContact((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleUpdate = async () => {
//     try {
//       const { data } = await Axios.post(
//         `/supplier-master/update-contact-person?id=${id}`,
//         contact
//       );

//       if (data.statusCode === 200) {
//         Swal.fire({
//           icon: "success",
//           title: data.message,
//           text: "",
//           timer: 1000,
//           showConfirmButton: false,
//         });
//         setToggle((prevState) => !prevState);
//         handleClose();
//       } else {
//         handleClose();
//         Swal.fire({
//           icon: "error",
//           title: data.message,
//           text: "",
//           timer: 1000,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       handleClose();
//       Swal.fire({
//         icon: "error",
//         title: error.response?.data?.message,
//         text: "",
//         timer: 1000,
//         showConfirmButton: false,
//       });
//     }
//   };

//   // const handleSubmit = () => {
//   //     onAddContact(contact);

//   // };

//   return (
//     <div>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style} className="lg:w-1/3 w-[20rem]">
//           <div className="flex flex-row items-center justify-between">
//             <Typography variant="h2" component="h2">
//               {" "}
//               {id ? "Update Contact Person" : "Add Contact Person"}
//             </Typography>
//             <Close
//               onClick={handleClose}
//               className="mb-4"
//               style={{ cursor: "pointer", float: "right" }}
//             />
//           </div>
//           <div className="grid grid-flow-row lg:grid-cols-2 grid-cols-1 gap-5">
//             <div className="flex flex-col items-start gap-1">
//               <Typography>Name</Typography>
//               <TextField
//                 size="small"
//                 fullWidth
//                 //   label="Name"
//                 name="name"
//                 value={contact?.name}
//                 onChange={handleChange}
//               />
//             </div>
//             <div className="flex flex-col items-start gap-1">
//               <Typography>Email</Typography>
//               <TextField
//                 size="small"
//                 fullWidth
//                 name="email"
//                 value={contact?.email}
//                 onChange={handleChange}
//               />
//             </div>
//             <div className="flex flex-col items-start gap-1">
//               <Typography>Designation</Typography>
//               <TextField
//                 size="small"
//                 fullWidth
//                 name="designation"
//                 value={contact?.designation}
//                 onChange={handleChange}
//               />
//             </div>
//             <div className="flex flex-col items-start gap-1">
//               <Typography>Mobile Number</Typography>
//               <TextField
//                 size="small"
//                 fullWidth
//                 name="mobile_number"
//                 value={contact?.mobile_number}
//                 onChange={handleChange}
//               />
//             </div>
//           </div>
//           <div className="flex items-center justify-center mt-4">
//             <Button
//               variant="contained"
//               size="small"
//               color="primary"
//               onClick={handleUpdate}
//             >
//               {id ? "Update" : "Create"}
//             </Button>
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

// export default ContactPersonModal;
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";
import { TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Axios } from "index";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const ContactPersonSchema = Yup.object().shape({
  name: Yup.string().required("Contact person name is required"),
  email: Yup.string()
    .email("Invalid email format"),
  // .required("Email is required"),
  mobile_number: Yup.number(),
  // .length(10, "Mobile number must be 10 digits")

  designation: Yup.string(),
});

function ContactPersonModal({ open, handleClose, id, setToggle, branchId }) {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile_number: "",
      designation: "",
    },
    validationSchema: ContactPersonSchema,
    onSubmit: async (values) => {
      try {
        const { data } = await Axios.post(
          `/supplier-master/update-contact-person?id=${id}`,
          values
        );

        if (data.statusCode === 200) {
          Swal.fire({
            icon: "success",
            title: data.message,
            timer: 2000,
            showConfirmButton: false,
          });
          setToggle((prevState) => !prevState);
          handleClose();
        } else {
          Swal.fire({
            icon: "error",
            title: data.message,
            timer: 2000,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        handleClose();
        Swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Error",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },
  });

  useEffect(() => {
    if (open && id) {
      const fetchData = async () => {
        const res = await Axios.get(`/supplier-master/contact-person/${id}`);
        const { name, email, mobile_number, designation } = res?.data?.result;
        formik.setValues({ name, email, mobile_number, designation });
      };
      fetchData();
    } else if (open && !id) {
      formik.resetForm();
    }
  }, [id, open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="lg:w-1/3 w-[20rem]">
          <div className="flex flex-row items-center justify-between">
            <Typography variant="h2" component="h2">
              {id ? "Update Contact Person" : "Add Contact Person"}
            </Typography>
            <Close
              onClick={handleClose}
              className="mb-4"
              style={{ cursor: "pointer", float: "right" }}
            />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-flow-row lg:grid-cols-2 grid-cols-1 gap-5">
              <div className="flex flex-col items-start gap-1">
                <Typography>Name*</Typography>
                <TextField
                  size="small"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </div>
              <div className="flex flex-col items-start gap-1">
                <Typography>Email</Typography>
                <TextField
                  size="small"
                  fullWidth
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                // error={formik.touched.email && Boolean(formik.errors.email)}
                // helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <div className="flex flex-col items-start gap-1">
                <Typography>Designation</Typography>
                <TextField
                  size="small"
                  fullWidth
                  name="designation"
                  value={formik.values.designation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                // error={
                //   formik.touched.designation &&
                //   Boolean(formik.errors.designation)
                // }
                // helperText={
                //   formik.touched.designation && formik.errors.designation
                // }
                />
              </div>
              <div className="flex flex-col items-start gap-1">
                <Typography>Mobile Number</Typography>
                <TextField
                  size="small"
                  fullWidth
                  type="number"
                  name="mobile_number"
                  value={formik.values.mobile_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                // error={
                //   formik.touched.mobile_number &&
                //   Boolean(formik.errors.mobile_number)
                // }
                // helperText={
                //   formik.touched.mobile_number && formik.errors.mobile_number
                // }
                />
              </div>
            </div>
            <div className="flex items-center justify-center mt-4">
              <Button
                variant="contained"
                size="small"
                color="primary"
                type="submit"
              >
                {id ? "Update" : "Create"}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export default ContactPersonModal;
