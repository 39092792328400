import Div from "@jumbo/shared/Div/Div";
import { Autocomplete, Grid, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { supplierBranchDropDownList, supplierDropDownList } from "app/services/apis/supplierList";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import { Box } from "@mui/material";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import FullScreenLoader from "app/components/ListingPageLoader";
import { itemNameDropDown } from "app/services/apis/addItemName";
import { currencyDropDownList } from "app/services/apis/currencyApis";
import { gstDropdownList } from "app/services/apis/gstApis";
import { itemSubCategoryListDropDown } from "app/services/apis/subCategoryApis";
import InvoiceDetailsTable from "./invoiceDetailTable";
import ItemDetailsTable from "./itemTable";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ pt: 2 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function AddLogStock() {
  const [currencyList, setCurrencyList] = useState([]);
  const [gstNameList, setGstNameList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);
  const [itemSubCategoryNameList, setItemSubCategoryNameList] = useState([]);


  const [tabValue, setTabValue] = useState(0);
  const [newTabValue, setNewTabValue] = useState(0);
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [supplierBranchList, setSupplierBranchList] = useState([]);
  const [addedItemDetailsList, setAddedItemDetailsList] = useState([]);

  const [finalData, setFinalData] = useState({
    company_details: {},
    branch_detail: {},
    no_of_workers: 0,
    exchange_rate: 0,
    shift: "",
    working_hours: "",
    total_hours: "",
    inward_date: getCurrentDate(),
    currency: null,
    invoice_no: null,
  });

  const [initialItemForm, setInitailItemForm] = useState({
    item_sr_no: addedItemDetailsList.length + 1 || 1,
    supplier_item_name: "",
    supplier_log_no: "",
    item_name: "",
    item_id: "",
    item_sub_category_name: "",
    item_sub_category_id: "",
    log_no: "",
    log_formula: "",
    invoice_length: 0,
    invoice_diameter: 0,
    invoice_cmt: 0,
    indian_cmt: 0,
    physical_length: 0,
    physical_diameter: 0,
    physical_cmt: 0,
    exchange_rate: finalData?.exchange_rate || 0,
    rate_in_currency: 0,
    rate_in_inr: 0,
    amount: 0,
    remark: "",
  });

  const [initialInvoiceData, setInitialInvoiceData] = useState({
    invoice_date: getCurrentDate(),
    total_item_amount: 0,
    transporter_details: "",
    port_of_loading: "",
    port_of_discharge: "",
    bill_of_landing: "",
    freight: 0,
    isFreightInclude: false,
    load_unload: 0,
    isLoadUnloadInclude: false,
    insurance: 0,
    isInsuranceInclude: false,
    other: 0,
    isOtherInclude: false,
    other_for_import: 0,
    gst_percentage: "",
    igst_percentage: 0,
    sgst_percentage: 0,
    cgst_percentage: 0,
    gst_value: 0,
    invoice_value_with_gst: 0,
    remark: "",
  });

  const getSupplierBranchList = async (supplierId) => {
    const supplerBranchList = await supplierBranchDropDownList(supplierId);
    if (supplerBranchList.data.success) {
      setSupplierBranchList(supplerBranchList?.data?.result);
    }
  };

  const handleSubmit = (values) => {
    if (addedItemDetailsList && addedItemDetailsList.length <= 0) {
      Swal.fire({
        text: "Add at least one item details.",
        icon: "warning",
        showCancelButton: false,
      });
    } else {
      setTabValue(newTabValue);
      // setInitailItemForm({
      //   item_sr_no: addedItemDetailsList.length + 1 || 1,
      //   supplier_item_name: "",
      //   supplier_log_no: "",
      //   item_name: "",
      //   item_id: "",
      //   item_sub_category_name: "",
      //   item_sub_category_id: "",
      //   log_no: "",
      //   log_formula: "",
      //   invoice_length: 0,
      //   invoice_diameter: 0,
      //   invoice_cmt: 0,
      //   indian_cmt: 0,
      //   physical_length: 0,
      //   physical_diameter: 0,
      //   physical_cmt: 0,
      //   exchange_rate: finalData?.exchange_rate || 0,
      //   rate_in_currency: 0,
      //   rate_in_inr: 0,
      //   amount: 0,
      //   remark: "",
      // })
    }
  };

  const stock = {
    inward_date: getCurrentDate(),
    invoice_no: "",
    currency: "",
    exchange_rate: 0,
    supplier_name: "",
    branch_name: "",
    no_of_workers: 0,
    shift: "",
    working_hours: "",
    total_hours: "",
  };

  const validationSchema = yup.object({
    inward_date: yup.date().required("Date of Inward is required"),
    currency: yup.string().required("Currency is required").nullable(),
    exchange_rate: yup
      .number()
      // .positive()
      // .moreThan(0, "Must be greater than 0")
      // .required("Required")
      .when((currency, schema) =>
        finalData?.currency?.currency_name !== "INR"
          ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.")
          : schema.notRequired()
      ),
    invoice_no: yup.string().required("Invoice No. is required"),
    supplier_name: yup.string().required("Supplier Name is required"),
    branch_name: yup.string().required("Branch Name is required"),
    no_of_workers: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    shift: yup.string().required("Required"),
    working_hours: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
  });

  const handleExpenseChange = (name, exchangeValue) => {
    if (addedItemDetailsList?.length > 0) {
      let updatedAddedItemDetailsList = [...addedItemDetailsList];
      if (name === "expense") {
        for (let i = 0; i < updatedAddedItemDetailsList.length; i++) {
          let updateRateInINR = Number((Number(updatedAddedItemDetailsList[i]?.rate_in_currency) * Number(exchangeValue))?.toFixed(2));
          let updateAmount = Number((Number(updatedAddedItemDetailsList[i]?.physical_cmt) * updateRateInINR)?.toFixed(2));

          updatedAddedItemDetailsList[i].exchange_rate = Number(exchangeValue);
          updatedAddedItemDetailsList[i].rate_in_inr = Number(updateRateInINR);
          updatedAddedItemDetailsList[i].amount = Number(updateAmount);
        }

      } else if (name === "currency") {
        for (let i = 0; i < updatedAddedItemDetailsList.length; i++) {
          // let updateRateInINR = Number(updatedAddedItemDetailsList[i]?.rate_in_currency) * Number(exchangeValue);
          // let updateAmount = Number(updatedAddedItemDetailsList[i]?.physical_cmt) * updateRateInINR;

          updatedAddedItemDetailsList[i].exchange_rate = 0;
          updatedAddedItemDetailsList[i].rate_in_currency = 0;
          updatedAddedItemDetailsList[i].rate_in_inr = 0;
          updatedAddedItemDetailsList[i].amount = 0;
        }
      }
      setAddedItemDetailsList(updatedAddedItemDetailsList);
    }
    // if (name === "expense") {
    //   const value = { ...initialItemForm };
    //   let updateRateInINR = Number((Number(value?.rate_in_currency) * Number(exchangeValue))?.toFixed(2));
    //   let updateAmount = Number((Number(value?.physical_cmt) * updateRateInINR)?.toFixed(2));

    //   value.exchange_rate = Number(exchangeValue);
    //   value.rate_in_inr = Number(updateRateInINR);
    //   value.amount = Number(updateAmount);

    //   setInitailItemForm({ ...value })

    // } else if (name === "currency") {
    //   const value = { ...initialItemForm };
    //   value.exchange_rate = 0;
    //   value.rate_in_currency = 0;
    //   value.rate_in_inr = 0;
    //   value.amount = 0;

    //   setInitailItemForm({ ...value })
    // }
  };

  const calculateTotalHours = function (no_of_workers = 0, working_hours = 0, setFieldValue) {
    const worker = Number(no_of_workers);
    const workingHours = Number(working_hours);
    const totalHours = Number((worker * workingHours)?.toFixed(2))
    setFieldValue("total_hours", totalHours)
    setFinalData({
      ...finalData,
      no_of_workers: worker,
      working_hours: workingHours,
      total_hours: totalHours,
    });
  }

  useEffect(() => {
    (async () => {
      try {
        supplierDropDownList("LOG")
          .then((response) => {
            if (response.data.success) {
              setSupplierNameList(response.data.result);
            }
          })
          .catch((error) => console.error("Error fetching supplier list:", error));

        itemNameDropDown("LOG")
          .then((response) => {
            setItemNameList(response?.data?.result);
          })
          .catch((error) => console.error("Error fetching item names:", error));

        itemSubCategoryListDropDown("")
          .then((response) => {
            setItemSubCategoryNameList(response?.data?.result);
          })
          .catch((error) => console.error("Error fetching item subcategories:", error));

        currencyDropDownList()
          .then((response) => {
            setCurrencyList(response?.data?.result);
          })
          .catch((error) => console.error("Error fetching currencies:", error));

        gstDropdownList()
          .then((response) => {
            setGstNameList(response?.data?.result);
          })
          .catch((error) => console.error("Error fetching GST list:", error));

      } catch (error) { }
    })();
    return () => { };
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Div>
        <Formik
          validateOnChange={false}
          initialValues={stock}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, handleBlur, setFieldError, setFieldTouched, touched, handleSubmit, validateForm }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4, width: "100%" }}>
                <Grid
                  sx={{
                    display: tabValue === 1 ? "none" : "",
                  }}
                  container
                  rowSpacing={2}
                  columnSpacing={3}
                >
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Inward Date*
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                        defaultValue={values?.inward_date !== "" ? dayjs(values?.inward_date) : null}
                        onChange={(newValue) => {
                          setFinalData({
                            ...finalData,
                            inward_date: newValue,
                          });
                          setFieldValue("inward_date", newValue);
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage name="inward_date" component="div" style={{ color: "red" }} />
                      </Div>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Supplier Name*
                    </Typography>
                    {/* <Select
                      autoSelect
                      autoHighlight
                      size="small"
                      sx={{ width: "100%" }}
                      name="supplier_name"
                      onChange={(e, options) => {
                        getSupplierBranchList(options?.props?.action?._id);
                        setFieldValue("supplier_name", options?.props?.action?._id);
                        setFinalData({
                          ...finalData,
                          company_details: options?.props?.action || {},
                        });
                      }}
                      value={values?.supplier_name || ''}
                    >
                      {supplierNameList?.map((item) => {
                        return (
                          <MenuItem value={item._id} action={item}>
                            {item?.supplier_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage name="supplier_name" component={"div"} style={{ color: "red" }}></ErrorMessage> */}
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={supplierNameList}
                      name="supplier_name"
                      value={values?.supplier_name || ''}
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.supplier_name;
                        } else {
                          return option;
                        }
                      }}
                      onBlur={() => setFieldTouched("supplier_name", true)}

                      onChange={(e, selectedData) => {
                        if (selectedData) {
                          getSupplierBranchList(selectedData?._id);
                          setFieldValue("supplier_name", selectedData?.supplier_name);
                          setFieldValue("branch_name", "");
                          setFinalData({
                            ...finalData,
                            company_details: selectedData || {},
                          });
                        } else {
                          setFieldValue("supplier_name", "");
                          setFieldValue("branch_name", "");
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.supplier_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.supplier_name && Boolean(errors.supplier_name)} // Show error if touched and there's an error
                          helperText={touched.supplier_name && errors.supplier_name}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Branch Name*
                    </Typography>
                    {/* <Select
                      autoSelect
                      autoHighlight
                      size="small"
                      sx={{ width: "100%" }}
                      name="branch_name"
                      onChange={(e, options) => {
                        setFieldValue("branch_name", options?.props?.action?._id);
                        setBranchDetails({ ...options?.props?.action });
                        setFinalData({
                          ...finalData,
                          branch_detail: options?.props?.action || {},
                        });
                      }}
                      value={values?.branch_name || ''}
                    >
                      {supplierBranchList?.map((item) => {
                        return (
                          <MenuItem value={item._id} action={item}>
                            {item?.branch_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage name="branch_name" component={"div"} style={{ color: "red" }}></ErrorMessage> */}
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={supplierBranchList}
                      name="branch_name"
                      value={(supplierBranchList && supplierBranchList.find((sup) => sup?.branch_name === values?.branch_name)) || null}
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.branch_name;
                        } else {
                          return option;
                        }
                      }}
                      onBlur={() => setFieldTouched("branch_name", true)}
                      onChange={(e, selectedData) => {
                        setFieldValue("branch_name", selectedData?.branch_name);
                        setFinalData({
                          ...finalData,
                          branch_detail: selectedData || {},
                        });
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.branch_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.branch_name && Boolean(errors.branch_name)} // Show error if touched and there's an error
                          helperText={touched.branch_name && errors.branch_name}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Invoice No.*
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="invoice_no"
                      onChange={(e) => {
                        setFieldValue("invoice_no", e.target.value);
                      }}
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          invoice_no: e.target.value,
                        });
                      }}
                      value={values?.invoice_no}
                      status={true}
                      error={touched?.invoice_no && errors?.invoice_no}
                      helperText={touched?.invoice_no && errors?.invoice_no}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Currency*
                    </Typography>
                    <Autocomplete
                      size="small"
                      // disabled={values?.supplier_details?.country === "India"}
                      options={currencyList}
                      name="currency"
                      // value={values?.currency}
                      getOptionLabel={(option) => option?.currency_name}
                      onBlur={() => setFieldTouched("currency", true)}
                      onChange={(e, selectedData) => {
                        setFinalData({ ...finalData, currency: selectedData, exchange_rate: 0 });
                        setFieldValue("currency", selectedData?._id);
                        setFieldValue("exchange_rate", 0);
                        handleExpenseChange("currency", e.target.value);
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.currency_name}
                        </Box>
                      )}
                      autoSelect
                      autoHighlight
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.currency && Boolean(errors.currency)} // Show error if touched and there's an error
                          helperText={touched.currency && errors.currency}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Exchange Rate*
                    </Typography>
                    <TextField
                      disabled={finalData?.currency?.currency_name !== "INR" ? false : true}
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="exchange_rate"
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          exchange_rate: e.target.value,
                        });
                        handleExpenseChange("expense", e.target.value);
                      }}
                      onChange={(e) => {
                        setFieldValue("exchange_rate", e.target.value);
                      }}
                      value={values?.exchange_rate || ""}
                      status={true}
                      error={touched?.exchange_rate && errors?.exchange_rate}
                      helperText={touched?.exchange_rate && errors?.exchange_rate}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Shift*
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="shift"
                      onChange={(e) => {
                        setFieldValue("shift", e.target.value);
                      }}
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({ ...finalData, shift: e.target.value });
                      }}
                      value={values?.shift}
                      status={true}
                      error={touched?.shift && errors?.shift}
                      helperText={touched?.shift && errors?.shift}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      Workers*
                    </Typography>
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="no_of_workers"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // setFinalData({
                        //   ...finalData,
                        //   no_of_workers: e.target.value,
                        // });
                        calculateTotalHours(e.target.value, values?.working_hours, setFieldValue)
                      }}
                      onChange={(e) => {
                        setFieldValue("no_of_workers", e.target.value);
                      }}
                      value={values?.no_of_workers || ""}
                      status={true}
                      error={touched?.no_of_workers && errors?.no_of_workers}
                      helperText={touched?.no_of_workers && errors?.no_of_workers}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      No. Of Working Hours*
                    </Typography>
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="working_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // setFinalData({
                        //   ...finalData,
                        //   working_hours: e.target.value,
                        // });
                        calculateTotalHours(values?.no_of_workers, e.target.value, setFieldValue)
                      }}
                      onChange={(e) => {
                        setFieldValue("working_hours", e.target.value);
                      }}
                      value={values?.working_hours}
                      status={true}
                      error={touched?.working_hours && errors?.working_hours}
                      helperText={touched?.working_hours && errors?.working_hours}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">
                      No Of Total Hours*
                    </Typography>
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      disabled
                      name="total_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      value={values?.total_hours}
                      status={true}
                      error={touched?.total_hours && errors?.total_hours}
                      helperText={touched?.total_hours && errors?.total_hours}
                    />
                  </Grid>
                </Grid>
              </Div>
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  handleSubmit();
                  setNewTabValue(newValue);
                }}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Item Details" {...a11yProps(0)} />
                <Tab label="Invoice Details" {...a11yProps(4)} />
              </Tabs>
            </Form>
          )}
        </Formik>
        <CustomTabPanel value={tabValue} index={0}>
          {true ? (
            <ItemDetailsTable
              itemSubCategoryNameList={itemSubCategoryNameList}
              itemNameList={itemNameList}
              setAddedItemDetailsList={setAddedItemDetailsList}
              addedItemDetailsList={addedItemDetailsList}
              finalData={finalData}
              initialItemForm={initialItemForm}
              setInitailItemForm={setInitailItemForm}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          {true ? (
            <InvoiceDetailsTable
              setAddedItemDetailsList={setAddedItemDetailsList}
              addedItemDetailsList={addedItemDetailsList}
              // setInvoiceDetails={setInvoiceDetails}
              finalData={finalData}
              tabValue={tabValue}
              gstNameList={gstNameList}
              initialInvoiceData={initialInvoiceData}
              setInitialInvoiceData={setInitialInvoiceData}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
      </Div>
    </Div>
  );
}

export default HeaderTitleHoc(AddLogStock, "Add Log Stock");
