import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ClearIcon from "@mui/icons-material/Clear";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";

import { useSelector } from "react-redux";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  // width: "100%",
  borderRight: "1px solid #7352C7",
};

const EditItemDetailsTable = ({
  setAddedItemDetailsList,
  addedItemDetailsList,
  machineList,
  finalData,
  physicalLength,
  actualLogData,
  isCalculated,
  setIsCalculated,
  handleCalculate,
  totalPhysicalCmt
}) => {
  const { user_type } = useSelector(
    (state) => state?.userReducer?.user?.[0]
  );
  const initialData = {
    item_sr_no: 0,
    log_inventory_item_id: actualLogData?.log_inventory_item_id,
    issue_for_flitching_id: actualLogData?._id,
    crosscut_done_id: actualLogData?.crosscut_done_id,
    sqm_factor: 0,
    machine_name: "",
    machine_id: "",
    log_no: "",
    flitch_code: "",
    log_no_code: "",
    flitch_formula: "",
    length: 0,
    width1: 0,
    width2: 0,
    width3: 0,
    height: 0,
    flitch_cmt: 0,
    per_cmt_cost: 0,
    cost_amount: 0,
    required_hours: 0,
    remarks: "",
  };

  const validationSchema = yup.object({
    machine_name: yup.string().required("Machine name is Required"),
    length: yup
      .number()
      .positive()
      .moreThan(0, "Must be greater than 0")
      .required("Required")
      .max(Number(actualLogData?.available_quantity?.length), `Can't be greater than ${actualLogData?.available_quantity?.length} `),
    width1: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    width2: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    width3: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    height: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    flitch_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
  });
  const [data, setData] = useState(initialData);
  const formula = actualLogData?.log_formula;

  const handleSubmit = async (values) => {
    console.log("all values => ", values);
  };

  //old method
  // const removeRow = (value, index) => {
  //   const updateData = [...addedItemDetailsList];
  //   updateData.splice(index, 1);
  //   setAddedItemDetailsList(updateData);
  //   // handleCalculate();
  // };
  const removeRow = (value, index) => {
    const updateData = [...addedItemDetailsList];

    updateData.splice(index, 1);

    const updatedList = updateData.map((item, idx) => {
      const newSrNo = idx + 1;

      const newLogNo = `${actualLogData?.log_no}${newSrNo}`;

      const newFlitchCode = idx + 1;

      return {
        ...item,
        item_sr_no: newSrNo,
        log_no_code: newLogNo,
        flitch_code: newFlitchCode,
      };
    });

    setAddedItemDetailsList(updatedList);
  };
  const updateRow = (values, index) => {
    const updateData = [...addedItemDetailsList];
    updateData.splice(index, 1, values);
    let totalFlitchCmt = 0;
    updateData?.forEach((item) => {
      totalFlitchCmt += Number(item?.flitch_cmt);
    });
    if (totalFlitchCmt > totalPhysicalCmt) {
      // setSubmitting(false);
      return Swal.fire({
        timer: 5000,
        icon: "error",
        text: `Total Flitch (${totalFlitchCmt}) Cmt cannot be greater than Actual (${totalPhysicalCmt}) Cmt.`,
        position: "center",
      });
    }


    setAddedItemDetailsList(updateData);
    setData(null);
    setIsCalculated(false);
    // handleCalculate();
  };

  // const checkLength = (value, sr_no, setFieldValue) => {
  //   const filteredLength = addedItemDetailsList?.filter((item) => item?.item_sr_no !== sr_no);
  //   console.log("filtered length => ", filteredLength);
  //   // const totalLength = addedItemDetailsList.reduce(
  //   //   (acc, item) => Number(acc) + Number(item?.length),
  //   //   0
  //   // );
  //   const totalLength = filteredLength.reduce((acc, item) => Number(acc) + Number(item?.length), 0);

  //   console.log("total length ", totalLength);
  //   if (totalLength + Number(value) > actualLogData?.available_quantity?.length) {
  //     setFieldValue("length", 0);
  //     return Swal.fire({
  //       timer: 3000,
  //       icon: "error",
  //       title: "Item Length Should be smaller than physical length...",
  //       position: "center",
  //       // background: theme.palette.background.paper,
  //     });
  //   }
  // };

  const calculateDIA = (width, height) => {
    const widthNum = Number(width);
    const heightNum = Number(height);
    const smaller = Math.min(widthNum, heightNum);
    const larger = Math.max(widthNum, heightNum);

    const DIA = (smaller * 2 + larger) / 2;

    return DIA;
  };
  const handleCalculateFlitchCmt = (width1, width2, width3, height, length, formula, setFieldValue, values) => {
    let pie = 22 / 7;
    let maxWidth = Math.max(Number(width1), Number(width2), Number(width3));

    if (formula === "TQF") {
      let dia = Number(maxWidth) + Number(height);

      console.log(dia, "232");
      let flitch_cmt = Number(((pie * dia * dia * Number(length)) / 16)?.toFixed(3));
      setFieldValue("flitch_cmt", flitch_cmt)
      let amount = Number((Number(flitch_cmt) * Number(values?.rate_in_inr)).toFixed(2));
      setFieldValue("amount", amount);
    } else if (formula === "FHF") {
      const dia = calculateDIA(maxWidth, height);

      let flitch_cmt = Number(((pie * dia * dia * Number(length)) / 8)?.toFixed(3));
      setFieldValue("flitch_cmt", flitch_cmt);
      let amount = Number((Number(flitch_cmt) * Number(values?.rate_in_inr))?.toFixed(2));
      setFieldValue("amount", amount);
    } else {
      let dia = (Number(maxWidth) + Number(height)) / 2;
      let flitch_cmt = Number(((pie * dia * dia * Number(length)) / 4)?.toFixed(3));
      setFieldValue("flitch_cmt", flitch_cmt);
      let amount = Number((Number(flitch_cmt) * Number(values?.rate_in_inr))?.toFixed(2));
      setFieldValue("amount", amount);
    }
  };

  return (
    <div>
      <Formik
        validateOnChange={false}
        initialValues={data}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ validateForm, setFieldValue, values, setFieldTouched, errors, initialValues, handleBlur, touched }) => (
          <Form noValidate autoComplete="off">
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow
                    sx={{
                      bgcolor: "#7352C7",
                      color: "white",
                    }}
                  >
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "100px" }}>Log No.</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "100px" }}>Flitch Code</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "100px" }}>Log No.Code</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Machine Name</TableCell>

                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch Formula</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Length</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width1</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width2</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width3</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Height</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch CMT</TableCell>
                    {user_type == "ADMIN" && (

                      <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Cost Amount</TableCell>
                    )}
                    {user_type == "ADMIN" && (

                      <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Per CMT Amount</TableCell>
                    )}
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                        position: "sticky",
                        right: 0,
                        zIndex: 1,
                        bgcolor: "#7352C7",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                {addedItemDetailsList && addedItemDetailsList.length ? (
                  <TableBody>
                    {addedItemDetailsList &&
                      addedItemDetailsList?.map((row, i) =>
                        values?.item_sr_no !== row?.item_sr_no ? (
                          <TableRow key={i}>
                            <TableCell className="" sx={{ ...tableBodyCellStyle }}>
                              {row?.item_sr_no}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.log_no}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.flitch_code}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.log_no_code}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.machine_name}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.flitch_formula}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.length}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.width1}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.width2}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.width3}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row?.height}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.flitch_cmt}</TableCell>
                            {user_type == "ADMIN" && (

                              <TableCell sx={{ ...tableBodyCellStyle }}>{row?.cost_amount}</TableCell>
                            )}
                            {user_type == "ADMIN" && (

                              <TableCell sx={{ ...tableBodyCellStyle }}>{row?.per_cmt_cost}</TableCell>
                            )}

                            <TableCell sx={{ ...tableBodyCellStyle }}>{row.remarks}</TableCell>

                            <TableCell
                              sx={{
                                // textAlign: "center",
                                minWidth: "100%",
                                verticalAlign: "middle",
                                color: "white",
                                px: 1,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                bgcolor: "white",
                                display: "flex",
                              }}
                            >
                              <IconButton color="warning" size="small" onClick={() => setData(row)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton color="error" size="small" onClick={() => removeRow(row, i)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell sx={{ ...tableBodyCellStyle }}>{values?.item_sr_no}</TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                disabled
                                value={values?.log_no}
                                sx={{ width: "100%" }}
                                name="log_no"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("log_no", e.target.value)}
                                error={touched?.log_no && errors?.log_no}
                                helperText={touched?.log_no && errors?.log_no}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                disabled
                                value={values?.flitch_code}
                                sx={{ width: "100%" }}
                                name="flitch_code"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("flitch_code", e.target.value)}
                                error={touched?.flitch_code && errors?.flitch_code}
                                helperText={touched?.flitch_code && errors?.flitch_code}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                disabled
                                value={values?.log_no_code}
                                sx={{ width: "100%" }}
                                name="log_no_code"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("log_no_code", e.target.value)}
                                error={touched?.log_no_code && errors?.log_no_code}
                                helperText={touched?.log_no_code && errors?.log_no_code}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                options={machineList}
                                name="machine_name"
                                value={values?.machine_name}
                                isOptionEqualToValue={(option, value) => option.machine_name === value || option === value}
                                getOptionLabel={(option) => {
                                  if (option instanceof Object && !Array.isArray(option)) {
                                    return option.machine_name;
                                  } else {
                                    return option;
                                  }
                                }}
                                onBlur={() => setFieldTouched("machine_name", true)}
                                onChange={(e, selectedData) => {
                                  setFieldValue("machine_name", selectedData?.machine_name || "");
                                  setFieldValue("machine_id", selectedData?._id || "");
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.machine_name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.machine_name && Boolean(errors.machine_name)} // Show error if touched and there's an error
                                    helperText={touched.machine_name && errors.machine_name}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                value={values?.flitch_formula}
                                options={["TQF", "FHF", "BF"]}
                                name="flitch_formula"
                                getOptionLabel={(option) => option}
                                onBlur={() => {
                                  setFieldTouched("flitch_formula", true);
                                }}
                                onChange={(e, formula) => {
                                  setFieldValue("flitch_formula", formula || "");
                                  handleCalculateFlitchCmt(
                                    values?.width1,
                                    values?.width2,
                                    values?.width3,
                                    values?.height,
                                    values?.length,
                                    formula,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={touched.flitch_formula && Boolean(errors.flitch_formula)}
                                    helperText={touched.flitch_formula && errors.flitch_formula}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>

                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // disabled
                                type="number"
                                size="small"
                                value={values?.length}
                                sx={{ width: "100%" }}
                                name="length"
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleCalculateFlitchCmt(
                                    values?.width1,
                                    values?.width2,
                                    values?.width3,
                                    values?.height,
                                    e.target.value,
                                    values?.flitch_formula,
                                    setFieldValue,
                                    values
                                  );
                                  // checkLength(e.target.value, values?.item_sr_no, setFieldValue);
                                  setIsCalculated(false);
                                }}
                                onChange={(e) => setFieldValue("length", e.target.value)}
                                error={touched?.length && errors?.length}
                                helperText={touched?.length && errors?.length}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                // disabled
                                size="small"
                                value={values?.width1}
                                sx={{ width: "100%" }}
                                name="width1"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculateFlitchCmt(
                                    e.target.value,
                                    values?.width2,
                                    values?.width3,
                                    values?.height,
                                    values?.length,
                                    values?.flitch_formula,
                                    setFieldValue,
                                    values
                                  );
                                  setIsCalculated(false);
                                }}
                                onChange={(e) => setFieldValue("width1", e.target.value)}
                                error={touched?.width1 && errors?.width1}
                                helperText={touched?.width1 && errors?.width1}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                // disabled
                                size="small"
                                value={values?.width2}
                                sx={{ width: "100%" }}
                                name="width2"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculateFlitchCmt(
                                    values?.width1,
                                    e.target.value,
                                    values?.width3,
                                    values?.height,
                                    values?.length,
                                    values?.flitch_formula,
                                    setFieldValue,
                                    values
                                  );
                                  setIsCalculated(false);
                                }}
                                onChange={(e) => setFieldValue("width2", e.target.value)}
                                error={touched?.width2 && errors?.width2}
                                helperText={touched?.width2 && errors?.width2}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                // disabled
                                size="small"
                                value={values?.width3}
                                sx={{ width: "100%" }}
                                name="width3"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculateFlitchCmt(
                                    values?.width1,
                                    values?.width2,
                                    e.target.value,
                                    values?.height,
                                    values?.length,
                                    values?.flitch_formula,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                onChange={(e) => setFieldValue("width3", e.target.value)}
                                error={touched?.width3 && errors?.width3}
                                helperText={touched?.width3 && errors?.width3}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                // disabled
                                size="small"
                                value={values?.height}
                                sx={{ width: "100%" }}
                                name="height"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculateFlitchCmt(
                                    values?.width1,
                                    values?.width2,
                                    values?.width3,
                                    e.target.value,
                                    values?.length,
                                    values?.flitch_formula,
                                    setFieldValue,
                                    values
                                  );
                                  setIsCalculated(false);
                                }}
                                onChange={(e) => setFieldValue("height", e.target.value)}
                                error={touched?.height && errors?.height}
                                helperText={touched?.height && errors?.height}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                size="small"
                                disabled
                                value={values?.flitch_cmt}
                                sx={{ width: "100%" }}
                                name="flitch_cmt"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("flitch_cmt", e.target.value)}
                                error={touched?.flitch_cmt && errors?.flitch_cmt}
                                helperText={touched?.flitch_cmt && errors?.flitch_cmt}
                              />
                            </TableCell>
                            {user_type == "ADMIN" && (

                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                <TextField
                                  disabled
                                  type="number"
                                  size="small"
                                  value={values?.cost_amount}
                                  sx={{ width: "100%" }}
                                  name="cost_amount"
                                  onBlur={async (e) => {
                                    handleBlur(e);
                                  }}
                                  onChange={(e) => setFieldValue("cost_amount", e.target.value)}
                                  error={touched?.cost_amount && errors?.cost_amount}
                                  helperText={touched?.cost_amount && errors?.cost_amount}
                                />
                              </TableCell>
                            )}
                            {user_type == "ADMIN" && (

                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                <TextField
                                  type="number"
                                  size="small"
                                  disabled
                                  value={values?.per_cmt_cost}
                                  sx={{ width: "100%" }}
                                  name="per_cmt_cost"
                                  onBlur={async (e) => {
                                    handleBlur(e);
                                  }}
                                  onChange={(e) => setFieldValue("per_cmt_cost", e.target.value)}
                                  error={touched?.per_cmt_cost && errors?.per_cmt_cost}
                                  helperText={touched?.per_cmt_cost && errors?.per_cmt_cost}
                                />
                              </TableCell>
                            )}

                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.remarks}
                                sx={{ width: "100%" }}
                                name="remarks"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => setFieldValue("remarks", e.target.value)}
                                error={touched?.remarks && errors?.remarks}
                                helperText={touched?.remarks && errors?.remarks}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                // textAlign: "center",
                                minWidth: "100%",
                                verticalAlign: "middle",
                                color: "white",
                                px: 1,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                bgcolor: "white",
                                display: "flex",
                              }}
                            >
                              <IconButton
                                color="success"
                                size="small"
                                onClick={async () => {
                                  // Manually validate the form
                                  const formErrors = await validateForm();
                                  console.log("Validation errors:", formErrors); // Handle errors if needed
                                  // Check if there are no validation errors
                                  if (Object.keys(formErrors).length === 0) {
                                    updateRow(values, i); // Call updateRow with values and index
                                  } else {
                                    console.log("Validation errors:", formErrors); // Handle errors if needed
                                  }
                                }}
                              >
                                <TaskAltIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => {
                                  setData(initialData);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        Data Not Found !!
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditItemDetailsTable;
