import React from 'react'
import Listing from './Listing'
import { Box } from '@mui/material'
import HeaderTitleHoc from 'app/components/HeaderTitleHoc'

const FleeceExpense = () => {
  return (
    <Box sx={{mt:-4}}>
        <Listing/>
    </Box>
  )
}

export default HeaderTitleHoc(FleeceExpense,"Fleece Paper Expenses")