import axios from "axios";

export const addCut = async (details) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_URL}/cut-master/add-cut-master`,
      details,
      config
    );
    // console.log(data);
    return data;
  } catch (error) {
    // console.log(error);
    return error.response;
  }
};

export const updateCut = async (details, id) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_URL}/cut-master/update-cut-master?id=${id}`,
      details,
      config
    );
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const dropdownCutName = async () => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.get(`${process.env.REACT_APP_URL}/cut-master/dropdown-cut-master`,config);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

