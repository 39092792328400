import React, { useEffect } from 'react';
import {config} from "./config/main";
import {APP_ACTIONS} from "./utils/constants/appActions";
import {AppContext} from "./AppContext";
import { socket } from 'index';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { veneerBulkUploadData } from './redux/actions/Inventory/VeneerInventory/veneerDispatch';

function init(initialValue) {
    return {
        customizerVisibility: initialValue.customizerVisibility,
        containerStyle: initialValue.containerStyle,
        rebuildRoutes: true,
    }
}

function appReducer(state, action) {
    switch(action.type) {
        case APP_ACTIONS.SET_CUSTOMIZER_VISIBILITY:
            return {
                ...state,
                customizerVisibility: action.payload,
            };

        case APP_ACTIONS.SET_CONTAINER_STYLE:
            return {
                ...state,
                containerStyle: action.payload,
            };

        case APP_ACTIONS.SET_APP:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
}

const AppProvider = ({children}) => {
    const [app, setApp] = React.useReducer(appReducer, {
        customizerVisibility: false,
        containerStyle: config.containerStyle,
    }, init);

    const setCustomizerVisibility = React.useCallback((value) => {
        setApp({type: APP_ACTIONS.SET_CUSTOMIZER_VISIBILITY, payload: value});
    }, [setApp]);

    const setContainerStyle = React.useCallback((containerStyle) => {
        setApp({type: APP_ACTIONS.SET_CONTAINER_STYLE, payload: containerStyle});
    }, [setApp]);

    const setAppState = React.useCallback((stateObject) => {
        setApp({type: APP_ACTIONS.SET_APP, payload: stateObject});
    }, [setApp]);

    const rebuildRoutes = React.useCallback((value) => {

    }, [setApp]);

    const contextValue = React.useMemo(() => ({
        ...app,
        setCustomizerVisibility,
        setContainerStyle,
        setAppState,
    }), [app]);


    const dispatch = useDispatch();

    React.useEffect(() => {
        // Listener for progress updates from the server
        const handleProgress = (data) => {
            dispatch(veneerBulkUploadData(data));

            if (data?.error?.status) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: data.error.message,
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                        container: "popupImportant",
                    },
                });
                // window.location.reload();
                // Consider whether you want to keep listening for updates after an error
            }

            if (data?.success?.status) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: data.success.message,
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                        container: "popupImportant",
                    },
                });
                // You might want to stop receiving updates after success, if appropriate
                // socket.off('progress'); // Uncomment if you want to stop listening after success
            }
        };

        // Register the listener
        socket.on('progress', handleProgress);

        // Cleanup function to remove the listener on unmount
        return () => {
            socket.off('progress', handleProgress);
        };
    }, [dispatch, socket]);

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};


export default AppProvider;