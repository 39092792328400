import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import FormTextField1 from "app/components/InputField/FormTextField1";
import { addSupplier, addSupplierAndBranch } from "app/services/apis/addSupplier";
import { codeList, outerDiv1 } from "app/utils/constants/dropdowns";
import { supplierTypes } from "app/utils/constants/supplierTypes";
import { City, Country, State } from "country-state-city";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import { addSupplierBranch } from "app/services/apis/addSupplierBranch";
import QuestionsModal from "app/components/Modal/Modal";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";

function AddSupplier() {
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();
  const [editValues, setEditValues] = useState({});
  // const [supplierSchema, setSupplierSchema] = useState({
  //   supplier_name: "",
  //   supplier_type: ""
  // })
  const { pathname } = useLocation();
  const { state } = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedContactIndex, setselectedContactIndex] = useState(null);
  const supplier = {
    supplier_name: "",
    supplier_type: [],
    branch_name: "",
    gst_number: "",
    address: "",
    state: "",
    country: "",
    city: "",
    pincode: "",
    web_url: "",
    contactPersons: [],
  };

  const onUserSave = async (values) => {
    const body = { ...values };

    if (body?.contactPersons?.length === 0) {
      return Swal.fire({
        icon: "warning",
        title: "At least one contact person is required",
        text: "",
        timer: 1000,
        showConfirmButton: false,
      });
    }

    const supplierData = {
      supplier_name: body.supplier_name,
      // supplier_type: body.supplier_type,
      supplier_type: Array.isArray(body.supplier_type) ? body.supplier_type : [body.supplier_type],
    };
    const branchData = {
      branch_name: body.branch_name,
      address: body.address,
      state: body.state,
      country: body.country,
      city: body.city,
      pincode: body.pincode,
      gst_number: body.gst_number,
      web_url: body.web_url,
      is_main_branch: true,
      contact_person: body.contactPersons,
    };

    setSubmitting(true);
    try {
      const data = await addSupplierAndBranch(supplierData, branchData);
      console.log(data?.data?.message)

      if (data?.data?.statusCode === 200) {
        setSubmitting(false)
        Swal.fire({
          icon: "success",
          // title: data?.data?.message,
          text: data?.data?.message,
        });
        navigate("/master/supplier")
      }
    } catch (error) {
      setSubmitting(false);
      console.error("Error saving supplier data => ", error.message);
      Swal.fire({
        icon: "error",
        title: "",
        text: error.response?.data?.message || error.message || "An unexpected error occurred",
      });
    }
  };
  const validationSchema = yup.object({
    supplier_name: yup.string().required("Supplier Name is required"),
    // supplier_type: yup.string().required("Supplier Type is required"),
    supplier_type: yup.array().of(yup.string().required("Supplier Type is required")).min(1, "At least one Supplier Type is required"),
    branch_name: yup.string().required("Branch Name is required"),
    address: yup.string().required("Address is required"),
    state: yup.string().required("State is required"),
    country: yup
      .string("Enter Country")
      .required("Country is required")
      .test("supplier_country-not-select", "Please select a valid Country", (value) => value !== "Select"),
    city: yup.string().required("City is required"),
    pincode: yup
      .string("Enter Pincode")
      // .matches(/^\d{6}$/, "Invalid PIN code. Please enter a valid 6-digit PIN code.")
      .required("Pincode is required"),
    // gst_number: yup
    //   .string()
    //   // .matches(
    //   //   /^[0-9]{15}$/,
    //   //   "Invalid GST Number. Please enter a valid 15-digit GST number."
    //   // )
    //   .length(15)
    //   .required("GST Number is required"),
    // gst_number: yup
    //   .string()
    //   .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, "Invalid GST Number")
    //   .length(15)
    //   .when("country", {
    //     is: "India",
    //     then: yup.string().required("GST Number is required"),
    //     otherwise: yup.string().notRequired()
    //   }),
    gst_number: yup
      .string()
      .when("country", {
        is: "India", // Apply validation only when the country is 'India'
        then: yup
          .string()
          .matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
            "Invalid GST Number"
          )
          .length(15, "GST Number must be exactly 15 characters")
          .required("GST Number is required"),
        otherwise: yup.string().notRequired() // Not required for other countries
      }),

    web_url: yup.string(),
    // .url("Enter a valid URL")
    // .required("Web URL is required"),
    contactPersons: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Contact Person Name is required"),
        email: yup.string().email("Enter a valid Email"),
        mobile_number: yup.string().matches(/^\d+$/, "Phone must be a number"),
        designation: yup.string(),
      })
    ),
  });

  const filterStates = (countryIsoCode) => {
    // Use your logic to filter states based on the selected country.
    const filteredStates = State.getAllStates().filter((state) => state.countryCode === countryIsoCode);
    setStateList(filteredStates);
  };

  const filterCities = (stateIsoCode, countryCode) => {
    // Use your logic to filter cities based on the selected state.
    const filteredCities = City.getAllCities().filter((city) => city.stateCode === stateIsoCode && city.countryCode === countryCode);
    setCityList(filteredCities);
  };

  useEffect(() => {
    if (state) {
      const country = Country.getAllCountries().filter((country) => country.name === state.country);
      const stateList = State.getAllStates().filter((country) => country.name === state.state);
      filterStates(country[0]?.isoCode);
      filterCities(stateList[0]?.isoCode, country[0]?.isoCode);
    }
  }, []);

  const handleOpenModal = (index) => {
    setselectedContactIndex(index);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  // const handleAddContact = (contact, setFieldValue, values) => {
  //   // setContacts(prev => [...prev, contact]);

  //   setFieldValue("contactPersons", [...values.contactPersons, contact]);
  // };

  const [menuState, setMenuState] = useState({
    anchorEl: null,
    currentEventId: null,
    selectedIndex: null
  });

  const handleMenuClick = (event, id) => {
    setMenuState({
      anchorEl: event.currentTarget,
      currentEventId: id,
    });
    // setEventId(id);
    console.log("Event ID set to: ", id);
  };
  const handleMenuClose = () => {
    setMenuState({
      anchorEl: null,
      currentEventId: null,
      selectedIndex: null
    });
  };

  const handleDeleteContact = (index, setFieldValue, values) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedContacts = values.contactPersons.filter((_, i) => i !== index);
        setFieldValue("contactPersons", updatedContacts);

        Swal.fire("Deleted!", "The contact person has been deleted.", "success");
      }
    });
  };

  const handleAddOrUpdateContact = (contact, setFieldValue, values) => {
    let updatedContacts = [...values.contactPersons];

    if (selectedContactIndex !== null) {
      // Update existing contact
      updatedContacts[selectedContactIndex] = contact;
    } else {
      // Add new contact
      updatedContacts = [...updatedContacts, contact];
    }

    // Update the field using setFieldValue
    setFieldValue("contactPersons", updatedContacts);
    handleCloseModal();
  };

  return (
    <Div sx={{ mt: 0 }}>
      {/* <Typography variant="h1">
        {pathname === "/master/supplier/add"
          ? "Add New Supplier"
          : "Edit Supplier"}
      </Typography> */}
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={supplier}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        // onSubmit={(values) => console.log("values => ", values)}
        >
          {({ values, setFieldValue, errors, setFieldTouched, touched }) => (
            <>
              <Form noValidate autoComplete="off">

                <Div sx={{ mt: 0 }}>
                  <Grid container rowSpacing={4} columnSpacing={4}>
                    <Grid item xs={6}>
                      <FormTextField1 name="supplier_name" label="Supplier Name*" />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h5">Supplier Type*</Typography>
                      <Autocomplete
                        autoSelect
                        autoHighlight
                        multiple={values?.supplier_type?.includes("STORE") ? false : true}
                        options={supplierTypes?.map((type) => type.type)}
                        // value={values.supplier_type || []}
                        value={Array.isArray(values.supplier_type) ? values.supplier_type : []}
                        onChange={(e, newValue) => {
                          if (newValue === null) {
                            setFieldValue("supplier_type", []);
                          } else if (newValue.includes("STORE")) {
                            setFieldValue("supplier_type", ["STORE"]);
                          } else {
                            setFieldValue("supplier_type", newValue);
                          }
                        }}
                        getOptionDisabled={(option) => {
                          return values.supplier_type.includes("STORE") && option !== "STORE";
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={!!errors.supplier_type}
                            // helperText={errors.supplier_type}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />

                      <Div style={{ height: "30px" }}>
                        <ErrorMessage name="supplier_type" component="div" style={{ color: "red" }} />
                      </Div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className="flex flex-col gap-5 -mt-5 -mb-5">
                        <Button className="self-end" variant="contained" size="small" onClick={() => handleOpenModal(null)}>
                          Add Contact Person
                        </Button>
                        <TableContainer component={Paper} sx={{ mb: -5 }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow
                                sx={{
                                  bgcolor: "#7352C7",
                                  color: "white",
                                  "& .MuiTableCell-root": {
                                    // py: 2,
                                  },
                                }}
                              >
                                <TableCell sx={{ color: "white" }}>Sr.No</TableCell>
                                <TableCell sx={{ color: "white" }}>Name</TableCell>
                                <TableCell sx={{ color: "white" }}>Email</TableCell>
                                <TableCell sx={{ color: "white" }}>Mobile Number</TableCell>
                                <TableCell sx={{ color: "white" }}>Designation</TableCell>
                                <TableCell sx={{ color: "white" }}>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values?.contactPersons?.length > 0 ? (
                                values?.contactPersons?.map((contact, index) => (
                                  <TableRow sx={{ py: 2 }} key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell name="contactPerson.name">{contact.name || "-"}</TableCell>
                                    <TableCell name="email">{contact.email || "-"}</TableCell>
                                    <TableCell name="mobile_number">{contact.mobile_number || "-"}</TableCell>
                                    <TableCell name="designation">{contact.designation || "-"}</TableCell>

                                    <TableCell>
                                      <IconButton
                                        //  onClick={(e) => handleMenuClick(e, index)}
                                        onClick={(e) =>
                                          setMenuState({
                                            anchorEl: e.currentTarget,
                                            currentEventId: index,
                                            selectedIndex: index,
                                          })
                                        }
                                      >
                                        <MoreHoriz />
                                      </IconButton>
                                    </TableCell>
                                    <Menu open={Boolean(menuState.anchorEl)} anchorEl={menuState.anchorEl} onClose={handleMenuClose}>
                                      <MenuItem
                                        onClick={() => {
                                          // setEditValues(values.contactPersons);
                                          handleOpenModal(menuState?.selectedIndex);
                                          handleMenuClose()
                                        }}
                                      >
                                        <ListItemIcon>
                                          <EditOutlined />
                                        </ListItemIcon>{" "}
                                        <ListItemText>Edit</ListItemText>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          handleDeleteContact(index, setFieldValue, values);
                                          handleMenuClose();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <DeleteOutlineOutlined />
                                        </ListItemIcon>{" "}
                                        <ListItemText>Delete</ListItemText>
                                      </MenuItem>
                                    </Menu>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell align="center" colSpan={6}>
                                    No Data Found...
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Div style={{ height: "30px" }}>
                          {values.contactPersons.length === 0 && (
                            <ErrorMessage name="contactPersons" component="div" style={{ color: "red" }} />
                          )}
                        </Div>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <FormTextField1 name="branch_name" label="Branch Name*" />
                    </Grid>
                    <Grid item xs={3}>
                      <FormTextField1 name="address" label="Address*" />
                    </Grid>

                    <Grid item xs={3} >
                      <Typography variant="h6" fontSize="14px">
                        Country*
                      </Typography>
                      <Autocomplete
                        size="small"
                        autoSelect
                        autoHighlight
                        options={countryList}
                        name="country"
                        getOptionLabel={(option) => {
                          if (option instanceof Object && !Array.isArray(option)) {
                            return option?.name;
                          } else {
                            return option;
                          }
                        }}
                        value={values?.country}
                        onChange={(e, value) => {
                          setFieldValue("country", value?.name);
                          setFieldValue("state", "");
                          setFieldValue("city", "");
                          setFieldValue("countryCode", value?.phonecode || "");
                          filterStates(value?.isoCode);
                        }}


                        onBlur={(e) => {
                          setFieldTouched("country", true);
                        }}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={touched.country && Boolean(errors.country)} // Show error if touched and there's an error
                            helperText={touched.country && errors.country} // Display helper text for the error
                            sx={{
                              width: "100%",
                            }}

                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={3} >
                      <Typography variant="h6" fontSize="14px">
                        State*
                      </Typography>
                      <Autocomplete
                        autoSelect
                        autoHighlight
                        size="small"
                        options={StateList}
                        name="state"
                        getOptionLabel={(option) => {
                          if (option instanceof Object && !Array.isArray(option)) {
                            return option?.name;
                          } else {
                            return option;
                          }
                        }}
                        value={values?.state}
                        onChange={(e, value) => {
                          setFieldValue("state", value?.name);
                          setFieldValue("city", "");
                          // filterCities(value?.countryCode, value?.isoCode);
                          // setFieldValue("state", e?.target?.value);
                          // setFieldValue("city", "");
                          filterCities(value?.isoCode, value?.countryCode);
                        }}
                        onBlur={(e) => {
                          setFieldTouched("state", true);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={touched.state && Boolean(errors.state)} // Show error if touched and there's an error
                            helperText={touched.state && errors.state} // Display helper text for the error
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>


                    <Grid item xs={3} >
                      <Typography variant="h6" fontSize="14px">
                        City*
                      </Typography>
                      <Autocomplete
                        autoSelect
                        autoHighlight
                        size="small"
                        options={cityList}
                        name="city"
                        getOptionLabel={(option) => {
                          if (option instanceof Object && !Array.isArray(option)) {
                            return option?.name;
                          } else {
                            return option;
                          }
                        }}
                        value={values?.city}
                        onChange={(e, value) => {
                          setFieldValue("city", value?.name);
                        }}
                        onBlur={(e) => {
                          setFieldTouched("city", true);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={touched.city && Boolean(errors.city)} // Show error if touched and there's an error
                            helperText={touched.city && errors.city} // Display helper text for the error
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormTextField1 name="pincode" label="Pincode*" />
                    </Grid>
                    <Grid item xs={3}>
                      <FormTextField1 name="gst_number" label={`GST Number`} />
                    </Grid>
                    <Grid item xs={3}>
                      <FormTextField1 name="web_url" label="Web Url" />
                    </Grid>
                  </Grid>

                  <Div
                    sx={{
                      width: "93.5%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 3,
                      mt: 3,
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        Swal.fire({
                          text: "Are you sure you want to cancel?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            navigate("/master/supplier");
                          }
                        });
                      }}
                    >
                      Cancel
                    </Button>

                    <LoadingButton variant="contained" type="submit" sx={{ width: "100px" }} loading={isSubmitting}>
                      Save
                    </LoadingButton>
                  </Div>
                </Div>
              </Form>
              <QuestionsModal
                handleClose={handleCloseModal}
                open={open}
                onAddContact={(contact) => handleAddOrUpdateContact(contact, setFieldValue, values)}
                values={selectedContactIndex !== null ? [values.contactPersons[selectedContactIndex]] : []}
              // values={editValues}
              />
            </>
          )}
        </Formik>
      </Div>
    </Div>
  );
}

export default HeaderTitleHoc(AddSupplier, "Add Supplier");
