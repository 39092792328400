export const fetchLogExpensesApi = `/expense-master/log-expense-invoice-listing`;
export const fetchLogItemsExpensesApi = function (invoice_id) {
  return `/expense-master/log-expense-item-listing-by-invoice/${invoice_id}`;
};
export const addLogExpensesApi = function (invoice_id) {
  return `/expense-master/add-log-expenses/${invoice_id}`;
};

//Flitch
export const fetchFlitchExpensesApi = `/expense-master/flitch-expense-invoice-listing`;
export const fetchFlitchItemsExpensesApi = function (invoice_id) {
  return `/expense-master/flitch-expense-item-listing-by-invoice/${invoice_id}`;
};
export const addFlitchExpensesApi = function (invoice_id) {
  return `/expense-master/add-flitch-expenses/${invoice_id}`;
};

// Face
export const fetchFaceExpensesApi = `/expense-master/face-expense-invoice-listing`;
export const fetchFaceItemsExpensesApi = function (invoice_id) {
  return `/expense-master/face-expense-item-listing-by-invoice/${invoice_id}`;
};
export const addFaceExpensesApi = function (invoice_id) {
  return `/expense-master/add-face-expenses/${invoice_id}`;
};

// Core
export const fetchCoreExpensesApi = `/expense-master/core-expense-invoice-listing`;
export const fetchCoreItemsExpensesApi = function (invoice_id) {
  return `/expense-master/core-expense-item-listing-by-invoice/${invoice_id}`;
};
export const addCoreExpensesApi = function (invoice_id) {
  return `/expense-master/add-core-expenses/${invoice_id}`;
};

// Plywood
export const fetchPlywoodExpensesApi = `/expense-master/plywood-expense-invoice-listing`;
export const fetchPlywoodItemsExpensesApi = function (invoice_id) {
  return `/expense-master/plywood-expense-item-listing-by-invoice/${invoice_id}`;
};
export const addPlywoodExpensesApi = function (invoice_id) {
  return `/expense-master/add-plywood-expenses/${invoice_id}`;
};

// Veneer
export const fetchVeneerExpensesApi = `/expense-master/veneer-expense-invoice-listing`;
export const fetchVeneerItemsExpensesApi = function (invoice_id) {
  return `/expense-master/veneer-expense-item-listing-by-invoice/${invoice_id}`;
};
export const addVeneerExpensesApi = function (invoice_id) {
  return `/expense-master/add-veneer-expenses/${invoice_id}`;
};

// Mdf
export const fetchMdfExpensesApi = `/expense-master/mdf-expense-invoice-listing`;
export const fetchMdfItemsExpensesApi = function (invoice_id) {
  return `/expense-master/mdf-expense-item-listing-by-invoice/${invoice_id}`;
};
export const addMdfExpensesApi = function (invoice_id) {
  return `/expense-master/add-mdf-expenses/${invoice_id}`;
};

// Fleece
export const fetchFleeceExpensesApi = `/expense-master/fleece-expense-invoice-listing`;
export const fetchFleeceItemsExpensesApi = function (invoice_id) {
  return `/expense-master/fleece-expense-item-listing-by-invoice/${invoice_id}`;
};
export const addFleeceExpensesApi = function (invoice_id) {
  return `/expense-master/add-fleece-expenses/${invoice_id}`;
};

// Fleece
export const fetchOtherGoodsExpensesApi = `/expense-master/otherGoods-expense-invoice-listing`;
export const fetchOtherGoodsItemsExpensesApi = function (invoice_id) {
  return `/expense-master/otherGoods-expense-item-listing-by-invoice/${invoice_id}`;
};
export const addOtherGoodsExpensesApi = function (invoice_id) {
  return `/expense-master/add-otherGoods-expenses/${invoice_id}`;
};
