import Page from "@jumbo/shared/Page";
import AddFaceStock from "app/pages/Inventory/Face Inventory/AddFace";
import EditFaceStock from "app/pages/Inventory/Face Inventory/EditFace";
import FaceInventoryList from "app/pages/Inventory/Face Inventory/ListFace";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";
import ViewDetails from "app/pages/Inventory/Face Inventory/ListFace/Approval/ViewDetails";

export const faceInventoryRoute = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "face_inventory", "view"],
      },
    ],
    routes: [
      {
        path: "/inventory/face",
        element: <Page component={FaceInventoryList} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/face/add",
        element: <Page component={AddFaceStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/face/edit",
        element: <Page component={EditFaceStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/face-approval/view-details/:id/:invoiceId",
        element: <Page component={ViewDetails} layout={"vertical-default"} />,
      },
    ],
  },
];
