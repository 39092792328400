import { flitchExpenseRoutes } from "./flitchExpenseRouter";
import { faceExpenseRoutes } from "./faceExpenseRouter";
import { logExpenseRoutes } from "./logExpenseRouter";
import { coreExpenseRoutes } from "./coreExpenseRouter";
import { plywoodExpenseRoutes } from "./plywoodExpenseRouter";
import { veneerExpenseRoutes } from "./veneerExpenseRouter";
import { mdfExpenseRoutes } from "./mdfExpenseRouter";
import { fleeceExpenseRoutes } from "./fleeceExpenseRouter";
import { otherGoodsExpenseRoutes } from "./otherGoodsExpenseRouter";

const expensesRouter = [
    ...logExpenseRoutes,
    ...flitchExpenseRoutes,
    ...faceExpenseRoutes,
    ...coreExpenseRoutes,
    ...plywoodExpenseRoutes,
    ...veneerExpenseRoutes,
    ...mdfExpenseRoutes,
    ...fleeceExpenseRoutes,
    ...otherGoodsExpenseRoutes,
    
]

export default expensesRouter;