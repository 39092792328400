import { Axios } from "index";
import Swal from "sweetalert2";

export const itemSrnoDropdown = async function (api) {
  try {
    const response = await Axios.get(api);
    return response.data;
  } catch (error) {
    Swal.fire({
      title: error.message,
      icon: "error",
      timer: 1000,
      showConfirmButton: false,
    });
  }
};

export const inwardSrnoDropdown = async function (api) {
  try {
    const response = await Axios.get(api);
    return response.data;
  } catch (error) {
    Swal.fire({
      title: error.message,
      icon: "error",
      timer: 1000,
      showConfirmButton: false,
    });
  }
};


