export const coreItemSrnoDropdownApi = "/core-inventory/item-srno-dropdown";
export const coreInwardSrnoDropdownApi = "/core-inventory/inward-srno-dropdown";

//Approval
export const fetchCoreApprovalApi = `/approval/core-approval-invoice-listing`;
export const fetchCoreItemsApprovalApi = function (id, invoice_id) {
  return `/approval/core-approval-item-listing-by-invoice/${id}/${invoice_id}`;
};
export const coreApproveInvoiceApi = function (id, invoice_id) {
  return `/approval/core-approve_invoice_details/${id}/${invoice_id}`;
};
export const coreRejectInvoiceApi = function (id, invoice_id) {
  return `/approval/core-reject_invoice_details/${id}/${invoice_id}`;
};