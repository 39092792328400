import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import JumboApp from "@jumbo/components/JumboApp";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import JumboTheme from "@jumbo/components/JumboTheme";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AppLayout from "./AppLayout";
import AppProvider from "./AppProvider";
import AppRoutes from "./AppRoutes";
import SessionTimeoutManager from "./SessionTimeOutManager";
import HeaderApp from "../@jumbo/components/HeaderApp";
import LoadingPage from "./components/LoadingPage";
import { config } from "./config/main";
import OfflineMessage from "./pages/OfflineMessage/offline";
import configureStore, { history } from "./redux/store";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const store = configureStore();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter history={history}>
          <HeaderApp>
            <AppProvider>
              <JumboApp activeLayout={config.defaultLayout}>
                <JumboTheme init={config.theme}>
                  <JumboRTL>
                    <JumboDialogProvider>
                      <JumboDialog />
                      <SnackbarProvider
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        maxSnack={3}
                      >
                        <AppLayout>
                          <OfflineMessage />
                          <Suspense
                            fallback={
                              <Div
                                sx={{
                                  display: "flex",
                                  minWidth: 0,
                                  alignItems: "center",
                                  alignContent: "center",
                                  height: "100%",
                                }}
                              >
                                <CircularProgress sx={{ m: "-40px auto 0" }} />
                              </Div>
                            }
                          >
                            <SessionTimeoutManager>
                              <LoadingPage>
                                <AppRoutes />
                              </LoadingPage>
                            </SessionTimeoutManager>
                          </Suspense>
                        </AppLayout>
                      </SnackbarProvider>
                    </JumboDialogProvider>
                  </JumboRTL>
                </JumboTheme>
              </JumboApp>
            </AppProvider>
          </HeaderApp>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
