import Div from '@jumbo/shared/Div';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { hideLoading, setLoading } from 'app/redux/actions/Loading';
import { crosscutDoneApproveInvoiceApi, crosscutDoneRejectInvoiceApi, fetchCrosscutDoneItemsApprovalApi } from 'app/services/apis/Factory/Crosscutting/CrosscuttingDone';
import { Axios } from 'index';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListingCrossCutItems from './ListingCrosscutItems';
import HeaderTitleHoc from 'app/components/HeaderTitleHoc';

const CrosscuttingApprovalDetails = () => {
    const [crosscuttingApprovalData, setCrosscuttingApprovalData] = useState([])
    const { id, issued_for_cutting_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const isApprovalPending = crosscuttingApprovalData?.[0]?.approval_status?.sendForApproval?.status || false;
    const isApproved = crosscuttingApprovalData?.[0]?.approval_status?.approved?.status || false;
    const isRejected = crosscuttingApprovalData?.[0]?.approval_status?.rejected?.status || false;


    const fetchItemDetails = async function () {
        try {
            dispatch(setLoading());
            const response = await Axios.get(fetchCrosscutDoneItemsApprovalApi(id, issued_for_cutting_id));
            if (response?.data?.status === "success") {
                const data = response?.data?.data?.crosscutting_details;
                setCrosscuttingApprovalData(data);
            }
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "error",
                text: error?.response?.data?.message || error?.message,
                showConfirmButton: false,
                timer: 1500,
            });
        } finally {
            dispatch(hideLoading());
        }
    }
    async function handleApprove() {
        try {
            dispatch(setLoading());
            const response = await Axios.post(crosscutDoneApproveInvoiceApi(id, issued_for_cutting_id));
            if (response?.data?.status === "success") {
                const data = response?.data?.data
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Approved",
                    text: data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                navigate('/factory/crosscut')
            }
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "error",
                text: error?.response?.data?.message || error?.message,
                showConfirmButton: false,
                timer: 1500,
            });
        } finally {
            dispatch(hideLoading());
        }
    }
    async function handleReject() {
        try {
            dispatch(setLoading());
            const response = await Axios.post(crosscutDoneRejectInvoiceApi(id, issued_for_cutting_id), {
                remark: "Rejected"
            });
            if (response?.data?.status === "success") {
                const data = response?.data?.data
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Rejected",
                    text: data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                navigate('/factory/crosscut')
            }
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "error",
                text: error?.response?.data?.message || error?.message,
                showConfirmButton: false,
                timer: 1500,
            });
        } finally {
            dispatch(hideLoading());
        }
    }

    useEffect(() => {
        fetchItemDetails()
    }, [])
    return (
        <Box sx={{ mt: -4 }}>
            <ListingCrossCutItems
                itemDetails={crosscuttingApprovalData}
                isApprovalPending={isApprovalPending}
            />
            <Div
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 5,
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                        Swal.fire({
                            title: "Warning",
                            text: "Are you sure you want to Cancel?",
                            icon: "error",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate("/factory/crosscut");
                            }
                        });
                    }}
                >
                    Cancel
                </Button>
                {
                    !isApproved && !isRejected && <>
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => {
                                Swal.fire({
                                    title: "Reject ?",
                                    text: "Are you sure you want to Reject?",
                                    icon: "error",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "No",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        handleReject()
                                    }
                                });
                            }}
                            disabled={isRejected}
                        >
                            Reject
                        </LoadingButton>
                        <LoadingButton
                            color="success"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                Swal.fire({
                                    title: "Approve ?",
                                    text: "Are you sure you want to Approve?",
                                    icon: "success",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "No",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        handleApprove()
                                    }
                                });
                            }}
                            disabled={isApproved}
                        >
                            Approve
                        </LoadingButton>
                    </>
                }
                {isApproved && <Typography variant='h4'><CheckCircleIcon color='success' sx={{ fontSize: "30px" }} /> Approved</Typography>}
                {isRejected && <Typography variant='h4'><CancelIcon color='error' sx={{ fontSize: "30px" }} /> Rejected</Typography>}
            </Div>
        </Box>

    )
}

export default HeaderTitleHoc(CrosscuttingApprovalDetails,"Crosscutting Done Approval Details")