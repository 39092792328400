import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { expenseTypeDropDownList } from "app/services/apis/expenseTypeApis";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { supplierBranchDropDownList, supplierDropDownList } from "app/services/apis/supplierList";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
  minWidth: "100px",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  borderRight: "1px solid #7352C7",
};

const initialData = {
  expenseType: "",
  expenseTypeId: "",
  invoiceDate: "",
  invoiceNo: "",
  serviceProviderName: "",
  serviceProviderDetails: "",
  amount: 0,
};

const validationSchema = yup.object().shape({
  expenseType: yup.string().required("Expense type is required"),
  expenseTypeId: yup.string().required("Expense type ID is required"),
  invoiceDate: yup.date().required("Invoice date is required").nullable(),
  invoiceNo: yup.string().required("Invoice number is required"),
  serviceProviderName: yup.string().required("Service provider name is required"),
  amount: yup.number().typeError("Amount must be a number").positive("Amount must be positive").required("Amount is required"),
});

const OtherExpensesForm = ({ setOtherExpensesList, expenseType, setIsCalculate }) => {
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [supplierBranch, setSupplierBranch] = useState([]);

  const handleSubmit = function (values, actions) {
    if (values?.serviceProviderDetails === "") {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Failed",
        text: "Error while setting branch details.",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      setOtherExpensesList((prev) => [...prev, values]);
      actions.resetForm();
      setIsCalculate(true);
    }
  };

  const getSupplierBranchList = async (supplierId, setFieldValue) => {
    const supplerBranchList = await supplierBranchDropDownList(supplierId);
    if (supplerBranchList?.data?.success) {
      let branchData = supplerBranchList?.data?.result;
      let mainBranch = branchData?.filter((ele) => ele?.is_main_branch === true);
      //   setSupplierBranch(mainBranch[0]);
      setFieldValue("serviceProviderDetails", mainBranch[0] || "");
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Failed",
        text: "Error while setting branch details.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    supplierDropDownList("SERVICE PROVIDER")
      .then((response) => {
        if (response.data.success) {
          setSupplierNameList(response.data.result);
        }
      })
      .catch((error) => console.error("Error fetching supplier list:", error));
  }, []);

  return (
    <>
      <Typography variant="h2" my={3}>
        Other Expenses
      </Typography>
      <Formik validateOnChange={true} initialValues={initialData} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ values, setFieldValue, handleBlur, handleChange, errors, touched, setFieldTouched }) => (
          <Form>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow
                    sx={{
                      bgcolor: "#7352C7",
                      color: "white",
                    }}
                  >
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Expense Type</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Date</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice No</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "200px" }}>Service Provider Name</TableCell>
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "100px" }}>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ ...tableBodyCellStyle }}>
                      <Autocomplete
                        autoSelect
                        autoHighlight
                        size="small"
                        options={expenseType}
                        name="expenseType"
                        value={values?.expenseType || null}
                        getOptionLabel={(option) => {
                          if (option instanceof Object && !Array.isArray(option)) {
                            return option.expense_type_name;
                          } else {
                            return option;
                          }
                        }}
                        isOptionEqualToValue={(option, value) => option?.expense_type_name === value || false}
                        onChange={(e, selectedData) => {
                          setFieldValue("expenseType", selectedData?.expense_type_name || "");
                          setFieldValue("expenseTypeId", selectedData?._id || "");
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                            {option?.expense_type_name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            name="expenseType"
                            error={touched.expenseType && Boolean(errors.expenseType)}
                            helperText={touched.expenseType && errors.expenseType}
                            sx={{
                              width: "100%",
                            }}
                            onBlur={handleBlur}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ ...tableBodyCellStyle }}>
                      <TextField
                        size="small"
                        sx={{
                          width: "100%",
                        }}
                        type="Date"
                        name="invoiceDate"
                        value={values?.invoiceDate}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched?.invoiceDate && errors?.invoiceDate}
                        helperText={touched?.invoiceDate && errors?.invoiceDate}
                      />
                    </TableCell>
                    <TableCell sx={{ ...tableBodyCellStyle }}>
                      <TextField
                        size="small"
                        sx={{
                          width: "100%",
                        }}
                        name="invoiceNo"
                        value={values?.invoiceNo}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched?.invoiceNo && errors?.invoiceNo}
                        helperText={touched?.invoiceNo && errors?.invoiceNo}
                      />
                    </TableCell>
                    <TableCell sx={{ ...tableBodyCellStyle }}>
                      {/* <TextField
                        size="small"
                        sx={{
                          width: "100%",
                        }}
                        name="serviceProviderName"
                        value={values?.serviceProviderName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched?.serviceProviderName && errors?.serviceProviderName}
                        helperText={touched?.serviceProviderName && errors?.serviceProviderName}
                      /> */}
                      <Autocomplete
                        autoSelect
                        autoHighlight
                        size="small"
                        options={supplierNameList}
                        name="serviceProviderName"
                        value={supplierNameList.find((sup) => sup?.supplier_name === values?.serviceProviderName) || null}
                        getOptionLabel={(option) => {
                          if (option instanceof Object && !Array.isArray(option)) {
                            return option?.supplier_name;
                          } else {
                            return option;
                          }
                        }}
                        onBlur={() => setFieldTouched("serviceProviderName", true)}
                        onChange={(e, selectedData) => {
                          if (selectedData) {
                            getSupplierBranchList(selectedData?._id, setFieldValue);
                            setFieldValue("serviceProviderName", selectedData?.supplier_name);
                          } else {
                            setFieldValue("serviceProviderName", "");
                            setFieldValue("serviceProviderDetails", "");
                          }
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.supplier_name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={touched.serviceProviderName && Boolean(errors.serviceProviderName)} // Show error if touched and there's an error
                            helperText={touched.serviceProviderName && errors.serviceProviderName}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ ...tableBodyCellStyle }}>
                      <TextField
                        size="small"
                        sx={{
                          width: "100%",
                        }}
                        type="number"
                        name="amount"
                        inputProps={{
                          step: "any", // Allows decimals
                        }}
                        value={values?.amount || ""}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched?.amount && errors?.amount}
                        helperText={touched?.amount && errors?.amount}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Div sx={{ textAlign: "right" }}>
              {/* <Button size="small" type="submit" variant="contained" startIcon={<AddCircleOutlineIcon />}>
                Add
              </Button> */}
              <LoadingButton
                // loading={submitting}
                type="submit"
                size="small"
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  marginY: 1,
                  width: "150px",
                  textAlign: "center",
                  verticalAlign: "middle",
                  color: "white",
                  px: 1,
                  position: "sticky",
                  right: "20px",
                  zIndex: 1,
                }}
              >
                Add Expense
              </LoadingButton>
            </Div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OtherExpensesForm;
