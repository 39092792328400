import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Snackbar,
  TextField
} from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LogTableListing from "./LogTableListing";
// import { fetchLogInventory } from "app/redux/actions/Inventory/LogInventory/logDispatch";
import { LoadingButton } from "@mui/lab";
import FullScreenLoader from "app/components/ListingPageLoader";
import { fetchFlitchInventory } from "app/redux/actions/Inventory/flitch/flitchDispatch";
import { flitchInventoryFilter } from "app/utils/constants/sessionFilter";
import { Axios } from "index";
import Swal from "sweetalert2";
import LogFilters from "./Filters";

const ListingLog = () => {
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );
  const flitchInventoryReducer = useSelector(
    (state) => state?.flitchInventoryReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [allFilterState, setAllFilterState] = useState({
    searchTerm: "",
    sort: "desc",
    sortBy: "updatedAt",
    page: 1,
    limit: 10,
    filter: null,
  });
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    customClass: {
      container: "popupImportant",
    },
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleSearch = (search) => {
    const filter = {
      ...allFilterState,
      searchTerm: search,
      page: 1,
    };
    setAllFilterState(filter);
    dispatch(fetchFlitchInventory(filter));
    sessionStorage.setItem(flitchInventoryFilter, JSON.stringify(filter));
  };

  const handleChangeLimit = (event) => {
    const filter = {
      ...allFilterState,
      limit: parseInt(event.target.value),
      page: 1,
    };
    setAllFilterState(filter);
    dispatch(fetchFlitchInventory(filter));
    sessionStorage.setItem(flitchInventoryFilter, JSON.stringify(filter));
  };

  const handleApplyFilter = useCallback(
    (filtersData) => {
      // setPage(1);
      const filter = {
        ...allFilterState,
        sort: "desc",
        sortBy: "updatedAt",
        page: 1,
        filter: filtersData,
      };
      dispatch(fetchFlitchInventory(filter));
      setAllFilterState(filter);
      sessionStorage.setItem(flitchInventoryFilter, JSON.stringify(filter));
    },
    [allFilterState?.limit, allFilterState?.searchTerm]
  );

  const handleClearFilter = useCallback(
    (filtersData) => {
      const filter = {
        ...allFilterState,
        sort: "desc",
        sortBy: "updatedAt",
        page: 1,
        filter: filtersData,
      };
      dispatch(fetchFlitchInventory(filter));
      setAllFilterState(filter);
      sessionStorage.removeItem(flitchInventoryFilter);
    },
    [allFilterState?.limit, allFilterState?.searchTerm]
  );

  const debouncedHandleSearch = debounce(handleSearch, 1000);

  useEffect(() => {
    //to deal with search lagging
    if (allFilterState?.searchTerm !== "") {
      debouncedHandleSearch(allFilterState?.searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [allFilterState?.searchTerm]);

  const handleExportCSV = async () => {
    try {
      setLoading(true);
      setSnackbarOpen(true);

      const res = await Axios.post(
        "/flitch-inventory/download-excel-flitch-logs"
      );

      if (res.data?.success) {
        window.open(res?.data?.result);
        Toast.fire({
          timer: 3000,
          icon: "success",
          title: "CSV  Downloaded Successfully",
          position: "top-right",
          // background: theme.palette.background.paper,
        });
        setLoading(false);
        setSnackbarOpen(false);
      } else {
        setLoading(false);
        setSnackbarOpen(false);
        Toast.fire({
          timer: 3000,
          icon: "success",
          title: "CSV  Downloading failed",
          position: "top-right",
          // background: theme.palette.background.paper,
        });
      }
    } catch (error) {
      setLoading(false);
      setSnackbarOpen(false);
      Toast.fire({
        timer: 3000,
        icon: "error",
        title:
          error.response?.data.message ||
          "An error occured while downloading csv",
        position: "top-right",
        // background: theme.palette.background.paper,
      });
    }
  };

  useEffect(() => {
    let retrieveFilter = sessionStorage.getItem(flitchInventoryFilter);
    let filtersValues = allFilterState;
    if (retrieveFilter) {
      filtersValues = JSON.parse(retrieveFilter);
      setAllFilterState(filtersValues);
    }
    dispatch(fetchFlitchInventory(filtersValues));
  }, []);

  return (
    <Box>
      {flitchInventoryReducer?.loading && <FullScreenLoader />}
      <Box sx={{ mt: 2 }}>
        <LogFilters
          handleSubmit={handleApplyFilter}
          handleClear={handleClearFilter}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          my: 2,
          justifyContent: "space-between",
          flexWrap: "wrap-reverse",
        }}
      >
        <Box>
          <TextField
            size="small"
            id="search"
            type="search"
            label="Search"
            value={allFilterState?.searchTerm}
            onChange={(e) => {
              setLoaded(false);
              setAllFilterState((prev) => ({
                ...prev,
                searchTerm: e.target.value,
              }));
              if (!e.target.value) {
                const filter = {
                  ...allFilterState,
                  searchTerm: "",
                  sort: "desc",
                  sortBy: "updatedAt",
                  page: 1,
                };
                setAllFilterState(filter);
                dispatch(fetchFlitchInventory(filter));
                sessionStorage.setItem(
                  flitchInventoryFilter,
                  JSON.stringify(filter)
                );
              }
            }}
            sx={{
              width: 300,
            }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
        </Box>

        <Box display={"flex"} gap={2} flexWrap={"wrap"}>
          {permissions?.flitch_inventory?.create && (
            <Button
              size="small"
              variant="contained"
              onClick={() => navigate("/inventory/flitch/add")}
            >
              Add Stock
            </Button>
          )}

          {permissions?.flitch_inventory?.view && (
            <LoadingButton
              loading={loading}
              disabled={snackbarOpen ? true : false}
              size="small"
              variant="contained"
              onClick={handleExportCSV}
            >
              Export CSV
            </LoadingButton>
          )}
          <TextField
            sx={{ width: "100px" }}
            type="number"
            size="small"
            inputProps={{
              min: 1,
            }}
            label="Page No"
            onKeyDown={(e) => {
              const inputValue = parseInt(e.target.value + e.key, 10);
              if (isNaN(inputValue) || inputValue < 0) {
                e.preventDefault();
              }
            }}
            value={allFilterState?.page}
            onChange={(e) => {
              let value = parseInt(e.target.value);
              // if(value > logInventoryReducer?.totalPage){
              //     return;
              // }
              setLoaded(false);
              const filter = {
                ...allFilterState,
                page: value,
              };
              setAllFilterState(filter);
              dispatch(fetchFlitchInventory(filter));
              sessionStorage.setItem(
                flitchInventoryFilter,
                JSON.stringify(filter)
              );
            }}
          ></TextField>
          <Snackbar
            TransitionComponent={Slide}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackbarOpen}
            message=" CSV Downloading in progress..."
            action={loading && <CircularProgress color="inherit" size={24} />}
          />
          <FormControl size="small">
            <InputLabel id="limit-label">Limit</InputLabel>
            <Select
              labelId="limit-label"
              id="limit"
              value={allFilterState?.limit}
              label="Limit"
              onChange={handleChangeLimit}
              sx={{ width: "80px" }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={75}>75</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <LogTableListing
        allFilterState={allFilterState}
        setAllFilterState={setAllFilterState}
      />

    </Box>
  );
};

export default ListingLog;
