import axios from "axios";

export const supplierDropDownList = async (type) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/supplier-master/dropdown-supplier-master?type=${type}`,
      config
    );
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const supplierBranchDropDownList = async (supplierId) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/supplier-master/branches-by-supplier/${supplierId}`,
      config
    );
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
