export const otherGoodsItemSrnoDropdownApi = "/other-goods-inventory/item-srno-dropdown";
export const otherGoodsInwardSrnoDropdownApi = "/other-goods-inventory/inward-srno-dropdown";

//Approval
export const fetchOtherGoodsApprovalApi = `/approval/otherGoods-approval-invoice-listing`;
export const fetchOtherGoodsItemsApprovalApi = function (id, invoice_id) {
  return `/approval/othergoods-approval-item-listing-by-invoice/${id}/${invoice_id}`;
};
export const othergoodsApproveInvoiceApi = function (id, invoice_id) {
  return `/approval/othergoods-approve_invoice_details/${id}/${invoice_id}`;
};
export const othergoodsRejectInvoiceApi = function (id, invoice_id) {
  return `/approval/othergoods-reject_invoice_details/${id}/${invoice_id}`;
};
