import { Axios } from "index";
import {
  FLEECE_INVENTORY_FAILED,
  FLEECE_INVENTORY_REQUEST,
  FLEECE_INVENTORY_SUCCESS,
} from "./constant";
import { filterObjectWithValues } from "app/utils/filterObjectWithValues";
import { fleeceListingApi } from "app/services/apis/Inventory/Fleece/constants";

export const fetchFleeceInventory = function ({
  searchTerm = "",
  sort = "desc",
  sortBy = "updatedAt",
  page = 1,
  limit = 10,
  filter = {},
}) {
  return async function (dispatch) {
    try {
      dispatch({ type: FLEECE_INVENTORY_REQUEST });

      const body = {
        filter: {
          ...filterObjectWithValues(filter),
        },
        searchFields: {
          string: [
            "supplier_item_name",
            "supplier_code",
            "item_name",
            "item_sub_category_name",
            "fleece_invoice_details.supplier_details.company_details.supplier_name",
            "fleece_invoice_details.invoice_Details.invoice_no",
            "fleece_invoice_details.currency",
            "remark",
            "created_user.user_name",
          ],
          numbers: [
            "item_sr_no",
            "length",
            "width",
            "thickness",
            "number_of_roll",
            "total_sq_meter",
            "exchange_rate",
            "rate_in_currency",
            "rate_in_inr",
            "amount",
            "expense_amount",
          ],
          arrayField: [],
        },
      };
      if (!searchTerm) {
        searchTerm = "";
      }

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: searchTerm.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
        limit: limit,
      });

      const response = await Axios.post(`${fleeceListingApi}?${urlParams.toString()}`,
        body,
        config
      );
      dispatch({
        type: FLEECE_INVENTORY_SUCCESS,
        payload: {
          data: response?.data?.data,
          totalPage: response?.data?.totalPage,
        },
      });
    } catch (error) {
      console.error(error)
      dispatch({
        type: FLEECE_INVENTORY_FAILED,
        payload: {
          isError: true,
          message: error.message,
        },
      });
    }
  };
};
