import { filterObjectWithValues } from "app/utils/filterObjectWithValues";
import { Axios } from "index";
import { fetchCrosscutDoneApprovalApi } from "app/services/apis/Factory/Crosscutting/CrosscuttingDone";
import { CROSSCUTTING_DONE_APPROVAL_LIST_FAILED, CROSSCUTTING_DONE_APPROVAL_LIST_REQUEST, CROSSCUTTING_DONE_APPROVAL_LIST_SUCCESS } from "./constant";
import { hideLoading, setLoading } from "app/redux/actions/Loading";

export const fetchCrosscuttingDoneApprovalList = function ({
    searchTerm = "",
    sort = "desc",
    sortBy = "updatedAt",
    page = 1,
    limit = 10,
    filter = {},
}) {
    return async function (dispatch) {
        try {
            dispatch(setLoading());
            dispatch({ type: CROSSCUTTING_DONE_APPROVAL_LIST_REQUEST });

            const body = {
                filter: {
                    ...filterObjectWithValues(filter)
                },
                searchFields: {
                    string: [
                        "machine_name",
                        "code",
                        "log_no_code",
                        "log_no",
                        "remarks",
                        "user.user_name",
                    ],
                    numbers: [
                        "length",
                        "girth",
                        "crosscut_cmt",
                        "per_cmt_cost",
                        "cost_amount",
                        "expense_amount",
                        "wastage_info.wastage_sqm",
                        "wastage_info.wastage_length",
                        "required_hours",
                    ],
                    arrayField: [],
                },
            };
            if (!searchTerm) {
                searchTerm = "";
            }

            const config = {
                withCredentials: true,
                headers: {
                    withCredentials: true,
                },
            };

            const urlParams = new URLSearchParams({
                search: searchTerm.trim(),
                page: page,
                sort: sort,
                sortBy: sortBy,
                limit: limit,
            });

            const response = await Axios.post(
                `${fetchCrosscutDoneApprovalApi}?${urlParams.toString()}`,
                body,
                config
            );
            dispatch({
                type: CROSSCUTTING_DONE_APPROVAL_LIST_SUCCESS,
                payload: {
                    data: response?.data?.data,
                    totalPage: response?.data?.totalPage,
                },
            });
        } catch (error) {
            console.error(error)
            dispatch({
                type: CROSSCUTTING_DONE_APPROVAL_LIST_FAILED,
                payload: {
                    isError: true,
                    message: error.message,
                },
            });
        } finally{
            dispatch(hideLoading())
        }
    };
};