import { HIDE_LOADING, SET_LOADING } from "./constants";

export const setLoading = function(message = null){
    return function (dispatch){
        dispatch({type:SET_LOADING,payload:{
            message:message
        }});
    }
}
export const hideLoading = function(message = null){
    return function (dispatch){
        dispatch({type:HIDE_LOADING,payload:{
            message:message
        }});
    }
}