import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import * as React from 'react';
import ListingIssueForCroscutting from './IssueForCrossCut/ListIssueCrossCut';
import ListingRejectedCroscutting from './RejectedCrosscut/ListRejectedCrossCut';
import ListCrossCutDone from './CrossCutDone/List';

import HeaderTitleHoc from 'app/components/HeaderTitleHoc';
import { factoryCrosscutTabFilter } from 'app/utils/constants/sessionFilter';
import { useSelector } from 'react-redux';
import ListCrossCutDoneApproval from './CrossCutDone/Approval/List';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function FactoryCrosscut() {
  const userReducer = useSelector((state) => state.userReducer);
  const user = userReducer?.user?.[0];

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem(factoryCrosscutTabFilter, newValue)
  };

  React.useEffect(() => {
    let newValue = Number(sessionStorage.getItem(factoryCrosscutTabFilter) || 0)
    setValue(newValue)
  }, [])

  return (
    <Box sx={{ width: '100%', mt: -4 }}>
      {/* <Typography variant="h1">Crosscut</Typography> */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Issues For Crosscutting" {...a11yProps(0)} />
          <Tab label="Crosscutting Done" {...a11yProps(1)} />
          <Tab label="Rejected Crosscutting" {...a11yProps(2)} />
          {user?.user_type === "ADMIN" && <Tab label="Crosscutting Done Approval" {...a11yProps(3)} />}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ListingIssueForCroscutting />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ListCrossCutDone />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ListingRejectedCroscutting />
      </CustomTabPanel>
      {user?.user_type === "ADMIN" && <CustomTabPanel value={value} index={3}>
        <ListCrossCutDoneApproval />
      </CustomTabPanel>}
    </Box>
  );
}

export default HeaderTitleHoc(FactoryCrosscut, "Crosscutting");
