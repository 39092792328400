import { Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FullScreenLoader from "app/components/ListingPageLoader";
import moment from "moment";

const tableHeaderCellStyle = {
  textAlign: "left",
  fontWeight: 400,
  verticalAlign: "center",
  color: "white",
  padding: "5px 5px",
};
const tableHeaderSortableLabelStyle = {
  color: "white",
  "&:hover": { color: "white" },
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white", // Set the color for the active state
  },
};
const tableBodyCellStyle = {
  textAlign: "left",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  // width: "100%",
};

export default function ListRoleTable({ searchTerm, page, setPage, sort, sortBy, setSort, setSortBy }) {
  const { allRoles, TotalPage, loading } = useSelector((state) => state.roleReducer);
  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
              }}
            >
              <TableCell
                sx={{
                  ...tableHeaderCellStyle,
                  minWidth: "180px",
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                  bgcolor: "#7352C7",
                }}
              >
                <TableSortLabel
                  active={sortBy === "role_name"}
                  direction={sort}
                  onClick={() => handleSort("role_name")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  Role Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  ...tableHeaderCellStyle,
                  minWidth: "180px",
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                  bgcolor: "#7352C7",
                }}
              >
                <TableSortLabel
                  active={sortBy === "dept_name"}
                  direction={sort}
                  onClick={() => handleSort("dept_name")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  Department Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "100px" }}>
                <TableSortLabel
                  active={sortBy === "status"}
                  direction={sort}
                  onClick={() => handleSort("status")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "180px" }}>Remarks</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                <TableSortLabel
                  active={sortBy === "created_employee_id.user_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.user_name")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "100px" }}>Created Date</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "100px" }}>Updated Date</TableCell>
              {permissions?.role?.edit === true && (
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "90px", textAlign: "center" }}>Configure</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allRoles?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.role_name}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.dept_name}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.status === false ? "INACTIVE" : "ACTIVE"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.roles_remarks ? row?.roles_remarks : "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.created_employee_id?.user_name}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{moment(row.created_at).format("DD/MM/YYYY") || "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{moment(row.updated_at).format("DD/MM/YYYY") || "-"}</TableCell>
                {permissions.role?.edit === true && (
                  <TableCell sx={{ ...tableBodyCellStyle, textAlign: "center" }}>
                    <SettingsIcon
                      sx={{ "&:hover": { cursor: "pointer", color: "black" } }}
                      onClick={() => {
                        navigate("/roles/edit", { state: row });
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          size="small"
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1,
          }}
        />
      </TableContainer>
    </>
  );
}
