import Page from "@jumbo/shared/Page";
import AddMdfStock from "app/pages/Inventory/Mdf Inventory/AddMdf";
import EditMdfStock from "app/pages/Inventory/Mdf Inventory/EditMdf";
import MdfInventoryList from "app/pages/Inventory/Mdf Inventory/ListMdf";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";
import ViewDetails from "app/pages/Inventory/Mdf Inventory/ListMdf/Approval/ViewDetails";

export const mdfInventoryRoute = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "mdf_inventory", "view"],
      },
    ],
    routes: [
      {
        path: "/inventory/mdf",
        element: <Page component={MdfInventoryList} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/mdf/add",
        element: <Page component={AddMdfStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/mdf/edit",
        element: <Page component={EditMdfStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/mdf-approval/view-details/:id/:invoiceId",
        element: <Page component={ViewDetails} layout={"vertical-default"} />,
      },
    ],
  },
];
