import { Axios } from "index";
import Swal from "sweetalert2";

export const log_no_dropdown = async (config) => {
    try {
        const response = await Axios.get(config);
        return response?.data;
    } catch (error) {
        Swal.fire({
            title: error.message,
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
        });
    }
}

// export const machineNameDropDown = async (config) => {
//     try {
//         const response = await Axios.get(config);
//         return response?.data;
//     } catch (error) {
//         Swal.fire({
//             title: error.message,
//             icon: "error",
//             timer: 1000,
//             showConfirmButton: false,
//         });
//     }
// }


export const revertCrossCuttingDone = async (id, status) => {

    const res = await Axios.post(`/factory/cross-cutting/revert-crosscutting-done-items/${id}`, { isChecked: status });
    return res
};
