import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";
import * as Yup from "yup";
import { TextField, Typography } from "@mui/material";

import { Axios } from "index";

import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import FormTextField1 from "app/components/InputField/FormTextField1";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

function CreatePersonModal({ open, handleCloseModal, branchId, setToggle }) {

  const initialValues = {
    name: "",
    email: "",
    mobile_number: "",
    designation: "",
  };

  const ContactPersonSchema = Yup.object().shape({
    name: Yup.string().required("Contact person name is required"),
    email: Yup.string().email("Invalid email format"),
    mobile_number: Yup.number(),
    // .length(10, "Mobile number must be 10 digits")
    // .required("Mobile number is required"),
    designation: Yup.string(),
  });

  const handleSubmit = async (values) => {
    try {
      const { data } = await Axios.post(`/supplier-master/add-contact-person/${branchId}`, values);

      if (data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: data.message,
          text: "",
          timer: 2000,
          showConfirmButton: false,
        });
        handleCloseModal();
        setToggle((prevState) => !prevState);
      } else {
        Swal.fire({
          icon: "error",
          title: data.message,
          text: "",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.log("creation err => ", error);
      handleCloseModal();
      Swal.fire({
        icon: "error",
        title: error.response?.data?.message,
        text: "",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className="lg:w-1/3 w-[20rem]">
          <div className="flex flex-row items-center justify-between mb-3">
            <Typography variant="h2" component="h2">
              {" "}
              Add Contact Person
            </Typography>
            <Close onClick={handleCloseModal} className="mb-4" style={{ cursor: "pointer", float: "right" }} />
          </div>
          <Formik initialValues={initialValues} validationSchema={ContactPersonSchema} onSubmit={handleSubmit}>
            {({ values, setFieldValue, errors }) => (
              <>
                <Form noValidate autoComplete="off">
                  <div className="grid grid-flow-row grid-cols-2 gap-5 place-items-center">
                    <FormTextField1 name="name" label="Name*" />
                    <FormTextField1 name="email" label="Email" />
                    <FormTextField1 name="designation" label="Designation" />
                    <FormTextField1
                      type="number"
                      sx={{
                        "& input[type=number]": {
                          MozAppearance: "textfield", // Hides the spinner in Firefox
                        },
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                          WebkitAppearance: "none", // Hides the spinner in Chrome, Safari, Edge, and Opera
                          margin: 0,
                        },
                      }}
                      name="mobile_number"
                      label="Mobile Number"
                    />
                  </div>

                  <div className="flex flex-col items-center justify-center mt-5 ">
                    <Button className="self-center" variant="contained" size="small" color="primary" type="submit">
                      Add
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default CreatePersonModal;

/* <AccordionDetails>
        <ContactPersonCrad
          totalPages={TotalPage}
          personData={branch.contact_person}
          page={page}
          rowsPerPage={rowsPerPage}
        />
        <Grid container spacing={2} className="mt-4">
          <Grid item xs={6}>
            <Typography variant="h5">Branch Name</Typography>
            <TextField
              size="small"
              fullWidth
              defaultValue={branch.branch_name}
              onChange={(e) => handleInputChange("branch_name", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5">Pincode</Typography>
            <TextField
              size="small"
              fullWidth
              defaultValue={branch.pincode}
              onChange={(e) => handleInputChange("pincode", e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">Country</Typography>
            <Select
              fullWidth
              value={branchData.country || ""}
              size="small"
              onChange={(e) => {
                const selectedCountry = e.target.value;
                const selectedCountryIsoCode =
                  Country.getCountryByCode(selectedCountry)?.isoCode;
                handleInputChange("country", selectedCountry);
                handleInputChange("countryIsoCode", selectedCountryIsoCode);
                filterStates(selectedCountryIsoCode);
              }}
            >
              <MenuItem value="">Select</MenuItem>
              {countryList.map((country) => (
                <MenuItem value={country.name} key={country.isoCode}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">State</Typography>
            <Select
              fullWidth
              value={branchData.state || ""}
              size="small"
              onChange={(e) => {
                const selectedState = e.target.value;
                const selectedStateIsoCode =
                  State.getStateByCode(selectedState)?.isoCode;
                handleInputChange("state", selectedState);
                handleInputChange("stateIsoCode", selectedStateIsoCode);
                filterCities(selectedStateIsoCode, branchData.countryIsoCode);
              }}
            >
              <MenuItem value="">Select</MenuItem>
              {stateList.map((state) => (
                <MenuItem value={state.name} key={state.isoCode}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">City</Typography>
            <Select
              fullWidth
              value={branchData.city || ""}
              size="small"
              onChange={(e) => handleInputChange("city", e.target.value)}
            >
              <MenuItem value="">Select</MenuItem>
              {cityList.map((city) => (
                <MenuItem value={city.name} key={city.isoCode}>
                  {city.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <Button
              onClick={() => handleUpdation(branch._id)}
              variant="contained"
              color="primary"
              fullWidth
            >
              Update Branch
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails> */
