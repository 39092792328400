import axios from "axios";

export const updateApprovalConfig = async (item) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(`${process.env.REACT_APP_URL}/approval-config/update-approvalConfig-master?id=${item.id}`, item, config);
    return data;
  } catch (error) {
    // console.log(data)
    return error?.response?.data;
  }
};

export const getApprovalConfig = async (item) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(`${process.env.REACT_APP_URL}/approval-config/list-approvalConfig-master`, config);
    return data;
  } catch (error) {
    // console.log(data)
    return error?.response?.data;
  }
};
