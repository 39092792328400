import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Slide,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import { flitchingDoneFilter } from "app/utils/constants/sessionFilter";
import { Axios } from "index";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import IssuesForCrosscuttingFilters from "./Filters";
import { LoadingButton } from "@mui/lab";

import CrosscuttingDoneTableListing from "./flitchingDoneTableListing";
import { fetchFlitchingDoneList } from "app/redux/actions/Factory/Flitching/flitchingDoneListing/flitchingDoneListing";

const ListFlitchingDone = () => {
    const permissions = useSelector(
        (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
    );
    const FlitchingDoneReducer = useSelector(
        (state) => state?.FlitchingDoneReducer
    );
    const [allFilterState, setAllFilterState] = useState({
        searchTerm: "",
        sort: "desc",
        sortBy: "updatedAt",
        page: 1,
        limit: 10,
        filter: null,
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
            container: "popupImportant",
        },
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const handleSearch = (search) => {
        const filter = {
            ...allFilterState,
            searchTerm: search,
            page: 1,
        };
        setAllFilterState(filter);
        dispatch(fetchFlitchingDoneList(filter));
        sessionStorage.setItem(flitchingDoneFilter, JSON.stringify(filter));
    };

    const handleChangeLimit = (event) => {
        const filter = {
            ...allFilterState,
            limit: parseInt(event.target.value),
            page: 1,
        };
        setAllFilterState(filter);
        dispatch(fetchFlitchingDoneList(filter));
        sessionStorage.setItem(flitchingDoneFilter, JSON.stringify(filter));
    };

    const handleApplyFilter = useCallback(
        (filtersData) => {
            // setPage(1);
            const filter = {
                ...allFilterState,
                sort: "desc",
                sortBy: "updatedAt",
                page: 1,
                filter: filtersData,
            };
            dispatch(fetchFlitchingDoneList(filter));
            setAllFilterState(filter);
            sessionStorage.setItem(
                flitchingDoneFilter,
                JSON.stringify(filter)
            );
        },
        [allFilterState?.limit, allFilterState?.searchTerm]
    );

    const handleClearFilter = useCallback(
        (filtersData) => {
            const filter = {
                ...allFilterState,
                sort: "desc",
                sortBy: "updatedAt",
                page: 1,
                filter: filtersData,
            };
            dispatch(fetchFlitchingDoneList(filter));
            setAllFilterState(filter);
            sessionStorage.removeItem(flitchingDoneFilter);
        },
        [allFilterState?.limit, allFilterState?.searchTerm]
    );

    const debouncedHandleSearch = debounce(handleSearch, 1000);

    useEffect(() => {
        //to deal with search lagging
        if (allFilterState?.searchTerm !== "") {
            debouncedHandleSearch(allFilterState?.searchTerm);
        }
        return () => {
            debouncedHandleSearch.cancel();
        };
    }, [allFilterState?.searchTerm]);

    useEffect(() => {
        let retrieveFilter = sessionStorage.getItem(flitchingDoneFilter);
        let filtersValues = allFilterState;
        if (retrieveFilter) {
            filtersValues = JSON.parse(retrieveFilter);
            setAllFilterState(filtersValues);
        }
        dispatch(fetchFlitchingDoneList(filtersValues));
    }, []);

    const handleExportCSV = async () => {
        try {
            // throw new Error("Create Issue for cutting api");
            setLoading(true);
            setSnackbarOpen(true);
            const res = await Axios.post("/factory/flitching/download-flitching-done-excel");
            if (res.data.success) {
                window.open(res?.data?.result);
                Toast.fire({
                    timer: 3000,
                    icon: "success",
                    title: "CSV  Downloaded Successfully...",
                    position: "top-right",
                    // background: theme.palette.background.paper,
                });
                setLoading(false);
                setSnackbarOpen(false);
            } else {
                Toast.fire({
                    timer: 3000,
                    icon: "error",
                    title: "CSV  Downloading failed..",
                    position: "top-right",
                    // background: theme.palette.background.paper,
                });
                setLoading(false);
                setSnackbarOpen(false);
            }
        } catch (error) {
            setLoading(false);
            setSnackbarOpen(false);
            Toast.fire({
                timer: 3000,
                icon: "error",
                title:
                    error.response?.data.message ||
                    error?.message ||
                    "An error occured while downloading csv",
                position: "top-right",
                // background: theme.palette.background.paper,
            });
        }
    };

    return (
        <Box>
            {FlitchingDoneReducer?.loading && <FullScreenLoader />}
            <Div>
                {/* <Typography variant="h1">Log Inventory</Typography> */}
                <Box>
                    <IssuesForCrosscuttingFilters
                        handleSubmit={handleApplyFilter}
                        handleClear={handleClearFilter}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        gap: 2,
                        my: 2,
                        justifyContent: "space-between",
                        flexWrap: "wrap-reverse",
                    }}
                >
                    <Box>
                        <TextField
                            size="small"
                            id="search"
                            type="search"
                            label="Search"
                            value={allFilterState?.searchTerm}
                            onChange={(e) => {
                                setLoaded(false);
                                setAllFilterState((prev) => ({
                                    ...prev,
                                    searchTerm: e.target.value,
                                }));
                                if (!e.target.value) {
                                    const filter = {
                                        ...allFilterState,
                                        searchTerm: "",
                                        sort: "desc",
                                        sortBy: "updatedAt",
                                        page: 1,
                                    };
                                    setAllFilterState(filter);
                                    dispatch(fetchFlitchingDoneList(filter));
                                    sessionStorage.setItem(
                                        flitchingDoneFilter,
                                        JSON.stringify(filter)
                                    );
                                }
                            }}
                            sx={{
                                width: 300,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <Div sx={{ cursor: "pointer" }}>
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    </Div>
                                ),
                            }}
                        />
                    </Box>

                    <Box display={"flex"} gap={2} flexWrap={"wrap"}>
                        {permissions?.crosscut_factory?.view && (
                            <LoadingButton
                                loading={loading}
                                disabled={snackbarOpen ? true : false}
                                size="small"
                                variant="contained"
                                onClick={handleExportCSV}
                            >
                                Export CSV
                            </LoadingButton>
                        )}
                        <Snackbar
                            TransitionComponent={Slide}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            open={snackbarOpen}
                            message=" CSV Downloading in progress..."
                            action={loading && <CircularProgress color="info" size={24} />}
                        />
                        {/* {permissions?.log_inventory_create && (
                        <Div>
                            <form>
                                <input type="file" onChange={handleFileChange} style={{ display: "none" }} id="fileInput" />
                                <label htmlFor="fileInput">
                                    <Button size="small" variant="contained" color="primary" component="span" sx={{ height: "100%" }}>
                                        Import
                                    </Button>
                                </label>
                            </form>
                        </Div>
                    )} */}

                        <TextField
                            sx={{ width: "100px" }}
                            type="number"
                            size="small"
                            inputProps={{
                                min: 1,
                            }}
                            label="Page No"
                            onKeyDown={(e) => {
                                const inputValue = parseInt(e.target.value + e.key, 10);
                                if (isNaN(inputValue) || inputValue < 0) {
                                    e.preventDefault();
                                }
                            }}
                            value={allFilterState?.page}
                            onChange={(e) => {
                                let value = parseInt(e.target.value);
                                // if(value > issuesForCrosscuttingReducer?.totalPage){
                                //     return;
                                // }
                                setLoaded(false);
                                const filter = {
                                    ...allFilterState,
                                    page: value,
                                };
                                setAllFilterState(filter);
                                dispatch(fetchFlitchingDoneList(filter));
                                sessionStorage.setItem(
                                    flitchingDoneFilter,
                                    JSON.stringify(filter)
                                );
                            }}
                        ></TextField>

                        <FormControl size="small">
                            <InputLabel id="limit-label">Limit</InputLabel>
                            <Select
                                autoSelect
                                autoHighlight
                                labelId="limit-label"
                                id="limit"
                                value={allFilterState?.limit}
                                label="Limit"
                                onChange={handleChangeLimit}
                                sx={{ width: "80px" }}
                            >
                                {/* <MenuItem value={1}>1</MenuItem> */}
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={75}>75</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <CrosscuttingDoneTableListing
                    allFilterState={allFilterState}
                    setAllFilterState={setAllFilterState}
                />
            </Div>
        </Box>
    );
};

export default ListFlitchingDone;
