export const listingIssuesForCrosscutting = '/factory/cross-cutting/listing_issue_for_crosscutting';
export const revertIssuesForCrosscuttingApi = function (issue_for_crosscutting_id) {
    return `/factory/cross-cutting/revert-issue-for-crosscutting/${issue_for_crosscutting_id}`
};
export const rejectIssuesForCrosscuttingApi = function (issue_for_crosscutting_id) {
    return `/factory/rejected-crosscutting/add-reject-crosscutting/${issue_for_crosscutting_id}`
};

export const logNoDropdownApi = "/factory/cross-cutting/log-no-dropdown";
export const machineNameDropdownApi =
    "/factory/cross-cutting/machine-name-dropdown";
