import Div from "@jumbo/shared/Div";
import { Box, Button, Checkbox, FormControlLabel, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { formatAmount } from "app/utils/formatAmount";
import moment from "moment";
const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  // width: "100%",
  borderRight: "1px solid #7352C7",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "90vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};
const disabledCheckBoxStyling = {
  "& .css-jxecqt-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled": {
    color: "#7352C7",
  },
  "& .MuiFormControlLabel-label": {
    color: "#475259 !important", // Change label color to blue
  },
};

const ItemDetailsModal = ({ itemDetailsForModal, setOpenDetailModal }) => {
  const invoiceData = itemDetailsForModal?.veneer_invoice_details;
  const invoiceDetails = itemDetailsForModal?.veneer_invoice_details?.invoice_Details;
  const invoiceSupplierDetails = itemDetailsForModal?.veneer_invoice_details?.supplier_details;
  const invoiceSupplierContactPersonDetails =
    itemDetailsForModal?.veneer_invoice_details?.supplier_details?.branch_detail?.contact_person || [];

  return (
    <Box sx={style}>
      <Div>
        <Typography variant="h5" mb={0}>
          Invoice Details
        </Typography>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: "#7352C7",
                  color: "white",
                }}
              >
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "100px" }}>Inward Sr.no</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Inward Date</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "80px" }}>Invoice No</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Date</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>No Of Workers</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Shift</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>No Of Working hours</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>No Of Total hours</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Currency</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Transporter Details</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Port Of Loading</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Port Of Discharge</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Bill Of Landing</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Freight</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Load Unload</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Insurance</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Other</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Other For Import</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>GST Percentage</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Total Item Amt</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>GST Amt</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Amt</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceData?.inward_sr_no}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{moment(invoiceData?.inward_date).format("DD/MM/YYYY") || "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceDetails?.invoice_no}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{moment(invoiceDetails?.invoice_date).format("DD/MM/YYYY") || "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceData?.workers_details?.no_of_workers}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceData?.workers_details?.shift}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceData?.workers_details?.working_hours}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceData?.workers_details?.total_hours}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceData?.currency}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceDetails?.transporter_details}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceDetails?.port_of_loading}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceDetails?.port_of_discharge}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceDetails?.bill_of_landing}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{
                  invoiceDetails?.isFreightInclude ?
                    <FormControlLabel disabled sx={{ ...disabledCheckBoxStyling }} control={<Checkbox size="small" checked={invoiceDetails?.isFreightInclude} />} label={invoiceDetails?.freight} /> :
                    invoiceDetails?.freight
                }</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}> {
                  invoiceDetails?.isLoadUnloadInclude ?
                    <FormControlLabel disabled sx={{ ...disabledCheckBoxStyling }} control={<Checkbox size="small" checked={invoiceDetails?.isLoadUnloadInclude} />} label={invoiceDetails?.load_unload} /> :
                    invoiceDetails?.load_unload
                }</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{
                  invoiceDetails?.isInsuranceInclude ?
                    <FormControlLabel disabled sx={{ ...disabledCheckBoxStyling }} control={<Checkbox size="small" checked={invoiceDetails?.isInsuranceInclude} />} label={invoiceDetails?.insurance} /> :
                    invoiceDetails?.insurance
                }</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{
                  invoiceDetails?.isOtherInclude ?
                    <FormControlLabel disabled sx={{ ...disabledCheckBoxStyling }} control={<Checkbox size="small" checked={invoiceDetails?.isOtherInclude} />} label={invoiceDetails?.other} /> :
                    invoiceDetails?.other
                }</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceDetails?.other_for_import}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceDetails?.gst_percentage}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{formatAmount(Math.round(invoiceDetails?.total_item_amount))}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceDetails?.gst_value}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{formatAmount(Math.round(invoiceDetails?.invoice_value_with_gst))}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceDetails?.remark}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Div>

      <Div sx={{ marginTop: "30px" }}>
        <Typography variant="h5" mb={0}>
          Supplier Details
        </Typography>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: "#7352C7",
                  color: "white",
                }}
              >
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Name</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Branch Name</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>GST No.</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "200px" }}>Address</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>City</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>State</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Country</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Pincode</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Web Url</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceSupplierDetails?.company_details?.supplier_name}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceSupplierDetails?.branch_detail?.branch_name}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceSupplierDetails?.branch_detail?.gst_number}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceSupplierDetails?.branch_detail?.address}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceSupplierDetails?.branch_detail?.city}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceSupplierDetails?.branch_detail?.state}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceSupplierDetails?.branch_detail?.country}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceSupplierDetails?.branch_detail?.pincode}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{invoiceSupplierDetails?.branch_detail?.web_url}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Div>

      <Div sx={{ marginTop: "30px" }}>
        <Typography variant="h5" mb={0}>
          Contact Person Details
        </Typography>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: "#7352C7",
                  color: "white",
                }}
              >
                <TableCell sx={{ ...tableHeaderCellStyle, width: "25%" }}>Person Name</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "25%" }}>Email Id</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "25%" }}>Mobile No.</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "25%" }}>Designation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceSupplierContactPersonDetails &&
                invoiceSupplierContactPersonDetails?.map((ele) => (
                  <TableRow>
                    <TableCell sx={{ ...tableBodyCellStyle }}>{ele?.name || "-"}</TableCell>
                    <TableCell sx={{ ...tableBodyCellStyle }}>{ele?.email || "-"}</TableCell>
                    <TableCell sx={{ ...tableBodyCellStyle }}>{ele?.mobile_number || "-"}</TableCell>
                    <TableCell sx={{ ...tableBodyCellStyle }}>{ele?.designation || "-"}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Div>
      <Div sx={{ textAlign: "end", marginTop: "20px" }}>
        <Button variant="contained" size="small" onClick={() => setOpenDetailModal(false)}>Close</Button>
      </Div>
    </Box>
  );
};

export default ItemDetailsModal;
