import Div from '@jumbo/shared/Div';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { hideLoading, setLoading } from 'app/redux/actions/Loading';
import { Axios } from 'index';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListingFlitchingItems from './ListingFlitchingItems';
import { fetchFlitchingDoneItemsApprovalApi, flitchingDoneApproveInvoiceApi, flitchingDoneRejectInvoiceApi } from 'app/services/apis/Factory/Flitching/flitchingDone/flitchingDoneApi';
import HeaderTitleHoc from 'app/components/HeaderTitleHoc';

const FlitchingApprovalDetails = () => {
    const [flitchingApprovalData, setFlitchingApprovalData] = useState([])
    const { id, issue_for_flitching_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isApprovalPending = flitchingApprovalData?.[0]?.approval_status?.sendForApproval?.status || false;
    const isApproved = flitchingApprovalData?.[0]?.approval_status?.approved?.status || false;
    const isRejected = flitchingApprovalData?.[0]?.approval_status?.rejected?.status || false;


    const fetchItemDetails = async function () {
        try {
            dispatch(setLoading());
            const response = await Axios.get(fetchFlitchingDoneItemsApprovalApi(id, issue_for_flitching_id));
            if (response?.data?.status === "success") {
                const data = response?.data?.data?.flitching_details;
                setFlitchingApprovalData(data);
            }
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "error",
                text: error?.response?.data?.message || error?.message,
                showConfirmButton: false,
                timer: 1500,
            });
        } finally {
            dispatch(hideLoading());
        }
    }
    async function handleApprove() {
        try {
            dispatch(setLoading());
            const response = await Axios.post(flitchingDoneApproveInvoiceApi(id, issue_for_flitching_id));
            if (response?.data?.status === "success") {
                const data = response?.data?.data
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Approved",
                    text: data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                navigate('/factory/flitching')
            }
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "error",
                text: error?.response?.data?.message || error?.message,
                showConfirmButton: false,
                timer: 1500,
            });
        } finally {
            dispatch(hideLoading());
        }
    }
    async function handleReject() {
        try {
            dispatch(setLoading());
            const response = await Axios.post(flitchingDoneRejectInvoiceApi(id, issue_for_flitching_id), {
                remark: "Rejected"
            });
            if (response?.data?.status === "success") {
                const data = response?.data?.data
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Rejected",
                    text: data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                navigate('/factory/flitching')
            }
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "error",
                text: error?.response?.data?.message || error?.message,
                showConfirmButton: false,
                timer: 1500,
            });
        } finally {
            dispatch(hideLoading());
        }
    }

    useEffect(() => {
        fetchItemDetails()
    }, [])
    return (
        <Box sx={{ mt: -4 }}>
            <ListingFlitchingItems
                itemDetails={flitchingApprovalData}
                isApprovalPending={isApprovalPending}
            />
            <Div
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 5,
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                        Swal.fire({
                            title: "Warning",
                            text: "Are you sure you want to Cancel?",
                            icon: "error",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate("/factory/flitching");
                            }
                        });
                    }}
                >
                    Cancel
                </Button>
                {
                    !isApproved && !isRejected && <>
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => {
                                Swal.fire({
                                    title: "Reject ?",
                                    text: "Are you sure you want to Reject?",
                                    icon: "error",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "No",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        handleReject()
                                    }
                                });
                            }}
                            disabled={isRejected}
                        >
                            Reject
                        </LoadingButton>
                        <LoadingButton
                            color="success"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                Swal.fire({
                                    title: "Approve ?",
                                    text: "Are you sure you want to Approve?",
                                    icon: "success",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "No",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        handleApprove()
                                    }
                                });
                            }}
                            disabled={isApproved}
                        >
                            Approve
                        </LoadingButton>
                    </>
                }
                {isApproved && <Typography variant='h4'><CheckCircleIcon color='success' sx={{ fontSize: "30px" }} /> Approved</Typography>}
                {isRejected && <Typography variant='h4'><CancelIcon color='error' sx={{ fontSize: "30px" }} /> Rejected</Typography>}
            </Div>
        </Box>

    )
}

export default HeaderTitleHoc(FlitchingApprovalDetails,"Flitching Done Approval Details")