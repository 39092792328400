import { filterObjectWithValues } from "app/utils/filterObjectWithValues";
import { Axios } from "index";
import {
    FLEECE_EXPENSES_REQUEST,
    FLEECE_EXPENSES_SUCCESS,
    FLEECE_EXPENSES_FAILED
} from "./constant";
import { fetchFleeceExpensesApi } from "app/services/apis/Master/Expenses";
import { hideLoading, setLoading } from "app/redux/actions/Loading";

export const fetchFleeceExpenses = function ({
    searchTerm = "",
    sort = "desc",
    sortBy = "updatedAt",
    page = 1,
    limit = 10,
    filter = {},
}) {
    return async function (dispatch) {
        try {
            dispatch(setLoading());
            dispatch({ type: FLEECE_EXPENSES_REQUEST });

            const body = {
                filter: {
                    ...filterObjectWithValues(filter),
                },
                searchFields: {
                    string: [
                        "invoice_Details.remark",
                        "supplier_details.company_details.supplier_name",
                        "supplier_details.branch_detail.branch_name",
                    ],
                    numbers: [
                        "inward_sr_no",
                        "invoice_Details.invoice_no",
                        "invoice_Details.invoice_value_with_gst",
                        "totalExpenseAmount"
                    ],
                    arrayField: [],
                },
            };
            if (!searchTerm) {
                searchTerm = "";
            }

            const config = {
                withCredentials: true,
                headers: {
                    withCredentials: true,
                },
            };

            const urlParams = new URLSearchParams({
                search: searchTerm.trim(),
                page: page,
                sort: sort,
                sortBy: sortBy,
                limit: limit,
            });

            const response = await Axios.post(
                `${fetchFleeceExpensesApi}?${urlParams.toString()}`,
                body,
                config
            );
            dispatch({
                type: FLEECE_EXPENSES_SUCCESS,
                payload: {
                    data: response?.data?.data,
                    totalPage: response?.data?.totalPage,
                },
            });
        } catch (error) {
            console.error(error)
            dispatch({
                type: FLEECE_EXPENSES_FAILED,
                payload: {
                    isError: true,
                    message: error.message,
                },
            });
        } finally {
            dispatch(hideLoading())
        }
    };
};