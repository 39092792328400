import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Grid,
  Typography,
  Autocomplete,
  Box,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormTextField1 from "app/components/InputField/FormTextField1";
import Div from "@jumbo/shared/Div";
import { addItemCategory } from "app/services/apis/addItemCategory";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Axios } from "index";
import { LoadingButton } from "@mui/lab";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";

function AddItemPage() {
  const navigate = useNavigate();
  const [unitList, setUnitList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const initialValues = {
    category: "",
    product_hsn_code: "",
    calculate_unit: "",
  };
  const validationSchema = Yup.object({
    category: Yup.string().required("Category is required"),
    product_hsn_code: Yup.string()
      .required("HSN Code is required")
      .matches(/^[0-9]+$/, "HSN Code must be numeric")
      .min(6, "HSN Code must be at least 6 digits"),
    calculate_unit: Yup.string().required("Please select a unit"),
  });

  const onSubmit = async (values) => {
    setSubmitting(true);
    console.log("Form Data:", values);
    values.category = values.category?.toUpperCase();
    const response = await addItemCategory(values);
    if (response.data?.statusCode === 200) {
      Swal.fire({
        icon: "success",
        text: "Category Added Successfully",
        // text: "",
        timer: 2000,
        showConfirmButton: false,
      });
      setSubmitting(false);
      navigate("/master/item-category/list");
    } else {
      setSubmitting(false);
      Swal.fire({
        icon: "error",
        text: response?.data?.message,
        // text: "",
        timer: 2000,
        showConfirmButton: true,
      });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get("/unit-master/dropdown-unit-master");
        setUnitList(res.data.result);
      } catch (error) {
        console.log("err fetching all units => ", error.message);
      }
    };
    fetchData();
  }, []);

  const [inlineText, setInlineText] = useState("");

  const handleInputChange = (event, value) => {
    // Find the first match for inline suggestion
    const match = unitList.find((unit) =>
      unit.unit_name.toLowerCase().startsWith(value.toLowerCase())
    );
    // Update the inline text
    if (match && value) {
      setInlineText(match.unit_name.slice(value.length));
    } else {
      setInlineText("");
    }
  };

  return (
    <div>
      {/* <Typography variant="h2">Add Category</Typography> */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors, setFieldTouched, touched }) => (
          <Form>

            <div className="grid grid-flow-row lg:grid-cols-3  gap-6 mt-6">
              <FormTextField1 name="category" label="Category*" />

              <div className="flex flex-col ">
                <Typography variant="h5">Calculate Unit*</Typography>
                {/* <Autocomplete
                  autoSelect
                  autoHighlight
                  options={unitList}
                  getOptionLabel={(option) => option?.unit_name || ""}
                  
                  onBlur={() => setFieldTouched("calculate_unit", true)}
                  name="calculate_unit"
                  value={unitList?.find((unit) => unit.unit_name === values.calculate_unit) || null}

                  size="small"
                  onChange={(e, selectedVal) => {
                    setFieldValue("calculate_unit", selectedVal?.unit_name);
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option?.unit_name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={touched.calculate_unit && Boolean(errors.calculate_unit)}
                      helperText={touched.calculate_unit && errors.calculate_unit}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                /> */}
                <Autocomplete
                  autoSelect
                  autoHighlight
                  options={unitList}
                  getOptionLabel={(option) => option?.unit_name || ""}
                  onBlur={() => setFieldTouched("calculate_unit", true)}
                  name="calculate_unit"
                  value={unitList?.find((unit) => unit.unit_name === values.calculate_unit) || null}
                  size="small"
                  onChange={(e, selectedVal) => {
                    setFieldValue("calculate_unit", selectedVal?.unit_name);
                  }}
                  onInputChange={(e, value) => handleInputChange(e, value)}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option?.unit_name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={touched.calculate_unit && Boolean(errors.calculate_unit)}
                      helperText={touched.calculate_unit && errors.calculate_unit}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {/* Inline text */}
                            {inlineText && (
                              <span
                                style={{
                                  color: "#ccc",
                                  position: "absolute",
                                  pointerEvents: "none",
                                  left: `${params.inputProps.value.length}ch`,
                                }}
                              >
                                {inlineText}
                              </span>
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <FormTextField1
                name="product_hsn_code"
                label="Product HSN Code*"
              />
            </div>
            <div className="flex flex-row items-center justify-center gap-5 mt-8">
              <Button
                // type="submit"
                variant="outlined"
                size="small"
                sx={{ py: 1 }}
                // color="secondary"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure you want to cancel?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/master/item-category/list");
                    }
                  });
                }}
              >
                Cancel
              </Button>
              {/* <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
              >
                Add Category
              </Button> */}
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ width: "100px" }}
                loading={submitting}
              >
                Save
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default HeaderTitleHoc(AddItemPage, "Add Category");
