import axios from "axios";

export const dropdownSeriesName = async () => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.get(`${process.env.REACT_APP_URL}/series-master/dropdown-series-master`,config);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };