import Page from "@jumbo/shared/Page/Page";
import ChangePassword from "app/pages/ChangePassword";
import ListRole from "app/pages/RoleManagement/ListRole";
import RoleLogTable from "app/pages/RoleManagement/RolesLog";
import AddUser from "app/pages/UserManagement/AddUser";
import ListUser from "app/pages/UserManagement/ListUser";
import UserLogTable from "app/pages/UserManagement/UserLog";
import EditRole from "app/pages/RoleManagement/EditRole";
import AddRole from "app/pages/RoleManagement/AddRole";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";
import EditUser from "app/pages/UserManagement/EditUser";

const userManagementRoutes = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "user", "view"],
      },
    ],
    routes: [
      {
        path: "/user",
        element: <Page component={ListUser} layout={"vertical-default"} />,
      },

      {
        path: "/user/add",
        element: <Page component={AddUser} layout={"vertical-default"} />,
      },
      {
        path: "/user/edit",
        element: <Page component={EditUser} layout={"vertical-default"} />,
      },
      {
        path: "/user/log",
        element: <Page component={UserLogTable} layout={"vertical-default"} />,
      },
      {
        path: "/user/change-password",
        element: <Page component={ChangePassword} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "role", "view"],
      },
    ],
    routes: [
      {
        path: "/roles",
        element: <Page component={ListRole} layout={"vertical-default"} />,
      },
      {
        path: "/roles/log",
        element: <Page component={RoleLogTable} layout={"vertical-default"} />,
      },
      {
        path: "/roles/add",
        element: <Page component={AddRole} layout={"vertical-default"} />,
      },
      {
        path: "/roles/edit",
        element: <Page component={EditRole} layout={"vertical-default"} />,
      },
    ],
  },

  //   {
  //     path: "/manage/users",
  //     element: <Page component={UsersList} layout={"vertical-default"} />,
  //   },
  //   {
  //     path: "/add/user",
  //     element: <Page component={AddUser} layout={"vertical-default"} />,
  //   },
];

export default userManagementRoutes;
