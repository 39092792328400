import { TableCell } from "@mui/material";

const tableBodyCell = { textAlign: "left", px: 1 };

export const ApprovalStatusTableCell = ({ approval_status }) => {
  if (approval_status?.sendForApproval?.status) {
    return <TableCell sx={{ ...tableBodyCell, color: "orange" }}>PENDING</TableCell>;
  } else if (approval_status?.approved?.status) {
    return <TableCell sx={{ ...tableBodyCell, color: "green" }}>APPROVED</TableCell>;
  } else if (approval_status?.rejected?.status) {
    return <TableCell sx={{ ...tableBodyCell, color: "red" }}>REJECTED</TableCell>;
  } else {
    return <TableCell sx={{ ...tableBodyCell }}>--</TableCell>;
  }
};
