import { Autocomplete, Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  inwardSrnoDropdown,
  itemSrnoDropdown,
} from "../../../../../../services/apis/Inventory/ApiCall";
import { itemNameDropDown } from "app/services/apis/addItemName";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { supplierDropDownList } from "app/services/apis/supplierList";

import { plywoodInwardSrnoDropdownApi, plywoodItemSrnoDropdownApi } from "app/services/apis/Inventory/plywood";

const datepickerStyling = {
  width: "100%",
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#aeb7bd ", // or any other color you prefer
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#475259", // Change to the color you want on hover
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#7352C7", // Change to the color you want when focused
  },
  "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
  {
    borderColor: "#7352C7", // Keep the same color as focused when hovered
  },
  "& .MuiInputLabel-outlined": {
    color: "#98a3aa", // Default label color
    "&.Mui-focused": {
      color: "#7352C7", // Label color on focus
    },
  },
};

export const ItemSrNo = React.memo(({ value, setFilters }) => {
  const [itemSrno, setItemSrno] = useState([]);
  // console.log("first", 1); 

  useEffect(() => {
    (async () => {
      const { data } = await itemSrnoDropdown(plywoodItemSrnoDropdownApi);
      setItemSrno(data);
    })();
  }, []);

  return (
    <Autocomplete
      autoSelect
      autoHighlight
      key={value}
      sx={{ width: "100%", textTransform: "capitalize" }}
      size="small"
      id="role-autocomplete"
      value={value || null}
      options={itemSrno || []}
      getOptionLabel={(option) => option?.toString()}
      onChange={(e, newValue) => {
        setFilters((prev) => ({ ...prev, item_sr_no: newValue }));
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option}
        </Box>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Item Sr.no" />
      )}
    />
  );
});

export const InwardSrNo = React.memo(({ value, setFilters }) => {
  const [inwardSrno, setInwardSrno] = useState([]);
  // console.log("first", 2);

  useEffect(() => {
    (async () => {
      const { data } = await inwardSrnoDropdown(plywoodInwardSrnoDropdownApi);
      setInwardSrno(data);
    })();
  }, []);

  return (
    <Autocomplete
      autoSelect
      autoHighlight
      key={value}
      sx={{ width: "100%", textTransform: "capitalize" }}
      size="small"
      id="role-autocomplete"
      value={value || null}
      options={inwardSrno || []}
      getOptionLabel={(option) => option?.toString()}
      onChange={(e, newValue) => {
        setFilters((prev) => ({
          ...prev,
          "plywood_invoice_details.inward_sr_no": newValue,
        }));
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option}
        </Box>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Inward Sr.no" />
      )}
    />
  );
});

export const ItemName = React.memo(({ value, setFilters }) => {
  const [itemName, setItemName] = useState([]);
  // console.log("first", 3);

  useEffect(() => {
    (async () => {
      const {
        data: { result },
      } = await itemNameDropDown("PLYWOOD");
      setItemName(result);
    })();
  }, []);

  return (
    <Autocomplete
      autoSelect
      autoHighlight
      key={value}
      sx={{ width: "100%", textTransform: "capitalize" }}
      size="small"
      id="role-autocomplete"
      value={value || null}
      options={itemName || []}
      getOptionLabel={(option) => {
        if (option instanceof Object && !Array.isArray(option)) {
          return option.item_name;
        } else {
          return option;
        }
      }}
      isOptionEqualToValue={(option, value) => option?.item_name === value}
      onChange={(e, newValue) => {
        setFilters((prev) => ({ ...prev, item_name: newValue?.item_name }));
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option?.item_name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Item Name" />
      )}
    />
  );
});

export const SupplierList = React.memo(({ name, value, setFilters }) => {
  const [suppliers, setSuppliers] = useState([]);
  // console.log("first", 3);

  useEffect(() => {
    (async () => {
      const {
        data: { result },
      } = await supplierDropDownList("PLYWOOD");
      setSuppliers(result);
    })();
  }, []);

  return (
    <Autocomplete
      autoSelect
      autoHighlight
      key={value}
      sx={{ width: "100%", textTransform: "capitalize" }}
      size="small"
      id="role-autocomplete"
      value={value || null}
      options={suppliers || []}
      getOptionLabel={(option) => {
        if (option instanceof Object && !Array.isArray(option)) {
          return option.supplier_name;
        } else {
          return option;
        }
      }}
      isOptionEqualToValue={(option, value) => option?.supplier_name === value}
      onChange={(e, newValue) => {
        setFilters((prev) => ({ ...prev, [name]: newValue?.supplier_name }));
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option?.supplier_name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Suppliers" />
      )}
    />
  );
});

export const CustomDatePicker = React.memo(
  ({ name, position, title, value, setFilters }) => {
    // console.log("first", 4, value, dayjs(value, "DD/MM/YYYY").startOf("day"));
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          key={value}
          id={title}
          format="DD/MM/YYYY"
          name={title}
          label={title}
          sx={datepickerStyling}
          maxDate={dayjs()}
          value={value !== "" ? dayjs(value, "YYYY-MM-DD") : null} // Use YYYY-MM-DD format
          onChange={(newValue) => {
            if (newValue) {
              let newDateValue = dayjs(newValue).format("YYYY-MM-DD"); // Only keep date, ignore time
              newDateValue = new Date(newDateValue);
              if (!isNaN(new Date(newDateValue))) {
                if (position === "from") {
                  newDateValue.setUTCHours(0, 0, 0, 0); // Set to midnight for 'from'
                } else {
                  newDateValue.setUTCHours(23, 59, 59, 999); // Set to end of the day for 'to'
                }

                setFilters((prev) => {
                  const updatedData = { ...prev };
                  updatedData.range.date[name][position] =
                    newDateValue.toISOString(); // Store ISO string without time consideration
                  return updatedData;
                });
              }
            }
          }}
          slotProps={{ textField: { size: "small" } }}
        />
      </LocalizationProvider>
    );
  }
);

export const ApprovalList = React.memo(({ filters, setFilters, pending, approved, rejected }) => {
  const [approvalStatus, setApprovalStatus] = React.useState('');

  const handleChange = (event) => {
    const status = event.target.value
    setApprovalStatus(status);
    if (status === "pending") {
      setFilters((prev) => ({
        ...prev,
        [pending]: true,
        [approved]: null,
        [rejected]: null
      }))
    } else if (status === "approved") {
      setFilters((prev) => ({
        ...prev,
        [pending]: null,
        [approved]: true,
        [rejected]: null
      }))
    } else if (status === "rejected") {
      setFilters((prev) => ({
        ...prev,
        [pending]: null,
        [approved]: null,
        [rejected]: true
      }))
    } else {
      setFilters((prev) => ({
        ...prev,
        [pending]: null,
        [approved]: null,
        [rejected]: null
      }))
    }
  };

  const setApprovalStatusFunction = () => {
    if (filters?.[pending]) {
      setApprovalStatus('pending')
    } else if (filters?.[approved]) {
      setApprovalStatus('approved')
    } else if (filters?.[rejected]) {
      setApprovalStatus('rejected')
    } else {
      setApprovalStatus('')
    }
  }

  useEffect(() => {
    setApprovalStatusFunction()
  }, [filters?.[pending], filters?.[approved], filters?.[rejected]])

  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <InputLabel id="approval-status-select-small-label">Approval Status</InputLabel>
      <Select
        autoSelect
        autoHighlight
        labelId="approval-status-select-small-label"
        id="approval-status-select-small"
        value={approvalStatus}
        label="Approval Status"
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={'pending'}>Pending</MenuItem>
        <MenuItem value={'approved'}>Approved</MenuItem>
        <MenuItem value={'rejected'}>Rejected</MenuItem>
      </Select>
    </FormControl>
  );
})