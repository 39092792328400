import Page from "@jumbo/shared/Page";
import VeneerExpenses from "app/pages/Master/ExpenseMaster/VeneerExpenses";
import AddExpenses from "app/pages/Master/ExpenseMaster/VeneerExpenses/AddExpenses";
import AccessMiddleware from "app/routes/middleware/auth/AccessMiddleware";

export const veneerExpenseRoutes = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard", "expense_master", "view"],
      },
    ],
    routes: [
      {
        path: "/master/expense/veneer",
        element: <Page component={VeneerExpenses} layout={"vertical-default"} />,
      },
      {
        path: "/master/expense/veneer/add/:invoiceId",
        element: <Page component={AddExpenses} layout={"vertical-default"} />,
      }
    ],
  },
];
