import Page from "@jumbo/shared/Page";
import LogInventoryList from "app/pages/Inventory/LogInventory/ListLog";
import AddLogStock from "app/pages/Inventory/LogInventory/AddLog";
import EditLogStock from "app/pages/Inventory/LogInventory/EditLog";
import AccessMiddleware from "./middleware/auth/AccessMiddleware";
import ViewDetails from "app/pages/Inventory/LogInventory/ListLog/Approval/ViewDetails";

export const logInventoryRoute = [
  {
    middleware: [
      {
        element: AccessMiddleware,
        fallbackPath: ["/dashboard","log_inventory","view"],
      },
    ],
    routes: [
      {
        path: "/inventory/log",
        element: (
          <Page component={LogInventoryList} layout={"vertical-default"} />
        ),
      },
      {
        path: "/inventory/log/add",
        element: <Page component={AddLogStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/log/edit",
        element: <Page component={EditLogStock} layout={"vertical-default"} />,
      },
      {
        path: "/inventory/log-approval/view-details/:id/:invoiceId",
        element: <Page component={ViewDetails} layout={"vertical-default"} />,
      },
    ],
  },
];
