import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  width: "100%",
};
const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 3000,
  customClass: {
    container: "popupImportant",
  },
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const AddItemModal = ({
  setAddedItemDetailsList,
  addedItemDetailsList,
  itemNameList,
  finalData,
  gradeNameList,
  initialItemForm, setInitailItemForm
}) => {
  const { state, pathname } = useLocation();

  // const stock = {
  //   invoice_id: state?.data?.invoice_id,
  //   item_sr_no: addedItemDetailsList.length + 1 || 1,
  //   supplier_item_name: "",
  //   item_name: "",
  //   item_id: "",
  //   grade_name: "",
  //   grade_id: "",
  //   length: 0,
  //   width: 0,
  //   thickness: 0,
  //   number_of_sheets: 0,
  //   total_sq_meter: 0,
  //   exchange_rate: finalData?.exchange_rate || 0,
  //   rate_in_currency: 0,
  //   rate_in_inr: 0,
  //   amount: 0,
  //   remark: "",
  //   created_by: state?.data?.created_by,
  // };

  const itemDetail = {
    ...initialItemForm,
    item_sr_no: addedItemDetailsList.length + 1 || 1,
    exchange_rate: finalData?.exchange_rate || 0,
  }

  const validationSchema = yup.object({
    supplier_item_name: yup.string("Enter Supplier Name").required("Supplier Name is required"),
    item_name: yup.string().required("Required"),
    length: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    width: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    thickness: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    number_of_sheets: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    total_sq_meter: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    grade_name: yup.string().required("Required"),
    // exchange_rate: yup
    //   .number()
    //   // .positive()
    //   // .moreThan(0, "Must be greater than 0")
    //   // .required("Required")
    //   .when((currency, schema) =>
    //     finalData?.currency !== "INR"
    //       ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.")
    //       : schema.notRequired()
    //   ),
    rate_in_currency: yup
      .number()
      .when((currency, schema) =>
        finalData?.currency !== "INR"
          ? schema.positive().moreThan(0, "Must be greater than 0").required("Required.")
          : schema.notRequired()
      ),
    rate_in_inr: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    amount: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
  });

  const handleSubmit = async (values) => {
    setAddedItemDetailsList([...addedItemDetailsList, { ...values, created_by: state?.data?.created_by }]);
    Toast.fire({
      timer: 3000,
      icon: "success",
      text: "Item Added Successfully.",
      position: "top-right",
      // background: theme.palette.background.paper,
    });
    setInitailItemForm((prev) => ({ ...values, item_sr_no: prev?.item_sr_no + 1 }))
  };

  const handleCalculateTotalSqm = (Length, width, thickness, numberOfSheet, setFieldValue, values) => {
    let totalSqm = Number((Number(Length) * Number(width) * Number(numberOfSheet))?.toFixed(3));
    setFieldValue("total_sq_meter", Number(totalSqm));
    let amount = Number((Number(totalSqm) * Number(values?.rate_in_inr))?.toFixed(2));
    setFieldValue("amount", Number(amount));
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={itemDetail}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors, handleBlur, initialValues, setFieldTouched, touched, isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                  }}
                >
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Item Name</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Name</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Grade</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Length</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Thickness</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Number Of Sheets</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Total Sq.m.</TableCell>
                  {/* {finalData?.currency !== "INR" ? (
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Exchange Rate</TableCell>
                  ) : (
                    ""
                  )} */}
                  {finalData?.currency !== "INR" ? (
                    <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in Currency</TableCell>
                  ) : (
                    ""
                  )}
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate in INR</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Amount</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ ...tableBodyCellStyle }}>{values?.item_sr_no}</TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.supplier_item_name}
                      sx={{ width: "100%" }}
                      name="supplier_item_name"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("supplier_item_name", e.target.value)}
                      error={touched?.supplier_item_name && errors?.supplier_item_name}
                      helperText={touched?.supplier_item_name && errors?.supplier_item_name}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={itemNameList}
                      name="item_name"
                      value={values?.item_name}
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option.item_name;
                        } else {
                          return option;
                        }
                      }}
                      onBlur={() => setFieldTouched("item_name", true)}
                      onChange={(e, selectedData) => {
                        setFieldValue("item_name", selectedData?.item_name || "");
                        setFieldValue("item_id", selectedData?._id || "");
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.item_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.item_name && Boolean(errors.item_name)} // Show error if touched and there's an error
                          helperText={touched.item_name && errors.item_name}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      options={gradeNameList}
                      name="grade_name"
                      value={values?.grade_name}
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option.grade_name;
                        } else {
                          return option;
                        }
                      }}
                      onBlur={() => setFieldTouched("grade_name", true)}
                      onChange={(e, selectedData) => {
                        // setFinalData({ ...finalData, grade_name: selectedData });
                        setFieldValue("grade_name", selectedData?.grade_name || "");
                        setFieldValue("grade_id", selectedData?._id || "");
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.grade_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.grade_name && Boolean(errors.grade_name)} // Show error if touched and there's an error
                          helperText={touched.grade_name && errors.grade_name}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.["length"] || ""}
                      sx={{ width: "100%" }}
                      name="length"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateTotalSqm(e.target.value, values?.width, values?.thickness, values?.number_of_sheets, setFieldValue, values);
                      }}
                      onChange={(e) => setFieldValue("length", e.target.value)}
                      error={touched?.["length"] && errors?.["length"]}
                      helperText={touched?.["length"] && errors?.["length"]}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.width || ""}
                      sx={{ width: "100%" }}
                      name="width"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateTotalSqm(
                          values?.["length"],
                          e.target.value,
                          values?.thickness,
                          values?.number_of_sheets,
                          setFieldValue,
                          values
                        );
                      }}
                      onChange={(e) => setFieldValue("width", e.target.value)}
                      error={touched?.width && errors?.width}
                      helperText={touched?.width && errors?.width}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.thickness || ""}
                      sx={{ width: "100%" }}
                      name="thickness"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateTotalSqm(
                          values?.["length"],
                          values?.width,
                          e.target.value,
                          values?.number_of_sheets,
                          setFieldValue,
                          values
                        );
                      }}
                      onChange={(e) => setFieldValue("thickness", e.target.value)}
                      error={touched?.thickness && errors?.thickness}
                      helperText={touched?.thickness && errors?.thickness}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.number_of_sheets || ""}
                      sx={{ width: "100%" }}
                      name="number_of_sheets"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateTotalSqm(
                          values?.["length"],
                          values?.width,
                          values?.thickness,
                          e.target.value,
                          setFieldValue,
                          values
                        );
                      }}
                      onChange={(e) => setFieldValue("number_of_sheets", e.target.value)}
                      error={touched?.number_of_sheets && errors?.number_of_sheets}
                      helperText={touched?.number_of_sheets && errors?.number_of_sheets}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      disabled
                      type="number"
                      size="small"
                      value={values?.total_sq_meter}
                      sx={{ width: "100%" }}
                      name="total_sq_meter"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("total_sq_meter", e.target.value)}
                      error={touched?.total_sq_meter && errors?.total_sq_meter}
                      helperText={touched?.total_sq_meter && errors?.total_sq_meter}
                    />
                  </TableCell>
                  {/* {finalData?.currency !== "INR" ? (
                    <TableCell sx={{ ...tableBodyCellStyle }}>
                      <TextField
                        type="number"
                        size="small"
                        value={values?.exchange_rate}
                        sx={{ width: "100%" }}
                        name="exchange_rate"
                        onBlur={async (e) => {
                          handleBlur(e);
                          let rateInINR = Number(e.target.value) * Number(values?.rate_in_currency);
                          let amount = Number(values?.total_sq_meter) * Number(rateInINR);
                          setFieldValue("rate_in_inr", Number(rateInINR?.toFixed(2)));
                          setFieldValue("amount", Number(amount?.toFixed(2)));
                        }}
                        onChange={(e) => setFieldValue("exchange_rate", e.target.value)}
                        error={touched?.exchange_rate && errors?.exchange_rate}
                        helperText={touched?.exchange_rate && errors?.exchange_rate}
                      />
                    </TableCell>
                  ) : (
                    ""
                  )} */}

                  {finalData?.currency !== "INR" ? (
                    <TableCell sx={{ ...tableBodyCellStyle }}>
                      <TextField
                        type="number"
                        size="small"
                        value={values?.rate_in_currency || ""}
                        sx={{ width: "100%" }}
                        name="rate_in_currency"
                        onBlur={async (e) => {
                          handleBlur(e);
                          let rateInINR = Number((Number(e.target.value) * Number(values?.exchange_rate))?.toFixed(2));
                          let amount = Number((Number(values?.total_sq_meter) * Number(rateInINR))?.toFixed(2));
                          setFieldValue("rate_in_inr", Number(rateInINR));
                          setFieldValue("amount", Number(amount));
                        }}
                        onChange={(e) => setFieldValue("rate_in_currency", e.target.value)}
                        error={touched?.rate_in_currency && errors?.rate_in_currency}
                        helperText={touched?.rate_in_currency && errors?.rate_in_currency}
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.rate_in_inr || ""}
                      sx={{ width: "100%" }}
                      name="rate_in_inr"
                      onBlur={async (e) => {
                        handleBlur(e);
                        let amount = Number((Number(values?.total_sq_meter) * Number(e.target.value))?.toFixed(2));
                        setFieldValue("amount", Number(amount));
                      }}
                      onChange={(e) => setFieldValue("rate_in_inr", e.target.value)}
                      error={touched?.rate_in_inr && errors?.rate_in_inr}
                      helperText={touched?.rate_in_inr && errors?.rate_in_inr}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      value={values?.amount || ""}
                      sx={{ width: "100%" }}
                      name="amount"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("amount", e.target.value)}
                      error={touched?.amount && errors?.amount}
                      helperText={touched?.amount && errors?.amount}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.remark}
                      sx={{ width: "100%" }}
                      name="remark"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("remark", e.target.value)}
                      error={touched?.remark && errors?.remark}
                      helperText={touched?.remark && errors?.remark}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Div sx={{ textAlign: "right" }}>
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              size="small"
              variant="contained"
              sx={{
                marginY: 1,
                width: "150px",
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                px: 1,
                position: "sticky",
                right: "20px",
                zIndex: 1,
              }}
            >
              Add New Item
            </LoadingButton>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default AddItemModal;
