import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { BorderRight, Padding } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import { getLatestCode } from "app/services/apis/getLatestCode";
import { incrementLogCode } from "app/utils/constants/incrementLogCode";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import moment from "moment";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  // width: "100%",
};

const AddItemModal = ({
  setAddedItemDetailsList,
  addedItemDetailsList,
  finalData,
  machineList,
  physicalLength,
  actualLogData,
  setIsCalculated,
  isChecked,
  setIsChecked,
  code,
  setCode,
  setFinalData, initialItemForm,
  setInitialItemForm
}) => {
  const [submitting, setSubmitting] = useState(false);

  // const formula = actualLogData?.log_formula;
  const formula = addedItemDetailsList?.[0]?.issuedCrossCuttingDetails?.log_formula;
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    customClass: {
      container: "popupImportant",
    },
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // const stock = {
  //   item_sr_no: addedItemDetailsList.length + 1 || 1,
  //   // item_name: "",
  //   // item_id: "",
  //   log_inventory_item_id: actualLogData?.issuedCrossCuttingDetails?.log_inventory_item_id,
  //   issue_for_crosscutting_id: actualLogData?.issue_for_crosscutting_id,
  //   factor: 0,
  //   machine_name: "",
  //   machine_id: "",
  //   log_no: "",
  //   code: "",
  //   log_no_code: "",
  //   length: 0,
  //   girth: actualLogData?.issuedCrossCuttingDetails?.physical_diameter || 0,
  //   crosscut_cmt: 0,
  //   per_cmt_cost: 0,
  //   cost_amount: 0,
  //   required_hours: 0,
  //   remarks: "",
  //   // crosscut_date: getCurrentDate(),
  //   // workers: 0,
  //   // shift: "",
  //   // working_hours: 0,
  //   required_workers: 0,
  //   worker_details: {
  //     crosscut_date: getCurrentDate(),
  //     workers: 0,
  //     working_hours: 0,
  //     shift: "",
  //   },
  // };

  const itemDetails = {
    ...initialItemForm,
    item_sr_no: addedItemDetailsList?.length + 1 || 1
  }
  const validationSchema = yup.object({
    machine_name: yup.string().required("Machine name is Required"),
    // log_no_code: yup.string().required("Required"),
    // code: yup.string().required("Required"),
    // log_no_code: yup.string().required("Required"),
    length: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    girth: yup
      .number()
      .positive()
      .moreThan(0, "Must be greater than 0")
      .required("Required")
      .max(
        Number(actualLogData?.issuedCrossCuttingDetails?.available_quantity?.physical_diameter),
        `Can't be greater than ${actualLogData?.issuedCrossCuttingDetails?.available_quantity?.physical_diameter} `
      ),
    crosscut_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    worker_details: yup.object().shape({
      crosscut_date: yup.date().required("Crosscut Date is required"),
      workers: yup.number().positive().moreThan(0, "Must be greater than 0").required("Number of workers is required"),
      working_hours: yup.number().positive().moreThan(0, "Must be greater than 0").required("Working hours is required"),
      shift: yup.string().required("Shift is required in worker details"),
    }),
  });
  useEffect(() => {
    const fetchCode = async () => {
      try {
        const latestCode = await getLatestCode(actualLogData?.issue_for_crosscutting_id);
        setCode(latestCode?.data?.result?.code);
      } catch (error) {
        console.log("err fetching code =>", error.message);
      }
    };
    fetchCode();
  }, []);

  const handleSubmit = async (values, action) => {
    setSubmitting(true);

    const logCode = actualLogData?.log_no;

    // Create the new item with logCode
    const newItem = { ...values, log_no: logCode };

    // Increment the log code for the new item
    const { newCode, newLogCode } = incrementLogCode(
      newItem?.item_sr_no,
      logCode
      // code,
    );

    // Add the new item with incremented codes
    const updatedItem = { ...newItem, code: newCode, log_no_code: newLogCode };

    const updatedList = [...addedItemDetailsList, updatedItem];


    let totalLength = 0
    updatedList?.forEach((i) => {
      totalLength += Number(i?.length)
    })

    if (
      totalLength >
      actualLogData?.issuedCrossCuttingDetails?.physical_length
    ) {
      let availableLength = actualLogData?.issuedCrossCuttingDetails?.physical_length - (totalLength - Number(newItem?.length))
      setSubmitting(false)
      return Swal.fire({
        timer: 5000,
        icon: "error",
        text: `Item length should be smaller than or equals to available (${availableLength.toFixed(2)}) length.`,
        position: "center",
        // background: theme.palette.background.paper,
      });
    }
    // Update the state with the new item
    // setAddedItemDetailsList((prevList) => [...prevList, updatedItem]);
    setAddedItemDetailsList(updatedList)

    // Reset form and state
    // action.resetForm();
    setInitialItemForm((prev) => ({ ...values, item_sr_no: prev?.item_sr_no + 1 }))
    setSubmitting(false);

    // Display success message
    Toast.fire({
      timer: 3000,
      icon: "success",
      title: "Item Added Successfully.",
      position: "top-right",
    });
  };

  const handleCalculatePhysicalCMT = (
    physicalLength,
    physicalDia,
    // formula,
    setFieldValue
  ) => {
    if (formula === "A") {
      let physicalCMT = Number(((Number(physicalLength) * Number(physicalDia) * Number(physicalDia)) / 16).toFixed(3));
      setFieldValue("crosscut_cmt", physicalCMT);
    }
    if (formula === "B") {
      let physicalCMT = Number(((Number(physicalLength) * Number(physicalDia) * Number(physicalDia)) / 16).toFixed(3));
      setFieldValue("crosscut_cmt", physicalCMT);
    }
  };

  const checkLength = (value, sr_no, setFieldValue) => {
    const filteredLength = addedItemDetailsList?.filter((item) => item?.item_sr_no !== sr_no);

    const totalLength = filteredLength?.reduce((acc, item) => Number(acc) + Number(item?.length), 0);

    if (totalLength + Number(value) > actualLogData?.issuedCrossCuttingDetails?.physical_length) {
      let availableLength = actualLogData?.issuedCrossCuttingDetails?.physical_length - totalLength;
      setFieldValue("length", 0);
      return Swal.fire({
        timer: 5000,
        icon: "error",
        text: `Item length should be smaller than or equals to available (${availableLength?.toFixed(2)}) length.`,
        position: "center",
        // background: theme.palette.background.paper,
      });
    }
  };

  const calculateRequiredHours = (hours, workers, setFieldValue) => {
    const totalRequiredHours = Number((Number(hours) * Number(workers)).toFixed(2));
    setFieldValue("required_hours", totalRequiredHours);
    setFieldValue("worker_details.no_of_total_hours", totalRequiredHours);
  };
  return (
    <Formik
      validateOnChange={false}
      initialValues={itemDetails}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors, handleBlur, initialValues, setFieldTouched, touched }) => (
        <Form noValidate autoComplete="off">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                  }}
                >
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Machine Name</TableCell>

                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Crosscut Date</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Shift</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Workers</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Working Hours</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>No. of Total Hours</TableCell>

                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Length</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Diameter</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Crosscut CMT</TableCell>

                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ ...tableBodyCellStyle }}>{values?.item_sr_no}</TableCell>

                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      value={values?.machine_name}
                      size="small"
                      options={machineList}
                      name="machine_name"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.machine_name;
                        } else {
                          return option;
                        }
                      }}
                      onBlur={() => setFieldTouched("machine_name", true)}
                      onChange={(e, selectedData) => {
                        setFieldValue("machine_name", selectedData?.machine_name || "");
                        setFieldValue("machine_id", selectedData?._id || "");
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.machine_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.machine_name && Boolean(errors.machine_name)} // Show error if touched and there's an error
                          helperText={touched.machine_name && errors.machine_name}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        size="small"
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                        defaultValue={values?.worker_details?.crosscut_date !== "" ? dayjs(values?.crosscut_date) : null}
                        onChange={(newValue) => {
                          setFinalData({
                            ...finalData,
                            crosscut_date: new Date(newValue),
                          });
                          // setFieldValue("worker_details.crosscut_date", newValue.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                          setFieldValue("crosscut_date", new Date(newValue));
                        }}
                      />
                      <Div sx={{ height: "5px" }}>
                        <ErrorMessage name="worker_details.crosscut_date" component="div" style={{ color: "red" }} />
                      </Div>
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="shift"
                      onChange={(e) => {
                        setFieldValue("worker_details.shift", e.target.value);
                      }}
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({ ...finalData, shift: e.target.value });
                      }}
                      value={values?.worker_details?.shift}
                      status={true}
                      error={touched?.worker_details?.shift && errors?.worker_details?.shift}
                      helperText={touched?.worker_details?.shift && errors?.worker_details?.shift}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      type="number"
                      name="worker_details.workers" //changed no.of.workers to required_workers
                      onBlur={async (e) => {
                        handleBlur(e);
                        // setFinalData({
                        //   ...finalData,
                        //   workers: e.target.value,
                        // });
                        calculateRequiredHours(values?.worker_details?.working_hours, e.target.value, setFieldValue);
                      }}
                      onChange={(e) => {
                        setFieldValue("worker_details.workers", e.target.value);
                        setFieldValue("required_workers", e.target.value);
                      }}
                      value={values?.worker_details?.workers || ""}
                      status={true}
                      error={touched?.worker_details?.workers && errors?.worker_details?.workers}
                      helperText={touched?.worker_details?.workers && errors?.worker_details?.workers}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="working_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                        setFinalData({
                          ...finalData,
                          working_hours: e.target.value,
                        });
                        calculateRequiredHours(e.target.value, values?.worker_details?.workers, setFieldValue);
                      }}
                      onChange={(e) => {
                        setFieldValue("worker_details.working_hours", e.target.value);
                      }}
                      value={values?.worker_details?.working_hours || ""}
                      status={true}
                      error={touched?.worker_details?.working_hours && errors?.worker_details?.working_hours}
                      helperText={touched?.worker_details?.working_hours && errors?.worker_details?.working_hours}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      disabled
                      value={values?.required_hours}
                      sx={{ width: "100%" }}
                      name="required_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // handleCalculatePhysicalCMT(
                        //   e.target.value,
                        //   values?.physical_diameter,
                        //   values?.log_formula,
                        //   setFieldValue
                        // );
                      }}
                      onChange={(e) => setFieldValue("required_hours", e.target.value)}
                      error={touched?.required_hours && errors?.required_hours}
                      helperText={touched?.required_hours && errors?.required_hours}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // disabled
                      type="number"
                      size="small"
                      value={values?.length || ""}
                      sx={{ width: "100%" }}
                      name="length"
                      onBlur={(e) => {
                        handleBlur(e);
                        handleCalculatePhysicalCMT(
                          e.target.value,
                          values?.girth,
                          // values?.log_formula,
                          setFieldValue
                        );
                        checkLength(e.target.value, values?.item_sr_no, setFieldValue);
                        setIsCalculated(false);
                      }}
                      onChange={(e) => setFieldValue("length", e.target.value)}
                      error={touched?.length && errors?.length}
                      helperText={touched?.length && errors?.length}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      disabled
                      size="small"
                      value={values?.girth}
                      sx={{ width: "100%" }}
                      name="girth"
                      onBlur={async (e) => {
                        handleBlur(e);

                        handleCalculatePhysicalCMT(
                          values?.length,
                          e.target.value,
                          // values?.log_formula,
                          setFieldValue
                        );
                        setIsCalculated(false);
                      }}
                      onChange={(e) => setFieldValue("girth", e.target.value)}
                      error={touched?.girth && errors?.girth}
                      helperText={touched?.girth && errors?.girth}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      disabled
                      value={values?.crosscut_cmt}
                      sx={{ width: "100%" }}
                      name="crosscut_cmt"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // handleCalculatePhysicalCMT(
                        //   e.target.value,
                        //   values?.physical_diameter,
                        //   values?.log_formula,
                        //   setFieldValue
                        // );
                      }}
                      onChange={(e) => setFieldValue("crosscut_cmt", e.target.value)}
                      error={touched?.crosscut_cmt && errors?.crosscut_cmt}
                      helperText={touched?.crosscut_cmt && errors?.crosscut_cmt}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // disabled
                      type="number"
                      size="small"
                      value={values?.cost_amount}
                      sx={{ width: "100%" }}
                      name="cost_amount"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) =>
                        setFieldValue("cost_amount", e.target.value)
                      }
                      error={touched?.cost_amount && errors?.cost_amount}
                      helperText={touched?.cost_amount && errors?.cost_amount}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      // disabled
                      size="small"
                      value={values?.per_cmt_cost}
                      sx={{ width: "100%" }}
                      name="per_cmt_cost"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) =>
                        setFieldValue("per_cmt_cost", e.target.value)
                      }
                      error={touched?.per_cmt_cost && errors?.per_cmt_cost}
                      helperText={touched?.per_cmt_cost && errors?.per_cmt_cost}
                    />
                  </TableCell> */}

                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.remarks}
                      sx={{ width: "100%" }}
                      name="remarks"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("remarks", e.target.value)}
                      error={touched?.remark && errors?.remark}
                      helperText={touched?.remark && errors?.remark}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Div sx={{ textAlign: "right" }}>
            <LoadingButton
              loading={submitting}
              type="submit"
              size="small"
              variant="contained"
              sx={{
                marginY: 1,
                width: "150px",
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                px: 1,
                position: "sticky",
                right: "20px",
                zIndex: 1,
              }}
            // onClick={() => incrementLogCode(values?.code, actualLogData?.log_no)}
            >
              Add New Item
            </LoadingButton>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default AddItemModal;
