import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { fetchIssueForCrosscutting } from "app/redux/actions/Factory/Crosscutting/issuesForCrosscutting/issuesForCrosscutting";
import { issueForCrosscuttingFilter } from "app/utils/constants/sessionFilter";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import IssueForCrosscuttingTableRow from "../Components/IssueForCrosscuttingTableRow";

const tableHeadCellSx = {
  textAlign: "left",
  minWidth: "100px",
  verticalAlign: "middle",
  px: 1,
  fontWeight: "400",
  color: "white",
};

const tableHeadSortSx = {
  color: "white",
  "&:hover": { color: "white" },
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white !important",
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important", // Set the color for the sorting icon
  },
  "&:hover": {
    color: "white !important", // Set the color when hovering
  },
};

const tableRowHeadSticky = {
  textAlign: "center",
  minWidth: "60px",
  verticalAlign: "middle",
  color: "white",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "#7352C7",
};

const IssueForCrosscuttingTableListing = ({
  allFilterState,
  setAllFilterState,
}) => {
  const issuesForCrosscuttingReducer = useSelector(
    (state) => state?.issuesForCrosscuttingReducer
  );

  const userDetails = useSelector((state) => state?.userReducer?.user?.[0]);

  const dispatch = useDispatch();
  const sort = allFilterState?.sort;
  const sortBy = allFilterState?.sortBy;
  const page = allFilterState?.page;

  const handleSort = function (name) {
    const filter = {
      ...allFilterState,
      sort: allFilterState.sort === "desc" ? "asc" : "desc",
      sortBy: name,
      page: 1,
    };
    setAllFilterState(filter);
    dispatch(fetchIssueForCrosscutting(filter));
    sessionStorage.setItem(issueForCrosscuttingFilter, JSON.stringify(filter));
  };

  const handleChangePage = (event, newPage) => {
    const filter = {
      ...allFilterState,
      page: newPage,
    };
    setAllFilterState(filter);
    dispatch(fetchIssueForCrosscutting(filter));
    sessionStorage.setItem(issueForCrosscuttingFilter, JSON.stringify(filter));
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
                "& .MuiTableCell-root": {
                  // py: 2,
                },
              }}
            >
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "log_invoice_details.inward_sr_no"}
                  direction={sort}
                  onClick={(e) =>
                    handleSort("log_invoice_details.inward_sr_no")
                  }
                  sx={{ ...tableHeadSortSx }}
                >
                  Inward Sr No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "log_invoice_details.inward_date"}
                  direction={sort}
                  onClick={(e) => handleSort("log_invoice_details.inward_date")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Inward Date
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={
                    sortBy === "log_invoice_details.invoice_Details.invoice_no"
                  }
                  direction={sort}
                  onClick={(e) =>
                    handleSort("log_invoice_details.invoice_Details.invoice_no")
                  }
                  sx={{ ...tableHeadSortSx }}
                >
                  Invoice No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "110px" }}>
                <TableSortLabel
                  active={sortBy === "item_sr_no"}
                  direction={sort}
                  onClick={(e) => handleSort("item_sr_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Log Sr No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "170px" }}>
                <TableSortLabel
                  active={sortBy === "item_name"}
                  direction={sort}
                  onClick={(e) => handleSort("item_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "item_sub_category_name"}
                  direction={sort}
                  onClick={(e) => handleSort("item_sub_category_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Sub Category
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "125px" }}>
                <TableSortLabel
                  active={sortBy === "log_formula"}
                  direction={sort}
                  onClick={(e) => handleSort("log_formula")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Log Formula
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "log_no"}
                  direction={sort}
                  onClick={(e) => handleSort("log_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Log No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>
                <TableSortLabel
                  active={sortBy === "physical_length"}
                  direction={sort}
                  onClick={(e) => handleSort("physical_length")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Physical Length
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "170px" }}>
                <TableSortLabel
                  active={sortBy === "available_quantity.physical_length"}
                  direction={sort}
                  onClick={(e) =>
                    handleSort("available_quantity.physical_length")
                  }
                  sx={{ ...tableHeadSortSx }}
                >
                  Avl Physical Length
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "physical_diameter"}
                  direction={sort}
                  onClick={(e) => handleSort("physical_diameter")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Physical Diameter
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "physical_cmt"}
                  direction={sort}
                  onClick={(e) => handleSort("physical_cmt")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Physical Cmt
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "170px" }}>
                <TableSortLabel
                  active={sortBy === "available_quantity.physical_cmt"}
                  direction={sort}
                  onClick={(e) => handleSort("available_quantity.physical_cmt")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Avl Physical Cmt
                </TableSortLabel>
              </TableCell>
              {/* {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                  <TableSortLabel
                    active={sortBy === "exchange_rate"}
                    direction={sort}
                    onClick={(e) => handleSort("exchange_rate")}
                    sx={{ ...tableHeadSortSx }}
                  >
                    Exchange Rate
                  </TableSortLabel>
                </TableCell>
              )} */}
              {/* {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                  <TableSortLabel
                    active={sortBy === "rate_in_currency"}
                    direction={sort}
                    onClick={(e) => handleSort("rate_in_currency")}
                    sx={{ ...tableHeadSortSx }}
                  >
                    Rate In Currency
                  </TableSortLabel>
                </TableCell>
              )} */}
              {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                  <TableSortLabel
                    active={sortBy === "rate_in_inr"}
                    direction={sort}
                    onClick={(e) => handleSort("rate_in_inr")}
                    sx={{ ...tableHeadSortSx }}
                  >
                    Rate In Inr
                  </TableSortLabel>
                </TableCell>
              )}
              {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx }}>
                  <TableSortLabel
                    active={sortBy === "amount"}
                    direction={sort}
                    onClick={(e) => handleSort("amount")}
                    sx={{ ...tableHeadSortSx }}
                  >
                    Amount
                  </TableSortLabel>
                </TableCell>
              )}

              {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                  <TableSortLabel
                    active={sortBy === "available_quantity.amount"}
                    direction={sort}
                    onClick={(e) => handleSort("available_quantity.amount")}
                    sx={{ ...tableHeadSortSx }}
                  >
                    Avl Amount
                  </TableSortLabel>
                </TableCell>
              )}

              {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                  <TableSortLabel
                    active={sortBy === "expense_amount"}
                    direction={sort}
                    onClick={(e) => handleSort("expense_amount")}
                    sx={{ ...tableHeadSortSx }}
                  >
                    Expense Amt
                  </TableSortLabel>
                </TableCell>
              )}
              {userDetails?.user_type == "ADMIN" && (
                <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>
                  <TableSortLabel
                    active={sortBy === "available_quantity.expense_amount"}
                    direction={sort}
                    onClick={(e) =>
                      handleSort("available_quantity.expense_amount")
                    }
                    sx={{ ...tableHeadSortSx }}
                  >
                    Avl Expense Amt
                  </TableSortLabel>
                </TableCell>
              )}
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "remark"}
                  direction={sort}
                  onClick={(e) => handleSort("remark")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Remark
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>
                Approval Status
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, ...tableRowHeadSticky }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {issuesForCrosscuttingReducer?.data &&
            issuesForCrosscuttingReducer?.data?.length > 0 ? (
              issuesForCrosscuttingReducer?.data?.map((e, i) => {
                return (
                  <IssueForCrosscuttingTableRow
                    key={e?._id}
                    e={e}
                    allFilterState={allFilterState}
                  />
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={14}
                  sx={{ textAlign: "center", py: 2, fontSize: 18 }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          size="medium"
          count={issuesForCrosscuttingReducer?.totalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1,
          }}
        />
      </TableContainer>
    </>
  );
};

export default React.memo(IssueForCrosscuttingTableListing);
