import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { CompareValue } from "app/components/CompareValue";
import React from "react";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  width: "100%",
  borderRight: "1px solid #7352C7",
};

const ListingInvoiceItems = ({ invoiceItems = [], isApprovalPending }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h5">Items Details</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
              }}
            >
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Item Name</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Supplier Log No.</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Name</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Item Sub Category</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log No.</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Log Formula</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Length</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice Diameter</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Invoice CMT</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Indian CMT</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Physical Length</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Physical Diameter</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Physical CMT</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Exchange Rate</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate In Currency</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Rate In Inr</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Amount</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Expense Amount</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceItems &&
              invoiceItems?.map((row, i) => (
                <TableRow key={i} sx={{ background: !row?.previous_data && isApprovalPending ? "rgb(255 0 0 / 15%) !important" : 'inherit' }}>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_sr_no}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.item_sr_no}
                      new_data={row?.item_sr_no}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.supplier_item_name}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.supplier_item_name}
                      new_data={row?.supplier_item_name}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.supplier_log_no}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.supplier_log_no}
                      new_data={row?.supplier_log_no}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_name}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.item_name}
                      new_data={row?.item_name}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.item_sub_category_name}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.item_sub_category_name}
                      new_data={row?.item_sub_category_name}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.log_no}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.log_no}
                      new_data={row?.log_no}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.log_formula}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.log_formula}
                      new_data={row?.log_formula}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.invoice_length}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.invoice_length}
                      new_data={row?.invoice_length}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.invoice_diameter}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.invoice_diameter}
                      new_data={row?.invoice_diameter}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.invoice_cmt}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.invoice_cmt}
                      new_data={row?.invoice_cmt}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.indian_cmt}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.indian_cmt}
                      new_data={row?.indian_cmt}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.physical_length}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.physical_length}
                      new_data={row?.physical_length}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.physical_diameter}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.physical_diameter}
                      new_data={row?.physical_diameter}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.physical_cmt}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.physical_cmt}
                      new_data={row?.physical_cmt}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.exchange_rate}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.exchange_rate}
                      new_data={row?.exchange_rate}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.rate_in_currency}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.rate_in_currency}
                      new_data={row?.rate_in_currency}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.rate_in_inr}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.rate_in_inr}
                      new_data={row?.rate_in_inr}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.amount}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.amount}
                      new_data={row?.amount}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.expense_amount}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.expense_amount || 0}
                      new_data={row?.expense_amount}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ ...tableBodyCellStyle }}>{row?.remark}</TableCell> */}
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <CompareValue isApprovalPending={isApprovalPending}
                      previous_data={row?.previous_data?.remark}
                      new_data={row?.remark}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ListingInvoiceItems;
