import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField, Typography } from "@mui/material";
import FormTextField1 from "../InputField/FormTextField1";
// import ToastAlerts from '../Toast';
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email format"),
  designation: Yup.string(),
  mobile_number: Yup.string()
  // .matches(/^[0-9]{10}$/, "Must be exactly 10 digits")
  ,
});

function QuestionsModal({ open, handleClose, onAddContact, values }) {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    designation: "",
    mobile_number: "",
  });

  useEffect(() => {
    if (values && values.length > 0) {
      // Access the first element in the array
      const contactPerson = values[0];
      setContact({
        name: contactPerson?.name || "",
        email: contactPerson?.email || "",
        designation: contactPerson?.designation || "",
        mobile_number: contactPerson?.mobile_number || "",
      });
    } else {
      setContact({ name: "", email: "", designation: "", mobile_number: "" });
    }
  }, [values]);


  const handleSubmit = (formValues) => {
    onAddContact(formValues);
    setContact({ name: "", email: "", designation: "", mobile_number: "" });
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className="lg:w-1/3 w-[20rem]">
          <div className="flex flex-row items-center justify-between mb-2">
            <Typography variant="h2" component="h2">
              Add Contact Person
            </Typography>
            <Close onClick={handleClose} className="mb-4" style={{ cursor: "pointer", float: "right" }} />
          </div>
          <Formik initialValues={contact} enableReinitialize validationSchema={validationSchema} onSubmit={handleSubmit}>
            <Form>
              <div className="grid grid-flow-row place-items-center grid-cols-2 gap-5">

                <FormTextField1 name="name" label="Name*" />
                <FormTextField1 name="email" label="Email" />
                <FormTextField1 name="designation" label="Designation" />
                <FormTextField1
                  name="mobile_number"
                  type="number"

                  sx={{
                    "& input[type=number]": {
                      MozAppearance: "textfield", // Hides the spinner in Firefox
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      WebkitAppearance: "none", // Hides the spinner in Chrome, Safari, Edge, and Opera
                      margin: 0,
                    },
                  }}
                  label="Mobile Number"
                />
              </div>
              <div className="flex items-center justify-center mt-5">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                // onClick={handleSubmit}
                >
                  {values.length > 0 ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default QuestionsModal;
