import { Box, Tab, Tabs } from "@mui/material";
import ListingFleece from "./ListingFleece";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import { useSelector } from "react-redux";
import React from "react";
import { fleeceInventoryTabFilter } from "app/utils/constants/sessionFilter";
import FleeceApproval from "./Approval";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FleeceInventoryList = () => {
  const userReducer = useSelector((state) => state.userReducer);
  const user = userReducer?.user?.[0];

  const [value, setValue] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem(fleeceInventoryTabFilter, newValue);
  };

  React.useEffect(() => {
    let newValue = Number(sessionStorage.getItem(fleeceInventoryTabFilter) || 0);
    setValue(newValue);
  }, []);
  return (
    <Box sx={{ width: "100%", mt: -4 }}>
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Fleece Inventory" {...a11yProps(0)} />
        {user?.user_type === "ADMIN" && <Tab label="Fleece Approval" {...a11yProps(1)} />}
      </Tabs>
    </Box>
    <CustomTabPanel value={value} index={0}>
      <ListingFleece />
    </CustomTabPanel>
    {user?.user_type === "ADMIN" && (
      <CustomTabPanel value={value} index={1}>
        <FleeceApproval />
      </CustomTabPanel>
    )}
  </Box>
  );
};

export default HeaderTitleHoc(FleeceInventoryList,"Fleece Paper Inventory");
