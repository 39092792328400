import AddItemModal from "./addNewItemTable";
import EditItemDetailsTable from "./EditItemTable";

const ItemDetailsTable = ({
  setAddedItemDetailsList,
  addedItemDetailsList,
  itemNameList,
  itemSubCategoryNameList,
  finalData,
  departmentList,
  // machineList,
  // getMachineList,
  initialItemForm, setInitailItemForm
}) => {
  console.log("initial item form in item details table => ", initialItemForm)
  return (
    <div>
      <AddItemModal
        itemNameList={itemNameList}
        itemSubCategoryNameList={itemSubCategoryNameList}
        setAddedItemDetailsList={setAddedItemDetailsList}
        addedItemDetailsList={addedItemDetailsList}
        finalData={finalData}
        departmentList={departmentList}
        initialItemForm={initialItemForm}
        setInitailItemForm={setInitailItemForm}
      // machineList={machineList}
      // getMachineList={getMachineList}
      />
      <EditItemDetailsTable
        itemNameList={itemNameList}
        itemSubCategoryNameList={itemSubCategoryNameList}
        setAddedItemDetailsList={setAddedItemDetailsList}
        addedItemDetailsList={addedItemDetailsList}
        finalData={finalData}
        departmentList={departmentList}
      // machineList={machineList}
      // getMachineList={getMachineList}
      />
    </div>
  );
};

export default ItemDetailsTable;
