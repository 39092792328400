
import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Grid,

  TextField,
  Paper,
  Autocomplete,
} from "@mui/material";
import { City, Country, State } from "country-state-city";
import ContactPersonCrad from "./ContactPersonCrad";
import Div from "@jumbo/shared/Div";
import { outerDiv1 } from "app/utils/constants/dropdowns";
import { Axios } from "index";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { setLoading } from "app/redux/actions/Loading";
function BranchAccordionItem({
  branch,
  handleOpenModal,
  // handleUpdation,
  page,
  rowsPerPage,
  TotalPage,
  setToggle,
}) {
  const [expanded, setExpanded] = useState(false);
  const [branchData, setBranchData] = useState(branch);
  const [countryList] = useState(Country.getAllCountries());
  const [loading, setLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const supplier = {
    branch_name: "",
    gst_number: "",
    address: "",
    state: "",
    country: "",
    city: "",
    pincode: "",
    web_url: "",
    contactPersons: [],
  };
  const validationSchema = yup.object({
    branch_name: yup.string().required("Branch Name is required"),
    address: yup.string().required("Address is required"),
    state: yup.string("Select State").required("State is required"),
    country: yup
      .string("Select Country")
      .required("Country is required"),
    city: yup.string("Select City").required("City is required"),
    pincode: yup
      .string("Enter Pincode")
      // .matches(
      //   /^\d{6}$/,
      //   "Invalid PIN code. Please enter a valid 6-digit PIN code."
      // )
      .required("Pincode is required"),
    // gst_number: yup
    //   .string()
    //   .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, "Invalid GST Number")
    //   .length(15)
    //   .when("country", {
    //     is: "India",
    //     then: yup.string().required("GST Number is required"),
    //     otherwise: yup.string().notRequired()
    //   }),
    gst_number: yup
      .string()
      .when("country", {
        is: "India",
        then: yup
          .string()
          .matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
            "Invalid GST Number"
          )
          .length(15, "GST Number must be exactly 15 characters")
          .required("GST Number is required"),
        otherwise: yup.string().notRequired()
      }),
    web_url: yup.string(),
    //   .url("Enter a valid URL")
    // .required("Web URL is required"),
    contactPersons: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Contact Person Name is required"),
        email: yup
          .string()
          .email("Enter a valid Email"),
        // .required("Email is required"),
        mobile_number: yup
          .string()
          .matches(/^\d+$/, "Phone must be a number"),
        // .required("Phone is required"),
        designation: yup.string(),
      })
    ),
  });
  useEffect(() => {
    if (branchData.country) {
      filterStates(Country.getCountryByCode(branchData.country)?.isoCode);
    }
  }, [branchData.country]);

  useEffect(() => {
    if (branchData.state) {
      filterCities(
        State.getStateByCode(branchData.state)?.isoCode,
        branchData.country
      );
    }
  }, [branchData.state]);

  const handleInputChange = (field, value) => {
    setBranchData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (branchData.country) {
      // Find the ISO code for the country based on the name
      const countryIsoCode = Country.getAllCountries().find(
        (country) => country.name === branchData.country
      )?.isoCode;

      if (countryIsoCode) {
        handleInputChange("countryIsoCode", countryIsoCode); // Update with country ISO code
        filterStates(countryIsoCode); // Fetch states for the selected country
      }
    }
  }, [branchData.country]);

  useEffect(() => {
    if (branchData.state && branchData.countryIsoCode) {
      // Find the ISO code for the state based on the name
      const stateIsoCode = State.getAllStates().find(
        (state) =>
          state.name === branchData.state &&
          state.countryCode === branchData.countryIsoCode
      )?.isoCode;

      if (stateIsoCode) {
        handleInputChange("stateIsoCode", stateIsoCode); // Update with state ISO code
        filterCities(stateIsoCode, branchData.countryIsoCode); // Fetch cities for the selected state and country
      }
    }
  }, [branchData.state, branchData.countryIsoCode]);

  // Filtering the states based on the country ISO code
  const filterStates = (countryIsoCode) => {
    const filteredStates = State.getAllStates().filter(
      (state) => state.countryCode === countryIsoCode
    );
    setStateList(filteredStates);
  };

  // Filtering the cities based on state and country ISO codes
  const filterCities = (stateIsoCode, countryCode) => {
    const filteredCities = City.getAllCities().filter(
      (city) =>
        city.stateCode === stateIsoCode && city.countryCode === countryCode
    );
    setCityList(filteredCities);
  };

  const handleUpdation = async (values) => {
    setLoading(true);
    try {
      console.log("val rc => ", values);
      // console.log("banch id => ", branchData?._id);
      const { data } = await Axios.post(
        `/supplier-master/update-supplier-branch?id=${branchData?._id}`,
        values
      );
      if (data?.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: data.message,
          timer: 1000,
          showConfirmButton: false,
        });
        setToggle((prevState) => !prevState);
        setLoading(false);
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          timer: 1000,
          showConfirmButton: false,
        });
        setLoading(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.message || "Error updating branch data",
        timer: 1000,
        showConfirmButton: false,
      });
      setLoading(false);
      console.error("Error updating branch data => ", error.message);
    }
  };
  return (
    <Accordion
      component={Paper}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
      sx={{ mb: 3 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div className="flex flex-row items-center justify-between w-full">
          <Typography variant="h5">{branch.branch_name}</Typography>
          <Button
            size="small"
            sx={{ marginRight: "10px" }}
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenModal(branch._id);
            }}
          >
            Add Contact Person
          </Button>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <ContactPersonCrad
          totalPages={TotalPage}
          personData={branch.contact_person}
          page={page}
          setToogle={setToggle}
          rowsPerPage={rowsPerPage}
        />

        <Formik
          validateOnChange={true}
          initialValues={branchData}
          enableReinitialize={true}
          validationSchema={validationSchema}
          // onSubmit={() => handleUpdation(branch._id)}
          // onSubmit={(values) => handleUpdation(branch._id, values)}
          onSubmit={handleUpdation}
        >
          {({
            handleSubmit,
            errors,
            touched,
            values,
            handleChange,
            setFieldTouched,
            setFieldValue,
            initialValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-flow-row lg:grid-cols-4 grid-cols-2 gap-5 mt-6">
                <div className="flex flex-col items-start">
                  <Typography variant="h5">Branch Name*</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="branch_name"
                    value={values.branch_name}
                    onChange={handleChange}
                    onBlur={(e) => {
                      setFieldTouched("branch_name", true);
                    }}
                    error={touched.branch_name && Boolean(errors.branch_name)}
                    helperText={touched.branch_name && errors.branch_name}
                  />
                </div>
                <div className="flex flex-col items-start">
                  <Typography variant="h5">Address*</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="address"
                    value={values.address}
                    onBlur={(e) => {
                      setFieldTouched("address", true);
                    }}
                    onChange={handleChange}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </div>
                <Grid item xs={3}>
                  <Typography variant="h5">Country*</Typography>
                  <Autocomplete
                    autoSelect
                    autoHighlight
                    size="small"
                    options={countryList}
                    name="country*"
                    getOptionLabel={(option) => {
                      if (option instanceof Object && !Array.isArray(option)) {
                        return option?.name;
                      } else {
                        return option;
                      }
                    }}
                    value={values?.country || ""}
                    onChange={(e, value) => {
                      setFieldValue("country", value?.name);
                      setFieldValue("state", "");
                      setFieldValue("city", "");
                      setFieldValue("countryCode", value?.phonecode || "");
                      filterStates(value?.isoCode);
                    }}
                    onBlur={(e) => {
                      setFieldTouched("country", true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={touched.country && Boolean(errors.country)}
                        helperText={touched.country && errors.country}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Grid>




                <Grid item xs={3}>
                  <Typography variant="h5">State*</Typography>
                  <Autocomplete
                    autoSelect
                    autoHighlight
                    size="small"
                    options={stateList}
                    name="state"
                    getOptionLabel={(option) => {
                      if (option instanceof Object && !Array.isArray(option)) {
                        return option?.name;
                      } else {
                        return option;
                      }
                    }}
                    // value={values?.state || ""}
                    value={
                      stateList.find((state) => state.name === values.state) ||
                      null
                    }
                    onChange={(e, value) => {
                      setFieldValue("state", value?.name);
                      setFieldValue("city", "");
                      // filterCities(value?.countryCode, value?.isoCode);
                      // setFieldValue("state", e?.target?.value);
                      // setFieldValue("city", "");
                      filterCities(value?.isoCode, value?.countryCode);
                    }}
                    onBlur={(e) => {
                      setFieldTouched("state", true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={touched.state && Boolean(errors.state)} // Show error if touched and there's an error
                        helperText={touched.state && errors.state} // Display helper text for the error
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">City*</Typography>
                  <Autocomplete
                    autoSelect
                    autoHighlight
                    size="small"
                    options={cityList}
                    name="city"
                    getOptionLabel={(option) => {
                      if (option instanceof Object && !Array.isArray(option)) {
                        return option?.name;
                      } else {
                        return option;
                      }
                    }}
                    value={values?.city || ""}
                    onChange={(e, value) => {
                      setFieldValue("city", value?.name);
                    }}
                    onBlur={(e) => {
                      setFieldTouched("city", true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={touched.city && Boolean(errors.city)} // Show error if touched and there's an error
                        helperText={touched.city && errors.city} // Display helper text for the error
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Grid>
                <div className="flex flex-col items-start">
                  <Typography variant="h5">Pincode*</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="pincode"
                    onBlur={(e) => {
                      setFieldTouched("pincode", true);
                    }}
                    value={values.pincode}
                    onChange={handleChange}
                    error={touched.pincode && Boolean(errors.pincode)}
                    helperText={touched.pincode && errors.pincode}
                  />
                </div>
                <div className="flex flex-col items-start">
                  <Typography variant="h5">GST Number</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    onBlur={(e) => {
                      setFieldTouched("gst_number", true);
                    }}
                    name="gst_number"
                    value={values?.gst_number}
                    onChange={handleChange}
                    error={touched.gst_number && Boolean(errors.gst_number)}
                    helperText={touched.gst_number && errors.gst_number}
                  />
                </div>
                <div className="flex flex-col items-start">
                  <Typography variant="h5">Web URL</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="web_url"
                    value={values.web_url}
                    onChange={handleChange}
                    error={touched.web_url && Boolean(errors.web_url)}
                    helperText={touched.web_url && errors.web_url}
                  />
                </div>
              </div>
              <div className="flex items-center justify-center mt-6 gap-6">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    console.log("branch => ", branch);
                    setExpanded(false);
                    setBranchData(branch);
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                >
                  Update
                </LoadingButton>
              </div>
            </form>
          )}
        </Formik>
      </AccordionDetails>
    </Accordion>
  );
}

export default BranchAccordionItem;