import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { BorderRight, Padding } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import { getLatestCode } from "app/services/apis/getLatestCode";
import { incrementLogCode } from "app/utils/constants/incrementLogCode";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import moment from "moment";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  // width: "100%",
};

const AddItemModal = ({
  setAddedItemDetailsList,
  addedItemDetailsList,
  finalData,
  machineList,
  physicalLength,
  actualLogData,
  setIsCalculated,
  isChecked,
  setIsChecked,
  code,
  setCode,
  setFinalData, initialItemForm, setInitialItemForm
}) => {
  const [submitting, setSubmitting] = useState(false);

  // const formula = actualLogData?.log_formula;
  const formula = addedItemDetailsList?.[0]?.issuedCrossCuttingDetails?.log_formula;
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    customClass: {
      container: "popupImportant",
    },
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  // const stock = {
  //   item_sr_no: addedItemDetailsList.length + 1 || 1,
  //   // item_name: "",
  //   // item_id: "",
  //   log_inventory_item_id: actualLogData?.log_inventory_item_id,
  //   issue_for_flitching_id: actualLogData?.issue_for_flitching_id,
  //   log_no_code: "",
  //   sqm_factor: 0,
  //   machine_name: "",
  //   machine_id: "",
  //   log_no: "",
  //   flitch_code: "",
  //   flitch_formula: "",
  //   length: actualLogData?.issueForFlitchingDetails?.available_quantity?.length || 0,
  //   width1: 0,
  //   width2: 0,
  //   width3: 0,
  //   height: 0,
  //   flitch_cmt: 0,
  //   per_cmt_cost: 0,
  //   cost_amount: 0,
  //   required_hours: 0,
  //   remarks: "",
  //   // flitching_date: getCurrentDate(),
  //   // no_of_workers: 0,
  //   // shift: "",
  //   // working_hours: 0,
  //   required_workers: 0,
  //   worker_details: {
  //     flitching_date: getCurrentDate(),
  //     workers: 0,
  //     working_hours: 0,
  //     shift: "",
  //   },
  // };
  const itemDetails = {
    ...initialItemForm,
    item_sr_no: addedItemDetailsList?.length + 1 || 1
  }


  const validationSchema = yup.object({
    machine_name: yup.string().required("Machine name is Required"),
    length: yup
      .number()
      .positive()
      .moreThan(0, "Must be greater than 0")
      .required("Required")
      .max(Number(actualLogData?.issueForFlitchingDetails?.length), `Can't be greater than ${actualLogData?.issueForFlitchingDetails?.length} `),
    width1: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    width2: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    width3: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    height: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    flitch_cmt: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    required_hours: yup.number().positive().moreThan(0, "Must be greater than 0").required("Required"),
    // flitching_date: yup.date().required("Crosscut Date is required"),
    flitch_formula: yup.string().required("Flitch Formula is Required"),
    // no_of_workers: yup
    //   .number()
    //   .nullable()
    //   .positive()
    //   .moreThan(0, "Must be greater than 0")
    //   .required("Required"),
    // shift: yup.string().required("Shift is Required"),
    // working_hours: yup
    //   .number()
    //   .nullable()
    //   .positive()
    //   .moreThan(0, "Must be greater than 0")
    //   .required("Working hours is Required"),
    worker_details: yup.object().shape({
      flitching_date: yup.date().required("Flitching Date is required"),
      workers: yup.number().positive().moreThan(0, "Must be greater than 0").required("Number of workers is required"),
      working_hours: yup.number().positive().moreThan(0, "Must be greater than 0").required("Working hours is required"),
      shift: yup.string().required("Shift is required in worker details"),
    }),
  });

  const handleSubmit = async (values, action) => {
    setSubmitting(true);

    const logCode = actualLogData?.issueForFlitchingDetails?.log_no;

    const newItem = { ...values, log_no: logCode };

    const updatedList = [...addedItemDetailsList, newItem];
    let totalFlitchCmt = 0;
    updatedList?.forEach((item) => {
      totalFlitchCmt += Number(item?.flitch_cmt);
    });

    if (totalFlitchCmt > actualLogData?.issueForFlitchingDetails?.available_quantity?.cmt) {
      setSubmitting(false);
      return Swal.fire({
        timer: 3000,
        icon: "error",
        // text: "Total Flitch Cmt cannot be greater than Physical Cmt.",
        text: `Total Flitch (${totalFlitchCmt}) Cmt cannot be greater than Actual (${actualLogData?.issueForFlitchingDetails?.available_quantity?.cmt}) Cmt.`,
        position: "center",
      });
    }

    const newArr = updatedList?.map((item, index) => {
      const newLogCode = `${logCode}${index + 1}`;
      return { ...item, flitch_code: index + 1, log_no_code: newLogCode };
    });

    setAddedItemDetailsList(newArr);

    // Reset form and state
    // action.resetForm();
    setInitialItemForm((prev) => ({ ...values, item_sr_no: prev?.item_sr_no + 1 }))
    setSubmitting(false)
    // Display success message
    Toast.fire({
      timer: 3000,
      icon: "success",
      title: "Item Added Successfully.",
      position: "top-right",
    });
  };

  const calculateDIA = (width, height) => {
    const widthNum = Number(width);
    const heightNum = Number(height);
    const smaller = Math.min(widthNum, heightNum);
    const larger = Math.max(widthNum, heightNum);

    const DIA = (smaller * 2 + larger) / 2;

    return DIA;
  };
  const handleCalculateFlitchCmt = (width1, width2, width3, height, length, formula, setFieldValue) => {
    let pie = 22 / 7;
    let maxWidth = Math.max(Number(width1), Number(width2), Number(width3));

    if (formula === "TQF") {
      let dia = Number(maxWidth) + Number(height);

      let flitch_cmt = Number(((pie * dia * dia * Number(length)) / 16).toFixed(3));
      setFieldValue("flitch_cmt", flitch_cmt);
    } else if (formula === "FHF") {
      const dia = calculateDIA(maxWidth, height);

      let flitch_cmt = Number(((pie * dia * dia * Number(length)) / 8).toFixed(3));
      setFieldValue("flitch_cmt", flitch_cmt);
    } else {
      let dia = (Number(maxWidth) + Number(height)) / 2;
      let flitch_cmt = Number(((pie * dia * dia * Number(length)) / 4).toFixed(3));
      setFieldValue("flitch_cmt", flitch_cmt);
    }
  };
  // const checkLength = (value, sr_no, setFieldValue) => {
  //   const filteredLength = addedItemDetailsList?.filter((item) => item?.item_sr_no !== sr_no);

  //   const totalLength = filteredLength?.reduce((acc, item) => Number(acc) + Number(item?.length), 0);

  //   if (totalLength + Number(value) > actualLogData?.issueForFlitchingDetails?.length) {
  //     setFieldValue("length", 0);
  //     return Swal.fire({
  //       timer: 3000,
  //       icon: "error",
  //       title: "Item Length Should be smaller than physical length...",
  //       position: "center",
  //       // background: theme.palette.background.paper,
  //     });
  //   }
  // };

  const calculateRequiredHours = (hours, workers, setFieldValue) => {
    const totalRequiredHours = Number((Number(hours) * Number(workers)).toFixed(2));
    setFieldValue("required_hours", totalRequiredHours);
    setFieldValue("worker_details.no_of_total_hours", totalRequiredHours);
  };
  return (
    <Formik
      validateOnChange={false}
      initialValues={itemDetails}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors, handleBlur, initialValues, setFieldTouched, touched }) => (
        <Form noValidate autoComplete="off">
          {console.log("err => ", errors)}
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                  }}
                >
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "60px" }}>Sr No.</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Machine Name</TableCell>
                  {/* <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Log No.
                  </TableCell> */}
                  {/* <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Code
                  </TableCell> */}
                  {/* <TableCell
                    sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                  >
                    Log No. Code
                  </TableCell> */}
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitching Date</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Shift</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Workers</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Working Hours</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Required Hours</TableCell>

                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch Formula</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Length</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width1</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width2</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Width3</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Height</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Flitch CMT</TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>Remark</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ ...tableBodyCellStyle }}>{values?.item_sr_no}</TableCell>

                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      value={values?.machine_name}
                      size="small"
                      options={machineList}
                      name="machine_name"
                      getOptionLabel={(option) => {
                        if (option instanceof Object && !Array.isArray(option)) {
                          return option?.machine_name;
                        } else {
                          return option;
                        }
                      }}
                      onBlur={() => setFieldTouched("machine_name", true)}
                      onChange={(e, selectedData) => {
                        setFieldValue("machine_name", selectedData?.machine_name || "");
                        setFieldValue("machine_id", selectedData?._id || "");
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.machine_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.machine_name && Boolean(errors.machine_name)} // Show error if touched and there's an error
                          helperText={touched.machine_name && errors.machine_name}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>

                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        size="small"
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                        defaultValue={values?.worker_details?.flitching_date !== "" ? dayjs(values?.worker_details?.flitching_date) : null}
                        onChange={(newValue) => {
                          // setFinalData({
                          //   ...finalData,
                          //   flitching_date: moment(newValue).format(),
                          // });
                          // setFieldValue("worker_details.flitching_date", newValue.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                          setFieldValue("worker_details.flitching_date", new Date(newValue));
                        }}
                      />
                      <Div sx={{ height: "5px" }}>
                        <ErrorMessage name="worker_details.crosscut_date" component="div" style={{ color: "red" }} />
                      </Div>
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="worker_details.shift"
                      onChange={(e) => {
                        setFieldValue("worker_details.shift", e.target.value);
                      }}
                      onBlur={async (e) => {
                        handleBlur(e);
                        // setFinalData({ ...finalData, shift: e.target.value });
                      }}
                      value={values?.worker_details?.shift}
                      status={true}
                      error={touched?.worker_details?.shift && errors?.worker_details?.shift}
                      helperText={touched?.worker_details?.shift && errors?.worker_details?.shift}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      type="number"
                      name="worker_details.workers"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // setFinalData({
                        //   ...finalData,
                        //   no_of_workers: e.target.value,
                        // });
                        calculateRequiredHours(values?.worker_details?.working_hours, e.target.value, setFieldValue);
                      }}
                      onChange={(e) => {
                        setFieldValue("worker_details.workers", e.target.value);
                        setFieldValue("required_workers", e.target.value);
                      }}
                      value={values?.worker_details?.workers || ""}
                      status={true}
                      error={touched?.worker_details?.workers && errors?.worker_details?.workers}
                      helperText={touched?.worker_details?.workers && errors?.worker_details?.workers}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      sx={{ width: "100%" }}
                      size="small"
                      name="worker_details.working_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // setFinalData({
                        //   ...finalData,
                        //   working_hours: e.target.value,
                        // });
                        calculateRequiredHours(e.target.value, values?.worker_details?.workers, setFieldValue);
                      }}
                      onChange={(e) => {
                        setFieldValue("worker_details.working_hours", e.target.value);
                      }}
                      value={values?.worker_details?.working_hours || ""}
                      status={true}
                      error={touched?.worker_details?.working_hours && errors?.worker_details?.working_hours}
                      helperText={touched?.worker_details?.working_hours && errors?.worker_details?.working_hours}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      disabled
                      value={values?.required_hours}
                      sx={{ width: "100%" }}
                      name="required_hours"
                      onBlur={async (e) => {
                        handleBlur(e);
                        // handleCalculatePhysicalCMT(
                        //   e.target.value,
                        //   values?.diameter,
                        //   values?.log_formula,
                        //   setFieldValue
                        // );
                      }}
                      onChange={(e) => setFieldValue("required_hours", e.target.value)}
                      error={touched?.required_hours && errors?.required_hours}
                      helperText={touched?.required_hours && errors?.required_hours}
                    />
                  </TableCell>

                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      size="small"
                      value={values?.flitch_formula || null}
                      options={["TQF", "FHF", "BF"]}
                      name="flitch_formula"
                      getOptionLabel={(option) => option}
                      onBlur={() => {
                        setFieldTouched("flitch_formula", true);
                      }}
                      onChange={(e, formula) => {
                        setFieldValue("flitch_formula", formula || "");
                        // console.log("fm : ", formula);
                        handleCalculateFlitchCmt(
                          values?.width1,
                          values?.width2,
                          values?.width3,
                          values?.height,
                          values?.length,
                          formula,
                          setFieldValue
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.flitch_formula && Boolean(errors.flitch_formula)}
                          helperText={touched.flitch_formula && errors.flitch_formula}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // disabled
                      type="number"
                      size="small"
                      value={values?.length}
                      sx={{ width: "100%" }}
                      name="length"
                      onBlur={(e) => {
                        handleBlur(e);
                        handleCalculateFlitchCmt(
                          values?.width1,
                          values?.width2,
                          values?.width3,
                          values?.height,
                          e.target.value,
                          values?.flitch_formula,
                          setFieldValue
                        );
                        setIsCalculated(false);
                      }}
                      onChange={(e) => setFieldValue("length", e.target.value)}
                      error={touched?.length && errors?.length}
                      helperText={touched?.length && errors?.length}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      // disabled
                      size="small"
                      value={values?.width1 || ""}
                      sx={{ width: "100%" }}
                      name="width1"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateFlitchCmt(
                          e.target.value,
                          values?.width2,
                          values?.width3,
                          values?.height,
                          values?.length,
                          values?.flitch_formula,
                          setFieldValue
                        );
                        setIsCalculated(false);
                      }}
                      onChange={(e) => setFieldValue("width1", e.target.value)}
                      error={touched?.width1 && errors?.width1}
                      helperText={touched?.width1 && errors?.width1}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      // disabled
                      size="small"
                      value={values?.width2 || ""}
                      sx={{ width: "100%" }}
                      name="width2"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateFlitchCmt(
                          values?.width1,
                          e.target.value,
                          values?.width3,
                          values?.height,
                          values?.length,
                          values?.flitch_formula,
                          setFieldValue
                        );
                        setIsCalculated(false);
                      }}
                      onChange={(e) => setFieldValue("width2", e.target.value)}
                      error={touched?.width2 && errors?.width2}
                      helperText={touched?.width2 && errors?.width2}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      // disabled
                      size="small"
                      value={values?.width3 || ""}
                      sx={{ width: "100%" }}
                      name="width3"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateFlitchCmt(
                          values?.width1,
                          values?.width2,
                          e.target.value,
                          values?.height,
                          values?.length,
                          values?.flitch_formula,
                          setFieldValue
                        );
                      }}
                      onChange={(e) => setFieldValue("width3", e.target.value)}
                      error={touched?.width3 && errors?.width3}
                      helperText={touched?.width3 && errors?.width3}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      // disabled
                      size="small"
                      value={values?.height || ""}
                      sx={{ width: "100%" }}
                      name="height"
                      onBlur={async (e) => {
                        handleBlur(e);
                        handleCalculateFlitchCmt(
                          values?.width1,
                          values?.width2,
                          values?.width3,
                          e.target.value,
                          values?.length,
                          values?.flitch_formula,
                          setFieldValue
                        );
                        setIsCalculated(false);
                      }}
                      onChange={(e) => setFieldValue("height", e.target.value)}
                      error={touched?.height && errors?.height}
                      helperText={touched?.height && errors?.height}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      type="number"
                      size="small"
                      disabled
                      value={values?.flitch_cmt}
                      sx={{ width: "100%" }}
                      name="flitch_cmt"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("flitch_cmt", e.target.value)}
                      error={touched?.flitch_cmt && errors?.flitch_cmt}
                      helperText={touched?.flitch_cmt && errors?.flitch_cmt}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCellStyle }}>
                    <TextField
                      // type="number"
                      size="small"
                      value={values?.remarks}
                      sx={{ width: "100%" }}
                      name="remarks"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("remarks", e.target.value)}
                      error={touched?.remark && errors?.remark}
                      helperText={touched?.remark && errors?.remark}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Div sx={{ textAlign: "right" }}>
            <LoadingButton
              loading={submitting}
              type="submit"
              size="small"
              variant="contained"
              sx={{
                marginY: 1,
                width: "150px",
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                px: 1,
                position: "sticky",
                right: "20px",
                zIndex: 1,
              }}
            // onClick={() => incrementLogCode(values?.code, actualLogData?.log_no)}
            >
              Add New Item
            </LoadingButton>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default AddItemModal;
