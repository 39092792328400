import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import AddItemModal from "./addNewItemTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ClearIcon from "@mui/icons-material/Clear";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import Div from "@jumbo/shared/Div";
import {
  incrementLogCode,
  incrementLogCodeForAdd,
} from "app/utils/constants/incrementLogCode";
import { useSelector } from "react-redux";

const tableHeaderCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  color: "white",
  padding: "5px 5px",
  borderRight: "1px solid white",
};
const tableBodyCellStyle = {
  textAlign: "center",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  // width: "100%",
  borderRight: "1px solid #7352C7",
};

const EditItemDetailsTable = ({
  setAddedItemDetailsList,
  addedItemDetailsList,
  machineList,
  finalData,
  physicalLength,
  actualLogData,
  isCalculated,
  setIsCalculated,
  handleCalculate,
  code,
}) => {
  const { user_type } = useSelector((state) => state?.userReducer?.user?.[0]);
  const initialData = {
    item_sr_no: 0,
    log_inventory_item_id: actualLogData?.log_inventory_item_id,
    issue_for_crosscutting_id: actualLogData?._id,
    machine_name: "",
    machine_id: "",
    log_no: "",
    code: "",
    log_no_code: "",
    length: 0,
    girth: actualLogData?.physical_diameter || 0,
    factor: 0,
    crosscut_cmt: 0,
    per_cmt_cost: 0,
    cost_amount: 0,
    required_hours: 0,
    remarks: "",
  };
  const [data, setData] = useState(initialData);
  const formula = actualLogData?.log_formula;

  const validationSchema = yup.object({
    machine_name: yup.string().required("Machine name is Required"),
    log_no_code: yup.string().required("Required"),
    length: yup
      .number()
      .positive()
      .moreThan(0, "Must be greater than 0")
      .required("Required"),
    girth: yup
      .number()
      .positive()
      .moreThan(0, "Must be greater than 0")
      .required("Required")
      .max(
        Number(actualLogData?.available_quantity?.physical_diameter),
        `Can't be greater than ${actualLogData?.available_quantity?.physical_diameter} `
      ),
    crosscut_cmt: yup
      .number()
      .positive()
      .moreThan(0, "Must be greater than 0")
      .required("Required"),
    // per_cmt_cost: yup
    //   .number()
    //   .positive()
    //   .moreThan(0, "Must be greater than 0")
    //   .required("Required"),
    // cost_amount: yup
    //   .number()
    //   .positive()
    //   .moreThan(0, "Must be greater than 0")
    //   .required("Required"),
  });

  const handleSubmit = async (values) => { };

  const removeRow = (value, index) => {
    const updateData = [...addedItemDetailsList];

    updateData.splice(index, 1);

    const updatedList = updateData.map((item, idx) => {
      const newSrNo = idx + 1;

      const { newCode, newLogCode } = incrementLogCodeForAdd(
        newSrNo,
        actualLogData?.log_no,
        code
      );
      setIsCalculated(false);
      return {
        ...item,
        item_sr_no: newSrNo,
        code: newCode,
        log_no_code: newLogCode,
      };
    });

    setAddedItemDetailsList(updatedList);
  };
  const updateRow = (values, index) => {
    const updateData = [...addedItemDetailsList];

    updateData.splice(index, 1, values);
    setAddedItemDetailsList(updateData);
    setData(null);
    setIsCalculated(false);
    // handleCalculate();
  };
  const handleCalculatePhysicalCMT = (
    physicalLength,
    physicalDia,
    // formula = "A",
    setFieldValue
  ) => {
    if (formula === "A") {
      let physicalCMT =
        Number(((Number(physicalLength) * Number(physicalDia) * Number(physicalDia)) /
          16).toFixed(3));
      setFieldValue("crosscut_cmt", physicalCMT);
    }
    if (formula === "B") {
      let physicalCMT =
        Number(((Number(physicalLength) * Number(physicalDia) * Number(physicalDia)) /
          16).toFixed(3));
      setFieldValue("crosscut_cmt", physicalCMT);
    }
  };
  const checkLength = (value, sr_no, setFieldValue) => {
    const filteredLength = addedItemDetailsList?.filter(
      (item) => item?.item_sr_no !== sr_no
    );

    const totalLength = filteredLength.reduce(
      (acc, item) => Number(acc) + Number(item?.length),
      0
    );

    if (
      totalLength + Number(value) >
      actualLogData?.available_quantity?.physical_length
    ) {
      let availableLength =
        actualLogData?.available_quantity?.physical_length - totalLength;
      setFieldValue("length", 0);
      return Swal.fire({
        timer: 3000,
        icon: "error",
        text: `Item length should be smaller than or equals to available (${availableLength.toFixed(2)}) length.`,
        position: "center",
        // background: theme.palette.background.paper,
      });
    }
  };

  // const handleCalculate = () => {
  //   const parsedPhysicalLength = Number(physicalLength);
  //   const totalLength = addedItemDetailsList.reduce(
  //     (acc, val) => acc + parseFloat(val?.length),
  //     0
  //   );
  //   const wastage = parsedPhysicalLength - totalLength;
  //   const availableLength = parsedPhysicalLength - wastage;
  //   const totalSqFactor = addedItemDetailsList.reduce(
  //     (acc, item) =>
  //       acc + (parseFloat(item?.crosscut_cmt) || 0) / availableLength,
  //     0
  //   );
  //   const totalAmount = actualLogData?.amount * totalSqFactor;
  //   console.log("totalSqm => ", totalLength);
  //   console.log("wastage ", wastage);
  //   console.log("sq factor", totalSqFactor);
  //   console.log("total => ", totalAmount);
  //   setIsCalculated(true);
  // };
  // const handleCalculate = () => {
  //   const parsedPhysicalSqm = parseFloat(actualLogData?.physical_cmt);

  //   const totalLength = addedItemDetailsList.reduce(
  //     (acc, val) => acc + (parseFloat(val?.length) || 0),
  //     0
  //   );
  //   const totalSqm = addedItemDetailsList.reduce(
  //     (acc, val) => acc + Number(val?.crosscut_cmt),
  //     0
  //   );

  //   const wastage = parsedPhysicalSqm - totalSqm;
  //   const outputSqm = parsedPhysicalSqm - wastage;
  //   console.log("sqm => ", outputSqm);
  //   const amountsForItems = addedItemDetailsList.map((item) => {
  //     const sqFactor = Number(item?.crosscut_cmt) / outputSqm;
  //     // const itemAmount = Number(actualLogData?.amount) * sqFactor;
  //     item.cost_amount = Number(actualLogData?.amount) * sqFactor;
  //     item.per_cmt_cost = Number(item.cost_amount) / Number(item?.crosscut_cmt);
  //     item.wastage_sqm = wastage;
  //     return item;
  //   });

  //   console.log("Total Length => ", totalLength);
  //   console.log("Wastage => ", wastage);
  //   console.log("Amounts for each item => ", amountsForItems);
  //   setIsCalculated(true);
  // };

  // const handlePerCmtAmount = (costAmount, cmt, setFieldValue) => {
  //   return setFieldValue("per_cmt_amount", Number(costAmount) / Number(cmt));
  // };

  return (
    <div>
      <Formik
        validateOnChange={false}
        initialValues={data}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          validateForm,
          setFieldValue,
          values,
          setFieldTouched,
          errors,
          initialValues,
          handleBlur,
          touched,
        }) => (
          <Form noValidate autoComplete="off">
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow
                    sx={{
                      bgcolor: "#7352C7",
                      color: "white",
                    }}
                  >
                    <TableCell
                      sx={{ ...tableHeaderCellStyle, minWidth: "40px" }}
                    >
                      Sr No.
                    </TableCell>
                    <TableCell
                      sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                    >
                      Machine Name
                    </TableCell>
                    <TableCell
                      sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                    >
                      Log No. Code
                    </TableCell>
                    <TableCell
                      sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                    >
                      Length
                    </TableCell>
                    <TableCell
                      sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                    >
                      Diameter
                    </TableCell>
                    <TableCell
                      sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                    >
                      Crosscut CMT
                    </TableCell>
                    {user_type == "ADMIN" && (
                      <TableCell
                        sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                      >
                        Cost Amount
                      </TableCell>
                    )}
                    {user_type == "ADMIN" && (
                      <TableCell
                        sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                      >
                        Per CMT Cost
                      </TableCell>
                    )}

                    <TableCell
                      sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}
                    >
                      Remark
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                        position: "sticky",
                        right: 0,
                        zIndex: 1,
                        bgcolor: "#7352C7",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                {addedItemDetailsList && addedItemDetailsList.length ? (
                  <TableBody>
                    {addedItemDetailsList &&
                      addedItemDetailsList?.map((row, i) =>
                        values?.item_sr_no !== row?.item_sr_no ? (
                          <TableRow key={i}>
                            <TableCell
                              className=""
                              sx={{ ...tableBodyCellStyle }}
                            >
                              {row?.item_sr_no}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row.machine_name}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row.log_no_code}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row?.length}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row?.girth}
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row.crosscut_cmt}
                            </TableCell>
                            {user_type == "ADMIN" && (
                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                {row?.cost_amount}
                              </TableCell>
                            )}
                            {user_type == "ADMIN" && (
                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                {row?.per_cmt_cost}
                              </TableCell>
                            )}
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              {row.remarks}
                            </TableCell>

                            <TableCell
                              sx={{
                                // textAlign: "center",
                                minWidth: "100%",
                                verticalAlign: "middle",
                                color: "white",
                                px: 1,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                bgcolor: "white",
                                display: "flex",
                              }}
                            >
                              <IconButton
                                color="warning"
                                size="small"
                                onClick={() => setData(row)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => removeRow(row, i)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              {values?.item_sr_no}
                            </TableCell>

                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <Autocomplete
                                autoSelect
                                autoHighlight
                                size="small"
                                options={machineList}
                                name="machine_name"
                                value={values?.machine_name}
                                isOptionEqualToValue={(option, value) =>
                                  option.machine_name === value ||
                                  option === value
                                }
                                getOptionLabel={(option) => {
                                  if (
                                    option instanceof Object &&
                                    !Array.isArray(option)
                                  ) {
                                    return option.machine_name;
                                  } else {
                                    return option;
                                  }
                                }}
                                onBlur={() =>
                                  setFieldTouched("machine_name", true)
                                }
                                onChange={(e, selectedData) => {
                                  setFieldValue(
                                    "machine_name",
                                    selectedData?.machine_name || ""
                                  );
                                  setFieldValue(
                                    "machine_id",
                                    selectedData?._id || ""
                                  );
                                }}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                  >
                                    {option.machine_name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={
                                      touched.machine_name &&
                                      Boolean(errors.machine_name)
                                    } // Show error if touched and there's an error
                                    helperText={
                                      touched.machine_name &&
                                      errors.machine_name
                                    }
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                disabled
                                value={values?.log_no_code}
                                sx={{ width: "100%" }}
                                name="log_no"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) =>
                                  setFieldValue("log_no", e.target.value)
                                }
                                error={touched?.log_no && errors?.log_no}
                                helperText={touched?.log_no && errors?.log_no}
                              />
                            </TableCell>

                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // disabled
                                type="number"
                                size="small"
                                value={values?.length}
                                sx={{ width: "100%" }}
                                name="length"
                                onBlur={(e) => {
                                  handleBlur(e);

                                  handleCalculatePhysicalCMT(
                                    e.target.value,
                                    values?.girth,
                                    // values?.log_formula,
                                    setFieldValue
                                  );
                                  checkLength(
                                    e.target.value,
                                    values?.item_sr_no,
                                    setFieldValue
                                  );
                                  setIsCalculated(false);
                                }}
                                onChange={(e) =>
                                  setFieldValue("length", e.target.value)
                                }
                                error={touched?.length && errors?.length}
                                helperText={touched?.length && errors?.length}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                type="number"
                                disabled
                                size="small"
                                value={values?.girth}
                                sx={{ width: "100%" }}
                                name="girth"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  handleCalculatePhysicalCMT(
                                    values?.length,
                                    e.target.value,
                                    // values?.log_formula,
                                    setFieldValue
                                  );
                                  setIsCalculated(false);
                                }}
                                onChange={(e) =>
                                  setFieldValue("girth", e.target.value)
                                }
                                error={touched?.girth && errors?.girth}
                                helperText={touched?.girth && errors?.girth}
                              />
                            </TableCell>
                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                disabled
                                type="number"
                                size="small"
                                value={values?.crosscut_cmt}
                                sx={{ width: "100%" }}
                                name="crosscut_cmt"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                  // handleCalculatePhysicalCMT(
                                  //   e.target.value,
                                  //   values?.physical_diameter,
                                  //   values?.log_formula,
                                  //   setFieldValue
                                  // );
                                }}
                                onChange={(e) =>
                                  setFieldValue("crosscut_cmt", e.target.value)
                                }
                                error={
                                  touched?.crosscut_cmt && errors?.crosscut_cmt
                                }
                                helperText={
                                  touched?.crosscut_cmt && errors?.crosscut_cmt
                                }
                              />
                            </TableCell>
                            {user_type == "ADMIN" && (
                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                <TextField
                                  disabled
                                  type="number"
                                  size="small"
                                  value={values?.cost_amount}
                                  sx={{ width: "100%" }}
                                  name="cost_amount"
                                  onBlur={async (e) => {
                                    handleBlur(e);
                                  }}
                                  onChange={(e) =>
                                    setFieldValue("cost_amount", e.target.value)
                                  }
                                  error={
                                    touched?.cost_amount && errors?.cost_amount
                                  }
                                  helperText={
                                    touched?.cost_amount && errors?.cost_amount
                                  }
                                />
                              </TableCell>
                            )}
                            {user_type == "ADMIN" && (
                              <TableCell sx={{ ...tableBodyCellStyle }}>
                                <TextField
                                  disabled
                                  type="number"
                                  size="small"
                                  value={values?.per_cmt_cost}
                                  sx={{ width: "100%" }}
                                  name="per_cmt_cost"
                                  onBlur={async (e) => {
                                    handleBlur(e);
                                  }}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "per_cmt_cost",
                                      e.target.value
                                    )
                                  }
                                  error={
                                    touched?.per_cmt_cost &&
                                    errors?.per_cmt_cost
                                  }
                                  helperText={
                                    touched?.per_cmt_cost &&
                                    errors?.per_cmt_cost
                                  }
                                />
                              </TableCell>
                            )}

                            <TableCell sx={{ ...tableBodyCellStyle }}>
                              <TextField
                                // type="number"
                                size="small"
                                value={values?.remarks}
                                sx={{ width: "100%" }}
                                name="remarks"
                                onBlur={async (e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) =>
                                  setFieldValue("remarks", e.target.value)
                                }
                                error={touched?.remarks && errors?.remarks}
                                helperText={touched?.remarks && errors?.remarks}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                // textAlign: "center",
                                minWidth: "100%",
                                verticalAlign: "middle",
                                color: "white",
                                px: 1,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                bgcolor: "white",
                                display: "flex",
                              }}
                            >
                              <IconButton
                                color="success"
                                size="small"
                                // onClick={() => updateRow(values, i)}
                                onClick={async () => {
                                  // Manually validate the form
                                  const formErrors = await validateForm();
                                  console.log("Validation errors:", formErrors); // Handle errors if needed
                                  // Check if there are no validation errors
                                  if (Object.keys(formErrors).length === 0) {
                                    updateRow(values, i); // Call updateRow with values and index
                                  } else {
                                    console.log(
                                      "Validation errors:",
                                      formErrors
                                    ); // Handle errors if needed
                                  }
                                }}
                              >
                                <TaskAltIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => {
                                  setData(initialData);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        Data Not Found !!
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditItemDetailsTable;
