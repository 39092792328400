// import axios from "axios";

// export const addSupplier = async (details) => {
//   try {
//     const config = {
//       withCredentials: true,
//       headers: {
//         withCredentials: true,
//       },
//     };
//     const data = await axios.post(
//       `${process.env.REACT_APP_URL}/supplier-master/add-supplier-master`,
//       details,
//       config
//     );
//     // console.log(data);
//     return data;
//   } catch (error) {
//     // console.log(error);
//     return error.response;
//   }
// };

import apiUrl from "app/utils/Axios.Config";

export const addSupplier = async (details) => {
  console.log("supplier data => ", details);

  const response = await apiUrl.post(
    "/supplier-master/add-supplier-master",
    details
  );
  return response;
};

export const addSupplierAndBranch = async (details, branchDetails) => {

  const response = await apiUrl.post(
    "/supplier-master/add-supplier-master-new",
    { supplier_name: details?.supplier_name, supplier_type: details?.supplier_type, branch_details: branchDetails }
  );
  return response;
};
