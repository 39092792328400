import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { EditOutlined } from "@mui/icons-material";
import { updateSupplier } from "app/services/apis/updateSupplier";
import { listItemCatgory } from "app/redux/actions/masterAction";
import Swal from "sweetalert2";

function ListItems({
  searchTerm,
  setPage,
  page,
  sort,
  sortBy,
  setSort,
  setSortBy,
  rowsPerPage,
}) {
  const { itemCategoryMaster, TotalPage, loading } = useSelector(
    (state) => state.masterReducer
  );
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    // setPage(1);
  };
  useEffect(() => {
    setLoader(loading);
  }, [loading]);
  const handleStatusChange = async (row) => {
    try {
      setLoader(true);
      const data = await updateSupplier(
        {
          status: row.status === "active" ? "inactive" : "active",
        },
        row._id
      );
      if (data?.status === 200) {
        dispatch(listItemCatgory("", "desc", "updated_at", 1));
        Swal.fire({
          icon: "success",
          title: "Status Updated",
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const handleItemAction = (menuItem) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case "edit":
        navigate(`/master/item-category/edit/${menuItem.id}`, {
          state: { rowData: menuItem.data },
        });
        break;
      case "delete":
        Swal.fire({
          title: `Are you sure you want to ${
            menuItem.data.status === "active" ? "Deactivate ?" : "Activate ?"
          }`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            handleStatusChange(menuItem.data);
          }
        });
        break;
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <>
      {loader && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
                "& .MuiTableCell-root": {},
              }}
            >
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  position: "sticky",
                  left: 0,
                  // height: "58px",
                  zIndex: 1,
                  bgcolor: "#7352C7",
                  padding: "0 10px",
                }}
              >
                <TableSortLabel
                  sx={{
                    //   maxWidth: "70px",
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                  active={sortBy === "sr_no"}
                  onClick={() => handleSort("sr_no")}
                >
                  Sr.No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "category"}
                  onClick={() => handleSort("category")}
                  sx={{
                    //   maxWidth: "70px",
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Category
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <TableSortLabel
                  active={sortBy === "calculate_unit"}
                  onClick={() => handleSort("calculate_unit")}
                  sx={{
                    //   maxWidth: "70px",
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Calculate Unit
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                {" "}
                <TableSortLabel
                  active={sortBy === "product_hsn_code"}
                  onClick={() => handleSort("product_hsn_code")}
                  sx={{
                    //   maxWidth: "70px",
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Product HSN Code
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                {" "}
                <TableSortLabel
                  active={sortBy === "userDetails.user_name"}
                  onClick={() => handleSort("userDetails.user_name")}
                  sx={{
                    //   maxWidth: "70px",
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                {" "}
                <TableSortLabel
                  active={sortBy === "createdAt"}
                  onClick={() => handleSort("createdAt")}
                  sx={{
                    //   maxWidth: "70px",
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  {" "}
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                  position: "sticky",
                  right: 0,
                  // height: "58px",
                  zIndex: 1,
                  bgcolor: "#7352C7",
                  padding: "0 10px",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemCategoryMaster?.length > 0 ? (
              itemCategoryMaster.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "40px",
                      verticalAlign: "middle",
                      // color: "white",
                      position: "sticky",
                      left: 0,
                      // height: "58px",
                      zIndex: 1,
                      // bgcolor: "#7352C7",
                      padding: "0 10px",
                    }}
                  >
                    {row.sr_no}
                  </TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>{row.calculate_unit}</TableCell>

                  <TableCell>{row.product_hsn_code}</TableCell>
                  <TableCell>
                    {row.userDetails?.user_name}
                  </TableCell>
                  <TableCell>
                    {new Date(row.createdAt).toLocaleDateString()}
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}
                  >
                    <JumboDdMenu
                      icon={<MoreHoriz />}
                      menuItems={
                        permissions?.item_category_master?.edit === true
                          ? [
                              {
                                icon: <EditOutlined />,
                                title: "Edit ",
                                action: "edit",
                                data: row,
                                id: row._id,
                              },

                              // {
                              //   icon: <DeleteOutlineOutlined />,
                              //   title: "Delete",
                              //   action: "delete",
                              //   data: row,
                              // },
                            ]
                          : [{ title: "No Actions" }]
                      }
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{
                  color: "white",
                  "& .MuiTableCell-root": {
                    py: 2,
                  },
                }}
              >
                <TableCell colSpan={10} align="center">
                  No Data Found...!!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {/* {TotalPage > 0 && (
                    <TablePagination
                        component="div"
                        count={TotalPage}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        // onRowsPerPageChange={handleRowsPerPageChange}
                        rowsPerPageOptions={""}
                    />
                )} */}

        <Pagination
          size="small"
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1,
          }}
        />
      </TableContainer>
    </>
  );
}

export default ListItems;
