import { Autocomplete, Box, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { inwardSrnoDropdown, itemSrnoDropdown } from '../../../../../../services/apis/Inventory/ApiCall';
import { itemNameDropDown } from 'app/services/apis/addItemName';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { supplierDropDownList } from 'app/services/apis/supplierList';
import { otherGoodsInwardSrnoDropdownApi } from 'app/services/apis/Inventory/otherGoods';
// import { otherGoodsInwardSrnoDropdownApi } from 'app/services/apis/Inventory/OtherGoods/constantsApi';
// import { otherGoodsInwardSrnoDropdownApi, otherGoodsItemSrnoDropdownApi } from 'app/services/apis/Inventory/OtherGoods/constantApi';

const datepickerStyling = {
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#aeb7bd ", // or any other color you prefer
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#475259", // Change to the color you want on hover
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#7352C7", // Change to the color you want when focused
    },
    "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
    {
        borderColor: "#7352C7", // Keep the same color as focused when hovered
    },
    "& .MuiInputLabel-outlined": {
        color: "#98a3aa", // Default label color
        "&.Mui-focused": {
            color: "#7352C7", // Label color on focus
        },
    },
};


export const InwardSrNo = React.memo(({ value, setFilters }) => {
    const [inwardSrno, setInwardSrno] = useState([]);

    useEffect(() => {
        (async () => {
            const { data } = await inwardSrnoDropdown(otherGoodsInwardSrnoDropdownApi);
            setInwardSrno(data);
        })()
    }, [])

    return (
        <Autocomplete
            autoSelect
            autoHighlight
            key={value}
            sx={{ width: "100%", textTransform: "capitalize" }}
            size="small"
            id="role-autocomplete"
            value={value || null}
            options={inwardSrno || []}
            getOptionLabel={(option) => option?.toString()}
            onChange={(e, newValue) => {
                setFilters((prev) => ({ ...prev, 'inward_sr_no': newValue }));
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option}
                </Box>
            )}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Inward Sr.no" />}
        />
    )
})


export const SupplierList = React.memo(({ name, value, setFilters }) => {
    const [suppliers, setSuppliers] = useState([]);
    // console.log("first", 3)

    useEffect(() => {
        (async () => {
            const { data: { result } } = await supplierDropDownList("OTHER GOODS");
            setSuppliers(result);
        })()
    }, [])

    return (
        <Autocomplete
            autoSelect
            autoHighlight
            key={value}
            sx={{ width: "100%", textTransform: "capitalize" }}
            size="small"
            id="role-autocomplete"
            value={value || null}
            options={suppliers || []}
            getOptionLabel={(option) => {
                if (option instanceof Object && !Array.isArray(option)) {
                    return option?.supplier_name
                } else {
                    return option
                }
            }}
            isOptionEqualToValue={(option, value) => option?.supplier_name === value}
            onChange={(e, newValue) => {
                setFilters((prev) => ({ ...prev, [name]: newValue?.supplier_name }));
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option?.supplier_name}
                </Box>
            )}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Suppliers" />}
        />
    )
})

export const CustomDatePicker = React.memo(({ name, position, title, value, setFilters }) => {
    // console.log("first", 4, value, dayjs(value, "DD/MM/YYYY").startOf('day'))
    return (

        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                key={value}
                id={title}
                format="DD/MM/YYYY"
                name={title}
                label={title}
                sx={datepickerStyling}
                maxDate={dayjs()}
                value={value ? dayjs(value, "YYYY-MM-DD") : null}   // Use YYYY-MM-DD format
                onChange={(newValue) => {
                    if (newValue) {
                        let newDateValue = dayjs(newValue).format("YYYY-MM-DD"); // Only keep date, ignore time
                        newDateValue = new Date(newDateValue);
                        if (!isNaN(new Date(newDateValue))) {
                            if (position === 'from') {
                                newDateValue.setUTCHours(0, 0, 0, 0); // Set to midnight for 'from'
                            } else {
                                newDateValue.setUTCHours(23, 59, 59, 999); // Set to end of the day for 'to'
                            }

                            setFilters((prev) => {
                                const updatedData = { ...prev };
                                updatedData.range.date[name][position] = newDateValue.toISOString(); // Store ISO string without time consideration
                                return updatedData;
                            });
                        }
                    }
                }}
                slotProps={{ textField: { size: "small" } }}
            />

        </LocalizationProvider>

    )
})