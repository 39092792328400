import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { Axios } from "index"; // Assuming Axios is properly configured
import { LoadingButton } from "@mui/lab";
import FormTextField1 from "app/components/InputField/FormTextField1";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  series_name: yup
    .string("Enter Series Name")
    .required("Series Name is required"),
  remark: yup.string("Enter reamrks"),
});

function SeriesModal({ open, handleClose, seriesData, setToggle }) {
  const initialValues = {
    series_name: seriesData?.data?.series_name
      ? seriesData?.data?.series_name
      : "",
    remark: seriesData?.data?.remark ? seriesData?.data?.remark : "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let res;
      values.series_name = values.series_name.toUpperCase();

      if (seriesData) {
        res = await Axios.post(
          `/series-master/update-series/${seriesData.id}`,
          values
        );
      } else {
        res = await Axios.post("/series-master/add-series", values);
      }

      if (res.data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          text: seriesData
            ? "Series Updated Successfully"
            : "Series Created Successfully",
          timer: 2000,
          showConfirmButton: false,
        });
        setToggle((prevState) => !prevState);
        handleClose();
      }
      // else {
      //   handleClose();
      //   Swal.fire({
      //     icon: "error",
      //     title: res.data.message,
      //     timer: 1000,
      //     showConfirmButton: false,
      //   });
      // }
    } catch (error) {
      // handleClose();
      handleClose();
      Swal.fire({
        icon: "error",
        text: error?.response?.data.message,
        timer: 2000,
        // showConfirmButton: false,
      });
      setSubmitting(false)
      console.log("Error adding/updating series => ", error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <Box sx={style} className="lg:w-1/3 w-[20rem]">
        <div className="flex flex-row items-center justify-between">
          <Typography variant="h2">
            {seriesData ? "Edit Series" : "Add Series"}
          </Typography>
          <Close onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, isSubmitting }) => (
            <Form noValidate>
              <div className="grid grid-flow-row lg:grid-cols-2 grid-cols-1 gap-5 mt-5">
                <FormTextField1 name="series_name" label="Series Name*" />
                <FormTextField1 name="remark" label="Remark" />
              </div>

              <div className="flex items-center justify-center mt-5">
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  {seriesData ? "Update" : "Add"}
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default SeriesModal;
