import AddItemModal from "./addNewItemTable";

import EditItemDetailsTable from "./EditItemTable";

const ItemDetailsTable = ({
  setAddedItemDetailsList,
  departmentList,
  machineList,
  addedItemDetailsList,
  itemNameList,
  itemSubCategoryNameList,
  finalData,
  itemDetailsListTableLoading,
  initialItemForm, setInitailItemForm
  // getMachineList
}) => {
  return (
    <div>
      <AddItemModal
        itemNameList={itemNameList}
        itemSubCategoryNameList={itemSubCategoryNameList}
        setAddedItemDetailsList={setAddedItemDetailsList}
        addedItemDetailsList={addedItemDetailsList}
        finalData={finalData}
        departmentList={departmentList}
        initialItemForm={initialItemForm}
        setInitailItemForm={setInitailItemForm}
      // machineList={machineList}
      // getMachineList={getMachineList}
      />
      <EditItemDetailsTable
        itemNameList={itemNameList}
        itemSubCategoryNameList={itemSubCategoryNameList}
        setAddedItemDetailsList={setAddedItemDetailsList}
        addedItemDetailsList={addedItemDetailsList}
        finalData={finalData}
        itemDetailsListTableLoading={itemDetailsListTableLoading}
        departmentList={departmentList}
      // machineList={machineList}
      // getMachineList={getMachineList}
      />
    </div>
  );
};

export default ItemDetailsTable;
