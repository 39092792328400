export const plywoodItemSrnoDropdownApi = "/plywood-inventory/item-srno-dropdown";
export const plywoodInwardSrnoDropdownApi = "/plywood-inventory/inward-srno-dropdown";

//Approval
export const fetchPlywoodApprovalApi = `/approval/plywood-approval-invoice-listing`;
export const fetchPlywoodItemsApprovalApi = function (id, invoice_id) {
  return `/approval/plywood-approval-item-listing-by-invoice/${id}/${invoice_id}`;
};
export const plywoodApproveInvoiceApi = function (id, invoice_id) {
  return `/approval/plywood-approve_invoice_details/${id}/${invoice_id}`;
};
export const plywoodRejectInvoiceApi = function (id, invoice_id) {
  return `/approval/plywood-reject_invoice_details/${id}/${invoice_id}`;
};