import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Axios } from "index";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogInventory } from "app/redux/actions/Inventory/LogInventory/logDispatch";
import { hideLoading, setLoading } from "app/redux/actions/Loading";
import InfoIcon from "@mui/icons-material/Info";
import { ApprovalStatusTableCell } from "app/components/ApprovalStatusTableCell";
import { formatAmount } from "app/utils/formatAmount";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const tableBodyCell = { textAlign: "left", px: 1 };

const tableRowBodySticky = {
  textAlign: "center",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "white",
  width: "50px",
};

const LogTableRow = ({ e, allFilterState, selectedIds, setSelectedIds, setItemDetailsForModal, handleOpenDetailModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);

  const handleCheckLogId = function (event, id) {
    const checked = event.target.checked;
    if (checked) {
      setSelectedIds((prev) => [...prev, id]);
    } else {
      setSelectedIds((prev) => {
        const restOfIds = prev?.filter((e) => e !== id);
        return restOfIds;
      });
    }
  };

  const handleIsssueForCrosscutting = async function (logId) {
    try {
      dispatch(setLoading());
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const issueForCrosscutting = await Axios.post(
        "/log-inventory/issue_for_crosscutting",
        {
          log_items_ids: logId,
        },
        config
      );

      if (issueForCrosscutting.status === 200) {
        Swal.fire({
          title: "Issued for crosscutting",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(fetchLogInventory(allFilterState));
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleIsssueForFlitching = async function (logId) {
    try {
      dispatch(setLoading());
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const issueForFliching = await Axios.post(
        "/log-inventory/issue_for_flitching",
        {
          log_items_ids: logId,
        },
        config
      );

      if (issueForFliching.status === 200) {
        Swal.fire({
          title: "Issued for flitching",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(fetchLogInventory(allFilterState));
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleItemAction = (menuItem) => {
    const logId = menuItem?.row?._id;
    switch (menuItem.action) {
      case "edit":
        navigate("/inventory/log/edit", { state: { data: menuItem?.row } });
        break;
      case "issueForCrosscutting":
        Swal.fire({
          text: "Are you sure you want to Issue for Crosscut?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            handleIsssueForCrosscutting([logId]);
          }
        });
        break;
      case "issueForFlitching":
        Swal.fire({
          text: "Are you sure you want to Issue for Flitching?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            handleIsssueForFlitching([logId]);
          }
        });
        break;
      default:
        navigate("/inventory/log");
    }
  };

  return (
    <TableRow key={e?._id}>
      <TableCell sx={{ ...tableBodyCell, textAlign: "center" }}>
        <Checkbox {...label} size="small" checked={selectedIds.includes(e?._id)} onChange={(event) => handleCheckLogId(event, e?._id)} />
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_invoice_details?.inward_sr_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{moment(e?.log_invoice_details?.inward_date).format("DD/MM/YYYY") || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_invoice_details?.invoice_Details?.invoice_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.item_sr_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_invoice_details?.supplier_details?.company_details?.supplier_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.supplier_item_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.supplier_log_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.item_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.item_sub_category_name || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_formula || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_no || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.invoice_length || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.invoice_diameter || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.invoice_cmt || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.indian_cmt || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.physical_length || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.physical_diameter || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.physical_cmt || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.log_invoice_details?.currency || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.exchange_rate || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.rate_in_currency || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{formatAmount(e?.rate_in_inr) || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{formatAmount(Math.round(e?.amount)) || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{formatAmount(Math.round(e?.expense_amount)) || "-"}</TableCell>
      <TableCell sx={{ ...tableBodyCell }}>{e?.remark || "-"}</TableCell>
      <ApprovalStatusTableCell approval_status={e?.log_invoice_details?.approval_status} />
      <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky, right: "50px" }}>
        <InfoIcon
          sx={{ "&:hover": { cursor: "pointer", color: "black" } }}
          onClick={() => {
            setItemDetailsForModal(e || {});
            handleOpenDetailModal(e);
          }}
        />
      </TableCell>
      <TableCell sx={{ ...tableBodyCell, ...tableRowBodySticky }}>
        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <EditIcon />,
              title: "Edit",
              action: "edit",
              show:
                permissions?.department_master?.edit === true &&
                e?.log_invoice_details?.isEditable &&
                !e?.log_invoice_details?.approval_status?.sendForApproval?.status
                  ? true
                  : false,
              row: e,
            },
            {
              icon: <AddCircleOutlineIcon />,
              title: "Issue For Crosscutting",
              action: "issueForCrosscutting",
              show: !e?.log_invoice_details?.approval_status?.sendForApproval?.status,
              row: e,
            },
            {
              icon: <AddCircleOutlineIcon />,
              title: "Issue For Flitching",
              action: "issueForFlitching",
              show: !e?.log_invoice_details?.approval_status?.sendForApproval?.status,
              row: e,
            },
          ].filter((ele) => ele?.show)}
          onClickCallback={handleItemAction}
        />
      </TableCell>
    </TableRow>
  );
};

export default LogTableRow;
