import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SettingsIcon from "@mui/icons-material/Settings";
import { Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const tableHeaderCellStyle = {
  textAlign: "left",
  fontWeight: 400,
  verticalAlign: "center",
  color: "white",
  padding: "5px 5px",
};
const tableHeaderSortableLabelStyle = {
  color: "white",
  "&:hover": { color: "white" },
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white", // Set the color for the active state
  },
};
const tableBodyCellStyle = {
  textAlign: "left",
  fontWeight: 400,
  verticalAlign: "middle",
  padding: "5px 5px",
  width: "100%",
};

export default function ListUserTable({ searchTerm, page, setPage, sort, sortBy, setSort, setSortBy }) {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { allUsers, TotalPage, loading } = useSelector((state) => state.userReducer);

  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/user/edit", { state: menuItem.data });
        break;
      case "configure":
        navigate("/user/change-password", { state: menuItem.data });
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
              }}
            >
              <TableCell
                sx={{
                  ...tableHeaderCellStyle,
                  minWidth: "120px",
                  maxWidth: "170px",
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                  bgcolor: "#7352C7",
                }}
              >
                <TableSortLabel
                  active={sortBy === "user_name"}
                  direction={sort}
                  onClick={() => handleSort("user_name")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  User Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  ...tableHeaderCellStyle,
                }}
              >
                <TableSortLabel
                  active={sortBy === "user_type"}
                  direction={sort}
                  onClick={() => handleSort("user_type")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  ...tableHeaderCellStyle,
                  minWidth: "150px",
                }}
              >
                <TableSortLabel
                  active={sortBy === "first_name"}
                  direction={sort}
                  onClick={() => handleSort("first_name")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  First Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  ...tableHeaderCellStyle,
                  minWidth: "150px",
                }}
              >
                <TableSortLabel
                  active={sortBy === "last_name"}
                  direction={sort}
                  onClick={() => handleSort("last_name")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  Last Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  ...tableHeaderCellStyle,
                  minWidth: "150px",
                }}
              >
                <TableSortLabel
                  active={sortBy === "dept_name"}
                  direction={sort}
                  onClick={() => handleSort("dept_name")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  Department
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "role_id.role_name"}
                  direction={sort}
                  onClick={() => handleSort("role_id.role_name")}
                  sx={{
                    ...tableHeaderSortableLabelStyle,
                  }}
                >
                  Role
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "approver_user_name"}
                  direction={sort}
                  onClick={() => handleSort("approver_user_name")}
                  sx={{
                    ...tableHeaderSortableLabelStyle,
                  }}
                >
                  Approver
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle }}>
                <TableSortLabel
                  active={sortBy === "gender"}
                  direction={sort}
                  onClick={() => handleSort("gender")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  Gender
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle }}>
                <TableSortLabel
                  active={sortBy === "age"}
                  direction={sort}
                  onClick={() => handleSort("age")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  Age
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "120px" }}>DOB</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle }}>
                <TableSortLabel
                  active={sortBy === "country"}
                  direction={sort}
                  onClick={() => handleSort("country")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  Country
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle }}>
                <TableSortLabel
                  active={sortBy === "state"}
                  direction={sort}
                  onClick={() => handleSort("state")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  State
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "city"}
                  direction={sort}
                  onClick={() => handleSort("city")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  City
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle }}>
                <TableSortLabel
                  active={sortBy === "pincode"}
                  direction={sort}
                  onClick={() => handleSort("pincode")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  Pincode
                </TableSortLabel>
              </TableCell>
              <TableCell active={sortBy === "mobile_no"} sx={{ ...tableHeaderCellStyle, minWidth: "150px" }}>
                Phone No
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "80px" }}>
                <TableSortLabel
                  active={sortBy === "email_id"}
                  direction={sort}
                  onClick={() => handleSort("email_id")}
                  sx={{ ...tableHeaderSortableLabelStyle }}
                >
                  Email ID
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "180px" }}>Remark</TableCell>
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "created_employee_id.user_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.user_name")}
                  sx={{
                    ...tableHeaderSortableLabelStyle,
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>

              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "140px" }}>Created Date</TableCell>
              {/* <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "140px" }}>Updated Date</TableCell> */}
              <TableCell sx={{ ...tableHeaderCellStyle, minWidth: "80px" }}>
                <TableSortLabel
                  active={sortBy === "status"}
                  direction={sort}
                  onClick={() => handleSort("status")}
                  sx={{
                    ...tableHeaderSortableLabelStyle,
                  }}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                  bgcolor: "#7352C7",
                  padding: "0 10px",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allUsers?.map((row, i) => (
              <TableRow key={i}>
                <TableCell
                  sx={{
                    ...tableBodyCellStyle,
                    position: "sticky",
                    left: 0,
                    zIndex: 1,
                    bgcolor: "white",
                  }}
                >
                  {row?.user_name}
                </TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.user_type}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.first_name}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.last_name}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.dept_name}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle, maxWidth: "80px" }}>{row?.role_id.role_name || "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle, maxWidth: "80px" }}>{row?.approver_user_name || "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle, maxWidth: "80px" }}>
                  {row?.gender !== "Select" && row?.gender !== "" ? row?.gender : "-"}
                </TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.age || "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.dob ? moment(row?.dob).format("DD/MM/YYYY") : "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.country !== "Select" && row?.country !== "" ? row?.country : "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.state || "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.city || "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.pincode || "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>
                  {row?.mobile_no !== "" ? `${row?.country_code} ${row?.mobile_no}` : "-"}
                </TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.email_id}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.user_remarks ? row?.user_remarks : "-"}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{row?.created_employee_id?.user_name}</TableCell>
                <TableCell sx={{ ...tableBodyCellStyle }}>{moment(row?.created_at).format("DD/MM/YYYY") || "-"}</TableCell>
                {/* <TableCell sx={{ ...tableBodyCellStyle }}>{moment(row?.updated_at).format("DD/MM/YYYY") || "-"}</TableCell> */}
                <TableCell sx={{ ...tableBodyCellStyle, color: row?.status === true ? "#26d145" : "red" }}>
                  {row?.status === false ? "INACTIVE" : "ACTIVE"}
                </TableCell>
                <TableCell
                  sx={{
                    ...tableBodyCellStyle,
                    position: "sticky",
                    right: 0,
                    zIndex: 1,
                    bgcolor: "white",
                  }}
                >
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={
                      permissions.user?.edit == true
                        ? [
                          {
                            icon: <EditIcon />,
                            title: "Edit User Details",
                            action: "edit",
                            data: row,
                          },
                          {
                            icon: <SettingsIcon />,
                            title: "Change Password",
                            action: "configure",
                            data: row,
                          },
                        ]
                        : [{ title: "No Actions" }]
                    }
                    onClickCallback={handleItemAction}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          size="small"
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1,
          }}
        />
      </TableContainer>
    </>
  );
}
