import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";

import { TextField, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Axios } from "index";
import ToastAlerts from "app/pages/extensions/sweetalert/components/ToastAlerts";
import Swal from "sweetalert2";
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

function EditContactPersonModal({
  open,
  handleClose,
  onAddContact,
  values,
  id,
  setToggle,
}) {
  const [contact, setContact] = useState({});


  useEffect(() => {
    if (open && id) {
      const fetchData = async () => {
        const res = await Axios.get(`/supplier-master/contact-person/${id}`);
        setContact(res?.data?.result);
        console.log("cotact data => ", res.data.result);
      };
      fetchData();
    } else if (open && !id) {
      setContact({
        name: "",
        email: "",
        designation: "",
        mobile_number: "",
      });
    }
  }, [id, open]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    try {
      const { data } = await Axios.post(
        `/supplier-master/update-contact-person?id=${id}`,
        contact
      );

      if (data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: "Contact Person Updated Successfully",
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
        setToggle((prevState) => !prevState);
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: data.message,
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.log("err updating person details => ", error.message);
      handleClose();
      Swal.fire({
        icon: "error",
        text:
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="lg:w-1/3 w-[20rem]">
          <div className="flex flex-row items-center justify-between mb-2">
            <Typography variant="h2" component="h2">
              {" "}
              {id ? "Update Contact Person" : "Add Contact Person"}
            </Typography>
            <Close
              onClick={handleClose}
              className="mb-4"
              style={{ cursor: "pointer", float: "right" }}
            />
          </div>
          <div className="grid grid-flow-row items-center grid-cols-2 gap-5 ">
            <div className="flex flex-col items-start gap-1">
              <Typography>Name*</Typography>
              <TextField
                size="small"
                fullWidth
                name="name"
                value={contact?.name}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col items-start gap-1">
              <Typography>Email</Typography>
              <TextField
                size="small"
                fullWidth
                name="email"
                value={contact?.email}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col items-start gap-1">
              <Typography>Designation</Typography>
              <TextField
                size="small"
                fullWidth
                name="designation"
                value={contact?.designation}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col items-start gap-1">
              <Typography>Mobile Number</Typography>
              <TextField
                size="small"
                fullWidth
                name="mobile_number"
                value={contact?.mobile_number}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex items-center justify-center mt-5">
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleUpdate}
            >
              Update
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default EditContactPersonModal;
