export const fleeceItemSrnoDropdownApi = "/fleece-inventory/item-srno-dropdown";
export const fleeceInwardSrnoDropdownApi =
  "/fleece-inventory/inward-srno-dropdown";
export const fleeceAddInventoryApi = "/fleece-inventory/add-inventory";
export const fleeceListingApi = "/fleece-inventory/list-inventory";
export const fleeceDownloadCsvApi = "/fleece-inventory/download-excel-fleece";
export const fleeceItemListingByInvoice = function (invoice_id) {
  return `/fleece-inventory/fleece-item-listing-by-invoice/${invoice_id}`;
};
export const fleeceEditInvoiceItemInventory = function (invoice_id) {
  return `/fleece-inventory/edit-invoice-item-inventory/${invoice_id}`;
};

//Approval
export const fetchFleeceApprovalApi = `/approval/fleece-approval-invoice-listing`;
export const fetchFleeceItemsApprovalApi = function (id, invoice_id) {
  return `/approval/fleece-approval-item-listing-by-invoice/${id}/${invoice_id}`;
};
export const fleeceApproveInvoiceApi = function (id, invoice_id) {
  return `/approval/fleece-approve_invoice_details/${id}/${invoice_id}`;
};
export const fleeceRejectInvoiceApi = function (id, invoice_id) {
  return `/approval/fleece-reject_invoice_details/${id}/${invoice_id}`;
};
