import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import { logApprovalFilter } from "app/utils/constants/sessionFilter";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LogApprovalTableRow from "../Components/LogApprovalTableRow";
import { fetchLogApproval } from "app/redux/Approval/LogApproval";

const tableHeadCellSx = {
  textAlign: "left",
  minWidth: "100px",
  verticalAlign: "middle",
  px: 1,
  color:"white"
};

const tableHeadSortSx = {
  color: "white",
  "&:hover": { color: "white" },
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white !important",
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important", // Set the color for the sorting icon
  },
  "&:hover": {
    color: "white !important", // Set the color when hovering
  },
};

const tableRowHeadSticky = {
  textAlign: "left",
  minWidth: "95px",
  verticalAlign: "middle",
  color: "white",
  px: 1,
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "#7352C7",
};

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const LogApprovalListing = ({ allFilterState, setAllFilterState }) => {
  const logApprovalReducer = useSelector(
    (state) => state?.logApprovalReducer
  );
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState([]);
  const sort = allFilterState?.sort;
  const sortBy = allFilterState?.sortBy;
  const page = allFilterState?.page;

  const handleSort = function (name) {
    const filter = {
      ...allFilterState,
      sort: allFilterState.sort === "desc" ? "asc" : "desc",
      sortBy: name,
      page: 1,
    };
    setAllFilterState(filter);
    dispatch(fetchLogApproval(filter));
    sessionStorage.setItem(logApprovalFilter, JSON.stringify(filter));
  };

  const handleChangePage = (event, newPage) => {
    const filter = {
      ...allFilterState,
      page: newPage,
    };
    setAllFilterState(filter);
    dispatch(fetchLogApproval(filter));
    sessionStorage.setItem(logApprovalFilter, JSON.stringify(filter));
  };


  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
                "& .MuiTableCell-root": {
                  // py: 2,
                },
              }}
            >
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "inward_sr_no"}
                  direction={sort}
                  onClick={(e) =>
                    handleSort("inward_sr_no")
                  }
                  sx={{ ...tableHeadSortSx }}
                >
                  Inward Sr No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "inward_date"}
                  direction={sort}
                  onClick={(e) => handleSort("inward_date")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Inward Date
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={
                    sortBy === "invoice_Details.invoice_no"
                  }
                  direction={sort}
                  onClick={(e) =>
                    handleSort("invoice_Details.invoice_no")
                  }
                  sx={{ ...tableHeadSortSx }}
                >
                  Invoice No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "110px" }}>
                <TableSortLabel
                  active={sortBy === "invoice_Details.invoice_date"}
                  direction={sort}
                  onClick={(e) => handleSort("invoice_Details.invoice_date")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Invoice Date
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "180px" }}>
                <TableSortLabel
                  active={
                    sortBy === "supplier_details.company_details.supplier_name"
                  }
                  direction={sort}
                  onClick={(e) =>
                    handleSort("supplier_details.company_details.supplier_name")
                  }
                  sx={{ ...tableHeadSortSx }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "180px" }}>
                <TableSortLabel
                  active={sortBy === "supplier_details.branch_detail.branch_name"}
                  direction={sort}
                  onClick={(e) => handleSort("supplier_details.branch_detail.branch_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Supplier Branch Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "invoice_Details.invoice_value_with_gst"}
                  direction={sort}
                  onClick={(e) => handleSort("invoice_Details.invoice_value_with_gst")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Amount
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "totalExpenseAmount"}
                  direction={sort}
                  onClick={(e) => handleSort("totalExpenseAmount")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Expense Amount
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "invoice_Details.remark"}
                  direction={sort}
                  onClick={(e) => handleSort("invoice_Details.remark")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Remark
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                Approval Status
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "user.user_name"}
                  direction={sort}
                  onClick={(e) => handleSort("user.user_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Edited By
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, ...tableRowHeadSticky }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logApprovalReducer?.data &&
              logApprovalReducer?.data?.length > 0 ? (
              logApprovalReducer?.data?.map((e, i) => {
                return (
                  <LogApprovalTableRow
                    key={e?._id}
                    e={e}
                    allFilterState={allFilterState}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                  />
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={14}
                  sx={{ textAlign: "center", py: 2, fontSize: 18 }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          size="medium"
          count={logApprovalReducer?.totalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1,
          }}
        />
      </TableContainer>
    </>
  );
};

export default React.memo(LogApprovalListing);
