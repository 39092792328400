import {
  PLYWOOD_INVENTORY_FAILED,
  PLYWOOD_INVENTORY_REQUEST,
  PLYWOOD_INVENTORY_SUCCESS,
} from "app/redux/actions/Inventory/plywood/constants";

const INIT_STATE = {
  loading: false,
  totalPage: 0,
  data: [],
  error: {
    isError: false,
    message: null,
  },
};

export const plywoodInventoryReducer = function (state = INIT_STATE, action) {
  switch (action?.type) {
    case PLYWOOD_INVENTORY_REQUEST:
      return {
        loading: true,
        totalPage: 0,
        data: [],
        error: {
          isError: false,
          message: null,
        },
      };
    case PLYWOOD_INVENTORY_SUCCESS:
      return {
        loading: false,
        data: action?.payload?.data,
        totalPage: action?.payload?.totalPage,
        error: {
          isError: false,
          message: null,
        },
      };
    case PLYWOOD_INVENTORY_FAILED:
      const { isError = true, message = "Something Went Wrong" } =
        action?.payload?.error || {};
      return {
        loading: false,
        totalPage: 0,
        data: [],
        error: {
          isError: isError,
          message: message,
        },
      };
    default:
      return state;
  }
};
