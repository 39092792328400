import Div from "@jumbo/shared/Div/Div";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Button, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { getAllRoles } from "app/redux/actions/roleAction";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListRoleTable from "./roletable";
import { handleLogs } from "app/components/Function/logsDownloadFunction";
import { LoadingButton } from "@mui/lab";
import HeaderTitleHoc from "app/components/HeaderTitleHoc";
import { departmentNameDropDown } from "app/services/apis/department_list";
import FilterAccordian from "app/components/FilterAccordian";

function ListRole() {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("updated_at");
  const [logLoader, setLogLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);
  const [filters, setFilters] = useState(null);
  const [resetInputField, setResetInputField] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);

  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllRoles(value, sort, sortBy, 1, filters));
  };

  const handleFilter = () => {
    console.log(filters);
    sessionStorage.setItem("roleMasterFilter", JSON.stringify({ page, filters }));
    setPage(1);
    dispatch(getAllRoles(searchTerm, sort, sortBy, 1, filters));
  };

  const handleClear = () => {
    setFilters(null);
    setResetInputField((prev) => !prev);
    setSearchTerm("");
    sessionStorage.removeItem("roleMasterFilter");
    dispatch(getAllRoles("", sort, sortBy, page, {}));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(getAllRoles(searchTerm, sort, sortBy, page, filters));
    departmentNameDropDown("")
      .then((response) => {
        if (response.data.success) {
          setDepartmentList(response.data.result);
        }
      })
      .catch((error) => console.error("Error fetching supplier list:", error));
  }, [sort, page]);

  useEffect(() => {
    const allKeys = Object.keys(sessionStorage);
    const filterKeys = allKeys.filter((key) => key.includes("Filter"));

    console.log(filterKeys, "76666");

    let retrieveFilter = sessionStorage.getItem("roleMasterFilter");
    if (retrieveFilter) {
      let parseFilterData = JSON.parse(retrieveFilter);
      setFilters(parseFilterData?.filters);
      setPage(parseFilterData?.page);
      dispatch(getAllRoles(searchTerm, sort, sortBy, parseFilterData?.page, parseFilterData?.filters));
    }
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      {/* <Typography variant="h1">Role Master</Typography> */}
      <FilterAccordian
        actions={
          <Div>
            <Button variant="contained" size="small" sx={{ marginRight: 1 }} onClick={handleFilter}>
              Apply
            </Button>

            <Button variant="outlined" size="small" onClick={handleClear}>
              Clear
            </Button>
          </Div>
        }
      >
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item xs={6} md={4} lg={3} xl={2}>
            <Autocomplete
              autoSelect
              autoHighlight
              size="small"
              options={departmentList}
              name="dept_name"
              getOptionLabel={(option) => {
                if (option instanceof Object && !Array.isArray(option)) {
                  return option?.dept_name;
                } else {
                  return option;
                }
              }}
              // value={values?.dept_name}
              value={departmentList.find((dept) => dept?.dept_name === filters?.dept_name) || null}
              onChange={(e, newValue) => {
                setFilters((pre) => ({ ...pre, dept_name: newValue?.dept_name }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Department"
                  sx={{
                    width: "100%",
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </FilterAccordian>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px 0",
        }}
      >
        <TextField
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          size="small"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value == "") {
              setSort("desc");
              setSortBy("updated_at");
              dispatch(getAllRoles("", "desc", "updated_at", 1));
            }
          }}
          sx={{ width: 300 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        <Div>
          {/* {permissions?.role_view == true && (
            <LoadingButton
              variant="contained"
              sx={{
                mr: 2,
                p: 1,
                pl: 4,
                pr: 4,
              }}
              loading={logLoader}
              onClick={() => handleLogs("role/roles-logs", "roles")}
            >
              Log
            </LoadingButton>
          )} */}

          {permissions?.role?.create == true && (
            <Button variant="contained" size="small" onClick={() => navigate("/roles/add")}>
              Add Role
            </Button>
          )}
        </Div>
      </Div>
      <ListRoleTable
        searchTerm={searchTerm}
        page={page}
        setPage={setPage}
        sort={sort}
        sortBy={sortBy}
        setSort={setSort}
        setSortBy={setSortBy}
      />
    </Div>
  );
}

export default HeaderTitleHoc(ListRole, "Roles & Permissions");
